import React, { ReactElement } from 'react';
import CustomLink from '../commons/CustomLink';

export default function TestDeviceLink({ uuid, title }: { uuid: string; title?: string }): ReactElement {
    return (
        <CustomLink
            to={`/test-devices/${uuid}`}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
            onMouseUp={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
        >
            {title || uuid}
        </CustomLink>
    );
}
