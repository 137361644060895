import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
    ErrorOutlineOutlined as ErrorIcon,
    ReportProblemOutlined as WarningIcon,
    SkipNextOutlined as SkipIcon,
    Flag as FlagIcon,
} from '@mui/icons-material';
import { Box, Chip, useTheme } from '@mui/material';
import { selectBuildDetailsResultMessagesCount } from './buildDetailsSlice';

export default function BuildMessagesChips(): ReactElement {
    const theme = useTheme();
    const messagesCount = useSelector(selectBuildDetailsResultMessagesCount);

    return (
        <Box
            sx={{
                minWidth: '10%',
                flexWrap: 'wrap',
                '& > *': {
                    margin: (theme) => theme.spacing(0, 0, 0, 2),
                },
                display: 'flex',
                justifyContent: 'flex-end',
                fontWeight: 'bold',
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                },
            }}
            data-testid='build-details-messages'
        >
            {messagesCount.error > 0 && (
                <Chip
                    title='Total Amount Of Errors'
                    label={messagesCount.error}
                    icon={<ErrorIcon />}
                    sx={{
                        backgroundColor: 'transparent',
                        '.MuiChip-icon': {
                            color: (theme) => theme.palette.error.main,
                        },
                    }}
                    data-testid='build-details-messages-error'
                />
            )}
            {messagesCount.warning > 0 && (
                <Chip
                    title='Total Amount Of Warnings'
                    label={messagesCount.warning}
                    icon={<WarningIcon />}
                    sx={{
                        backgroundColor: 'transparent',
                        '.MuiChip-icon': {
                            color: (theme) => theme.palette.warning.main,
                        },
                    }}
                    data-testid='build-details-messages-warning'
                />
            )}
            {messagesCount.flag > 0 && (
                <Chip
                    title='Total Amount Of Precompiler Directives'
                    label={messagesCount.flag}
                    icon={<FlagIcon />}
                    sx={{
                        backgroundColor: 'transparent',
                        '.MuiChip-icon': {
                            color: (theme) => theme.palette.error.dark,
                        },
                    }}
                    data-testid='build-details-messages-flag'
                />
            )}
            {messagesCount.skipped > 0 && (
                <Chip
                    title='Total Amount Of Skipped Steps'
                    label={messagesCount.skipped}
                    icon={<SkipIcon />}
                    sx={{
                        backgroundColor: 'transparent',
                        '.MuiChip-icon': {
                            color: (theme) => theme.palette.info.main,
                        },
                    }}
                    data-testid='build-details-messages-skipped'
                />
            )}
        </Box>
    );
}
