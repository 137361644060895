import { IToggleColumn } from '../commons/interfaces';
import { ScheduledBuildsColumnId } from './enums';

export const scheduledBuildColumns: Array<IToggleColumn<ScheduledBuildsColumnId>> = [
    { id: ScheduledBuildsColumnId.name, label: 'Game', align: 'left', width: '15%', visible: true, disabled: false },
    {
        id: ScheduledBuildsColumnId.branch,
        label: 'Branch',
        align: 'left',
        width: '15%',
        visible: true,
        disabled: false,
    },
    {
        id: ScheduledBuildsColumnId.description,
        label: 'Description',
        align: 'left',
        width: '15%',
        visible: true,
        disabled: false,
    },
    { id: ScheduledBuildsColumnId.cron, label: 'Cron', align: 'left', width: '20%', visible: true, disabled: false },
    {
        id: ScheduledBuildsColumnId.platform,
        label: 'Platform',
        align: 'left',
        width: '30%',
        visible: true,
        disabled: false,
    },
    {
        id: ScheduledBuildsColumnId.messages,
        label: 'Messages',
        align: 'center',
        width: '150px',
        visible: true,
        disabled: false,
    },
];
