import { ReactElement } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material';
import { tableMinWidth } from '../../buildAgents/BuildAgents';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { ITestSuiteDetails } from '../interfaces';
import { getTestSuiteCases } from '../utils';
import { testSuiteTestCaseListColumns } from './testSuiteTestCaseListColumns';
import TestSuiteTestCaseRow from './TestSuiteTestCaseRow';

export default function TestSuiteTestCaseList({ testSuite }: { testSuite: ITestSuiteDetails }): ReactElement {
    const testCases = getTestSuiteCases(testSuite);
    return (
        <>
            <Typography
                variant='h6'
                sx={{ m: 2, color: ({ palette }) => palette.tactile.blueGray[700], fontSize: '16px' }}
            >
                Test Cases ({testCases.length})
            </Typography>
            <TableContainer>
                <Table stickyHeader aria-label='test cases table' sx={{ minWidth: tableMinWidth }}>
                    <TableHead data-testid='test-case-table-head'>
                        <TableHeaderRow>
                            {testSuiteTestCaseListColumns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    size='small'
                                    style={{
                                        width: column.width,
                                        minWidth: column.minWidth,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody data-testid='test-case'>
                        {testCases?.map((testCase) => (
                            <TestSuiteTestCaseRow
                                key={`${testCase.parentTest._id}-${testCase.id || testCase.testName}`}
                                testSuiteType={testSuite.type}
                                testCase={testCase}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
