import React, { ReactElement } from 'react';
import {
    CancelRounded as CanceledIcon,
    WarningRounded as FailedIcon,
    QuestionMark as InconclusiveIcon,
    PlaylistPlay as QueuedIcon,
    Check as SuccessIcon,
    AccessTime as WaitingIcon,
} from '@mui/icons-material';
import { Box, Chip, CircularProgress } from '@mui/material';
import { ETestStatus } from '../tests/interfaces';
import { ITestSuiteResultsCount } from './interfaces';

export function TestSuiteResults({
    results,
    detailed,
}: {
    results: ITestSuiteResultsCount;
    detailed?: boolean;
}): ReactElement | null {
    return (
        <Box>
            {results.waiting > 0 && (
                <TestStatusChip
                    status={ETestStatus.waiting}
                    label={detailed ? detailedStatusLabel(results.waiting, 'Waiting') : results.waiting}
                    noLabel={results.waiting === 0}
                />
            )}
            {results.running > 0 && (
                <TestStatusChip
                    status={ETestStatus.running}
                    label={detailed ? detailedStatusLabel(results.running, 'Running') : results.running}
                    noLabel={results.running === 0}
                />
            )}
            {results.queued > 0 && (
                <TestStatusChip
                    status={ETestStatus.queued}
                    label={detailed ? detailedStatusLabel(results.queued, 'Queued') : results.queued}
                    noLabel={results.queued === 0}
                />
            )}
            {results.cancelled > 0 && (
                <TestStatusChip
                    status={ETestStatus.cancelled}
                    label={detailed ? detailedStatusLabel(results.cancelled, 'Cancelled') : results.cancelled}
                />
            )}
            {results.success > 0 && (
                <TestStatusChip
                    status={ETestStatus.success}
                    label={detailed ? detailedStatusLabel(results.success, 'Passed') : results.success}
                />
            )}
            {results.inconclusive > 0 && (
                <TestStatusChip
                    status={ETestStatus.inconclusive}
                    label={detailed ? detailedStatusLabel(results.inconclusive, 'Inconclusive') : results.inconclusive}
                />
            )}
            {results.failed > 0 && (
                <TestStatusChip
                    status={ETestStatus.failed}
                    label={detailed ? detailedStatusLabel(results.failed, 'Failed') : results.failed}
                />
            )}
        </Box>
    );
}

export function TestStatusChip({
    status,
    label,
    noLabel,
}: {
    status: ETestStatus;
    label?: string | number;
    noLabel?: boolean;
}): ReactElement {
    const icon = statusIcon(status);
    return (
        <Chip
            sx={{
                textTransform: 'capitalize',
                fontWeight: 500,
                mr: 0.5,
                maxHeight: 28,
                ...(noLabel && {
                    '.MuiChip-label': {
                        paddingLeft: 0,
                    },
                }),
                ...(status === ETestStatus.waiting && {
                    background: (theme) => theme.palette.tactile.blueGray[300],
                    color: (theme) => theme.palette.tactile.blueGray[800],
                    '& .MuiChip-icon': {
                        color: (theme) => theme.palette.tactile.blueGray[800],
                    },
                }),
                ...(status === ETestStatus.queued && {
                    background: (theme) => theme.palette.warning.main,
                    color: (theme) => theme.palette.warning.contrastText,
                    '& .MuiChip-icon': {
                        color: (theme) => theme.palette.warning.contrastText,
                    },
                }),
                ...(status === ETestStatus.running && {
                    background: (theme) => theme.palette.tactile.blue[700],
                    color: (theme) => theme.palette.common.white,
                    '& .MuiChip-icon': {
                        color: (theme) => theme.palette.common.white,
                    },
                }),
                ...(status === ETestStatus.cancelled && {
                    background: (theme) => theme.palette.tactile.blueGray[300],
                    color: (theme) => theme.palette.tactile.blueGray[800],
                    '& .MuiChip-icon': {
                        color: (theme) => theme.palette.tactile.blueGray[800],
                    },
                }),
                ...(status === ETestStatus.success && {
                    background: (theme) => theme.palette.success.main,
                    color: (theme) => theme.palette.success.contrastText,
                    '& .MuiChip-icon': {
                        color: (theme) => theme.palette.success.contrastText,
                    },
                }),
                ...(status === ETestStatus.failed && {
                    background: (theme) => theme.palette.error.main,
                    color: (theme) => theme.palette.error.contrastText,
                    '& .MuiChip-icon': {
                        color: (theme) => theme.palette.error.contrastText,
                    },
                }),
                ...(status === ETestStatus.inconclusive && {
                    background: (theme) => theme.palette.warning.main,
                    color: (theme) => theme.palette.warning.contrastText,
                    '& .MuiChip-icon': {
                        color: (theme) => theme.palette.warning.contrastText,
                    },
                }),
            }}
            icon={icon}
            label={noLabel ? undefined : label || status}
        />
    );
}

function statusIcon(status: ETestStatus): React.ReactElement {
    switch (status) {
        case ETestStatus.waiting:
            return <WaitingIcon sx={{ fontSize: 18 }} />;
        case ETestStatus.finishing:
            return <CircularProgress size={18} sx={{ color: 'inherit' }} />;
        case ETestStatus.queued:
            return <QueuedIcon sx={{ fontSize: 18 }} />;
        case ETestStatus.cancelled:
            return <CanceledIcon sx={{ fontSize: 18 }} />;
        case ETestStatus.running:
            return <CircularProgress size={18} sx={{ color: 'inherit' }} />;
        case ETestStatus.success:
            return <SuccessIcon sx={{ fontSize: 18 }} />;
        case ETestStatus.failed:
            return <FailedIcon sx={{ fontSize: 18 }} />;
        case ETestStatus.inconclusive:
            return <InconclusiveIcon sx={{ fontSize: 18 }} />;
    }
}

function detailedStatusLabel(testsCount: number, status: string): string {
    return `${testsCount} Test${testsCount > 1 ? 's' : ''} ${status}`;
}
