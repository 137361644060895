import { ReactElement } from 'react';
import { TableRow, TableRowProps } from '@mui/material';

export default function TableHeaderRow({ sx, children, ...props }: TableRowProps): ReactElement {
    return (
        <TableRow
            sx={{
                ':last-of-type .MuiTableCell-root': {
                    background: (theme) => theme.palette.surface.main,
                    borderTop: (theme) => `1px solid ${theme.palette.border.light}`,
                    borderBottom: (theme) => `1px solid ${theme.palette.border.light}`,
                    textTransform: 'unset',
                    py: 1,
                    borderWidth: 1,
                },
                '.MuiTableCell-root:first-of-type': {
                    pl: { xs: 2, sm: 4 },
                },
                ...sx,
            }}
            {...props}
        >
            {children}
        </TableRow>
    );
}
