import { ReactElement, useMemo } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import { ETestDeviceState } from '../../testDevices/interfaces';
import TestDeviceState from '../../testDevices/TestDeviceState';
import { ITestAgent } from '../interfaces';

export default function TestAgentStateCell({
    testAgent,
    align,
}: {
    testAgent: ITestAgent;
    align?: MuiAlignSetting;
}): ReactElement {
    const state: ETestDeviceState = useMemo(() => {
        if (testAgent.offline) {
            return ETestDeviceState.offline;
        }
        const isRunning = testAgent.devices.find(
            (testDevice) => testDevice.state === ETestDeviceState.running || testDevice.state === ETestDeviceState.done,
        );
        return isRunning ? ETestDeviceState.running : ETestDeviceState.idle;
    }, [testAgent]);

    return (
        <TableCell align={align}>
            <TestDeviceState state={state} />
        </TableCell>
    );
}
