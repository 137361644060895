import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
} from '@mui/material';
import { AppThunkDispatch } from '../../store';
import isAddressValid from '../commons/isAddressValid';
import { registerAccelerator, selectAcceleratorDialogOpen, toggleRegisterAcceleratorDialog } from './acceleratorSlice';
import { IAcceleratorsSlice } from './interfaces';

export default function RegisterAcceleratorDialog(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IAcceleratorsSlice>>();
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const open = useSelector(selectAcceleratorDialogOpen);

    const handleSetAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    const handleClose = () => {
        setError('');
        setLoading(false);
        dispatch(toggleRegisterAcceleratorDialog());
    };

    const handleSubmit = () => {
        setLoading(true);
        setError('');

        if (!isAddressValid(address)) {
            setError('Invalid Address');
            setLoading(false);
            return;
        }

        registerAcceleratorRequest();
    };

    const registerAcceleratorRequest = async () => {
        const [ip, port] = address.split(':');

        const { payload } = await dispatch(registerAccelerator({ ip, port: parseInt(port) }));
        if (payload.accelerator) {
            dispatch(toggleRegisterAcceleratorDialog());
        } else {
            setError(
                payload?.error?.message ? payload.error.message : 'There was an error registering the accelerator',
            );
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ my: -1 }}>Register Accelerator Service</DialogTitle>
            <Divider sx={{ my: 1 }} />
            <DialogContent>
                <TextField
                    sx={{ width: '100%', mb: 1 }}
                    autoFocus
                    fullWidth
                    value={address}
                    onChange={handleSetAddress}
                    label='Address'
                    placeholder='192.168.2.xx:700x'
                    type='text'
                    variant='outlined'
                />
                {error && <Alert severity='error'>{error}</Alert>}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ mt: -1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Button onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant='contained'
                        disabled={!address || loading}
                        color='primary'
                        style={{ minWidth: '80px' }}
                    >
                        {loading ? <CircularProgress size={15} variant='indeterminate' /> : 'Register'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
