import { ReactElement, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, Table, TableHead, TableCell } from '@mui/material';
import ContentLoading from '../commons/ContentLoading';
import HeaderSection from '../commons/HeaderSection';
import NoResultsFound from '../commons/NoResultsFound';
import Page from '../commons/Page';
import TableBody from '../commons/TableBody';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import TablePagination from '../commons/TablePagination';
import { openSocket } from '../sockets';
import TestsFilters, { columnFilters } from './filters/TestsFilters';
import TestLoadingRows from './row/TestLoadingRows';
import TestRow from './row/TestRow';
import TestActionBox from './TestActionBox';
import { useGetTestsQuery } from './testsApi';
import {
    cleanupTestsFilter,
    initTestsFilter,
    selectTestsColumns,
    selectTestsFilters,
    setTestsFilter,
} from './testsSlice';

export default function TestsList(): ReactElement {
    const dispatch = useDispatch();
    const columns = useSelector(selectTestsColumns);
    const filters = useSelector(selectTestsFilters);
    const { data, isFetching, refetch } = useGetTestsQuery(filters, { skip: !filters });

    const { tests, totalCount } = data || { tests: [], totalCount: 0 };
    const isLoading = isFetching;

    useEffect(() => {
        if (!filters) {
            dispatch(initTestsFilter());
        }
    }, [dispatch, filters]);

    useEffect(() => {
        return () => {
            dispatch(cleanupTestsFilter());
        };
    }, [dispatch]);

    const handleChangePage = (_event: unknown, newPage: number) => {
        dispatch(setTestsFilter({ page: newPage }));
    };

    useEffect(() => {
        const socket = openSocket('tests');
        socket.on('new-test', refetch);

        return () => {
            socket.off('new-test', refetch);
        };
    }, [refetch]);

    return (
        <Suspense fallback={<ContentLoading />}>
            <Page>
                <HeaderSection title='Tests' actionBox={<TestActionBox />} />
                <Paper
                    data-testid='tests'
                    sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
                >
                    <TestsFilters />
                    <TableContainer sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Table
                            stickyHeader
                            aria-label='tests table'
                            sx={{ width: '100%', tableLayout: 'fixed', minWidth: 1600 }}
                        >
                            <TableHead data-testid='tests-table-head'>
                                <TableHeaderRow>
                                    {columns.map((column, i) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            size='small'
                                            sx={{
                                                width: column.width,
                                                minWidth: column.minWidth,
                                                display: column.visible ? undefined : 'none',
                                                px: { xs: i ? 1 : 2, sm: i ? 1 : 4 },
                                            }}
                                        >
                                            <Box>
                                                {column.label}
                                                {columnFilters[column.id]?.({ showOnlyValues: false })}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableHeaderRow>
                            </TableHead>
                            {(isLoading || tests.length > 0) && (
                                <TableBody data-testid='tests-table-body'>
                                    {isLoading ? (
                                        <TestLoadingRows rows={10} />
                                    ) : (
                                        tests.map((test) => (
                                            <TestRow
                                                key={test._id}
                                                test={test}
                                                columns={columns.filter((column) => column.visible)}
                                            />
                                        ))
                                    )}
                                </TableBody>
                            )}
                        </Table>
                        {!isLoading && !tests.length && (
                            <NoResultsFound text="We couldn't find any tests with the selected filters" />
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={totalCount}
                        rowsPerPage={50}
                        page={Number(filters?.page || 0)}
                        onPageChange={handleChangePage}
                        data-testid='tests-table-pagination'
                    />
                </Paper>
            </Page>
        </Suspense>
    );
}
