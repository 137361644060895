import { useEffect, useMemo } from 'react';
import { openSocket } from '../sockets';
import { ITestDevice } from '../testDevices/interfaces';
import { ETestStatus, ITest } from '../tests/interfaces';
import { ITestSuiteListItem } from './interfaces';
import { useGetTestSuitesQuery } from './testSuitesApi';
import { getTestsQueuePerDevice } from './utils';

export type TestSuitesQueue = Map<string, { queue: number; testSuites: ITestSuiteListItem[] }>;

export function useTestSuitesQueue(): TestSuitesQueue {
    const { data, refetch } = useGetTestSuitesQuery({
        status: [ETestStatus.queued, ETestStatus.running, ETestStatus.waiting, ETestStatus.finishing],
    });

    useEffect(() => {
        const socket = openSocket('tests');

        socket.on('update-test-suite', refetch);

        return () => {
            socket.off('update-test-suite', refetch);
        };
    }, [refetch]);

    return useMemo(() => getTestsQueuePerDevice(data?.testSuites), [data?.testSuites]);
}

export function addTestSuitesQueueInfo(
    testDevices: ITestDevice[] | undefined,
    testQueue: TestSuitesQueue,
    runningTests: ITest[],
): ITestDevice[] | undefined {
    return testDevices?.map((testDevice) => ({
        ...testDevice,
        queueLength: testQueue.get(testDevice._id)?.queue,
        testSuiteRunning: runningTests.find((test: ITest) => test.testDevice?._id === testDevice._id)?.testSuite,
    }));
}
