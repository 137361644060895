import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Chip, Link, Paper } from '@mui/material';
import { blue, red } from '@tactileentertainment/core-designsystem';
import HeaderSection from '../../commons/HeaderSection';
import NoResultsFound from '../../commons/NoResultsFound';
import Page from '../../commons/Page';
import { useGetGameModuleQuery } from '../gameModulesApi';
import { formatDate } from '../utils';
import GameModuleProjectsTable from './GameModuleProjectsTable';

export default function GameModuleDetails(): ReactElement {
    const { moduleName = '' } = useParams<{ moduleName: string }>();
    const { data: module, isLoading } = useGetGameModuleQuery({ moduleName }, { skip: !moduleName });
    const decodedModuleName = decodeURIComponent(moduleName);
    const lastSynced = module?.versionUpdatedAt ? formatDate(module.versionUpdatedAt) : '…';

    return (
        <Page>
            <HeaderSection
                title={decodedModuleName}
                titleAdornment={
                    module?.homepage ? (
                        <Link target='_blank' href={module.homepage} sx={{ ml: 1.5, display: 'flex' }}>
                            <OpenInNewIcon fontSize='small' />
                        </Link>
                    ) : undefined
                }
                subTitle={lastSynced ? `Last modified ${lastSynced}` : undefined}
                breadcrumbs={[{ text: 'Game Modules', href: '/modules' }, { text: decodedModuleName }]}
                helmetTitle={decodedModuleName}
            />
            <>
                {module?.primary ? (
                    <Chip
                        sx={{
                            ml: 4,
                            mb: 1,
                            background: blue[200],
                            color: blue[700],
                            width: '10%',
                        }}
                        size='small'
                        label='Primary'
                    ></Chip>
                ) : undefined}
                {module?.deprecated ? (
                    <Chip
                        sx={{ ml: 4, mb: 2, background: red[200], color: red[700], width: '10%' }}
                        size='small'
                        label='Deprecated'
                    ></Chip>
                ) : undefined}
            </>
            <Paper data-testid='game-module-details' sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                {(module || isLoading) && <GameModuleProjectsTable module={module} isLoading={isLoading} />}
                {!module && !isLoading && (
                    <NoResultsFound text='Nothing found, please try again.' sx={{ width: '100%' }} />
                )}
            </Paper>
        </Page>
    );
}
