import { IBuildResult } from '../buildDetails/interfaces';
import { EBuildStatus } from '../commons/enums';
import { IBuild } from '../commons/interfaces';
import { ITestProject } from './interfaces';

export function canOrderTestOnBuild(
    build: IBuild,
    buildResult: IBuildResult | undefined,
    projects: ITestProject[] | undefined,
): boolean {
    return Boolean(
        build.kind === 'build' &&
            build.status !== EBuildStatus.failed &&
            build.status !== EBuildStatus.cancelled &&
            (build.status !== EBuildStatus.finished || buildResult?.mainArtifact) &&
            projects?.find((project) => project.projectName?.toLowerCase() === build?.projectName.toLowerCase()),
    );
}
