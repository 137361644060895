import { ReactElement } from 'react';
import { IconButton, SxProps } from '@mui/material';
import { SVGIcon, SVGIcons } from '../../assets/SVGIcon';
import { stringify } from './queryString';

export default function DatadogLink({ url, sx }: { url: string; sx?: SxProps }): ReactElement {
    return (
        <IconButton
            size='small'
            title='View in Datadog'
            href={url}
            target='_blank'
            sx={sx}
            onClick={(event) => event.stopPropagation()}
            onMouseUp={(event) => event.stopPropagation()}
        >
            <SVGIcon icon={SVGIcons.datadog} />
        </IconButton>
    );
}

export function buildInfrastructureUrl(hostname: string, tags?: string[], tab?: string): string {
    const tagsEncoded = encodeURIComponent(tags?.join(',') || '');
    return `https://app.datadoghq.com/infrastructure?page_size=100&tags=${tagsEncoded}&tab=${tab}host=${hostname}`;
}

export function buildLogsUrl(
    query: Record<string, string>,
    columns?: string[],
    fromDate?: string,
    toDate?: string,
): string {
    const queryString = Object.entries(query)
        .map(([key, value]) => `${key}:${value}`)
        .join(' ');
    const queryParams = {
        query: queryString,
        cols: columns?.join(','),
        from_ts: fromDate ? new Date(fromDate).getTime() : undefined,
        to_ts: toDate ? new Date(toDate).getTime() : undefined,
        live: fromDate || toDate ? 'false' : undefined,
    };
    return `https://app.datadoghq.com/logs?${stringify(queryParams)}`;
}
