import React, { ReactElement } from 'react';
import { Box, SxProps } from '@mui/material';
import { CopyButton } from '@tactileentertainment/core-designsystem';

export interface ICopyToClipboardBoxProps {
    children: React.ReactNode;
    value: string;
    sx?: SxProps;
    sxButton?: SxProps;
}

export default function CopyToClipboardBox({ children, value, sx, sxButton }: ICopyToClipboardBoxProps): ReactElement {
    return (
        <Box sx={sx}>
            {children}
            <CopyButton
                value={value}
                isSquared
                variant='text'
                size='small'
                color='secondary'
                sx={{ ml: 0.5, mr: 0, ...sxButton }}
                onMouseUp={(event) => event.stopPropagation()}
            />
        </Box>
    );
}
