import React, { ReactElement } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

export default function SubscribeToTestResults({
    subscribed,
    setSubscribed,
}: {
    subscribed: boolean;
    setSubscribed: (subscribed: boolean) => void;
}): ReactElement {
    return (
        <FormControl style={{ flexGrow: 1, marginLeft: 16 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        size='small'
                        checked={subscribed}
                        onChange={(event: React.ChangeEvent<any>) => setSubscribed(event.target.checked)}
                    />
                }
                label='Notify results to the project slack channel'
            />
        </FormControl>
    );
}
