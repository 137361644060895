import { ReactElement, useEffect, useState } from 'react';
import { Box, Card } from '@mui/material';

const paddingWidth = 20;

interface BuildLiveGraphProps {
    embedId: string;
    hostname: string;
    width: number;
    height: number;
    env: 'staging' | 'production';
}

export default function BuildLiveStatsGraph({
    embedId,
    width,
    height,
    hostname,
    env,
}: BuildLiveGraphProps): ReactElement {
    const [graphWidth, setGraphWidth] = useState<number>(width);

    function updateWidthSize() {
        const cardWidth = document.getElementById('build-live-stats-graph')?.offsetWidth;
        const newWidth = (cardWidth ? cardWidth - paddingWidth : 0) || width;
        setGraphWidth(newWidth);
    }

    const uri = `https://app.datadoghq.com/graph/embed?token=${embedId}&height=${height}&width=${graphWidth}&legend=true`;

    useEffect(() => {
        updateWidthSize();
        window.addEventListener('resize', updateWidthSize);
        return () => {
            window.removeEventListener('resize', updateWidthSize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            id='build-live-stats-graph'
            data-testid='test-build-live-stats-graph'
            sx={{
                overflow: 'auto',
                boxShadow: 'none',
                border: '1px solid',
                borderColor: (theme) => theme.palette.grey[200],
                p: 1,
                maxWidth: 500,
                mx: 'auto',
            }}
            onClick={() => {
                const url = `https://app.datadoghq.com/dashboard/g45-q5x-hzj?tpl_var_host=${hostname}&tpl_var_env=${env}&live=true`;
                window.open(url, '_blank');
            }}
        >
            <Box>
                <iframe title={`grapgh-${embedId}`} src={uri} width='100%' height={height} frameBorder='0'></iframe>
            </Box>
        </Card>
    );
}
