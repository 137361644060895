import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { ToastProvider, createCustomTheme } from '@tactileentertainment/core-designsystem';
import '@tactileentertainment/core-designsystem/styles/global.css';
import { AuthProvider } from '@tactileentertainment/core-shared.auth-react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createStore } from './store';

const store = createStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const theme = createCustomTheme();

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <HelmetProvider data-rh='true'>
                    <AuthProvider
                        domain={process.env.REACT_APP_AUTH_DOMAIN || ''}
                        clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ''}
                        audience='https://tactile.build'
                        permissionScope='btc'
                        baseApiUrl={`${process.env.REACT_APP_API_URL}/auth`}
                        managementDashboard={process.env.REACT_APP_AUTH_DASHBOARD}
                    >
                        <App />
                    </AuthProvider>
                    <ToastProvider limit={10} options={{ duration: 10000 }} />
                </HelmetProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
);
