import { ReactElement } from 'react';
import BuildLiveStatsGraph from './BuildLiveStatsGraph';
import { BuildGraphItem } from './BuildStats';
import BuildStatsSkeleton from './BuildStatsSkeleton';
import { useGetLiveGraphQuery } from './getBuildStatsGraphApi';
import { IBuildStatsParams } from './interfaces';

export default function BuildLiveStats(
    params: Omit<IBuildStatsParams, 'status' | 'stoppedAt'> & { env: 'staging' | 'production' },
): ReactElement {
    const { id, startedAt, hostname, env, width, height } = params;
    const start = Math.round(new Date(startedAt).getTime() / 1000);
    const { data, isLoading } = useGetLiveGraphQuery({ id, start, hostname });

    if (isLoading || !data?.embedIds.length) {
        return <BuildStatsSkeleton />;
    }

    return (
        <>
            {data?.embedIds.map((embedId, key) => (
                <BuildGraphItem key={key}>
                    <BuildLiveStatsGraph
                        embedId={embedId}
                        key={key}
                        width={width}
                        height={height}
                        hostname={hostname}
                        env={env}
                    />
                </BuildGraphItem>
            ))}
        </>
    );
}
