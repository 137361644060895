import React, { ReactElement, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import {
    Box,
    Checkbox,
    CircularProgress,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { blueGray, SearchField } from '@tactileentertainment/core-designsystem';
import { FilterOption, SelectedFilter } from './interfaces';
import useFilterOptions from './useFilterOptions';

interface ColumnFilterSelectorProps {
    id?: string;
    options: FilterOption[];
    selected?: SelectedFilter;
    loading?: boolean;
    onChange?: (value: string[] | null) => void;
}

const maxItems = 50;

export default function ColumnFilterSelector({
    id,
    options = [],
    selected = [],
    loading = false,
    onChange,
}: ColumnFilterSelectorProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const [searchText, setSearchText] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [disableFocus, setDisableFocus] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const open = Boolean(anchorEl);

    const selectedValues = useMemo(() => (selected || [])?.map((item) => String(item)), [selected]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPage(0);
        setAnchorEl(event.currentTarget);
        setDisableFocus(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchText('');
    };

    const filteredOptions = useFilterOptions({ options, searchText });
    const visibleOptions = useMemo(() => {
        const sortedSelectedOptions =
            filteredOptions.length > maxItems ? sortSelectedOptions(filteredOptions, selected) : filteredOptions;
        return sortedSelectedOptions.slice(0, (page + 1) * maxItems);
    }, [filteredOptions, page, selected]);

    return (
        <>
            <IconButton
                id={id}
                data-testid={id}
                sx={{ m: 0.5, p: 0.5, cursor: 'pointer', ':focus': { outline: `1px solid ${blueGray[700]}` } }}
                onClick={handleClick}
                disabled={loading}
                title='Column Filter'
                tabIndex={0}
            >
                {loading && <CircularProgress size={16} />}
                {!loading &&
                    (selectedValues.length ? (
                        <FilterAltRoundedIcon sx={{ color: blueGray[700], fontSize: 16 }} />
                    ) : (
                        <FilterAltOutlinedIcon sx={{ fontSize: 16 }} />
                    ))}
            </IconButton>
            <Popover
                id={!anchorEl ? undefined : 'builds-columns-popup'}
                onClose={handleClose}
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <SearchField
                    size='small'
                    placeholder='Search'
                    value={searchText || ''}
                    timeout={200}
                    sx={{
                        width: '100%',
                        fieldset: { borderRadius: '6px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
                    }}
                    onSearch={(text) => {
                        setSearchText(text as string);
                        setPage(0);
                    }}
                    inputRef={(input) => {
                        if (input != null && !disableFocus && !isMobile) {
                            setDisableFocus(true);
                            setTimeout(() => input.focus(), 100);
                        }
                    }}
                />
                <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => setPage(page + 1)}
                        hasMore={visibleOptions.length !== filteredOptions.length && open}
                        loader={
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={0}>
                                <CircularProgress size={16} />
                            </Box>
                        }
                        useWindow={false}
                    >
                        {visibleOptions.map((option, index) => {
                            const optionValue = String(option.value);
                            const isOptionSelected = !!selectedValues.includes(optionValue);

                            return (
                                <ListItemButton
                                    dense={true}
                                    sx={{ maxWidth: 400 }}
                                    key={`${optionValue}-${index}`}
                                    onClick={() => {
                                        if (isOptionSelected) {
                                            const newSelectedValue = selectedValues.filter(
                                                (item: string) => item !== optionValue,
                                            );
                                            onChange?.(newSelectedValue?.length ? newSelectedValue : null);
                                        } else {
                                            onChange?.([...selectedValues, String(optionValue)]);
                                        }
                                    }}
                                    data-option-index={index}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge='start'
                                            checked={isOptionSelected}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': String(option.label || '') }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<OptionHighlight text={option.label} searchKey={searchText} />}
                                    />
                                </ListItemButton>
                            );
                        })}
                        {!visibleOptions.length && (
                            <Typography
                                color='textSecondary'
                                sx={{ px: 1.5, py: 1, height: 44, display: 'flex', alignItems: 'center' }}
                            >
                                No Options
                            </Typography>
                        )}
                    </InfiniteScroll>
                </List>
            </Popover>
        </>
    );
}

const OptionHighlight = ({ text = '', searchKey = '' }: { text: string; searchKey: string }): ReactElement => {
    const searchKeyIndex = text.toLowerCase().indexOf(searchKey.toLowerCase());
    const searchKeyLength = searchKey.length;

    return (
        <span
            title={text}
            style={{ display: 'block', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
            {searchKeyIndex > -1 && text.slice(0, searchKeyIndex)}
            <strong>{text.slice(searchKeyIndex, searchKeyIndex + searchKeyLength)}</strong>
            {text.slice(searchKeyIndex + searchKeyLength)}
        </span>
    );
};

const sortSelectedOptions = (options: FilterOption[], selected: SelectedFilter): FilterOption[] => {
    const sortedSelectedOptions =
        options.length > maxItems
            ? options.slice().sort((a, b) => {
                  const selectedOptions = (selected || []) as string[];
                  const aIsSelected = selectedOptions.includes(String(a.value));
                  const bIsSelected = selectedOptions.includes(String(b.value));

                  if (aIsSelected && bIsSelected) {
                      return selectedOptions.indexOf(String(a.value)) < selectedOptions.indexOf(String(b.value))
                          ? -1
                          : 1;
                  }

                  if (aIsSelected) {
                      return -1;
                  }

                  return 1;
              })
            : options;

    return sortedSelectedOptions;
};
