import { ReactElement, useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Chip } from '@mui/material';
import { ETestDeviceState, ITestDevice } from '../interfaces';
import DeleteTestDeviceDialog from './dialogs/DeleteTestDeviceDialog';

export default function TestDeviceDelete({ testDevice }: { testDevice: ITestDevice }): ReactElement | null {
    const [open, setOpen] = useState<boolean>(false);

    if (testDevice?.state !== ETestDeviceState.offline) {
        return null;
    }
    if (testDevice?.isDeleted) {
        return (
            <Chip
                sx={{
                    borderRadius: 5,
                    fontWeight: 600,
                    color: (theme) => theme.palette.common.white,
                    maxHeight: 28,
                    textTransform: 'capitalize',
                    '.MuiChip-icon': {
                        fontSize: 18,
                        color: (theme) => theme.palette.common.white,
                    },
                    backgroundColor: (theme) => theme.palette.tactile.red[500],
                }}
                color='primary'
                icon={<DeleteIcon />}
                label='Deleted'
            />
        );
    }
    return (
        <>
            <Button onClick={() => setOpen(true)} color='error' size='small'>
                <DeleteIcon fontSize='small'></DeleteIcon>
            </Button>
            <DeleteTestDeviceDialog testDevice={testDevice} open={open} handleClose={() => setOpen(false)} />
        </>
    );
}
