import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { LazyLog, ScrollFollow } from 'react-lazylog';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
    ExpandMore as ExpandMoreIcon,
    CheckCircle as CheckCircleIcon,
    ErrorOutlineOutlined as ErrorIcon,
    Help as HelpIcon,
    SkipNextOutlined as SkipIcon,
    ShortText as CommandIcon,
    Clear as CancelledIcon,
} from '@mui/icons-material';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    CircularProgress,
    Typography,
    Switch,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    useMediaQuery,
} from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import coy from 'react-syntax-highlighter/dist/cjs/styles/prism/coy';
import { AppThunkDispatch } from '../../store';
import MessagesIcons from '../messages/MessagesIcons';
import MessageTableRow from '../messages/MessageTableRow';
import {
    getBuildResultArtifactContent,
    preventAndStopClick,
    selectBuildDetailsResultSteps,
    setBuildResultTabAccordion,
    isTabAccordionActive,
} from './buildDetailsSlice';
import DownloadArtifactBuildDetailsButton from './DownloadArtifactBuildDetailsButton';
import { StepState } from './enums';
import { IStep } from './interfaces';

export const ARTIFACT_LINE_HEIGHT = 19;

export default function StepsAccordion(): ReactElement {
    const steps = useSelector(selectBuildDetailsResultSteps);

    return (
        <Box data-testid='build-details-steps-accordion'>
            {steps?.length ? (
                steps.map((step, index) => <AccordionStep key={index} step={step} />)
            ) : (
                <Typography
                    variant='body1'
                    color='textSecondary'
                    align='center'
                    gutterBottom
                    data-testid='build-details-steps-accordion-no-steps'
                >
                    This build has no steps
                </Typography>
            )}
        </Box>
    );
}

function StatusIcon({ status }: { status?: StepState }): ReactElement {
    switch (status) {
        case StepState.done:
            return <CheckCircleIcon sx={{ color: (theme) => theme.palette.success.main }} />;
        case StepState.failed:
            return <ErrorIcon sx={{ color: (theme) => theme.palette.error.main }} />;
        case StepState.running:
            return <CircularProgress variant='indeterminate' size={20} />;
        case StepState.skipped:
            return <SkipIcon sx={{ color: (theme) => theme.palette.info.main }} />;
        case StepState.cancelled:
            return <CancelledIcon />;

        default:
            return <HelpIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
    }
}

export function AccordionStep({ step }: { step: IStep }): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const ref = useRef<HTMLDivElement | null>(null);
    const [expanded, setExpanded] = useState(false);
    const [followLog, setFollowLog] = useState(true);
    const [loadingContent, setLoadingContent] = useState(false);
    const [content, setContent] = useState('');
    const [manuallyExpanded, setManuallyExpanded] = useState(expanded);
    const [contentLines, setContentLines] = useState(0);
    const isMobile = useMediaQuery('(max-width: 760px)');

    const handleFollowLog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        preventAndStopClick(event);
        setFollowLog(!followLog);
    };

    const loadContent = useCallback(() => {
        if (step.logFile?.file) {
            setLoadingContent(true);
            dispatch(getBuildResultArtifactContent(step.logFile.file)).then((action) => {
                setContent(action.payload);
                setContentLines(action.payload?.split('\n').length || 0);
                setLoadingContent(false);
            });
        }
    }, [dispatch, step.logFile?.file]);

    const handleExpandChange = () => {
        const newExpanded = !expanded;
        setExpanded(newExpanded);

        setManuallyExpanded(true);
        dispatch(setBuildResultTabAccordion(newExpanded ? step.name : undefined));

        if (newExpanded) {
            loadContent();
        }
    };

    useEffect(() => {
        if (isTabAccordionActive(step.name) && ref.current) {
            window.scrollTo(0, ref.current?.offsetTop);

            setManuallyExpanded(true);
            setExpanded(true);
            loadContent();
        }
    }, [ref, step.name, loadContent]);

    useEffect(() => {
        if (
            !manuallyExpanded &&
            ((!expanded && step.state === StepState.running) || (expanded && step.state !== StepState.running))
        ) {
            const newExpanded = !expanded;
            setExpanded(newExpanded);

            if (newExpanded) {
                loadContent();
            }
        }
    }, [step.state, expanded, manuallyExpanded, loadContent]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (expanded && step.logFile && step.state === StepState.running) {
            interval = setInterval(() => {
                if (step.logFile && !loadingContent) {
                    dispatch(getBuildResultArtifactContent(step.logFile.file)).then((action) => {
                        setContent(action.payload);
                        if (contentLines < 20) {
                            // check if updated content would use more height
                            setContentLines(action.payload?.split('\n').length || 0);
                        }
                    });
                }
            }, 2000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [expanded, content, step.state, step.logFile, dispatch, contentLines, loadingContent]);

    return (
        <Accordion
            ref={ref}
            sx={{ backgroundColor: (theme) => theme.palette.common.white, mx: 0 }}
            expanded={expanded}
            onClick={handleExpandChange}
            data-testid='build-details-accordion-step'
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    '.MuiAccordionSummary-content': {
                        display: 'flex',
                        alignItems: 'center',
                        mr: 1,
                        '&.Mui-expanded': {
                            mr: 1,
                        },
                    },
                    '.MuiAccordionSummary-expandIconWrapper': {
                        color: blueGray[800],
                    },
                    '&:hover': {
                        background: (theme) => theme.palette.grey[200],
                    },
                }}
            >
                <StatusIcon status={step.state} />
                <Typography
                    variant='body2'
                    color='textPrimary'
                    component='span'
                    sx={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '20vw',
                        margin: (theme) => theme.spacing(0, 0.5, 0, 1),
                        flexGrow: 1,
                    }}
                >
                    {step.name}
                </Typography>

                <MessagesIcons messages={step.messages} />
                <ElapsedTime start={step.start} end={step.end} />
                <StepAccordionButtons step={step} />
                {expanded && content && step.state === StepState.running && (
                    <FormControlLabel
                        control={
                            <Switch size='small' color='secondary' checked={followLog} onClick={handleFollowLog} />
                        }
                        label={
                            isMobile ? (
                                ''
                            ) : (
                                <Typography component='span' color='textSecondary'>
                                    follow log
                                </Typography>
                            )
                        }
                        sx={{ margin: (theme) => theme.spacing(0, 0.5, 0, 1) }}
                    />
                )}
            </AccordionSummary>

            <AccordionInfoTable step={step} />

            {!content && (
                <AccordionDetails
                    onClick={preventAndStopClick}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                            margin: 1,
                        },
                    }}
                >
                    {loadingContent ? (
                        <CircularProgress variant='indeterminate' size={20} />
                    ) : (
                        <Typography>This step has no displayable content</Typography>
                    )}
                </AccordionDetails>
            )}
            {content && (
                <AccordionDetails
                    sx={{ padding: 0 }}
                    onClick={preventAndStopClick}
                    data-testid='build-details-accordion-step-content'
                >
                    <div
                        style={{
                            maxHeight: '40vh',
                            width: '100%',
                            height: `${4 * ARTIFACT_LINE_HEIGHT + contentLines * ARTIFACT_LINE_HEIGHT}px`,
                        }}
                    >
                        {step.state === StepState.running ? (
                            <ScrollFollow
                                startFollowing
                                render={() => (
                                    <LazyLog
                                        text={content}
                                        extraLines={1}
                                        enableSearch
                                        caseInsensitive
                                        selectableLines
                                        follow={followLog}
                                    />
                                )}
                            />
                        ) : (
                            <LazyLog
                                text={content}
                                extraLines={1}
                                enableSearch
                                caseInsensitive
                                selectableLines
                                follow
                            />
                        )}
                    </div>
                </AccordionDetails>
            )}
        </Accordion>
    );
}

function StepAccordionButtons({ step }: { step: IStep }): ReactElement {
    return (
        <Box sx={{ ml: 1, width: { xs: '20px', sm: '35px' } }}>
            <DownloadArtifactBuildDetailsButton artifact={step.logFile} />
        </Box>
    );
}

function ElapsedTime({ start, end }: { start?: string; end?: string }): ReactElement | null {
    if (!start) {
        return null;
    }

    if (start === end) {
        return (
            <Typography
                variant='body2'
                component='span'
                sx={{ color: blueGray[800], width: { xs: '30px', sm: '80px', md: '100px' } }}
            >
                0:00
            </Typography>
        );
    }

    return (
        <Typography
            variant='body2'
            component='span'
            sx={{ color: blueGray[800], width: { xs: '30px', sm: '80px', md: '100px' } }}
        >
            {end ? <Moment duration={start} date={end} /> : <Moment date={start} durationFromNow interval={1000} />}
        </Typography>
    );
}

function AccordionInfoTable({ step }: { step: IStep }): ReactElement {
    return (
        <TableContainer onClick={preventAndStopClick}>
            <Table>
                <TableBody>
                    {step.command && (
                        <TableRow sx={{ padding: 0 }}>
                            <TableCell align='right' sx={{ width: '85px', p: 1 }}>
                                command
                            </TableCell>
                            <TableCell align='center' sx={{ width: '40px', p: 1 }}>
                                <CommandIcon sx={{ verticalAlign: 'middle', fontSize: '19px' }} />
                            </TableCell>
                            <TableCell sx={{ p: 1 }} align='left'>
                                <SyntaxHighlighter language='bash' style={coy} wrapLongLines>
                                    {step.command}
                                </SyntaxHighlighter>
                            </TableCell>
                        </TableRow>
                    )}
                    {step.messages?.map((message, index) => <MessageTableRow key={index} message={message} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
