import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container, useMediaQuery } from '@mui/material';
import HeaderSection from '../commons/HeaderSection';
import Page from '../commons/Page';
import NewBuildActionButtons from './NewBuildActionButtons';
import NewBuildFavourites from './NewBuildFavourites';
import NewBuildOptions from './NewBuildOptions';
import { selectNewBuildValidationErrors, selectNewBuildConfigurationSelectedBuilds } from './newBuildSlice';
import NewBuildValidationErrors from './NewBuildValidationErrors';

export default function NewBuild(): ReactElement {
    const isMobile = useMediaQuery('(max-width: 1000px)');
    const validationErrors = useSelector(selectNewBuildValidationErrors);
    const selectedBuilds = useSelector(selectNewBuildConfigurationSelectedBuilds);

    return (
        <Page>
            <HeaderSection
                title='Order Build'
                isMobile={isMobile}
                helmetTitle='Order New'
                breadcrumbs={[
                    {
                        text: 'Builds',
                        href: '/builds',
                    },
                    {
                        text: 'Order Build',
                    },
                ]}
            />
            <Container maxWidth={false}>
                <NewBuildValidationErrors validationErrors={validationErrors} selectedBuilds={selectedBuilds} />
            </Container>
            <Container
                maxWidth={false}
                sx={{
                    overflowY: 'auto',
                    mt: -1,
                }}
                data-testid='new-build'
            >
                <Grid container direction={!isMobile ? 'row' : undefined} columnSpacing={8} sx={{ overflowY: 'auto' }}>
                    {!isMobile && (
                        <Grid item xs={3.25} sx={{ height: '100%' }}>
                            <NewBuildFavourites />
                        </Grid>
                    )}
                    <Grid item xs={!isMobile ? 8.75 : 12} sx={{ height: '100%' }}>
                        <NewBuildOptions />
                    </Grid>
                    <NewBuildActionButtons />
                </Grid>
            </Container>
        </Page>
    );
}
