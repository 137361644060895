import { ISelectedTestCase } from '../../../interfaces';

export function removeUnselectedTags(testCases: ISelectedTestCase[], tags: string[]): string[] {
    const selectedTags = [...tags];
    for (const tag of tags) {
        const someSelected = testCases.some((testCase) => testCase.selected && testCase.tags.includes(tag));
        if (!someSelected) {
            selectedTags.splice(selectedTags.indexOf(tag), 1);
        }
    }
    return selectedTags;
}
