import { ESelectedProperty, ISelectedTestCase } from '../interfaces';

export function compareTestCases(
    allTestCases: ISelectedTestCase[],
    property: ESelectedProperty,
): { all: boolean; some: boolean } {
    return {
        all: !allTestCases.some((testCase) => testCase.visible && !testCase[property]),
        some: allTestCases.some((testCase) => testCase.visible && testCase[property]),
    };
}
