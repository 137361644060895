import { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Box, Card, Container } from '@mui/material';
import ContentLoading from '../commons/ContentLoading';
import { getAcronym } from '../commons/getAcronym';
import NoContent from '../commons/NoContent';
import { openSocket } from '../sockets';
import SubscribeButton from '../subscriptions/SubscribeButton';
import TestSuiteBreadcrumbs from './detail/TestSuiteBreadcrumbs';
import TestSuiteDevicesList from './detail/TestSuiteDevicesList';
import TestSuiteInfo from './detail/TestSuiteInfo';
import TestSuiteTestsList from './detail/TestSuiteTestCaseList';
import { ITestSuiteDetails } from './interfaces';
import { useGetTestSuiteDetailsQuery } from './testSuitesApi';
import TestSuiteStatus from './TestSuiteStatus';
import { isTestStatusCompleted } from './utils';

export default function TestSuiteDetails(): ReactElement {
    const { projectName, testSuiteNumber } = useParams<{ projectName: string; testSuiteNumber: string }>();
    const { data, isFetching } = useGetTestSuiteDetailsQuery({ testSuiteNumber: Number(testSuiteNumber) });
    const [testSuite, setTestSuite] = useState<ITestSuiteDetails | undefined>(data);

    useEffect(() => {
        setTestSuite(data);
    }, [data]);

    useEffect(() => {
        const socket = openSocket('tests');

        const handleTestSuiteUpdate = ({ testSuite }: { testSuite: ITestSuiteDetails }) => {
            if (testSuite._id === data?._id) {
                setTestSuite(testSuite);
            }
        };

        socket.on('update-test-suite', handleTestSuiteUpdate);

        return () => {
            socket.off('update-test-suite', handleTestSuiteUpdate);
        };
    }, [data]);

    return (
        <>
            <Helmet title={`${getAcronym(projectName)} #${testSuiteNumber}`} />
            <Container
                maxWidth={false}
                sx={{
                    height: 'unset',
                    minHeight: 'calc(100vh - 70px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                data-testsuiteid='test-suite-details'
            >
                {isFetching && <ContentLoading />}
                {!testSuite && !isFetching && <NoContent text='Test suite not found' />}

                {testSuite && (
                    <>
                        <Box
                            sx={{ p: 1, my: 2, display: { xs: 'block', sm: 'flex' }, justifyContent: 'space-between' }}
                        >
                            <TestSuiteBreadcrumbs testSuite={testSuite} />
                            {testSuite && (
                                <Box
                                    sx={{
                                        display: { xs: 'block', sm: 'flex' },
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TestSuiteStatus testSuite={testSuite} detailed />
                                    {!isTestStatusCompleted(testSuite.status) && (
                                        <SubscribeButton
                                            event={{
                                                namespace: 'tests',
                                                projectName: testSuite.build.projectName,
                                                testSuiteNumber: testSuite.testSuiteNumber,
                                            }}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                        <TestSuiteDetailsContent testSuite={testSuite} />
                    </>
                )}
            </Container>
        </>
    );
}

function TestSuiteDetailsContent({ testSuite }: { testSuite: ITestSuiteDetails }): ReactElement {
    return (
        <>
            <TestSuiteInfo testSuite={testSuite} />
            <Card data-testid='test-suite-devices' sx={{ mb: 2, marginTop: '16px' }}>
                <TestSuiteDevicesList testSuite={testSuite} />
            </Card>
            <Card data-testid='test-results' sx={{ mb: 10 }}>
                <TestSuiteTestsList testSuite={testSuite} />
            </Card>
        </>
    );
}
