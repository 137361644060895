import { ReactElement } from 'react';
import {
    DesktopAccessDisabled as OfflineIcon,
    AccessTime as IdleIcon,
    CheckCircle as DoneIcon,
    Error as ErrorIcon,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { EBuildAgentState } from './enums';

export function getBuildAgentStateIcon(state: EBuildAgentState): ReactElement | undefined {
    switch (state) {
        case EBuildAgentState.offline:
            return <OfflineIcon />;
        case EBuildAgentState.idle:
            return <IdleIcon />;
        case EBuildAgentState.running:
        case EBuildAgentState.cancelling:
        case EBuildAgentState.maintenance:
            return <CircularProgress size={16} style={{ margin: 0 }} />;
        case EBuildAgentState.done:
            return <DoneIcon />;
        default:
            return <ErrorIcon />;
    }
}
