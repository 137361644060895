import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { IScheduleBuild, ICreateScheduleBuildParams, IUpdateScheduleBuildParams } from './interfaces';

export const scheduledBuildsApi = createApi({
    reducerPath: 'scheduledBuildsApi',
    baseQuery: apiBaseQuery(),
    tagTypes: ['ScheduledBuilds'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getScheduledBuilds: builder.query<IScheduleBuild[], void>({
            query: () => ({
                url: `/builds/build-schedule`,
                method: 'GET',
            }),
            transformResponse: (response: { scheduleBuilds: IScheduleBuild[] }): IScheduleBuild[] =>
                response.scheduleBuilds,
            providesTags: ['ScheduledBuilds'],
        }),
        createScheduledBuild: builder.mutation<IScheduleBuild[], ICreateScheduleBuildParams>({
            query: ({ project, branch, description, cron, buildConfigurations }) => ({
                url: `/builds/build-schedule`,
                method: 'POST',
                body: {
                    project,
                    branch,
                    description,
                    cron,
                    buildConfigurations,
                },
            }),
            invalidatesTags: ['ScheduledBuilds'],
        }),
        updateScheduledBuild: builder.mutation<IScheduleBuild[], IUpdateScheduleBuildParams>({
            query: ({ _id, project, branch, description, cron, buildConfigurations }) => ({
                url: `/builds/build-schedule/${_id}`,
                method: 'PATCH',
                body: {
                    project,
                    branch,
                    description,
                    cron,
                    buildConfigurations,
                },
            }),
            invalidatesTags: ['ScheduledBuilds'],
        }),
        removeScheduledBuild: builder.mutation<IScheduleBuild[], { _id: string }>({
            query: ({ _id }) => ({
                url: `/builds/build-schedule/${_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ScheduledBuilds'],
        }),
    }),
});

export const {
    useGetScheduledBuildsQuery,
    useCreateScheduledBuildMutation,
    useUpdateScheduledBuildMutation,
    useRemoveScheduledBuildMutation,
} = scheduledBuildsApi;
