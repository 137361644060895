import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import DownloadArtifactButton from '../commons/DownloadArtifactButton';
import { selectBuildDetailsBuild } from './buildDetailsSlice';
import { IArtifact } from './interfaces';

interface DownloadArtifactBuildDetailsButtonProps {
    artifact?: IArtifact;
    isIconButton?: boolean;
}

// This is a specific implementation of the DownloadArtifactsButton that uses the buildDetailsSlice
export default function DownloadArtifactBuildDetailsButton({
    artifact,
    isIconButton = true,
}: DownloadArtifactBuildDetailsButtonProps): ReactElement | null {
    const build = useSelector(selectBuildDetailsBuild);
    const buildId = build?._id;

    if (!buildId || !artifact) {
        return null;
    }

    return <DownloadArtifactButton artifact={artifact} buildId={buildId} isIconButton={isIconButton} />;
}
