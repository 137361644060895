import { ReactElement } from 'react';
import ColumnFilterSelector from './ColumnFilterSelector';
import { FilterOption, SelectedFilter } from './interfaces';
import SelectedFilters from './SelectedFilters';

interface ColumnFilterProps {
    id?: string;
    label?: string;
    options: FilterOption[];
    selected?: SelectedFilter;
    loading?: boolean;
    showOnlyValues?: boolean;
    onChange?: (value: string[] | null) => void;
}

export default function ColumnFilter(props: ColumnFilterProps): ReactElement {
    const { id, label, options = [], selected = [], loading = false, showOnlyValues, onChange } = props;
    if (showOnlyValues) {
        return (
            <SelectedFilters
                name={label || ''}
                loading={loading}
                selected={selected}
                options={options}
                onDelete={onChange}
            />
        );
    }

    return (
        <ColumnFilterSelector id={id} selected={selected} options={options} loading={!!loading} onChange={onChange} />
    );
}
