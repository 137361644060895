import { ReactElement } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';

interface ProjectNameFilterProps {
    projectList: string[];
    selectedProjects: string[];
    onSelectedProjectsChange: (selectedProjects: string[]) => void;
    label?: string;
}

export default function ProjectNameFilter({
    projectList,
    selectedProjects,
    onSelectedProjectsChange,
    label,
}: ProjectNameFilterProps): ReactElement {
    return (
        <Autocomplete
            size='small'
            data-testid='game-projects-filter'
            options={projectList}
            value={selectedProjects}
            multiple
            disableCloseOnSelect
            renderInput={(params) => <TextField {...params} size='small' placeholder={label || 'Filter Projects'} />}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox sx={{ marginRight: 1 }} checked={selected} />
                    {option}
                </li>
            )}
            renderTags={(values) => (
                <span style={{ marginLeft: '10px' }}>
                    {values.slice(0, 2).join(', ')}
                    {values.length > 2 ? '…' : ''}
                </span>
            )}
            onChange={(_, value) => onSelectedProjectsChange(value as string[])}
            sx={{
                minWidth: 320,
                maxWidth: { xs: '100%', md: 550 },
                width: { xs: '100%', md: 'auto' },
                mt: { xs: 2, md: 0 },
                display: 'flex',
                alignItems: 'center',
                '.MuiAutocomplete-inputRoot': {
                    '.MuiAutocomplete-input': { minWidth: 90 },
                },
            }}
        />
    );
}
