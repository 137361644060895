import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button, Grid, Typography } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { handleRedirectToPage } from '../../../utils';
import SubscribeButton from '../../subscriptions/SubscribeButton';
import { ITest } from '../interfaces';
import TestCaseList from './TestCaseList';

export default function TestResults({ test }: { test: ITest }): ReactElement {
    const history = useHistory();

    const openTestDetailsTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tab: string) => {
        const redirectUrl = `/tests/${test.projectName}/${test.testNumber}/${tab}`;
        handleRedirectToPage(e, redirectUrl, history);
    };

    return (
        <>
            <Grid container justifyContent='flex-end' sx={{ mb: 3, mt: 1 }}>
                <Grid item>
                    <Button
                        disabled={!test.logFile}
                        variant='outlined'
                        size='small'
                        sx={{ mr: 2 }}
                        color='secondary'
                        onMouseUp={(e) => openTestDetailsTab(e, 'log')}
                    >
                        Log File
                    </Button>
                    <SubscribeButton
                        event={{ namespace: 'tests', projectName: test.projectName, testNumber: test.testNumber }}
                    />
                </Grid>
            </Grid>
            <Card data-testid='test-results' sx={{ mb: 10 }}>
                <Typography variant='h6' sx={{ m: 3, color: blueGray[700], fontSize: '18px' }}>
                    Test Cases
                </Typography>
                <TestCaseList test={test} />
            </Card>
        </>
    );
}
