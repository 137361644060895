import { ReactElement, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@mui/material';
import { EnableIfCan } from '@tactileentertainment/core-shared.auth-react';
import { EAuthResource } from '../../commons/EAuthResource';
import TestDevicesSettingsDialog from './TestDevicesSettingsDialog';

export default function TestDevicesSettingsButton(): ReactElement {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <EnableIfCan resource={EAuthResource.testDevices} operation='edit'>
            <Button
                onClick={() => setOpenDialog(true)}
                startIcon={<SettingsIcon />}
                variant='outlined'
                size='medium'
                color='primary'
            >
                Global Settings
            </Button>
            {openDialog && <TestDevicesSettingsDialog open={openDialog} onClose={() => setOpenDialog(false)} />}
        </EnableIfCan>
    );
}
