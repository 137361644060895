import { ChangeEvent, ReactElement } from 'react';
import { Autocomplete, Box, FormControlLabel, Switch, TextField } from '@mui/material';
import { useGetProjectsQuery } from '../projects/projectsApi';

interface SelectProjectNameProps {
    projectName: string;
    setProjectName: (projectName: string) => void;
}

export default function SelectProjectName({ projectName, setProjectName }: SelectProjectNameProps): ReactElement {
    const { data: projects, isFetching } = useGetProjectsQuery();
    const projectNames = projects?.map((project) => project.name) || [];

    return (
        <Box display='flex'>
            <Autocomplete
                size='medium'
                fullWidth
                disabled={projectName === 'all'}
                options={projectNames || []}
                autoHighlight
                clearOnBlur
                clearOnEscape
                disableClearable
                loading={isFetching}
                onChange={(e: ChangeEvent<any>, option?: string) => setProjectName(option || '')}
                value={projectName === 'all' ? undefined : projectName}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoComplete='off'
                        label='Project Name'
                        placeholder='Select project'
                        variant='outlined'
                    />
                )}
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={projectName === 'all'}
                        onChange={(event, checked) => setProjectName(checked ? 'all' : '')}
                    />
                }
                label='Select All Projects'
                labelPlacement='end'
                sx={{ ml: 1, minWidth: 300 }}
            />
        </Box>
    );
}
