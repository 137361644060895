import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Edit as EditIcon, Construction as MaintenanceIcon } from '@mui/icons-material';
import { TableCell, IconButton, useTheme } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { useAuthUser } from '@tactileentertainment/core-shared.auth-react';
import { MuiAlignSetting } from '../../typings/material-ui';
import DatadogLink, { buildInfrastructureUrl } from '../commons/DatadogLink';
import { toggleShowBuildAgentDialog, toggleShowMaintenanceDialog } from './buildAgentsSlice';
import { AgentDialogActionType } from './enums';
import { BuildAgent } from './interfaces';

interface BuildAgentActionsCellProps {
    buildAgent: BuildAgent;
    align?: MuiAlignSetting;
}

export default function BuildAgentActions({ buildAgent, align = 'left' }: BuildAgentActionsCellProps): ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { isLoggedIn } = useAuthUser();

    const handleEditBuildAgentClick = (e: any) => {
        e.stopPropagation();
        dispatch(toggleShowBuildAgentDialog({ action: AgentDialogActionType.edit, buildAgent }));
    };

    const handleMaintenanceClick = (e: any) => {
        e.stopPropagation();
        dispatch(toggleShowMaintenanceDialog({ action: AgentDialogActionType.maintenance, buildAgent }));
    };

    return (
        <TableCell
            align={align}
            sx={{
                padding: (theme) => theme.spacing(2, 1),
                [theme.breakpoints.down('sm')]: {
                    padding: (theme) => theme.spacing(1, 0.5),
                    width: '30%',
                },
            }}
        >
            <DatadogLink url={buildInfrastructureUrl(buildAgent.hostname, ['role:buildserver'])} sx={{ mr: 1 }} />
            <IconButton
                disabled={!isLoggedIn}
                size='small'
                title='Build Agent Maintenance'
                aria-label='download'
                sx={{ color: blueGray[700] }}
                onClick={handleMaintenanceClick}
            >
                <MaintenanceIcon />
            </IconButton>
            <IconButton
                disabled={!isLoggedIn}
                size='small'
                title='Edit Build Agent'
                aria-label='download'
                sx={{ color: blueGray[700] }}
                onClick={handleEditBuildAgentClick}
            >
                <EditIcon />
            </IconButton>
        </TableCell>
    );
}
