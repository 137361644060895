import { ReactElement } from 'react';
import { Dialog } from '@tactileentertainment/core-designsystem';

interface DeleteProjectConfirmDialogProps {
    open: boolean;
    onConfirmClick: () => void;
    onClose: () => void;
}

export default function DeleteProjectConfirmDialog({
    open,
    onConfirmClick,
    onClose,
}: DeleteProjectConfirmDialogProps): ReactElement {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title='Delete Project'
            icon='delete'
            content='Are you sure you want to delete this project?'
            variant='confirmation'
            primaryButton={{
                text: 'Delete',
                color: 'error',
                onClick: onConfirmClick,
            }}
        />
    );
}
