import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Checkbox,
    FormControlLabel,
    Box,
    FormHelperText,
} from '@mui/material';
import { LoadingScreen, successToast } from '@tactileentertainment/core-designsystem';
import { EnableIfCan, useHasResourcePermissions } from '@tactileentertainment/core-shared.auth-react';
import { AppThunkDispatch } from '../../store';
import { IBuildsSliceState } from '../builds/interfaces';
import CustomLink from '../commons/CustomLink';
import { EAuthResource } from '../commons/EAuthResource';
import { IBuild } from '../commons/interfaces';
import { getBuildConfigurations } from '../newBuild/newBuildSlice';
import { IReleaseNote } from '../releaseNotes/interfaces';
import SelectReleaseNote from '../releaseNotes/SelectReleaseNote';
import {
    setAppUploaderDialogOpen,
    selectAppUploaderDialog,
    uploadApp,
    selectBuildDetailsResult,
} from './buildDetailsSlice';
import { IAppUploaderPayload, IConfigurationProject } from './interfaces';

export default function AppUploaderDialog({ build }: { build: IBuild | undefined }): ReactElement | null {
    const { open } = useSelector(selectAppUploaderDialog);
    const history = useHistory();

    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();

    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string | undefined>();
    const [release, setReleaseFlag] = useState<boolean>(false);
    const [review, setReviewFlag] = useState<boolean>(false);
    const [gameConfig, setGameConfig] = useState<IConfigurationProject>();
    const [releaseNote, setReleaseNote] = useState<IReleaseNote | undefined>();
    const buildResult = useSelector(selectBuildDetailsResult);
    const userPermission = useHasResourcePermissions(EAuthResource.appUploader, 'edit');

    useEffect(() => {
        if (!gameConfig && build) {
            setLoading(true);
            dispatch(
                getBuildConfigurations({
                    projectName: build?.projectName,
                    branch: build?.branch,
                    revision: build?.revision,
                }),
            ).then(({ payload }) => {
                if (payload?.buildConfigs?.[build.buildConfigFileName || 'build_config.json']) {
                    const { project } = payload.buildConfigs?.[build.buildConfigFileName || 'build_config.json'] || {};
                    setGameConfig(project);
                }
                setLoading(false);
            });
        }
    }, [dispatch, build, gameConfig]);

    useEffect(() => {
        if (!buildResult) {
            setError(`Error: missing build result`);
        } else if (!gameConfig) {
            setError(`Error: missing game configuration`);
        } else if (!build) {
            setError(`Error: missing build`);
        } else {
            setError(undefined);
        }
    }, [buildResult, gameConfig, build]);

    const handleSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.id === 'review') {
            setReviewFlag(event.target.checked);
            if (!event.target.checked) {
                // if the review flag is unchecked, the release flag is also unchecked
                setReleaseFlag(event.target.checked);
            }
        }
        if (event.target.id === 'release') {
            setReleaseFlag(event.target.checked);
        }
    };

    const handleClose = () => {
        setReleaseFlag(false);
        setReviewFlag(false);
        setReleaseNote(undefined);
        dispatch(setAppUploaderDialogOpen(false));
    };

    const handleOk = () => {
        const uploaderRequestPayload: IAppUploaderPayload = {
            gameName: build?.projectName,
            buildNumber: build?.buildNumber,
            platform: build?.platform,
            versionName: build?.versionName,
            kind: 'custom',
            type: 'upload',
            org: getOrganization(),
            udp_client_id: gameConfig?.udp_client_id,
            amazon_app_id: gameConfig?.amazon_app_id,
            release,
            review,
            packageName: buildResult?.packageName,
            mainArtifact: getArtifactForStore(),
            parentBuild: build?._id,
            releaseNote: releaseNote?._id,
        };
        dispatch(uploadApp(uploaderRequestPayload)).then(({ payload }: { payload: any }) => {
            if (payload.errors || payload.error) {
                const { status, type, message } = payload;
                setError(`Error: ${status} ${type} : ${message}`);
            } else {
                handleClose();
                successToast(`Uploading to store`);
                history.push('/builds?kind=custom');
            }
        });
    };

    const getArtifactForStore = () => {
        if (build?.platform === 'ios') {
            return 'build.ipa';
        } else if (build?.platform === 'android' || build?.platform === 'udp') {
            return 'app-bundle.aab';
        }
        return buildResult?.mainArtifact.file;
    };

    const getOrganization = () => {
        if (build?.platform === 'ios') {
            return gameConfig?.apple_appstore_org;
        } else if (build?.platform === 'android') {
            return gameConfig?.google_playstore_org;
        } else if (build?.platform === 'kindle') {
            return gameConfig?.amazon_appstore_org;
        }
    };

    const getDialogTitleText = () => {
        if (build?.platform === 'ios') {
            return 'Upload build to the Apple App Store';
        } else if (build?.platform === 'android') {
            return 'Upload build to the Google Play Store';
        } else if (build?.platform === 'kindle') {
            return 'Upload build to the Amazon App Store';
        } else if (build?.platform === 'udp') {
            return 'Upload build to the Unity Distribution Portal';
        }
        return 'Upload to app store';
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth data-testid='build-details-app-uploader-dialog'>
            <DialogTitle>{getDialogTitleText()}</DialogTitle>
            {loading && <LoadingScreen />}
            {!loading && (
                <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        control={
                            <EnableIfCan resource={EAuthResource.appUploader} operation='edit'>
                                <Checkbox
                                    checked={review}
                                    onChange={handleSettingsChange}
                                    color='primary'
                                    id='review'
                                />
                            </EnableIfCan>
                        }
                        label='Submit for review after upload'
                    />
                    {!userPermission && (
                        <FormHelperText>You do not have permissions to submit for review.</FormHelperText>
                    )}
                    {review && (
                        <FormControlLabel
                            sx={{ mt: 1 }}
                            control={
                                <Checkbox
                                    checked={release}
                                    onChange={handleSettingsChange}
                                    color='primary'
                                    id='release'
                                />
                            }
                            label='Auto-release once approved'
                        />
                    )}
                    {build && (
                        <Box display='flex' flexDirection='column' sx={{ mt: 1 }}>
                            <SelectReleaseNote
                                projectName={build?.projectName}
                                releaseNote={releaseNote}
                                onSelectReleaseNote={(value) => setReleaseNote(value)}
                            />
                            <CustomLink to='/projects/release-notes' target='_blank' sx={{ alignSelf: 'flex-end' }}>
                                Edit Release Notes
                            </CustomLink>
                        </Box>
                    )}
                </DialogContent>
            )}

            <DialogContent>{!loading && error && <Alert severity='error'>{error}</Alert>}</DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button autoFocus onClick={handleClose} color='primary'>
                    Cancel
                </Button>
                <Button
                    onClick={handleOk}
                    variant='contained'
                    color='primary'
                    disabled={!build || !gameConfig || !buildResult}
                >
                    Upload to store
                </Button>
            </DialogActions>
        </Dialog>
    );
}
