import { ReactElement, useState } from 'react';
import { TableCell, Divider } from '@mui/material';
import { Chip, Typography } from '@tactileentertainment/core-designsystem';
import BuildNumberLink from '../../builds/BuildNumberLink';
import BuildStatusChip from '../../commons/BuildStatusChip';
import { DataValueBox, IDataValueBoxProps } from '../../commons/DataValueBox';
import Duration from '../../commons/Duration';
import ExtraInfoPopover from '../../commons/ExtraInfoPopover';
import { IBuild } from '../../commons/interfaces';
import { EBuildAgentState } from '../enums';
import { getBuildAgentStateIcon } from '../getBuildAgentStateIcon';
import { BuildAgent } from '../interfaces';

interface BuildAgentStateProps {
    buildAgent: BuildAgent;
    build?: IBuild;
    isLoading?: boolean;
    onMouseEnter?: () => void;
}

export default function BuildAgentState({
    buildAgent,
    build,
    onMouseEnter,
}: BuildAgentStateProps): ReactElement | null {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorElement);

    const handleChipMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorElement(event.currentTarget);
        onMouseEnter?.();
    };

    const handleChipMouseLeave = () => {
        setAnchorElement(null);
    };

    return (
        <TableCell
            sx={{ padding: (theme) => theme.spacing(2, 1) }}
            align='left'
            aria-owns={open ? 'mouse-over-popover' : undefined}
        >
            <BuildAgentStateChip
                buildAgentState={buildAgent.state}
                onChipMouseEnter={handleChipMouseEnter}
                onChipMouseLeave={handleChipMouseLeave}
            />
            {build && (
                <ExtraInfoPopover
                    id='mouse-over-popover'
                    open={open}
                    anchorEl={anchorElement}
                    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onClose={() => setAnchorElement(null)}
                    disableRestoreFocus
                    disableScrollLock
                    sx={{ minWidth: 550 }}
                >
                    <BuildAgentStateChip
                        buildAgentState={buildAgent.state}
                        onChipMouseEnter={handleChipMouseEnter}
                        onChipMouseLeave={handleChipMouseLeave}
                    />
                    {build && (
                        <>
                            <DataValueBoxWrapper label='Status' value={<BuildStatusChip status={build.status} />} />
                            <DataValueBoxWrapper label='Project' value={build.projectName} />
                            <DataValueBoxWrapper
                                label='Build ID'
                                value={
                                    <BuildNumberLink projectName={build.projectName} buildNumber={build.buildNumber} />
                                }
                            />
                            <DataValueBoxWrapper
                                label='Duration'
                                value={<Duration started={build.started} completed={build.completed} />}
                            />
                            <Divider sx={{ my: 1.5, mx: -2, borderColor: (theme) => theme.palette.border.light }} />
                            <Typography variant='h-6' sx={{ fontWeight: 600, marginTop: '8px', px: 2 }}>
                                About Build
                            </Typography>
                            <DataValueBoxWrapper label='Branch' value={build.branch} />
                            <DataValueBoxWrapper label='Type' value={build.type} />
                            <DataValueBoxWrapper label='Platform' value={build.platform} />
                        </>
                    )}
                </ExtraInfoPopover>
            )}
        </TableCell>
    );
}

interface BuildStateChipProps {
    buildAgentState: EBuildAgentState;
    onChipMouseEnter: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onChipMouseLeave: () => void;
}

function BuildAgentStateChip({ buildAgentState, onChipMouseEnter, onChipMouseLeave }: BuildStateChipProps) {
    return (
        <Chip
            icon={getBuildAgentStateIcon(buildAgentState)}
            size='md'
            sx={{
                borderRadius: 5,
                fontWeight: 600,
                color: (theme) => theme.palette.common.white,
                textTransform: 'capitalize',
                ...(buildAgentState === EBuildAgentState.offline && {
                    backgroundColor: (theme) => theme.palette.tactile.blueGray[500],
                }),
                ...(buildAgentState === EBuildAgentState.idle && {
                    backgroundColor: (theme) => theme.palette.tactile.blueGray[700],
                }),
                ...(buildAgentState === EBuildAgentState.cancelling && {
                    backgroundColor: (theme) => theme.palette.warning.main,
                }),
                ...(buildAgentState === EBuildAgentState.done && {
                    backgroundColor: (theme) => theme.palette.success.main,
                }),
                ...(buildAgentState === EBuildAgentState.running && {
                    backgroundColor: (theme) => theme.palette.tactile.blue[700],
                }),
                ...(buildAgentState === EBuildAgentState.maintenance && {
                    backgroundColor: (theme) => theme.palette.tactile.blue[700],
                }),
                ...(buildAgentState === EBuildAgentState.unknown && {
                    backgroundColor: (theme) => theme.palette.error.main,
                }),
                '& .MuiChip-icon': {
                    color: (theme) => theme.palette.common.white,
                    path: {
                        color: (theme) => theme.palette.common.white,
                    },
                },
                '& .MuiChip-label': {
                    color: (theme) => theme.palette.common.white,
                },
            }}
            onMouseEnter={onChipMouseEnter}
            onMouseLeave={onChipMouseLeave}
            label={buildAgentState}
        />
    );
}

function DataValueBoxWrapper(props: IDataValueBoxProps): ReactElement | null {
    return (
        <DataValueBox
            {...props}
            sx={{ mt: 1, justifyContent: 'space-between', px: 3 }}
            labelSx={{ width: 150 }}
            valueSx={{ fontWeight: 700 }}
        />
    );
}
