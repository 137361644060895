import { ReactElement, useMemo, useState } from 'react';
import { FeedOutlined as LogIcon, OpenInNew as OpenInNewTabIcon } from '@mui/icons-material';
import AodIcon from '@mui/icons-material/Aod';
import { FormControlLabel, IconButton, Switch, TableCell, TableRow } from '@mui/material';
import { createSharableServerUrl } from '../../../api';
import DatadogLink, { buildLogsUrl } from '../../commons/DatadogLink';
import Duration from '../../commons/Duration';
import ExpandIcon from '../../commons/ExpandIcon';
import MessagesTable from '../../messages/MessagesTable';
import TestDeviceLink from '../../testDevices/TestDeviceLink';
import formatDate from '../../tests/details/formatDate';
import LogFile from '../../tests/details/LogFile';
import Report from '../../tests/details/Report';
import TestCaseName from '../../tests/details/TestCaseName';
import TestCaseStatusInfo from '../../tests/details/TestCaseStatusInfo';
import { ETestSuiteAllocationType, ETestSuiteTestCaseLogType, ITestSuiteCase } from '../interfaces';
import { isTestStatusCompleted } from '../utils';
import TestSuiteDetailsTableCell from './TestSuiteDetailsTableCell';
import {
    TestSuiteTestCaseListColumnsId as columnIds,
    testSuiteTestCaseListColumns,
} from './testSuiteTestCaseListColumns';

export default function TestSuiteTestCaseRow({
    testCase,
    testSuiteType,
}: {
    testCase: ITestSuiteCase;
    testSuiteType: ETestSuiteAllocationType;
}): ReactElement {
    const { device, parentTest } = testCase;
    const [expandedContent, setExpandedContent] = useState<ETestSuiteTestCaseLogType | undefined>();
    const [followLogs, setFollowLogs] = useState<boolean>(true);

    const expandContent = (content: ETestSuiteTestCaseLogType) => {
        if (!testCase.logFile && !testCase.reportFile && !parentTest.logFile) {
            return;
        }
        setExpandedContent(expandedContent === content ? undefined : content);
    };

    const handleExpandButtonToggle = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (expandedContent) {
            setExpandedContent(undefined);
        } else {
            expandContent(ETestSuiteTestCaseLogType.caseReport);
        }
    };

    const openReportInNewTab = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(createSharableServerUrl(`/tests/${parentTest._id}/artifact/${testCase.reportFile}`), '_blank');
    };

    const onExpandContent = (event: React.MouseEvent, content: ETestSuiteTestCaseLogType) => {
        event.preventDefault();
        event.stopPropagation();
        expandContent(content);
    };

    const showFollowSwitch = useMemo(
        () => expandedContent === 'testLogs' && !isTestStatusCompleted(testCase.status),
        [expandedContent, testCase],
    );

    return (
        <>
            <TableRow
                data-testid='test-case-row'
                sx={{ cursor: 'pointer' }}
                onClick={(e) => onExpandContent(e, ETestSuiteTestCaseLogType.caseReport)}
            >
                {testSuiteTestCaseListColumns.map((column) => {
                    switch (column.id) {
                        case columnIds.expandable:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    <IconButton
                                        disabled={!testCase.reportFile}
                                        aria-label='expand row'
                                        size='small'
                                        onClick={(e) => handleExpandButtonToggle(e)}
                                        sx={{ color: (theme) => theme.palette.tactile.blueGray[800] }}
                                    >
                                        <ExpandIcon expanded={!!expandedContent} mode='tree' />
                                    </IconButton>
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.status:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    <TestCaseStatusInfo testCase={testCase} />
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.testCases:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    <TestCaseName testCase={testCase} />
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.device:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    {device ? (
                                        <TestDeviceLink key={device.uuid} uuid={device.uuid} title={device.name} />
                                    ) : (
                                        '-'
                                    )}
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.started:
                        case columnIds.ended:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    {(column.id === columnIds.started
                                        ? formatDate(testCase.startedAt)
                                        : formatDate(testCase.completedAt)) || '-'}
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.duration:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    <Duration started={testCase.startedAt} completed={testCase.completedAt} />
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.actions:
                            return (
                                <TestSuiteDetailsTableCell
                                    key={column.id}
                                    column={column}
                                    sx={{ display: 'flex', gap: 1 }}
                                >
                                    {testSuiteType === ETestSuiteAllocationType.divide ? (
                                        <IconButton
                                            disabled={!parentTest.logFile}
                                            title='Device Logs'
                                            size='small'
                                            onClick={(e) => onExpandContent(e, ETestSuiteTestCaseLogType.testLogs)}
                                        >
                                            <AodIcon sx={{ color: (theme) => theme.palette.tactile.blueGray[700] }} />
                                        </IconButton>
                                    ) : null}

                                    <IconButton
                                        disabled={!testCase.logFile}
                                        title='AirTest Logs'
                                        size='small'
                                        onClick={(e) => onExpandContent(e, ETestSuiteTestCaseLogType.caseLogs)}
                                    >
                                        <LogIcon sx={{ color: (theme) => theme.palette.tactile.blueGray[700] }} />
                                    </IconButton>
                                    <IconButton
                                        disabled={!testCase.reportFile}
                                        title='View report in new tab'
                                        size='small'
                                        onMouseUp={openReportInNewTab}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <OpenInNewTabIcon
                                            sx={{ color: (theme) => theme.palette.tactile.blueGray[700] }}
                                        />
                                    </IconButton>
                                    {testCase.gameDeviceId && (
                                        <DatadogLink
                                            url={buildLogsUrl(
                                                { '@device.uid': testCase.gameDeviceId },
                                                ['host', 'service', '@url'],
                                                testCase.startedAt,
                                                testCase.completedAt,
                                            )}
                                        />
                                    )}
                                    {showFollowSwitch && (
                                        <FormControlLabel
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                setFollowLogs(!followLogs);
                                            }}
                                            control={<Switch size='small' checked={followLogs} />}
                                            label='Follow'
                                        />
                                    )}
                                </TestSuiteDetailsTableCell>
                            );
                        default:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    -
                                </TestSuiteDetailsTableCell>
                            );
                    }
                })}
            </TableRow>
            {expandedContent && testCase.messages && (
                <TableRow>
                    <TableCell colSpan={testSuiteTestCaseListColumns.length} sx={{ py: 0 }}>
                        <MessagesTable messages={testCase.messages} />
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell colSpan={testSuiteTestCaseListColumns.length} sx={{ py: 0 }}>
                    {expandedContent === ETestSuiteTestCaseLogType.testLogs && parentTest.logFile && (
                        <LogFile testId={parentTest._id} scrollFollow={followLogs} logFile={parentTest.logFile} />
                    )}
                    {expandedContent === ETestSuiteTestCaseLogType.caseLogs && testCase.logFile && (
                        <LogFile testId={parentTest._id} scrollFollow={followLogs} logFile={testCase.logFile} />
                    )}
                    {expandedContent === ETestSuiteTestCaseLogType.caseReport && testCase.reportFile && (
                        <Report testId={parentTest._id} reportFile={testCase.reportFile} />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
}
