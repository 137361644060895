import { IToggleColumn } from '../../commons/interfaces';

export enum TestSuiteDevicesColumnsId {
    status = 'status',
    devices = 'devices',
    duration = 'duration',
    actions = 'actions',
}

export const testSuiteDevicesColumns: Array<IToggleColumn<TestSuiteDevicesColumnsId>> = [
    { id: TestSuiteDevicesColumnsId.status, label: 'Status', align: 'left', width: '10%' },
    { id: TestSuiteDevicesColumnsId.devices, label: 'Device', align: 'left', width: '44%' },
    { id: TestSuiteDevicesColumnsId.duration, label: 'Runtime', align: 'left', width: '12%' },
    { id: TestSuiteDevicesColumnsId.actions, label: 'Actions', align: 'left', width: '10%' },
];
