import { ReactElement } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Popper, Tooltip, Typography } from '@mui/material';
import { TestSuitesQueue } from '../testSuites/useTestSuitesQueue';
import DeviceAdvancedInfo from './details/DeviceAdvancedInfo';
import DeviceModelInfo from './details/DeviceModelInfo';
import { ITestDevice } from './interfaces';
import TestDeviceName from './TestDeviceName';

export default function TestDeviceOption({
    option,
    testsQueue,
}: {
    option: ITestDevice;
    testsQueue: TestSuitesQueue;
}): ReactElement {
    const queueLength = testsQueue.get(option._id)?.queue || 0;

    return (
        <Box display='flex' justifyContent='space-between' width='100%'>
            <TestDeviceName testDevice={option} size='small' variant='text' />
            <Box display='flex'>
                <Typography color='secondary'>
                    {queueLength > 0 ? `${queueLength} test${queueLength > 1 ? 's' : ''} in the queue` : ''}
                </Typography>
                <Tooltip
                    PopperComponent={(props) => (
                        <Popper
                            {...props}
                            sx={{
                                '.MuiTooltip-tooltip': {
                                    border: '2px solid',
                                    borderColor: (theme) => theme.palette.tactile.blueGray[100],
                                    background: (theme) => theme.palette.background.default,
                                    minWidth: 350,
                                },
                            }}
                            onClick={(event) => event.stopPropagation()}
                        />
                    )}
                    title={
                        <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            spacing={1}
                            sx={{ px: 1, display: { xs: 'block', sm: 'flex' } }}
                        >
                            <DeviceModelInfo testDevice={option} showDeviceLink />
                            <DeviceAdvancedInfo deviceInfo={option.deviceInfo} />
                        </Grid>
                    }
                >
                    <InfoIcon fontSize='small' color='secondary' sx={{ verticalAlign: 'bottom', marginLeft: '8px' }} />
                </Tooltip>
            </Box>
        </Box>
    );
}
