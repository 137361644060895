import { ChangeEvent, ReactElement, useMemo } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ITestDevice } from '../../testDevices/interfaces';
import TestDeviceOption from '../../testDevices/TestDeviceOption';
import { useGetActiveTestDevicesQuery } from '../../testDevices/testDevicesApi';
import { useTestSuitesQueue } from '../../testSuites/useTestSuitesQueue';

export default function SelectTestDevice({
    platform,
    testDevice,
    setTestDevice,
}: {
    platform: string;
    testDevice: ITestDevice | null;
    setTestDevice: (device: ITestDevice | null) => void;
}): ReactElement {
    const { data: testDevices, isFetching } = useGetActiveTestDevicesQuery();
    const testsQueue = useTestSuitesQueue();

    const sortedDevices = useMemo(() => {
        return testDevices?.slice().sort((a: ITestDevice, b: ITestDevice) => {
            const testAgentA = a.testAgent;
            const testAgentB = b.testAgent;
            if (!testAgentA || !testAgentB) {
                return testAgentA ? -1 : 1;
            } else {
                return testAgentA.hostname > testAgentB.hostname ? 1 : -1;
            }
        });
    }, [testDevices]);

    return (
        <Autocomplete
            size='small'
            options={
                sortedDevices?.filter(
                    (device: ITestDevice) => device.platform === platform && device.isDisabled === false,
                ) || []
            }
            autoHighlight
            clearOnBlur
            clearOnEscape
            loading={isFetching}
            groupBy={(option) => option.testAgent?.hostname || 'No Agent'}
            onChange={(e: ChangeEvent<any>, option: ITestDevice | null) => setTestDevice(option)}
            renderOption={(props, option: ITestDevice) => (
                <Box component='li' {...props}>
                    <TestDeviceOption option={option} testsQueue={testsQueue} />
                </Box>
            )}
            getOptionLabel={(option) => option.name}
            value={testDevice}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete='off'
                    label='Test device'
                    placeholder='Select device'
                    variant='outlined'
                />
            )}
        />
    );
}
