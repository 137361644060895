import { ReactElement } from 'react';
import SettingsIcon from '@mui/icons-material/SettingsApplications';
import VideoRecordingIcon from '@mui/icons-material/Videocam';
import { Box, Typography } from '@mui/material';
import { TactileTooltip } from '@tactileentertainment/core-designsystem';
import BuildNumberLink from '../../builds/BuildNumberLink';
import { useGetBuildQuery } from '../../builds/buildsApi';
import SelectedSettings from '../../configurationSettings/SelectedSettings';
import { ITestCase } from '../interfaces';
import TestBranchLink from '../TestBranchLink';

export default function TestCaseName({ testCase }: { testCase: ITestCase }): ReactElement {
    const { data: build } = useGetBuildQuery(testCase.customBuild?.build || '', { skip: !testCase.customBuild });

    return (
        <Box display='flex' flexWrap='wrap' alignItems='center'>
            <Box sx={{ mr: 1 }}>
                {testCase.displayName || testCase.testName}
                {testCase.settings && (
                    <TactileTooltip
                        title={
                            <SelectedSettings
                                selectedSettings={testCase.settings}
                                sx={{ color: (theme) => theme.palette.tactile.blueGray[200] }}
                            />
                        }
                    >
                        <SettingsIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                    </TactileTooltip>
                )}
                {testCase.videoRecording && (
                    <TactileTooltip title='Video recording enabled'>
                        <VideoRecordingIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                    </TactileTooltip>
                )}
            </Box>
            {build && (
                <Typography color='secondary' variant='caption'>
                    (run on&nbsp;
                    <BuildNumberLink
                        projectName={build.projectName}
                        buildNumber={build.buildNumber}
                        sx={{ display: 'inline' }}
                    />
                    &nbsp;version <b>{build.versionName}</b>
                    &nbsp; revision <b>{build.revision}</b>
                    {testCase.customBuild?.testBranch && (
                        <>
                            &nbsp; tests{' '}
                            <TestBranchLink
                                projectName={build.projectName}
                                testBranch={testCase.customBuild.testBranch}
                                commitSha={testCase.customBuild.commitSha}
                                sx={{ display: 'inline' }}
                            />
                        </>
                    )}
                    )
                </Typography>
            )}
        </Box>
    );
}
