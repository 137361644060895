import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch } from '../../store';
import { SliceStatus } from '../commons/enums';
import ClearFiltersButton from '../commons/filters/ClearFiltersButton';
import ColumnFilter from '../commons/filters/ColumnFilter';
import { CollapseFilters, FiltersContainer } from '../commons/filters/commons';
import { parseFilterOptions } from '../commons/filters/utils';
import {
    getBuildAgents,
    selectFilterHostname,
    selectFilterProject,
    selectFilterState,
    setFilterState,
    setFilterUnity,
    setFilterSupportedProject,
    setFilterVersion,
    selectFilterUnity,
    clearAllFilters,
    selectBuildAgentColumns,
    selectAmountOfActiveAgentsFilters,
    setStatus,
    selectFilterGitInfo,
    setFilterGifInfo,
    selectFilterVersion,
    setFilterHostname,
    selectFilterXcode,
    setFilterXcode,
} from './buildAgentsSlice';
import { AgentsColumnId } from './enums';
import { getHashFromGitInfo } from './table/BuildAgentCommitHash';

interface FilterProps {
    showOnlyValues?: boolean;
}

export const filters: { [id: string]: (props: FilterProps) => ReactElement } = {
    [AgentsColumnId.name]: HostnameSelector,
    [AgentsColumnId.state]: StateSelector,
    [AgentsColumnId.unityVersions]: UnitySelector,
    [AgentsColumnId.xcode]: XcodeSelector,
    [AgentsColumnId.projects]: SupportedProjectsSelector,
    [AgentsColumnId.commit]: CommitHashSelector,
    [AgentsColumnId.version]: AgentVersionSelector,
};

export default function BuildAgentsAppliedFilters(): ReactElement | null {
    const hasFilters = !!useSelector(selectAmountOfActiveAgentsFilters);
    const columns = useSelector(selectBuildAgentColumns);

    return (
        <CollapseFilters in={hasFilters} data-testid='agents-filters'>
            <FiltersContainer>
                {columns
                    .filter((column) => filters[column.id])
                    .map((column): ReactElement => {
                        const Filter = filters[column.id];
                        return <Filter key={column.id} showOnlyValues />;
                    })}
                <ClearAllFiltersButton />
            </FiltersContainer>
        </CollapseFilters>
    );
}

function HostnameSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectFilterHostname);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterHostname(newValue as string | null));
        dispatch(getBuildAgents());
        dispatch(setStatus(SliceStatus.loading));
    };

    return (
        <ColumnFilter
            id='hostname-selector'
            label='Hostname'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

function SupportedProjectsSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectFilterProject);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterSupportedProject(newValue as string | null));
        setTimeout(() => {
            dispatch(getBuildAgents());
            dispatch(setStatus(SliceStatus.loading));
        }, 50);
    };

    return (
        <ColumnFilter
            id='projects-selector'
            label='Projects'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

function CommitHashSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectFilterGitInfo);
    const parsedOptions = (options || [])
        .map((option) => {
            const { shortCommitHash } = getHashFromGitInfo(option);

            return {
                value: option,
                label: String(shortCommitHash || ''),
            };
        })
        .filter((option) => option.value);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterGifInfo(newValue as string | null));
        setTimeout(() => {
            dispatch(getBuildAgents());
            dispatch(setStatus(SliceStatus.loading));
        }, 50);
    };

    return (
        <ColumnFilter
            id='commit-hash-selector'
            label='Commit Hash'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

function StateSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectFilterState);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterState(newValue as string | null));
        setTimeout(() => {
            dispatch(getBuildAgents());
            dispatch(setStatus(SliceStatus.loading));
        }, 50);
    };

    return (
        <ColumnFilter
            id='state-selector'
            label='State'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

function UnitySelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectFilterUnity);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterUnity(newValue as string | null));
        setTimeout(() => {
            dispatch(getBuildAgents());
            dispatch(setStatus(SliceStatus.loading));
        }, 50);
    };

    return (
        <ColumnFilter
            id='unity-selector'
            label='Unity'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

function XcodeSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectFilterXcode);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterXcode(newValue as string | null));
        setTimeout(() => {
            dispatch(getBuildAgents());
            dispatch(setStatus(SliceStatus.loading));
        }, 50);
    };

    return (
        <ColumnFilter
            id='Xcode-selector'
            label='Xcode'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

function AgentVersionSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectFilterVersion);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterVersion(newValue as string | null));
        setTimeout(() => {
            dispatch(getBuildAgents());
            dispatch(setStatus(SliceStatus.loading));
        }, 50);
    };

    return (
        <ColumnFilter
            id='version-selector'
            label='Version'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

function ClearAllFiltersButton(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();

    const handleClearClick = () => {
        dispatch(clearAllFilters());
        setTimeout(() => {
            dispatch(getBuildAgents());
            dispatch(setStatus(SliceStatus.loading));
        }, 50);
    };

    return <ClearFiltersButton onClick={handleClearClick} />;
}
