import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { setHighlightBuildAgents } from '../buildAgents/buildAgentsSlice';
import CustomLink from './CustomLink';
import getBuildAgentName from './getBuildAgentName';
import { IBuildAgent } from './interfaces';

interface LinkToBuildAgentProps {
    buildAgent?: IBuildAgent;
}
export default function LinkToBuildAgent({ buildAgent }: LinkToBuildAgentProps): ReactElement | null {
    const dispatch = useDispatch();
    const name = getBuildAgentName(buildAgent);

    if (!buildAgent) {
        return null;
    }

    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        dispatch(setHighlightBuildAgents([buildAgent._id]));
    };

    return (
        <CustomLink
            to={`/agents?hostname=${buildAgent.hostname}`}
            onMouseUp={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
            onClick={handleOnClick}
        >
            {name}
        </CustomLink>
    );
}
