import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { TableRow } from '@mui/material';
import BuildAgentProjects from '../buildAgents/table/BuildAgentProject';
import TableTextCell from '../commons/TableTextCell';
import AcceleratorActions from './AcceleratorActions';
import { selectAcceleratorColumns } from './acceleratorSlice';
import { AcceleratorColumnId } from './enums';
import { IAcceleratorDetails } from './interfaces';

interface AcceleratorRowProps {
    acceleratorDetails: IAcceleratorDetails;
}

export default function AcceleratorRow({ acceleratorDetails }: AcceleratorRowProps): ReactElement {
    const columns = useSelector(selectAcceleratorColumns);
    const { ip, port } = acceleratorDetails;
    const address = `${ip}:${port}`;

    return (
        <TableRow>
            {columns.map((column) => {
                switch (column.id) {
                    case AcceleratorColumnId.serviceName:
                        return <TableTextCell text={acceleratorDetails.serviceName} key={column.id} />;
                    case AcceleratorColumnId.host:
                        return <TableTextCell text={acceleratorDetails.hostname} key={column.id} />;
                    case AcceleratorColumnId.address:
                        return <TableTextCell text={address} key={column.id} />;
                    case AcceleratorColumnId.games:
                        return <BuildAgentProjects projects={acceleratorDetails.games} key={column.id} />;
                    case AcceleratorColumnId.actions:
                        return <AcceleratorActions acceleratorDetails={acceleratorDetails} key={column.id} />;
                    default:
                        return <></>;
                }
            })}
        </TableRow>
    );
}
