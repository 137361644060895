import { ReactElement } from 'react';
import ColumnFilter from '../../commons/filters/ColumnFilter';
import { useGetAvailableOptionsQuery } from '../testSuitesApi';
import { ITestSuitesFilterProps } from './TestSuitesFilters';

export default function TestDeviceSelector({
    filters,
    onFiltersChange,
    showOnlyValues,
}: ITestSuitesFilterProps): ReactElement {
    const selected = filters.devices;
    const { data, isFetching: loading } = useGetAvailableOptionsQuery();

    const parsedOptions =
        data?.options.devices?.map((device) => ({
            value: device._id,
            label: device.name || device.deviceInfo?.manufacturer + ' ' + device.deviceInfo.model,
        })) || [];

    const handleFiltersChange = (newValue: string[] | null) => {
        onFiltersChange({
            ...filters,
            devices: newValue || undefined,
        });
    };
    return (
        <ColumnFilter
            id='device-selector'
            label='Devices'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={handleFiltersChange}
            showOnlyValues={showOnlyValues}
        />
    );
}
