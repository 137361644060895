export function parsePropertyToLabel(property = ''): string {
    const label = capitalizeFirstLetter(property.trim().replace(/_/g, ' '));
    return transformShortWordsToUppercase(label);
}

function capitalizeFirstLetter(string: string): string {
    return string
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const WORD_SIZE = 3;
function transformShortWordsToUppercase(label: string): string {
    return label
        .split(' ')
        .map((word) => (word.trim().length <= WORD_SIZE ? word.trim().toUpperCase() : word.trim()))
        .join(' ');
}
