import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../../store';
import {
    selectAgentMaintenanceDialog,
    toggleShowMaintenanceDialog,
    doMaintenance,
    rebootAgent,
} from '../buildAgentsSlice';
import { IBuildAgentSliceState } from '../interfaces';
import AgentCard from './AgentCard';
import RebootCard from './RebootCard';

export default function AgentMaintenanceDialog(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildAgentSliceState>>();
    const { open, action, buildAgent, agentsOnMachine } = useSelector(selectAgentMaintenanceDialog);

    const [reboot, setReboot] = useState<boolean>(false);
    const [all, setAll] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setLoading(false);
        setReboot(false);
        setAll(false);
        dispatch(toggleShowMaintenanceDialog({ action, buildAgent }));
    };

    const handleSubmit = () => {
        setLoading(true);
        if (!buildAgent) {
            return;
        }

        if (reboot) {
            dispatch(rebootAgent(`${buildAgent.ip}:${buildAgent.port}`));
        } else {
            const agentsToHandle = all
                ? agentsOnMachine?.map((agent) => {
                      return { ip: agent.ip, port: agent.port.toString() };
                  })
                : [{ ip: buildAgent.ip, port: buildAgent.port.toString() }];
            dispatch(doMaintenance(agentsToHandle || []));
        }

        setTimeout(() => {
            handleClose();
        }, 3000);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
            <DialogTitle sx={{ color: blueGray[800] }}>
                {'Maintenance Mode'}
                <Typography variant='subtitle1' color='text.secondary'>
                    Set agent for maintenance and other settings
                </Typography>
                <Divider sx={{ mx: -4, mt: 2 }} />
            </DialogTitle>
            <DialogContent>
                {reboot && <RebootCard hostname={buildAgent?.hostname} />}
                {!reboot ? (
                    all && agentsOnMachine && agentsOnMachine?.length > 1 ? (
                        <>
                            <Typography fontSize={14} mb={2}>{`${agentsOnMachine.length} agent(s)`}</Typography>
                            <Box style={{ display: 'flex', gap: 4, overflowX: 'auto' }}>
                                {agentsOnMachine?.map((agent) => <AgentCard key={agent._id} buildAgent={agent} />)}
                            </Box>
                        </>
                    ) : (
                        <AgentCard buildAgent={buildAgent} />
                    )
                ) : null}
                <Divider flexItem sx={{ mx: -4, mt: 2, mb: 2 }} />
                <FormGroup>
                    <FormControlLabel
                        disabled={reboot}
                        control={
                            <Checkbox
                                checked={all}
                                onChange={(e: React.ChangeEvent<any>) => setAll(e.target.checked)}
                            />
                        }
                        label='Run maintenance on all agents'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={reboot}
                                onChange={(e: React.ChangeEvent<any>) => {
                                    setReboot(e.target.checked);
                                    setAll(!e.target.checked);
                                }}
                            />
                        }
                        label='Reboot machine'
                    />
                </FormGroup>
                <Divider flexItem sx={{ mx: -4, mt: 2 }} />
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant='contained'
                        color='primary'
                        style={{ minWidth: '80px', alignSelf: 'flex-end' }}
                    >
                        {loading ? (
                            <CircularProgress size={15} variant='indeterminate' color='warning' />
                        ) : reboot ? (
                            'Reboot'
                        ) : (
                            'Run'
                        )}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
