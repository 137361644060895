import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Typography } from '@mui/material';
import { AppThunkDispatch } from '../../store';
import ArtifactContentRenderer from './ArtifactContentRenderer';
import { AccordionButtons, ArtifactSize, FileIcon } from './ArtifactsAccordion';
import {
    getBuildResultArtifact,
    getBuildResultArtifactContent,
    preventAndStopClick,
    selectBuildDetailsBuild,
} from './buildDetailsSlice';
import { IArtifact } from './interfaces';
import { ARTIFACT_LINE_HEIGHT } from './StepsAccordion';

export default function SingleArtifactAccordion(): ReactElement | null {
    const dispatch = useDispatch<AppThunkDispatch>();
    const location = useLocation();
    const filename = location.pathname.split('/').pop();
    const build = useSelector(selectBuildDetailsBuild);
    const [loadingContent, setLoadingContent] = useState(false);
    const [contentLines, setContentLines] = useState(0);
    const [content, setContent] = useState('');
    const [artifact, setArtifact] = useState<IArtifact>();

    useEffect(() => {
        if (!artifact && filename) {
            dispatch(getBuildResultArtifact(filename)).then((action) => {
                if (action?.payload?.artifact) {
                    setArtifact(action.payload.artifact);
                }
            });
        }

        if (build && !content && filename) {
            setLoadingContent(true);
            dispatch(getBuildResultArtifactContent(filename)).then((action) => {
                if (action?.payload) {
                    setContentLines(action.payload?.split('\n').length || 0);
                    setContent(action.payload);
                }
                setLoadingContent(false);
            });
        }
    }, [artifact, build, content, dispatch, filename]);

    if (!filename || !build) {
        return null;
    }

    const fileExtension = filename.split('.')?.pop();

    return (
        <>
            <Button
                variant='text'
                size='small'
                sx={{ color: (theme) => theme.palette.text.secondary, m: 0, p: 1 }}
                startIcon={<ArrowBackIcon />}
                component={Link}
                to={`/builds/${build.projectName}/${build.buildNumber}`}
            >
                Go Back
            </Button>
            <Accordion sx={{ backgroundColor: (theme) => theme.palette.common.white }} expanded={true}>
                <AccordionSummary
                    sx={{
                        cursor: 'default',
                        '.MuiAccordionSummary-content': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                >
                    <FileIcon fileExtension={fileExtension} />
                    <Typography
                        component='span'
                        variant='body2'
                        sx={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '20vw',
                            ml: 1,
                            flexGrow: 1,
                        }}
                    >
                        {filename || 'filename not available'}
                        {artifact && (
                            <Typography
                                component='span'
                                variant='body2'
                                sx={{ color: (theme) => theme.palette.text.disabled, my: 0, mx: 1 }}
                            >
                                {' '}
                                {artifact.step}{' '}
                            </Typography>
                        )}
                    </Typography>

                    {artifact && <ArtifactSize size={artifact.size} />}
                    {artifact && <AccordionButtons artifact={artifact} />}
                </AccordionSummary>

                {!content && (
                    <AccordionDetails
                        onClick={preventAndStopClick}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                                margin: 1,
                            },
                        }}
                    >
                        {loadingContent ? (
                            <CircularProgress variant='indeterminate' size={20} />
                        ) : (
                            <Typography>This artifact has no displayable content</Typography>
                        )}
                    </AccordionDetails>
                )}
                {content && (
                    <AccordionDetails sx={{ p: 0 }} onClick={preventAndStopClick}>
                        <div
                            style={{
                                maxHeight: '70vh',
                                width: '100%',
                                height: `${4 * ARTIFACT_LINE_HEIGHT + contentLines * ARTIFACT_LINE_HEIGHT}px`,
                            }}
                        >
                            <ArtifactContentRenderer content={content} type={fileExtension} />
                        </div>
                    </AccordionDetails>
                )}
            </Accordion>
        </>
    );
}
