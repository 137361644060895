import { ReactElement } from 'react';
import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { ITestDeviceError } from '../interfaces';

export default function TestDeviceErrorRow({
    error,
    onErrorChange,
}: {
    error: ITestDeviceError;
    onErrorChange: (error: ITestDeviceError) => void;
}): ReactElement {
    return (
        <Grid container spacing={1} alignItems='center' data-testid='test-device-error-row'>
            <Grid item xs={1}>
                <TextField
                    value={error.code || null}
                    onChange={(event) => {
                        onErrorChange({
                            ...error,
                            code: Number(event.target.value || 0),
                        });
                    }}
                    required
                    label='Code'
                    type='number'
                    variant='outlined'
                    autoComplete='off'
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    value={error.name}
                    onChange={(event) => {
                        onErrorChange({
                            ...error,
                            name: event.target.value,
                        });
                    }}
                    required
                    label='Name'
                    type='text'
                    variant='outlined'
                    autoComplete='off'
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    value={error.tag || ''}
                    onChange={(event) => {
                        onErrorChange({
                            ...error,
                            tag: event.target.value,
                        });
                    }}
                    label='Device Tag'
                    type='text'
                    variant='outlined'
                    autoComplete='off'
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                error.action.isElegibleForQueuedTests === undefined
                                    ? false
                                    : !error.action.isElegibleForQueuedTests
                            }
                            onChange={(event) => {
                                onErrorChange({
                                    ...error,
                                    action: {
                                        ...error.action,
                                        isElegibleForQueuedTests: !event.target.checked,
                                        isDisabled: !event.target.checked ? false : error.action.isDisabled,
                                    },
                                });
                            }}
                        />
                    }
                    label='Disable for scheduled tests'
                />
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={error.action.isDisabled || false}
                            onChange={(event) => {
                                onErrorChange({
                                    ...error,
                                    action: {
                                        ...error.action,
                                        isElegibleForQueuedTests: event.target.checked
                                            ? false
                                            : error.action.isElegibleForQueuedTests,
                                        isDisabled: event.target.checked,
                                    },
                                });
                            }}
                        />
                    }
                    label='Disable'
                />
            </Grid>
        </Grid>
    );
}
