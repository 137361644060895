import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { IFavourite, IUser, IUserPreferences } from './interfaces';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['CurrentUser', 'Favourites'],
    endpoints: (builder) => ({
        getCurrentUser: builder.query<{ user?: IUser; internal?: boolean }, void>({
            query: () => ({
                url: 'users',
            }),
            providesTags: ['CurrentUser'],
        }),
        getFavourites: builder.query<IFavourite[], void>({
            query: () => ({
                url: '/favourites',
            }),
            transformResponse: (res: { favourites: IFavourite[] }) => res.favourites,
            providesTags: ['Favourites'],
        }),
        saveToFavourites: builder.mutation<void, Omit<IFavourite, '_id'>>({
            query: (favourite) => ({
                method: 'POST',
                url: '/favourites',
                body: favourite,
            }),
            invalidatesTags: ['Favourites'],
        }),
        deleteFavourite: builder.mutation<void, string>({
            query: (favouriteId) => ({
                method: 'DELETE',
                url: `/favourites/${favouriteId}`,
            }),
            invalidatesTags: ['Favourites'],
        }),
        updateAuthorizationToken: builder.mutation<void, void>({
            query: () => ({
                method: 'PATCH',
                url: '/users/authorization',
            }),
            invalidatesTags: ['CurrentUser'],
        }),
        updatePreferences: builder.mutation<void, IUserPreferences>({
            query: (preferences: IUserPreferences) => ({
                method: 'PATCH',
                url: '/users/preferences',
                body: preferences,
            }),
            invalidatesTags: ['CurrentUser'],
        }),
    }),
});

export const {
    useGetCurrentUserQuery,
    useGetFavouritesQuery,
    useSaveToFavouritesMutation,
    useDeleteFavouriteMutation,
    useUpdateAuthorizationTokenMutation,
    useUpdatePreferencesMutation,
} = usersApi;
