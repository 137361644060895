import { ReactElement } from 'react';
import { NotInterested } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';

export default function TestDeviceDisabledIcon({ size }: { size?: 'large' | 'medium' | 'small' }): ReactElement {
    return (
        <Tooltip title={<Typography variant='subtitle2'>This test device does not accept new tests.</Typography>}>
            <NotInterested fontSize={size} sx={{ verticalAlign: 'bottom' }} />
        </Tooltip>
    );
}
