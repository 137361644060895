import { ITestDevice } from '../testDevices/interfaces';
import { ETestStatus, ITest } from './interfaces';

export type TestQueue = Map<string, { queue: number; test: ITest }>;

export function getTestsQueue(tests?: ITest[]): TestQueue {
    const queue = new Map();

    if (!tests?.length) {
        return queue;
    }

    for (const test of tests) {
        if (!test.testDevice) {
            continue;
        }
        queue.set(test.testDevice.uuid, {
            queue: (queue.get(test.testDevice?.uuid)?.queue || 0) + 1,
            test: test.status === ETestStatus.running ? test : undefined,
        });
    }
    return queue;
}

export function addTestsQueueInfo(
    testDevices: ITestDevice[] | undefined,
    testQueue: TestQueue,
): ITestDevice[] | undefined {
    return testDevices?.map((testDevice) => ({
        ...testDevice,
        queueLength: testQueue.get(testDevice.uuid)?.queue,
        testRunning: testQueue.get(testDevice.uuid)?.test,
    }));
}
