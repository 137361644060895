import { Link, LinkProps } from 'react-router-dom';
import { Typography, TypographyProps, styled } from '@mui/material';

// eslint-disable-next-line no-unused-vars
const CustomLink = styled(({ ref, to, ...props }: TypographyProps & LinkProps) => (
    <Typography noWrap color='primary' component={Link} to={to} {...props} />
))(({ theme }) => ({
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary.dark,
    },
    cursor: 'pointer',
    display: 'block',
}));

export default CustomLink;
