import { ReactElement } from 'react';
import CancelIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import PauseIcon from '@mui/icons-material/Pause';
import WarningIcon from '@mui/icons-material/WarningRounded';
import { CircularProgress, Chip, SxProps } from '@mui/material';
import { blue, blueGray, green, red } from '@tactileentertainment/core-designsystem';
import { EBuildStatus } from './enums';

export interface IBuildStatusChipProps {
    status: EBuildStatus;
    size?: number;
    forcedMobile?: boolean;
    sx?: SxProps;
}

export default function BuildStatusChip({
    status,
    size = 20,
    forcedMobile,
    sx = {},
}: IBuildStatusChipProps): ReactElement | null {
    const isMobile = !!forcedMobile;
    const icon = getIcon(status, !!isMobile, size);

    return isMobile ? (
        icon
    ) : (
        <Chip
            sx={{
                borderRadius: 5,
                fontWeight: 600,
                color: (theme) => theme.palette.common.white,
                maxHeight: 28,
                textTransform: 'capitalize',
                '.MuiChip-icon': {
                    fontSize: 18,
                    color: (theme) => theme.palette.common.white,
                },
                ...(status === EBuildStatus.queued && { backgroundColor: blueGray[700] }),
                ...(status === EBuildStatus.running && { backgroundColor: blue[700] }),
                ...(status === EBuildStatus.cancelled && {
                    backgroundColor: blueGray[300],
                    color: blueGray[800],
                    '.MuiChip-icon': {
                        color: blueGray[800],
                    },
                }),
                ...(status === EBuildStatus.finished && { backgroundColor: green[700] }),
                ...(status === EBuildStatus.failed && { backgroundColor: red[700] }),
                ...sx,
            }}
            icon={icon}
            label={status?.toLowerCase()}
        />
    );
}

function getIcon(status: EBuildStatus, isMobile: boolean, size?: number): ReactElement {
    switch (status) {
        case EBuildStatus.queued:
            return (
                <PauseIcon
                    titleAccess={status}
                    sx={{
                        color: (theme) => (isMobile ? theme.palette.tactile.blueGray[400] : theme.palette.common.white),
                    }}
                />
            );
        case EBuildStatus.running:
            return (
                <CircularProgress
                    title={status}
                    size={size}
                    variant='indeterminate'
                    sx={{ color: (theme) => (isMobile ? theme.palette.tactile.blue[700] : theme.palette.common.white) }}
                />
            );
        case EBuildStatus.cancelled:
            return (
                <CancelIcon
                    titleAccess={status}
                    sx={{
                        color: (theme) => (isMobile ? theme.palette.tactile.blueGray[700] : theme.palette.common.white),
                    }}
                />
            );
        case EBuildStatus.failed:
            return (
                <WarningIcon
                    titleAccess={status}
                    sx={{ color: (theme) => (isMobile ? theme.palette.error.main : theme.palette.common.white) }}
                />
            );
        case EBuildStatus.finished:
            return (
                <CheckCircleIcon
                    titleAccess={status}
                    sx={{ color: (theme) => (isMobile ? theme.palette.success.main : theme.palette.common.white) }}
                />
            );
        default:
            return (
                <HelpIcon
                    titleAccess={status}
                    sx={{
                        color: (theme) => (isMobile ? theme.palette.tactile.blueGray[400] : theme.palette.common.white),
                    }}
                />
            );
    }
}
