import AutocompleteFilter from '../commons/filters/AutocompleteFilter';

interface GameAndBranchSelectorProps {
    projects: string[];
    onChangeProject: (value?: string) => void;
    selectedProject?: string;
    projectsLoading?: boolean;
    branches: string[];
    branchesLoading?: boolean;
    selectedBranch?: string;
    onChangeBranch: (value?: string) => void;
    isProjectDisabled?: boolean;
    isBranchDisabled?: boolean;
}

export const GameAndBranchSelector = ({
    projects,
    branches,
    selectedProject,
    onChangeProject,
    projectsLoading,
    branchesLoading,
    selectedBranch,
    onChangeBranch,
    isProjectDisabled,
    isBranchDisabled,
}: GameAndBranchSelectorProps) => {
    return (
        <>
            <AutocompleteFilter
                id='projects'
                options={projects}
                onChange={onChangeProject}
                selected={selectedProject}
                placeholder='Project'
                loading={projectsLoading}
                size='medium'
                disabled={isProjectDisabled}
            />
            <AutocompleteFilter
                id='branch'
                options={branches}
                onChange={onChangeBranch}
                selected={selectedBranch}
                placeholder='Branch'
                loading={branchesLoading}
                size='medium'
                disabled={isBranchDisabled}
            />
        </>
    );
};
