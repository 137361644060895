export enum SettingType {
    select = 'select',
    checkbox = 'checkbox',
    radio = 'radio',
    switch = 'switch',
    number = 'number',
    text = 'text',
}

export interface IConfigurationSettings {
    [setting: string]: ISetting;
}

export interface ISetting {
    settingKey?: string;
    display_name: string;
    type: SettingType;
    group?: string;
    multi?: boolean;
    values?: string[];
    default?: number | string | string[] | boolean;
    required?: boolean;
    hidden?: boolean;
}

export interface ISelectedSettings {
    [settingKey: string]: TSetting;
}

export type TSetting = number | number[] | string | string[] | boolean | null | undefined;

export interface ISettingsByGroup {
    [group: string]: ISetting[];
}
