import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
    Menu as MenuIcon,
    Check as CheckIcon,
    AddCircleOutline as AddCircleIcon,
    SignalWifi2BarLock as InternalIpIcon,
    Close as DismissIcon,
} from '@mui/icons-material';
import { AppBar, Box, Button, Fade, Toolbar, Typography, IconButton, useMediaQuery } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { SuperAdminToolbar, useLogin } from '@tactileentertainment/core-shared.auth-react';
import { AppThunkDispatch } from '../../store';
import { BuildDetailsRegExp } from '../../utils';
import { selectBuildDetailsBuildReleaseCandidate } from '../buildDetails/buildDetailsSlice';
import { getProjects } from '../newBuild/newBuildSlice';
import { useGetProjectNamesQuery } from '../projects/projectsApi';
import { selectSelectedMenu, setDrawerOpen, setSelectedMenu } from './headerSlice';
import { getInitialMenuItem, menuItems, newBuildMenu } from './menuItems';
import NavButton from './NavButton';
import NavButtonGroup from './NavButtonGroup';
import ProfileContextMenu from './ProfileContextMenu';

export default function BtcAppBar({
    userIsLoggedIn,
    userIsInternal,
}: {
    userIsLoggedIn: boolean;
    userIsInternal: boolean;
}): ReactElement {
    const isLoggedInOrInternal = userIsLoggedIn || userIsInternal;

    return (
        <AppBar
            position='sticky'
            sx={{
                transition: (theme) =>
                    theme.transitions.create(['margin', 'width'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <SuperAdminToolbar />
            {userIsInternal && <InternalIpToolbar />}
            <Toolbar variant='dense'>
                {isLoggedInOrInternal && <AppBarMenu />}
                <Box sx={{ ml: 'auto', display: 'flex' }}>
                    {isLoggedInOrInternal && <BuildsActions />}
                    <ProfileContextMenu />
                </Box>
            </Toolbar>
            <ReleaseCandidateToolbar />
        </AppBar>
    );
}

function AppBarMenu(): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const dispatch = useDispatch<AppThunkDispatch>();
    const location = useLocation();
    const selectedMenu = useSelector(selectSelectedMenu);
    const { data: projects } = useGetProjectNamesQuery();

    useEffect(() => {
        const menuItem = getInitialMenuItem();
        if (menuItem) {
            dispatch(setSelectedMenu({ to: menuItem.to, text: menuItem.text }));
        }
    }, [location.pathname, dispatch]);

    if (!projects?.length) {
        return <Box sx={{ flex: 1, color: (theme) => theme.palette.common.white }} />;
    }

    return (
        <Box sx={{ flex: 1, display: 'flex', color: (theme) => theme.palette.common.white }}>
            {isMobile ? (
                <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={() => dispatch(setDrawerOpen(true))}
                    edge='start'
                    sx={{ color: (theme) => theme.palette.common.white, p: 0, mr: 1, ml: 0 }}
                >
                    <MenuIcon />
                    <Typography
                        sx={{ flex: 1, color: (theme) => theme.palette.common.white, ml: 1 }}
                        variant='body1'
                        noWrap
                    >
                        <strong>{selectedMenu?.text}</strong>
                    </Typography>
                </IconButton>
            ) : (
                <>
                    {menuItems
                        .filter((items) => !items.mobileOnly)
                        .map((item, index) => {
                            if (item.options) {
                                return <NavButtonGroup key={index} item={item} selected={selectedMenu} />;
                            }

                            return <NavButton key={index} item={item} selected={selectedMenu} />;
                        })}
                </>
            )}
        </Box>
    );
}

function BuildsActions(): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const dispatch = useDispatch<AppThunkDispatch>();

    const handleOnClick = () => {
        dispatch(getProjects());
        dispatch(setSelectedMenu({ to: newBuildMenu.to, text: newBuildMenu.text }));
    };

    return (
        <>
            {!isMobile && (
                <>
                    <Button
                        variant='text'
                        color='inherit'
                        sx={{ margin: '0px 8px' }}
                        component={Link}
                        to='/new-build'
                        onClick={handleOnClick}
                        startIcon={<AddCircleIcon />}
                    >
                        Order Build
                    </Button>
                </>
            )}
        </>
    );
}

function InternalIpToolbar(): ReactElement | null {
    const { isLoggedIn: userIsLoggedIn, handleLogin } = useLogin();
    const [showToolbarTimeout, setShowToolbarTimeout] = useState(true);

    useEffect(() => {
        let timeoutRef: NodeJS.Timeout;
        if (!userIsLoggedIn && showToolbarTimeout) {
            timeoutRef = setTimeout(() => {
                setShowToolbarTimeout(false);
            }, 5000);
        }

        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
        };
    }, [showToolbarTimeout, userIsLoggedIn]);

    const handleDismiss = () => {
        setShowToolbarTimeout(false);
    };

    return (
        <Fade in={!userIsLoggedIn && showToolbarTimeout}>
            <Toolbar
                variant='dense'
                sx={{
                    backgroundColor: blueGray[800],
                    minHeight: 'unset',
                    height: '48px',
                    zIndex: 1000,
                    position: 'absolute',
                    top: 48,
                    width: '100%',
                }}
            >
                <InternalIpIcon color='inherit' fontSize='small' />
                <span
                    style={{
                        flexGrow: 1,
                        padding: '0px 8px',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                    }}
                >
                    You are accessing from within Tactile&apos;s network. Please{' '}
                    <Link
                        color='inherit'
                        to={window.location.pathname}
                        onClick={handleLogin}
                        style={{ color: 'inherit' }}
                    >
                        login
                    </Link>{' '}
                    to order builds and access all features.
                </span>
                <IconButton onClick={handleDismiss} title='Dismiss' color='inherit' sx={{ margin: 0, padding: 0 }}>
                    <DismissIcon color='inherit' fontSize='small' />
                </IconButton>
            </Toolbar>
        </Fade>
    );
}

function ReleaseCandidateToolbar(): ReactElement | null {
    const location = useLocation();
    const releaseCandidate = useSelector(selectBuildDetailsBuildReleaseCandidate);
    if (!BuildDetailsRegExp.test(location.pathname) || !releaseCandidate) {
        return null;
    }

    return (
        <Toolbar
            variant='dense'
            sx={{
                backgroundColor: (theme) => theme.palette.success.main,
                minHeight: 'unset',
                height: '30px',
            }}
        >
            <CheckIcon color='inherit' fontSize='small' />
            <span style={{ flexGrow: 1, padding: '0px 8px' }}>This build is a release candidate</span>
        </Toolbar>
    );
}
