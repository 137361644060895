import { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Table, TableCell, TableHead } from '@mui/material';
import { EBuildPlatform } from '../builds/enums';
import ProjectNameFilter from '../commons/filters/ProjectNameFilter';
import HeaderSection from '../commons/HeaderSection';
import NoResultsFound from '../commons/NoResultsFound';
import Page from '../commons/Page';
import * as queryString from '../commons/queryString';
import TableBody from '../commons/TableBody';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import { useGetProjectsQuery } from '../projects/projectsApi';
import { defaultLanguage } from './appUploaderLanguages';
import { IReleaseNote } from './interfaces';
import ReleaseNoteRow from './ReleaseNoteRow';
import { useGetReleaseNotesQuery } from './releaseNotesApi';
import { columns } from './releaseNotesColumns';
import ReleaseNotesDeleteDialog from './ReleaseNotesDeleteDialog';
import ReleaseNotesEditDialog from './ReleaseNotesEditDialog';

export default function ReleaseNotesList(): ReactElement {
    const { search } = useLocation();
    const [selectedProjects, setSelectedProjects] = useState<string[]>(getFiltersFromUrl(search).projectName);
    const { data: projects } = useGetProjectsQuery();
    const projectNames = projects?.map((project) => project.name) || [];
    const { data: releaseNotes, isLoading } = useGetReleaseNotesQuery({ projectName: selectedProjects });
    const [editDialog, setEditDialog] = useState<{
        isNew?: boolean;
        isClone?: boolean;
        isDelete?: boolean;
        releaseNote: IReleaseNote;
    } | null>(null);

    return (
        <Page>
            <HeaderSection
                title='Release Notes'
                actionBox={
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: { xs: 'flex-start', md: 'center' },
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <ProjectNameFilter
                            projectList={projectNames}
                            selectedProjects={selectedProjects}
                            onSelectedProjectsChange={(value) => {
                                setSelectedProjects(value);
                                setFiltersToUrl({ projectName: value });
                            }}
                        />
                        <Button
                            variant='contained'
                            color='primary'
                            startIcon={<AddIcon />}
                            onClick={() =>
                                setEditDialog({
                                    isNew: true,
                                    releaseNote: createNewReleaseNote(selectedProjects[0] || projectNames[0]),
                                })
                            }
                            sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 }, width: { xs: '100%', md: 'auto' } }}
                        >
                            New Release Note
                        </Button>
                    </Box>
                }
                breadcrumbs={[{ text: 'Projects', href: '/projects' }, { text: 'Release Notes' }]}
            />
            <TableContainer sx={{ background: (theme) => theme.palette.common.white, height: '100%' }}>
                <Table stickyHeader style={{ width: '100%', tableLayout: 'fixed' }} data-testid='release-notes-table'>
                    <TableHead data-testid='tests-table-head'>
                        <TableHeaderRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    size='small'
                                    sx={{
                                        width: column.width,
                                        minWidth: column.minWidth,
                                        display: column.visible ? undefined : 'none',
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableHeaderRow>
                    </TableHead>
                    {releaseNotes && (
                        <TableBody>
                            {releaseNotes.map((releaseNote) => (
                                <ReleaseNoteRow
                                    key={releaseNote._id}
                                    releaseNote={releaseNote}
                                    onEdit={() => setEditDialog({ releaseNote })}
                                    onClone={() => setEditDialog({ isClone: true, releaseNote })}
                                    onDelete={() => setEditDialog({ isDelete: true, releaseNote })}
                                />
                            ))}
                        </TableBody>
                    )}
                </Table>
                {!isLoading && !releaseNotes?.length && <NoResultsFound text="We couldn't find any release notes" />}
            </TableContainer>
            {editDialog && !editDialog.isDelete && (
                <ReleaseNotesEditDialog
                    open={Boolean(editDialog)}
                    isNew={editDialog.isNew}
                    isClone={editDialog.isClone}
                    releaseNote={editDialog.releaseNote}
                    onClose={() => setEditDialog(null)}
                />
            )}
            {editDialog?.isDelete && (
                <ReleaseNotesDeleteDialog
                    open={Boolean(editDialog?.isDelete)}
                    releaseNote={editDialog.releaseNote}
                    onClose={() => setEditDialog(null)}
                />
            )}
        </Page>
    );
}

function createNewReleaseNote(projectName: string): IReleaseNote {
    return {
        projectName,
        title: '',
        localizedMessages: [
            {
                language: defaultLanguage,
                message: '',
                platforms: [EBuildPlatform.android, EBuildPlatform.ios, EBuildPlatform.kindle],
            },
        ],
    };
}

function getFiltersFromUrl(search: string): { projectName: string[] } {
    const params = queryString.parse(search);
    const projectName = params.projectName || [];
    return {
        projectName: Array.isArray(projectName) ? (projectName as string[]) : [projectName],
    };
}

function setFiltersToUrl(filters: { projectName: string[] }): void {
    const newParams = queryString.stringify({
        projectName: filters.projectName,
    });

    window.history.replaceState(null, 'update search query', `?${newParams}`);
}
