import { ReactElement } from 'react';
import { Autocomplete, AutocompleteProps, Box, Collapse, CollapseProps, styled, useTheme } from '@mui/material';

export const FiltersContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    '& >> .MuiAutocomplete-tag': {
        padding: 10,
    },
}));

export const CollapseFilters = styled((props: CollapseProps) => <Collapse {...props} />)({});

interface ResponsiveAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'size'> {
    size?: 'xSmall' | 'small' | 'medium';
}

export function ResponsiveAutocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(props: ResponsiveAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): ReactElement {
    const { size, ...defaultProps } = props;
    const theme = useTheme();

    return (
        <Autocomplete
            {...defaultProps}
            size={size === 'small' ? 'small' : undefined}
            sx={{
                flexGrow: 1,
                [theme.breakpoints.down('md')]: {
                    width: '45%',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                },
                '& .MuiAutocomplete-input, .MuiInputBase-root': {
                    margin: 0,
                },
                ...(props.size === 'small' && {
                    width: '140px',
                    flexGrow: 0.5,
                    [theme.breakpoints.down('md')]: {
                        flexGrow: 1,
                        width: '45%',
                    },
                    [theme.breakpoints.down('sm')]: {
                        flexGrow: 1,
                        width: '100%',
                    },
                }),
                ...(props.size === 'medium' && {
                    minWidth: '170px',
                    maxWidth: 'auto',
                    flexGrow: 1.1,
                    [theme.breakpoints.down('sm')]: {
                        flexGrow: 1.5,
                        width: '100%',
                    },
                    [theme.breakpoints.down('md')]: {
                        flexGrow: 1,
                        width: '45%',
                    },
                }),
                ...(props.size === 'xSmall' && {
                    width: '110px',
                    flexGrow: 0.2,
                    [theme.breakpoints.down('md')]: {
                        flexGrow: 1,
                        width: '45%',
                    },
                    [theme.breakpoints.down('sm')]: {
                        flexGrow: 1,
                        width: '100%',
                    },
                }),
            }}
        />
    );
}
