import { ReactElement, useEffect, useMemo, useState } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, Typography } from '@mui/material';
import { preventAndStopClick } from '../buildDetails/buildDetailsSlice';
import { IToggleColumn } from '../commons/interfaces';
import { openSocket } from '../sockets';
import { ETestStatus } from '../tests/interfaces';
import { ITestSuiteListItem } from './interfaces';
import TestSuiteRow from './row/TestSuiteRow';
import { useGetTestSuitesQuery } from './testSuitesApi';
import { TestSuitesColumnId } from './testSuitesColumns';

const columns: Array<IToggleColumn<TestSuitesColumnId>> = [
    { id: TestSuitesColumnId.status, label: 'Status' },
    { id: TestSuitesColumnId.testSuiteNumber, label: 'Test Suite ID' },
    { id: TestSuitesColumnId.testBranch, label: 'Test Branch', align: 'left' },
    { id: TestSuitesColumnId.devices, label: 'Devices' },
    { id: TestSuitesColumnId.author, label: 'Author', align: 'center' },
    { id: TestSuitesColumnId.runtime, label: 'Duration' },
];

export default function BuildRelatedTestSuites({ buildId }: { buildId: string }): ReactElement | null {
    const [expanded, setExpanded] = useState<boolean>(false);
    const testSuitesQuery = useMemo(() => ({ build: { _id: [buildId] } }), [buildId]);
    const { data } = useGetTestSuitesQuery(testSuitesQuery);
    const [{ testSuites, totalCount }, setTestSuites] = useState<{
        testSuites: ITestSuiteListItem[];
        totalCount: number;
    }>(data || { testSuites: [], totalCount: 0 });

    useEffect(() => {
        setTestSuites(data || { testSuites: [], totalCount: 0 });
    }, [data]);

    useEffect(() => {
        const handleNewTestSuite = ({ testSuite }: { testSuite: ITestSuiteListItem }) => {
            if (testSuite.build._id === buildId) {
                setTestSuites((prevState) => ({
                    testSuites: [testSuite, ...prevState.testSuites],
                    totalCount: prevState.totalCount + 1,
                }));
                setExpanded(true);
            }
        };

        const socket = openSocket('tests');
        socket.on('new-test-suite', handleNewTestSuite);
        return () => {
            socket.off('new-test-suite', handleNewTestSuite);
        };
    }, [buildId]);

    useEffect(() => {
        const handleUpdateTestSuite = (testSuite: ITestSuiteListItem) => {
            const testIndex = testSuites.findIndex((item) => item._id === testSuite._id);
            if (testIndex >= 0) {
                const updatedTestSuites = [...testSuites];
                updatedTestSuites[testIndex] = testSuite;
                setTestSuites({ testSuites: updatedTestSuites, totalCount });
            }
        };

        const socket = openSocket('tests');
        socket.on('update-test-suite', handleUpdateTestSuite);

        return () => {
            socket.off('update-test-suite', handleUpdateTestSuite);
        };
    }, [testSuites, totalCount]);

    if (!testSuites.length) {
        return null;
    }

    const runningTestSuites = testSuites.filter((testSuite) =>
        [ETestStatus.waiting, ETestStatus.queued, ETestStatus.running].includes(testSuite.status),
    ).length;

    return (
        <Box sx={{ width: '100%', padding: 1, borderRadius: (theme) => theme.shape.borderRadius }}>
            <Accordion
                expanded={expanded}
                elevation={0}
                sx={{ backgroundColor: (theme) => theme.palette.common.white }}
            >
                <AccordionSummary
                    sx={{
                        '& > .MuiAccordionSummary-content': {
                            margin: '4px 0',
                        },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: (theme) => theme.palette.text.secondary,
                                p: 1,
                                pl: 0,
                            }}
                        >
                            <AssignmentIcon />
                        </Box>
                        <Box>
                            <Typography variant='body2'>Test Suites ({totalCount})</Typography>
                            {runningTestSuites > 0 && (
                                <Typography variant='caption' color='textSecondary'>
                                    {runningTestSuites} Running
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, overflowX: 'auto' }} onClick={preventAndStopClick}>
                    <Table>
                        <TableBody
                            sx={{
                                borderTop: '1px solid',
                                borderColor: (theme) => theme.palette.grey[200],
                            }}
                        >
                            {testSuites.map((testSuite) => (
                                <TestSuiteRow key={testSuite._id} testSuite={testSuite} columns={columns} />
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
