export function compareVersions(versionA?: string, versionB?: string): number {
    if (!versionA || !versionB) {
        return 0;
    }

    const [majorA, minorA, patchA] = versionA.split('.');
    const [majorB, minorB, patchB] = versionB.split('.');

    const parsedPatchA = patchA === 'x' ? '-1' : patchA;
    const parsedPatchB = patchB === 'x' ? '-1' : patchB;

    const numVersionA = Number(majorA) * 1e9 + Number(minorA) * 1e6 + Number(parsedPatchA) * 1e3;
    const numVersionB = Number(majorB) * 1e9 + Number(minorB) * 1e6 + Number(parsedPatchB) * 1e3;

    return numVersionB - numVersionA;
}
