import React, { ReactElement } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRightOutlined';
import { Breadcrumbs as MuiBreadcrumbs, SxProps, useMediaQuery, useTheme } from '@mui/material';

interface BreadcrumbsProps {
    children: React.ReactNode | React.ReactNode[];
    sx?: SxProps;
}

export default function Breadcrumbs({ children, sx }: BreadcrumbsProps): ReactElement | null {
    const isMobile = useMediaQuery('(max-width: 1280px)');
    const theme = useTheme();

    return (
        <MuiBreadcrumbs
            maxItems={isMobile ? 3 : undefined}
            itemsAfterCollapse={1}
            itemsBeforeCollapse={2}
            aria-label='breadcrumb'
            sx={{
                margin: (theme) => theme.spacing(0.5, 1, 0, 0),
                minWidth: '10%',
                overflowX: 'auto',
                color: (theme) => theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    display: 'block',
                },
                '& > *': {
                    display: 'flex',
                },
                '& > ol > li > a': {
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
                ...sx,
            }}
            separator={<ChevronRightIcon />}
            data-testid='breadcrumbs'
        >
            {children}
        </MuiBreadcrumbs>
    );
}
