import { ReactElement, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { LoadingScreen } from '@tactileentertainment/core-designsystem';
import { useAuthUser, Login, AccessControl } from '@tactileentertainment/core-shared.auth-react';
import 'react-js-cron/dist/styles.css';
import { makeNotification } from './browserNotifications';
import {
    Builds,
    NewBuild,
    BtcAppBar,
    BtcDrawer,
    BuildDetails,
    BuildIdRedirect,
    UserProfile,
    BuildAgents,
    ScheduledBuilds,
    TestsList,
    TestDetails,
} from './components';
import GameModuleDetails from './components/gameModules/details/GameModuleDetails';
import GameModules from './components/gameModules/GameModules';
import EditProject from './components/projects/editView/EditProject';
import ProjectsList from './components/projects/ProjectsList';
import ReleaseNotesList from './components/releaseNotes/ReleaseNotesList';
import { closeSockets, openSocket } from './components/sockets';
import TestAgentsList from './components/testAgents/list/TestAgentsList';
import TestDeviceDetails from './components/testDevices/details/TestDeviceDetails';
import TestDeviceLog from './components/testDevices/details/TestDeviceLog';
import TestDevicesList from './components/testDevices/list/TestDevicesList';
import TestSuiteDetails from './components/testSuites/TestSuiteDetails';
import TestSuitesList from './components/testSuites/TestSuitesList';
import AcceleratorList from './components/unityAccelerators/AcceleratorList';
import { useGetCurrentUserQuery } from './components/userProfile/usersApi';

export default function App(): ReactElement {
    const { isLoading: authIsLoading } = useAuthUser();
    const { data: userData, isLoading: userIsLoading } = useGetCurrentUserQuery(undefined, { skip: authIsLoading });
    const userIsLoggedIn = Boolean(userData?.user);
    const userIsInternal = Boolean(userData?.internal);

    useEffect(() => {
        if (userData?.user?._id) {
            const socket = openSocket(`user/${userData?.user?._id}`);
            const socketHandler = (data: any) => makeNotification(data.title, { body: data.body }, data.onClickUrl);
            socket.on('browser-notification', socketHandler);

            return () => {
                socket.off('browser-notification', socketHandler);
            };
        }
    }, [userData]);

    useEffect(() => {
        return () => {
            closeSockets();
        };
    }, []);

    if (authIsLoading || userIsLoading) {
        return <LoadingScreen />;
    }

    if (!userIsLoggedIn && !userIsInternal) {
        return <Login />;
    }

    return (
        <div
            className='App'
            style={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}
            data-testid='app'
        >
            <CssBaseline />
            <BrowserRouter>
                <BtcAppBar userIsLoggedIn={userIsLoggedIn} userIsInternal={userIsInternal} />
                {(userIsLoggedIn || userIsInternal) && <BtcDrawer />}
                <main style={{ height: '100%' }}>
                    <AppSwitch userIsLoggedIn={userIsLoggedIn} userIsInternal={userIsInternal} />
                </main>
            </BrowserRouter>
        </div>
    );
}

const routesComponents = [
    { to: '/builds', component: Builds },
    {
        to: ['/builds/:projectName/:buildNumber', '/builds/:projectName/:buildNumber/:filename'],
        component: BuildDetails,
    },
    { to: '/builds/:buildId', component: BuildIdRedirect },
    { to: '/agents', component: BuildAgents },
    { to: '/scheduled-builds', component: ScheduledBuilds },
    { to: '/tests', component: TestsList },
    { to: '/test-suites', component: TestSuitesList },
    { to: '/test-suites/:projectName/:testSuiteNumber', component: TestSuiteDetails },
    { to: '/tests/:projectName/:testNumber', component: TestDetails },
    { to: '/tests/:projectName/:testNumber/:tab', component: TestDetails },
    { to: '/test-devices', component: TestDevicesList },
    { to: '/test-devices/:uuid', component: TestDeviceDetails },
    { to: '/test-devices/:uuid/log', component: TestDeviceLog },
    { to: '/test-agents', component: TestAgentsList },
    { to: '/unity-accelerators', component: AcceleratorList },
    { to: '/modules', component: GameModules },
    { to: '/modules/:moduleName', component: GameModuleDetails },
    { to: '/projects/release-notes', component: ReleaseNotesList },
];

function AppSwitch({
    userIsLoggedIn,
    userIsInternal,
}: {
    userIsLoggedIn: boolean;
    userIsInternal: boolean;
}): ReactElement {
    return (
        <Switch>
            {routesComponents.map((routesComponent, index) => (
                <Route key={index} exact path={routesComponent.to} component={routesComponent.component} />
            ))}
            <Route path='/new-build' render={() => (userIsLoggedIn ? <NewBuild /> : <Login />)} />
            <Route path='/profile' render={() => (userIsLoggedIn ? <UserProfile /> : <Login />)} />
            <Route path='/access-control' render={() => (userIsLoggedIn ? <AccessControl /> : <Login />)} />
            <Route path='/projects' exact render={() => (userIsLoggedIn ? <ProjectsList /> : <Login />)} />
            <Route path='/projects/:id' render={() => (userIsLoggedIn ? <EditProject /> : <Login />)} />
            {(userIsLoggedIn || userIsInternal) && <Route exact path='/' render={() => <Redirect to='/builds' />} />}
            <Route render={() => <Login />} />
        </Switch>
    );
}
