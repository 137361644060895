import { ReactElement } from 'react';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { SVGIcon, SVGIcons } from '../../../assets/SVGIcon';
import BuildDuration from '../../builds/BuildDuration';
import BuildProjectName from '../../builds/BuildProjectName';
import Author from '../../commons/Author';
import NoContent from '../../commons/NoContent';
import TableBody from '../../commons/TableBody';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { formatDate } from '../../gameModules/utils';
import { ITestSuiteListItem } from '../../testSuites/interfaces';
import TestSuiteCell from '../../testSuites/row/TestSuiteCell';
import TestSuiteStatusCell from '../../testSuites/row/TestSuiteStatusCell';
import { testSummaryColumns } from './testSuiteSummaryColumns';

export const tableMinWidth = 1400;

export default function TestSuiteSummaryList({ testSuites }: { testSuites: ITestSuiteListItem[] }): ReactElement {
    return (
        <TableContainer>
            <Table stickyHeader aria-label='test cases table' sx={{ minWidth: tableMinWidth }}>
                <TableHead data-testid='test-case-table-head'>
                    <TableHeaderRow>
                        {testSummaryColumns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                size='small'
                                style={{
                                    width: column.width,
                                    minWidth: column.minWidth,
                                }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableHeaderRow>
                </TableHead>
                <TableBody data-testid='test-summary'>
                    {!testSuites.length && (
                        <TableRow>
                            <TableCell colSpan={8} sx={{ height: 'calc(100vh - 400px)' }}>
                                <NoContent
                                    text='No tests queued yet'
                                    icon={<SVGIcon icon={SVGIcons.noTestQueued} sx={{ margin: 'auto' }} />}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {testSuites.map((testSuite) => (
                        <TestSuiteSummaryRow key={testSuite._id} testSuite={testSuite} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function TestSuiteSummaryRow({ testSuite }: { testSuite: ITestSuiteListItem }): ReactElement {
    return (
        <>
            <TableRow data-testid='test-summary-row'>
                <TestSuiteStatusCell testSuite={testSuite} />
                <TestSuiteCell testSuite={testSuite} />
                <BuildProjectName projectName={testSuite.build.projectName} />
                <TableCell>{testSuite.testBranch}</TableCell>
                <TableCell>{formatDate(testSuite.startedAt) || '-'}</TableCell>
                <BuildDuration
                    started={testSuite.startedAt?.toString()}
                    completed={testSuite.completedAt?.toString()}
                />
                <Author author={testSuite.author} />
            </TableRow>
        </>
    );
}
