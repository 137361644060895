import { ReactElement } from 'react';
import { Box, TableCell } from '@mui/material';
import { AvatarChip } from '@tactileentertainment/core-designsystem';
import { MuiAlignSetting } from '../../typings/material-ui';
import { IAuthor } from './interfaces';

export default function Author(props: { align?: MuiAlignSetting; author?: IAuthor }): ReactElement {
    const { align, author } = props;

    return (
        <TableCell align={align} sx={{ verticalAlign: 'center' }} data-testid='table-cell-author'>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {author && <AvatarChip label={author.email} altLabel={author.name} src={author.imageUrl} avatarOnly />}
            </Box>
        </TableCell>
    );
}
