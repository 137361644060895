import { useMemo } from 'react';
import { FilterOption } from './interfaces';

interface UseFilterOptionsProps {
    options: FilterOption[];
    searchText?: string;
}

export default function useFilterOptions({ options, searchText }: UseFilterOptionsProps): FilterOption[] {
    const filteredOptions = useMemo(() => {
        if (!searchText) {
            return options;
        }

        const filteredOptions = options
            .filter((option: FilterOption) => String(option.label).toLowerCase().includes(searchText.toLowerCase()))
            .slice()
            .sort((a: FilterOption, b: FilterOption) => {
                const searchKey = searchText.toLowerCase();
                const matchAIndex = a.label.toLowerCase().match(searchKey)?.index ?? 999;
                const matchBIndex = b.label.toLowerCase().match(searchKey)?.index ?? 999;

                return matchAIndex - matchBIndex;
            });

        return filteredOptions;
    }, [searchText, options]);

    return filteredOptions;
}
