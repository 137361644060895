import { ReactElement } from 'react';
import { Box, Chip, TableBody, Typography } from '@mui/material';
import TableContainer from '../commons/TableContainer';
import { ISvnCommit } from './interfaces/ISvnCommit';
import LogSkeleton from './LogSkeleton';

export function LogsTable({
    logs,
    clearLogs,
    logsLoading,
}: {
    logs?: ISvnCommit[];
    clearLogs: boolean;
    logsLoading: boolean;
}): ReactElement {
    const displayComponent = logs && !clearLogs && !logsLoading;
    return (
        <TableContainer>
            <TableBody>
                {logsLoading && <LogSkeleton />}
                {displayComponent &&
                    logs.map(({ commitMessage, revision }, index) => (
                        <LogRow commitMessage={commitMessage} revision={revision} key={index} />
                    ))}
            </TableBody>
        </TableContainer>
    );
}

function LogRow({ commitMessage, revision }: ISvnCommit): ReactElement {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: 1,
            }}
        >
            <Typography sx={{ justifySelf: 'flex-start' }}>{commitMessage}</Typography>
            <Chip
                size='small'
                sx={{
                    m: 0.25,
                    justifySelf: 'flex-end',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    border: `none`,
                }}
                key={revision}
                label={revision}
            />
        </Box>
    );
}
