import { ReactElement } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Typography } from '@mui/material';
import { ITestDeviceError } from '../interfaces';
import TestDeviceErrorRow from './TestDeviceErrorRow';

export default function TestDeviceErrorsTable({
    errors,
    onErrorsChange,
}: {
    errors: ITestDeviceError[];
    onErrorsChange: (errors: ITestDeviceError[]) => void;
}): ReactElement {
    const handleErrorChange = (index: number, error: ITestDeviceError) => {
        const updatedErrors = [...errors];
        updatedErrors[index] = error;
        onErrorsChange(updatedErrors);
    };

    const handleAddError = (error: ITestDeviceError) => {
        const updatedErrors = [...errors, error];
        onErrorsChange(updatedErrors);
    };

    const handleRemoveError = (index: number) => {
        const updatedErrors = [...errors.slice(0, index), ...errors.slice(index + 1)];
        onErrorsChange(updatedErrors);
    };

    return (
        <Box display='flex' flexDirection='column' gap={1} data-testid='test-device-errors'>
            <Typography variant='h6'>Error Codes</Typography>
            {errors.map((error, index) => (
                <Box key={index} display='flex' flexDirection='row' alignItems='center'>
                    <TestDeviceErrorRow error={error} onErrorChange={(error) => handleErrorChange(index, error)} />
                    <Box>
                        <Button onClick={() => handleRemoveError(index)} variant='outlined' color='error' size='small'>
                            <RemoveIcon />
                        </Button>
                    </Box>
                </Box>
            ))}
            <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center'>
                <Box>
                    <Button
                        onClick={() =>
                            handleAddError({
                                code: 0,
                                name: '',
                                tag: '',
                                action: {
                                    isDisabled: false,
                                    isElegibleForQueuedTests: true,
                                },
                            })
                        }
                        variant='outlined'
                        color='secondary'
                        size='small'
                    >
                        <AddIcon />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
