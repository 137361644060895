import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useLogin } from '@tactileentertainment/core-shared.auth-react';
import { toggleShowBuildAgentDialog } from './buildAgentsSlice';
import { AgentDialogActionType } from './enums';

export default function BuildAgentActionBox(): ReactElement {
    const dispatch = useDispatch();
    const { isLoggedIn, handleLogin } = useLogin();

    const handleShowBuildAgentDialog = () => {
        if (isLoggedIn) {
            return dispatch(toggleShowBuildAgentDialog({ action: AgentDialogActionType.register }));
        } else {
            handleLogin();
        }
    };

    return (
        <Button
            variant='contained'
            color='primary'
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            onClick={handleShowBuildAgentDialog}
            startIcon={<AddIcon />}
        >
            Register Agent
        </Button>
    );
}
