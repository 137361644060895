import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import { ITestSuiteBase } from '../interfaces';
import TestSuiteStatus from '../TestSuiteStatus';

export default function TestSuiteStatusCell({
    testSuite,
    align,
}: {
    testSuite: ITestSuiteBase;
    align?: MuiAlignSetting;
}): ReactElement {
    return (
        <TableCell
            sx={{ pl: { xs: 2, sm: 4 } }}
            align={align}
            id='table-cell-test-status'
            data-testsuiteid='tests-table-test-status'
        >
            <TestSuiteStatus testSuite={testSuite} />
        </TableCell>
    );
}
