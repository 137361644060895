import { ReactElement } from 'react';
import { Skeleton, TableRow, TableCell } from '@mui/material';

interface GameModulesLoadingRowsProps {
    rows?: number;
    projectListSize?: number;
}

export default function GameModulesLoadingRows({
    rows = 10,
    projectListSize = 1,
}: GameModulesLoadingRowsProps): ReactElement {
    const array = [...Array(rows)].map((_value, index) => index);

    return (
        <>
            {array.map((key) => (
                <LoadingRow key={key} projectListSize={projectListSize} />
            ))}
        </>
    );
}

function LoadingRow({ projectListSize }: GameModulesLoadingRowsProps): ReactElement {
    const bigRand = Math.random() * 0.5 + 0.5;

    return (
        <TableRow data-testid='game-modules-loading-row'>
            <TableCell sx={{ pl: 3 }}>
                <Skeleton width={`${bigRand * 200}px`} />
            </TableCell>
            <TableCell>
                <Skeleton sx={{ maxWidth: 100, mx: 'auto' }} />
            </TableCell>
            <TableCell>
                <Skeleton sx={{ maxWidth: 100 }} />
            </TableCell>
            {[...Array(projectListSize)].map((_value, index) => (
                <TableCell sx={{ py: '10px' }} key={index}>
                    <Skeleton variant='rectangular' width={'100%'} height={24} sx={{ borderRadius: '10px' }} />
                </TableCell>
            ))}
        </TableRow>
    );
}
