import React, { ReactElement } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Box, Chip, styled, TableCell, Theme, useMediaQuery, useTheme } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';

const ChipStatus = styled(Chip)(({ theme }) => ({
    borderRadius: 16,
    fontWeight: 600,
    color: theme.palette.common.white,
    maxHeight: '30px',
}));

export interface IProjectStatusCellProps {
    isEnabled: boolean;
    forcedMobile?: boolean;
    style?: React.CSSProperties;
    align?: MuiAlignSetting;
}

export default function ProjectStatusCell({
    isEnabled,
    forcedMobile,
    style,
    align,
}: IProjectStatusCellProps): ReactElement | null {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width: 1280px)') || forcedMobile;

    const icon = getIcon(isEnabled, theme, isMobile, style);

    return (
        <TableCell align={align} id='table-cell-status'>
            {isMobile && <Box sx={{ display: 'flex' }}>{icon}</Box>}
            {!isMobile && (
                <ChipStatus
                    icon={icon}
                    label={isEnabled ? 'Enabled' : 'Disabled'}
                    sx={{
                        ...style,
                        '.MuiChip-icon': {
                            fontSize: 18,
                        },
                        backgroundColor: isEnabled ? theme.palette.success.main : theme.palette.tactile.blueGray[600],
                    }}
                />
            )}
        </TableCell>
    );
}

function getIcon(isEnabled: boolean, theme: Theme, isMobile?: boolean, style?: React.CSSProperties): ReactElement {
    if (isEnabled) {
        return (
            <CheckCircleIcon
                style={{
                    ...style,
                    color: isMobile ? theme.palette.success.main : theme.palette.common.white,
                }}
            />
        );
    }

    return (
        <DoNotDisturbIcon
            style={{
                ...style,
                color: isMobile ? theme.palette.tactile.blueGray[700] : theme.palette.common.white,
            }}
        />
    );
}
