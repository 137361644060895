import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColumnFilter from '../../commons/filters/ColumnFilter';
import { parseFilterOptions } from '../../commons/filters/utils';
import { useGetAvailableOptionsQuery } from '../testsApi';
import { selectTestBranchFilter, setTestsFilter } from '../testsSlice';
import { ITestFilterProps } from './TestsFilters';

export default function TestBranchSelector({ showOnlyValues }: ITestFilterProps): ReactElement {
    const dispatch = useDispatch();
    const selected = useSelector(selectTestBranchFilter);
    const { data, isFetching: loading } = useGetAvailableOptionsQuery();

    const parsedOptions = parseFilterOptions(data?.options.testBranch || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setTestsFilter({ testBranch: newValue || undefined }));
    };

    return (
        <ColumnFilter
            id='testBranch-selector'
            label='Test Branch'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}
