import { ReactElement } from 'react';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import cronstrue from 'cronstrue';
import { ScheduledBuildsColumnId } from './enums';
import { IScheduleBuild } from './interfaces';
import MessagesCell from './MessagesCell';
import PlatformCell from './PlatformCell';
import { scheduledBuildColumns } from './ScheduledBuildColumns';
import { parse6To5Cron } from './utils/parseCron';

interface ScheduledBuildRowProps {
    scheduledBuild: IScheduleBuild;
    onClick?: (selected: IScheduleBuild) => void;
}

export default function ScheduledBuildRow({ scheduledBuild, onClick }: ScheduledBuildRowProps): ReactElement {
    return (
        <TableRow
            hover
            key={scheduledBuild._id}
            tabIndex={-1}
            data-testid='scheduled-build-row'
            sx={{ cursor: 'pointer' }}
            onClick={() => onClick?.(scheduledBuild)}
        >
            <FullScheduledBuildRow scheduledBuild={scheduledBuild} />
        </TableRow>
    );
}

export function FullScheduledBuildRow({ scheduledBuild }: ScheduledBuildRowProps): ReactElement {
    return (
        <>
            {scheduledBuildColumns.map((column) => {
                switch (column.id) {
                    case ScheduledBuildsColumnId.name:
                        return (
                            <TableCell
                                key={`${column.id}-${scheduledBuild._id}`}
                                align={column.align}
                                width={column.width}
                                sx={{ px: { xs: 2, sm: 4 } }}
                            >
                                {scheduledBuild.project.name}
                            </TableCell>
                        );

                    case ScheduledBuildsColumnId.branch:
                        return (
                            <TableCell
                                key={`${column.id}-${scheduledBuild._id}`}
                                align={column.align}
                                width={column.width}
                            >
                                {scheduledBuild.branch}
                            </TableCell>
                        );

                    case ScheduledBuildsColumnId.description:
                        return (
                            <Tooltip
                                title={scheduledBuild.description || ''}
                                key={`${column.id}-${scheduledBuild._id}`}
                                placement='bottom-start'
                            >
                                <TableCell
                                    align={column.align}
                                    width={column.width}
                                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                >
                                    {scheduledBuild.description}
                                </TableCell>
                            </Tooltip>
                        );

                    case ScheduledBuildsColumnId.cron:
                        return (
                            <TableCell key={`${column.id}-${scheduledBuild._id}`} align={column.align}>
                                {cronstrue.toString(parse6To5Cron(scheduledBuild.cron))}
                            </TableCell>
                        );

                    case ScheduledBuildsColumnId.platform:
                        return (
                            <PlatformCell
                                key={`${column.id}-${scheduledBuild._id}`}
                                scheduledBuild={scheduledBuild}
                                align={column.align}
                            />
                        );

                    case ScheduledBuildsColumnId.messages:
                        return (
                            <MessagesCell
                                key={`${column.id}-${scheduledBuild._id}`}
                                scheduledBuild={scheduledBuild}
                                align={column.align}
                            />
                        );

                    default:
                        return (
                            <TableCell key={column.id} align={column.align}>
                                {scheduledBuild[column.id]}
                            </TableCell>
                        );
                }
            })}
        </>
    );
}
