import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, TableRow as MuiTableRow, TableRowProps } from '@mui/material';
import { red } from '@mui/material/colors';
import { handleRedirectToPage } from '../../utils';

const TableRow = styled(MuiTableRow)(({ theme }) => ({
    cursor: 'pointer',
    maxWidth: '100vw',
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
        'td.MuiTableCell-root': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    '&.Mui-selected': {
        backgroundColor: red[50],
    },
}));

interface ClickableTableRowProps extends TableRowProps {
    redirectUrl?: string;
    children: React.ReactNode;
}

export default function ClickableTableRow({
    redirectUrl = '',
    children,
    ...props
}: ClickableTableRowProps): ReactElement {
    const history = useHistory();

    return (
        <TableRow
            {...props}
            onMouseUp={(e: React.MouseEvent) => {
                if (redirectUrl && (e.target as HTMLInputElement).type !== 'checkbox') {
                    handleRedirectToPage(e, redirectUrl, history);
                } else {
                    e.stopPropagation();
                }
            }}
        >
            {children}
        </TableRow>
    );
}
