import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import GridItem from '../../commons/GridItem';
import TestAgentsLink from '../../testAgents/TestAgentsLink';
import { ITestDevice } from '../interfaces';
import TestDeviceName from '../TestDeviceName';

export default function DeviceModelInfo({
    testDevice,
    showDeviceLink,
}: {
    testDevice: ITestDevice;
    showDeviceLink?: boolean;
}): ReactElement {
    return (
        <Grid container item spacing={1} xs={8} justifyContent='space-between' data-testid='test-device-model-info'>
            <GridItem label='Device Name'>
                <TestDeviceName testDevice={testDevice} variant={showDeviceLink ? 'link' : 'edit'} />
            </GridItem>
            <GridItem label='Device UUID'>{testDevice.uuid}</GridItem>
            <GridItem label='Game Device UID'>{testDevice.gameDeviceId}</GridItem>
            <GridItem label='Model'>{testDevice.deviceInfo.model}</GridItem>
            <GridItem label='Manufacturer'>{testDevice.deviceInfo.manufacturer}</GridItem>
            <GridItem label='Platform'>{testDevice.platform}</GridItem>
            <GridItem label='Test Agent'>
                {testDevice.testAgent && <TestAgentsLink title={testDevice.testAgent.hostname} />}
            </GridItem>
        </Grid>
    );
}
