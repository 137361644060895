import { ReactElement } from 'react';
import { Chip, TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import { ONE_GIGABYTE } from '../../../utils';
import { IDiskSpace } from '../interfaces';

export default function BuildAgentDiskSpace({
    diskInfo,
    align,
}: {
    diskInfo: IDiskSpace;
    align?: MuiAlignSetting;
}): ReactElement {
    const freeGB = diskInfo.free / ONE_GIGABYTE;
    const totalGB = diskInfo.total / ONE_GIGABYTE;
    const percentage = (100 * freeGB) / totalGB;

    const getDiskInfoString = () => {
        return `${freeGB.toFixed(2)} / ${totalGB.toFixed(2)}`;
    };

    const getDiskInfoPercentage = () => {
        return `${percentage.toFixed(1)}% free`;
    };

    return (
        <TableCell sx={{ py: 2, px: 1 }} align={align}>
            <Chip
                size='small'
                title={getDiskInfoPercentage()}
                sx={{
                    m: 0.25,
                    display: 'inline-grid',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                label={getDiskInfoString()}
            />
        </TableCell>
    );
}
