import * as socketIOClient from 'socket.io-client';

const sockets = new Map<string, socketIOClient.Socket>();

export function openSocket(namespace: string): socketIOClient.Socket {
    if (!sockets.has(namespace)) {
        const socket = socketIOClient.connect(`${process.env.REACT_APP_API_URL}/${namespace}`);
        sockets.set(namespace, socket);
    }

    return sockets.get(namespace) as socketIOClient.Socket;
}

export function closeSockets(): void {
    sockets.forEach((socket) => {
        socket.disconnect();
    });
}
