import React, { ReactElement, useMemo, useState } from 'react';
import { Chip, TableCell } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { EBuildStatus } from '../../commons/enums';
import { getAcronym } from '../../commons/getAcronym';
import { IBuild } from '../../commons/interfaces';

interface BuildAgentProjectsProps {
    projects: string[];
    dynamicCheckouts?: string[];
    build?: IBuild;
}

export default function BuildAgentProjects({
    projects,
    dynamicCheckouts,
    build,
}: BuildAgentProjectsProps): ReactElement {
    const [showAcronym, setShowAcronym] = useState(true);

    const isRunning = build?.status === EBuildStatus.running;
    const sortedProjects = useMemo(() => (projects ? [...projects].sort() : []), [projects]);

    if (!projects || projects.length < 1) {
        return <TableCell />;
    }

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        setShowAcronym(!showAcronym);
    };

    return (
        <TableCell sx={{ py: 2, px: 1 }} align='left' onClick={handleClick}>
            {sortedProjects.map((project) => (
                <Chip
                    size='small'
                    title='Click to toggle project full name and acronym'
                    sx={{
                        m: 0.25,
                        display: 'inline-grid',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        border: 'none',
                        ...(dynamicCheckouts?.includes(project) && {
                            border: `2px dashed ${blueGray[500]}`,
                        }),
                        ...(isRunning &&
                            project === build.projectName && {
                                background: (theme) => theme.palette.tactile.blue[100],
                                '& span': {
                                    color: (theme) => theme.palette.tactile.blue[700],
                                },
                            }),
                    }}
                    key={project}
                    label={showAcronym ? getAcronym(project) : project}
                    onClick={handleClick}
                />
            ))}
        </TableCell>
    );
}
