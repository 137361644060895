import React, { ReactElement } from 'react';
import CustomLink from '../commons/CustomLink';

export default function TestNumberLink({
    projectName,
    testNumber,
}: {
    projectName: string;
    testNumber: number;
}): ReactElement {
    return (
        <CustomLink
            to={`/tests/${projectName}/${testNumber}`}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
            onMouseUp={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
        >
            {`#${testNumber}`}
        </CustomLink>
    );
}
