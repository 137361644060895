import React, { ReactElement, useState } from 'react';
import { Box, Chip, Divider, TableCell, Typography } from '@mui/material';
import { BuildAgent } from '../../buildAgents/interfaces';
import { DataValueBox } from '../../commons/DataValueBox';
import ExtraInfoPopover from '../../commons/ExtraInfoPopover';
import formatMemoryString from '../../commons/formatMemoryString';
import getBuildAgentName from '../../commons/getBuildAgentName';
import getDiskInfo from '../../commons/getDiskInfo';

interface AgentsSelectorBuildAgentCellProps {
    buildAgent?: BuildAgent;
}

export default function AgentsSelectorBuildAgentCell({ buildAgent }: AgentsSelectorBuildAgentCellProps): ReactElement {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const name = getBuildAgentName(buildAgent).split('-')?.[0] || '';
    const open = Boolean(anchorElement);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorElement(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorElement(null);
    };

    return (
        <TableCell
            id='table-cell-build-agent-info'
            aria-owns={open ? 'mouse-over-popover' : undefined}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{ px: 3 }}
        >
            <Box style={{ display: 'flex' }}>
                <Typography variant='body2' color='textPrimary'>
                    {name}
                </Typography>
                <Typography variant='body2' sx={{ ml: 1 }} color='textPrimary'>
                    {getDiskInfo(buildAgent?.agentInfo.disk_space)}
                </Typography>

                <ExtraInfoPopover
                    id='mouse-over-popover'
                    sx={{ minWidth: 450 }}
                    open={open}
                    anchorEl={anchorElement}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography variant='subtitle2' style={{ fontWeight: 600 }}>
                        Server Specs
                    </Typography>
                    <DataValueBoxWrapper title='Server Name' value={name} />
                    <DataValueBoxWrapper
                        title='Free Disk Space'
                        value={getDiskInfo(buildAgent?.agentInfo.disk_space)}
                    />
                    <DataValueBoxWrapper
                        title='Memory'
                        value={formatMemoryString(buildAgent?.agentInfo.memory_info || '')}
                    />
                    <DataValueBoxWrapper title='OS' value={buildAgent?.agentInfo.os} />
                    <DataValueBoxWrapper title='CPU' value={buildAgent?.agentInfo.cpu_info} />
                    <Divider sx={{ mt: 1 }} />
                    <Typography variant='subtitle2' sx={{ fontWeight: 600, mt: 1 }}>
                        Resources
                    </Typography>
                    <DataValueBoxWrapper
                        title='SDK'
                        value={[...(buildAgent?.supportedTools.android_sdk || {})].sort()}
                    />
                    <DataValueBoxWrapper title='Unity' value={[...(buildAgent?.supportedTools.unity || {})].sort()} />
                    <DataValueBoxWrapper title='Xcode' value={[...(buildAgent?.supportedTools.xcode || {})].sort()} />
                    <DataValueBoxWrapper title='SVN' value={[buildAgent?.agentInfo.svn || '']} />
                    <DataValueBoxWrapper title='Cocoapods' value={[buildAgent?.supportedTools.cocoapods || '']} />
                </ExtraInfoPopover>
            </Box>
        </TableCell>
    );
}

function DataValueBoxWrapper({ title, value }: { title: string; value?: string | string[] }): ReactElement | null {
    if (value === undefined) {
        return null;
    }

    const getValue = () => {
        if (typeof value === 'object') {
            return value.map((item) => <Chip variant='outlined' size='small' sx={{ mr: 1 }} key={item} label={item} />);
        }

        return value;
    };

    return (
        <DataValueBox
            label={title}
            value={getValue()}
            sx={{ mt: 1, justifyContent: 'flex-start' }}
            labelSx={{ width: 150 }}
            valueSx={{ fontWeight: 700 }}
        />
    );
}
