import { PropsWithChildren, ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

export function LoadingSpinner(props: PropsWithChildren<{ loading?: boolean }>): ReactElement | null {
    return props.loading ? (
        <CircularProgress
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                margin: '-20px',
            }}
            data-testid='load-spinner'
        />
    ) : null;
}
