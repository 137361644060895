import { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import ContentLoading from '../../commons/ContentLoading';
import { openSocket } from '../../sockets';
import { ITestDevice } from '../interfaces';
import TestDeviceBreadcrumbs from '../TestDeviceBreadcrumbs';
import { useGetTestDeviceQuery } from '../testDevicesApi';
import TestDeviceState from '../TestDeviceState';
import TestDeviceActions from './TestDeviceActions';
import TestDeviceDelete from './TestDeviceDelete';
import TestDeviceInfo from './TestDeviceInfo';
import TestDeviceQueue from './TestDeviceQueue';

export default function TestDeviceDetails(): ReactElement {
    const { uuid } = useParams<{ uuid: string }>();
    const { data: testDevice, isFetching, refetch } = useGetTestDeviceQuery(uuid);

    const isLoading = isFetching && !testDevice;

    useEffect(() => {
        const updateDevice = ({ testDevice: updatedDevice }: { testDevice: ITestDevice }) => {
            if (updatedDevice.uuid === testDevice?.uuid) {
                refetch();
            }
        };
        const socket = openSocket('test-devices');
        socket.on('update-device', updateDevice);

        return () => {
            socket.off('update-device', updateDevice);
        };
    }, [refetch, testDevice]);

    return (
        <>
            <Helmet title={testDevice?.name} />
            <Container maxWidth={false} data-testid='test-device-details'>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <TestDeviceBreadcrumbs testDevice={testDevice} />
                    {testDevice && (
                        <div>
                            <TestDeviceState state={testDevice.state} />
                            <TestDeviceDelete testDevice={testDevice} />
                        </div>
                    )}
                </Grid>

                {isLoading && <ContentLoading />}

                {testDevice && (
                    <>
                        <TestDeviceInfo testDevice={testDevice} />
                        <TestDeviceActions testDevice={testDevice} />
                        <TestDeviceQueue testDevice={testDevice} />
                    </>
                )}
            </Container>
        </>
    );
}
