import queryString from 'query-string';

export default function getUrlParam(name: string): string | number | null {
    const params = queryString.parse(window.location.search);

    if (name === 'page') {
        const page = (params[name] as string) || '0';
        return parseInt(page);
    }

    return params[name] as string;
}

export function getUrlFilterValue(name: string): string[] | null {
    const params = queryString.parse(window.location.search);

    let value = params[name] || [];
    value = Array.isArray(value) ? value : [value];
    if (!value.length || !value?.[0]) {
        return null;
    }

    return value.join(',').split(',');
}
