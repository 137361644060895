import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { IBuild } from '../commons/interfaces';
import * as queryString from '../commons/queryString';
import { IGetBuildsParams } from './interfaces';

export const buildsApi = createApi({
    reducerPath: 'buildsApi',
    baseQuery: apiBaseQuery(),
    endpoints: (builder) => ({
        getBuilds: builder.query<IBuild[], IGetBuildsParams>({
            query: (params) => {
                const query = queryString.stringify(params as Record<string, string>);
                return {
                    url: `/builds?${query}`,
                };
            },
            transformResponse: (response: { builds: IBuild[] }): IBuild[] => response.builds,
        }),
        getBuild: builder.query<IBuild, string>({
            query: (buildId) => {
                return {
                    url: `/builds/${buildId}`,
                };
            },
            transformResponse: (response: { build: IBuild }): IBuild => response.build,
        }),
    }),
});

export const { useGetBuildsQuery, useLazyGetBuildsQuery, useGetBuildQuery } = buildsApi;
