import { ISelectedTestCase } from '../../../interfaces';

const counterSuffixRegex = /\(\d+\)/;

export function duplicateTestCase(testCases: ISelectedTestCase[], testCase: ISelectedTestCase): ISelectedTestCase[] {
    const countOfDuplicates = getCountOfDuplicates(testCase.testName, testCases);
    const testCaseDuplicate = duplicate(testCase, countOfDuplicates);

    const index = testCases.findIndex((item) => item.testName === testCase.testName);

    return insertIntoIndex([...testCases], testCaseDuplicate, index + countOfDuplicates);
}

function duplicate(testCase: ISelectedTestCase, countOfDuplicates: number): ISelectedTestCase {
    const testCaseDuplicate = structuredClone(testCase);

    testCaseDuplicate.id = `${testCase.id}-${countOfDuplicates}`;

    const counterSuffix = `(${countOfDuplicates})`;
    testCaseDuplicate.displayName = testCase.displayName.match(counterSuffixRegex)
        ? testCase.displayName.replace(counterSuffixRegex, counterSuffix)
        : `${testCase.displayName} ${counterSuffix}`;

    return testCaseDuplicate;
}

function getCountOfDuplicates(testName: string, testCases: ISelectedTestCase[]): number {
    return testCases.reduce((acc, item) => {
        if (item.testName === testName) {
            return acc + 1;
        }
        return acc;
    }, 0);
}

function insertIntoIndex<T>(array: T[], item: T, index: number): T[] {
    return [...array.slice(0, index), item, ...array.slice(index, array.length)];
}
