import { ReactElement } from 'react';
import Moment from 'react-moment';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';

interface BuildAgentLastSeenCellProps {
    lastSeen?: string;
    align?: MuiAlignSetting;
}

export default function BuildAgentLastSeen({ lastSeen, align }: BuildAgentLastSeenCellProps): ReactElement | null {
    return (
        <TableCell sx={{ padding: (theme) => theme.spacing(2, 1) }} title={lastSeen} align={align}>
            {lastSeen && (
                <Moment fromNow interval={1000}>
                    {lastSeen}
                </Moment>
            )}
        </TableCell>
    );
}
