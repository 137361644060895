import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { capitalize, Link, Box } from '@mui/material';
import Breadcrumbs from '../commons/Breadcrumbs';
import { ITestDevice } from './interfaces';

export default function TestDeviceBreadcrumbs({
    testDevice,
    tab,
}: {
    testDevice?: ITestDevice;
    tab?: 'log';
}): ReactElement {
    return (
        <Box sx={{ py: 3 }}>
            <Breadcrumbs>
                <Link color='inherit' component={RouterLink} to='/tests'>
                    Tests
                </Link>
                <Link color='inherit' component={RouterLink} to='/test-devices'>
                    Devices
                </Link>
                {testDevice && (
                    <Link color='inherit' component={RouterLink} to={`/test-devices/${testDevice.uuid}`}>
                        <span>{testDevice.name}</span>
                    </Link>
                )}
                {testDevice && tab === 'log' && (
                    <Link color='inherit' component={RouterLink} to={`/test-devices/${testDevice.uuid}/log`}>
                        <span>{capitalize(tab)}</span>
                    </Link>
                )}
            </Breadcrumbs>
        </Box>
    );
}
