import { useCallback, useEffect, useMemo, useState } from 'react';
import { errorToast } from '@tactileentertainment/core-designsystem';
import { getDefaultSettings } from '../../../configurationSettings/getDefaultSettings';
import { ESelectedProperty, ISelectedTestCase, ITestCaseConfig } from '../../interfaces';
import { duplicateTestCase } from './utils/duplicateTestCase';
import { getTags } from './utils/getTags';
import { removeUnselectedTags } from './utils/removeUnselectedTags';
import { searchTestCases } from './utils/searchTestCases';
import { selectAllTestCases } from './utils/selectAllTestCases';
import { selectTestCase } from './utils/selectTestCase';
import { selectTestCasesByTags } from './utils/selectTestCasesByTags';

export interface IUseSelectTestCases {
    onSelect: (id: string, property: ESelectedProperty, value: ISelectedTestCase[ESelectedProperty]) => void;
    onSelectAll: (property: ESelectedProperty, value: boolean) => void;
    allTags: string[];
    selectedTags: string[];
    onSelectByTags: (newTags: string[]) => void;
    searchTerm: string;
    onSearch: (searchTerm: string) => void;
    onDuplicate: (testCase: ISelectedTestCase) => void;
}

export function useSelectTestCases(
    testCasesConfig: ITestCaseConfig[] | undefined,
    testCases: ISelectedTestCase[],
    setTestCases: (testCases: ISelectedTestCase[]) => void,
): IUseSelectTestCases {
    const allTags = useMemo<string[]>(() => getTags(testCasesConfig), [testCasesConfig]);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    useEffect(() => {
        if (!testCasesConfig) {
            setTestCases([]);
            return;
        }
        try {
            setTestCases(
                testCasesConfig.map((testCase, index) => ({
                    ...testCase,
                    id: index.toString(),
                    visible: true,
                    selected: false,
                    selectedSettings: testCase.settings ? getDefaultSettings(testCase.settings) : undefined,
                })),
            );
        } catch (error) {
            errorToast((error as Error).message);
        }
    }, [testCasesConfig, setTestCases]);

    const onSelectAll = useCallback(
        (property: ESelectedProperty, value: boolean) => {
            setTestCases(selectAllTestCases(testCases, property, value));
        },
        [testCases, setTestCases],
    );

    const onSelect = useCallback(
        (id: string, property: ESelectedProperty, value: ISelectedTestCase[ESelectedProperty]) => {
            const selectedTestCases = selectTestCase({ testCases, id, property, value });
            setTestCases(selectedTestCases);
            setSelectedTags((selectedTags) => removeUnselectedTags(selectedTestCases, selectedTags));
        },
        [testCases, setTestCases],
    );

    const onSelectByTags = useCallback(
        (newTags: string[]) => {
            setTestCases(selectTestCasesByTags({ testCases, currentTags: selectedTags, newTags }));
            setSelectedTags(newTags);
        },
        [testCases, setTestCases, selectedTags, setSelectedTags],
    );

    const onSearch = useCallback(
        (searchTerm: string) => {
            setSearchTerm(searchTerm);
            setTestCases(searchTestCases(testCases, searchTerm));
        },
        [testCases, setTestCases, setSearchTerm],
    );

    const onDuplicate = useCallback(
        (testCase: ISelectedTestCase) => {
            setTestCases(duplicateTestCase(testCases, testCase));
        },
        [testCases, setTestCases],
    );

    return { onSelect, onSelectAll, allTags, selectedTags, onSelectByTags, searchTerm, onSearch, onDuplicate };
}
