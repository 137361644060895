import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { ETestSuiteAllocationType } from '../interfaces';
import SelectTestAllocation from './SelectTestAllocation';

export default function SelectTestExecutionOptions({
    testAllocation,
    onTestAllocationChange,
}: {
    testAllocation: ETestSuiteAllocationType;
    onTestAllocationChange: (testAllocation: ETestSuiteAllocationType) => void;
}): ReactElement {
    return (
        <Grid item xs={12}>
            <SelectTestAllocation testAllocation={testAllocation} onTestAllocationChange={onTestAllocationChange} />
        </Grid>
    );
}
