import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import { ITest } from '../interfaces';
import TestStatus from '../TestStatus';

export default function TestStatusCell({ test, align }: { test: ITest; align?: MuiAlignSetting }): ReactElement {
    return (
        <TableCell
            sx={{ px: { xs: 2, sm: 4 } }}
            align={align}
            id='table-cell-test-status'
            data-testid='tests-table-test-status'
        >
            <TestStatus test={test} />
        </TableCell>
    );
}
