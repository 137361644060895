import { ReactElement } from 'react';
import { Box } from '@mui/material';
import TestAgentDisabledIcon from '../testAgents/TestAgentDisabledIcon';
import TestDeviceNameEdit from './details/TestDeviceNameEdit';
import { ETestDeviceState, ITestDevice } from './interfaces';
import BatteryLevel from './list/BatteryLevel';
import Temperature from './list/Temperature';
import TestDeviceDisabledIcon from './TestDeviceDisabledIcon';
import TestDeviceLink from './TestDeviceLink';
import TestDeviceNotEligibleIcon from './TestDeviceNotEligibleIcon';

export default function TestDeviceName({
    testDevice,
    variant,
    hideErrorIcons,
    size,
}: {
    testDevice: ITestDevice;
    variant: 'link' | 'text' | 'edit';
    hideErrorIcons?: boolean;
    size?: 'large' | 'medium' | 'small';
}): ReactElement {
    const isOffline = testDevice.state === ETestDeviceState.offline;

    return (
        <Box display='flex' alignItems='center' gap={0.5}>
            {variant === 'link' && (
                <TestDeviceLink key={testDevice.uuid} uuid={testDevice.uuid} title={testDevice.name} />
            )}
            {variant === 'text' && <span>{testDevice.name}</span>}
            {testDevice.isDisabled && <TestDeviceDisabledIcon size={size} />}
            {testDevice.isElegibleForQueuedTests === false && <TestDeviceNotEligibleIcon size={size} />}
            {testDevice.testAgent?.manuallyDisabled && (
                <TestAgentDisabledIcon
                    disabledReason={testDevice.testAgent.disabledReason}
                    disabledDate={testDevice.testAgent.disabledDate}
                    updatedBy={testDevice.testAgent.updatedBy}
                    size={size}
                />
            )}
            {!hideErrorIcons && (
                <BatteryLevel
                    battery={testDevice.deviceInfo.battery}
                    isOffline={isOffline}
                    showOnlyOnError
                    size={size}
                />
            )}
            {!hideErrorIcons && (
                <Temperature
                    temperature={testDevice.deviceInfo.temperature}
                    isOffline={isOffline}
                    showOnlyOnError
                    size={size}
                />
            )}
            {variant === 'edit' && <TestDeviceNameEdit testDevice={testDevice} />}
        </Box>
    );
}
