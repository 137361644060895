import { ReactElement } from 'react';
import { TableCell, TableRow, Skeleton } from '@mui/material';
import { ProjectsColumnId } from '../projectsColumns';
import useProjectColumns from '../useProjectColumns';

export default function ProjectLoadingRows({ rows = 5 }: { rows?: number }): ReactElement {
    const loadingRows = [...Array(rows)].map((_value, index) => index);

    return (
        <>
            {loadingRows.map((key) => (
                <LoadingRow key={key} random={Math.random() * 0.5 + 0.5} />
            ))}
        </>
    );
}

function LoadingRow({ random }: { random: number }): ReactElement {
    const columns = useProjectColumns();

    return (
        <TableRow data-testid='project-loading-row'>
            {columns
                .filter((col) => col.visible)
                .map((column, index) => {
                    switch (column.id) {
                        case ProjectsColumnId.isEnabled:
                            return (
                                <TableCell key={index} style={{ padding: '16px 8px' }}>
                                    <Skeleton
                                        variant='rectangular'
                                        width={`${random * 80}%`}
                                        height={30}
                                        style={{ borderRadius: 5 }}
                                    />
                                </TableCell>
                            );

                        case ProjectsColumnId.agents:
                            return (
                                <TableCell key={index} style={{ padding: '16px 8px', margin: 'auto' }}>
                                    <Skeleton width={30} height={20} style={{ margin: 'auto' }} />
                                </TableCell>
                            );

                        case ProjectsColumnId.managers:
                            return (
                                <TableCell key={index} style={{ padding: '16px 8px', display: 'flex' }}>
                                    <Skeleton variant='rounded' width={36} height={36} style={{ margin: 0 }} />
                                    {Math.random() > 0.5 && (
                                        <Skeleton
                                            variant='rounded'
                                            width={36}
                                            height={36}
                                            style={{ margin: 0, marginLeft: '-8px' }}
                                        />
                                    )}
                                </TableCell>
                            );

                        case ProjectsColumnId.isOrderable:
                            return (
                                <TableCell key={index} style={{ padding: '16px 8px' }}>
                                    <Skeleton variant='rounded' width={20} height={20} style={{ margin: 'auto' }} />
                                </TableCell>
                            );

                        default:
                            return (
                                <TableCell key={index} style={{ padding: '16px 8px' }} align={column.align}>
                                    <Skeleton width={`${random * 100}%`} height={20} />
                                </TableCell>
                            );
                    }
                })}
        </TableRow>
    );
}
