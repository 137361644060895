export enum AgentsColumnId {
    name = 'name',
    priority = 'priority',
    state = 'state',
    ip = 'ip',
    unityVersions = 'unity',
    projects = 'supportedProjects',
    lastSeen = 'lastSeen',
    xcode = 'xcode',
    ndk = 'android_ndk',
    sdk = 'android_sdk',
    jdk = 'java_sdk',
    cocoapods = 'cocoapods',
    svn = 'svn',
    disk = 'disk_space',
    memory = 'memory_info',
    os = 'os',
    cpu = 'cpu_info',
    commit = 'git_info',
    version = 'version',
    actions = 'actions',
    updatedBy = 'updatedBy',
}

export enum EBuildAgentState {
    offline = 'offline',
    idle = 'idle',
    running = 'running',
    cancelling = 'cancelling',
    done = 'done',
    maintenance = 'maintenance',
    unknown = 'unknown',
}

export enum AgentDialogActionType {
    edit = 'edit',
    register = 'register',
    maintenance = 'maintenance',
}
