import { ReactElement, useEffect, useState } from 'react';
import BuildDuration from '../../builds/BuildDuration';
import BuildNumber from '../../builds/BuildNumber';
import BuildPlatform from '../../builds/BuildPlatform';
import BuildProjectName from '../../builds/BuildProjectName';
import Author from '../../commons/Author';
import ClickableTableRow from '../../commons/ClickableTableRow';
import { IToggleColumn } from '../../commons/interfaces';
import TableTextCell from '../../commons/TableTextCell';
import { openSocket } from '../../sockets';
import { ITestSuiteBase, ITestSuiteDetails } from '../interfaces';
import { TestSuitesColumnId } from '../testSuitesColumns';
import TestSuiteActionsCell from './TestSuiteActionsCell';
import TestSuiteCell from './TestSuiteCell';
import TestSuiteDevicesCell from './TestSuiteDevicesCell';
import TestSuiteStatusCell from './TestSuiteStatusCell';

export interface ITestSuiteRowProps {
    testSuite: ITestSuiteBase;
    columns: Array<IToggleColumn<TestSuitesColumnId>>;
}

export default function TestSuiteRow(props: ITestSuiteRowProps): ReactElement {
    const [suite, setSuite] = useState<ITestSuiteBase>(props.testSuite);

    const build = props.testSuite.build;
    const columns = props.columns;

    useEffect(() => {
        const socket = openSocket('tests');

        const handleTestSuiteUpdate = ({ testSuite }: { testSuite: ITestSuiteDetails }) => {
            if (testSuite._id === props.testSuite._id) {
                setSuite(testSuite);
            }
        };

        socket.on('update-test-suite', handleTestSuiteUpdate);

        return () => {
            socket.off('update-test-suite', handleTestSuiteUpdate);
        };
    }, [props.testSuite]);

    return (
        <ClickableTableRow
            redirectUrl={`/test-suites/${build.projectName}/${suite.testSuiteNumber}`}
            data-testsuiteid='test-suite-row'
        >
            {columns.map((column) => {
                switch (column.id) {
                    case TestSuitesColumnId.testSuiteNumber:
                        return <TestSuiteCell key={column.id} align={column.align} testSuite={suite} />;
                    case TestSuitesColumnId.projectName:
                        return (
                            <BuildProjectName key={column.id} align={column.align} projectName={build.projectName} />
                        );

                    case TestSuitesColumnId.status:
                        return <TestSuiteStatusCell key={column.id} testSuite={suite} />;

                    case TestSuitesColumnId.testBranch:
                        return <TableTextCell key={column.id} align={column.align} text={suite.testBranch || '-'} />;

                    case TestSuitesColumnId.buildBranch:
                        return <TableTextCell key={column.id} align={column.align} text={build.branch} />;

                    case TestSuitesColumnId.buildType:
                        return <TableTextCell key={column.id} align={column.align} text={build.type} />;

                    case TestSuitesColumnId.buildNumber:
                        return (
                            <BuildNumber
                                key={column.id}
                                align={column.align}
                                projectName={build.projectName}
                                buildNumber={build.buildNumber}
                            />
                        );

                    case TestSuitesColumnId.version:
                        return <TableTextCell key={column.id} align={column.align} text={build.versionName} />;

                    case TestSuitesColumnId.platform:
                        return <BuildPlatform key={column.id} align={column.align} platform={build.platform} />;

                    case TestSuitesColumnId.devices:
                        return <TestSuiteDevicesCell key={column.id} align={column.align} testSuite={suite} />;

                    case TestSuitesColumnId.runtime:
                        return (
                            <BuildDuration
                                key={column.id}
                                align={column.align}
                                started={suite.startedAt?.toString()}
                                completed={suite.completedAt?.toString()}
                            />
                        );

                    case TestSuitesColumnId.author:
                        return <Author key={column.id} align={column.align} author={suite.author} />;

                    case TestSuitesColumnId.actions:
                        return <TestSuiteActionsCell key={column.id} align={column.align} testSuite={suite} />;

                    default:
                        return <TableTextCell key={column.id} align={column.align} text={suite[column.id]} />;
                }
            })}
        </ClickableTableRow>
    );
}
