import { ReactElement } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { Icon, SxProps } from '@mui/material';
import { ReactComponent as amazonSVG } from './svg/amazon-icon.svg'; // this import lets webpack know to include the svg
import { ReactComponent as appletvSVG } from './svg/appletv-icon.svg';
import { ReactComponent as buildServerSVG } from './svg/build-server.svg';
import { ReactComponent as datadogSVG } from './svg/datadog-icon.svg';
import { ReactComponent as googleSVG } from './svg/google.svg';
import { ReactComponent as gradleSVG } from './svg/gradle-icon.svg';
import { ReactComponent as macosSVG } from './svg/macos-icon.svg';
import { ReactComponent as noTestQueuedSVG } from './svg/no-test-queued.svg';
import { ReactComponent as slackSVG } from './svg/slack-icon.svg';
import { ReactComponent as udpSVG } from './svg/udp-icon.svg';
import { ReactComponent as unitySVG } from './svg/unity-icon.svg';
import { ReactComponent as webglSVG } from './svg/webgl-icon.svg';

export enum SVGIcons {
    kindle = 'kindle',
    appletv = 'appletv',
    tvos = 'tvos',
    macos = 'macos',
    osx = 'osx',
    webgl = 'webgl',
    gradle = 'gradle',
    datadog = 'datadog',
    udp = 'udp',
    unity = 'unity',
    google = 'google',
    buildServer = 'build-server',
    noTestQueued = 'no-test-queued',
    slack = 'slack',
}

export enum SVGSizes {
    small = 'small',
    large = 'large',
}

interface SVGIconProps {
    icon: SVGIcons;
    size?: SVGSizes;
    disableDisplayBehavior?: boolean;
    title?: string;
    sx?: SxProps;
}

export function SVGIcon({ icon, size, disableDisplayBehavior, title, sx = {} }: SVGIconProps): ReactElement {
    let src;
    switch (icon) {
        case SVGIcons.kindle:
            src = amazonSVG;
            break;
        case SVGIcons.appletv:
        case SVGIcons.tvos:
            src = appletvSVG;
            break;
        case SVGIcons.macos:
        case SVGIcons.osx:
            src = macosSVG;
            break;
        case SVGIcons.webgl:
            src = webglSVG;
            break;
        case SVGIcons.gradle:
            src = gradleSVG;
            break;
        case SVGIcons.datadog:
            src = datadogSVG;
            break;
        case SVGIcons.udp:
            src = udpSVG;
            break;
        case SVGIcons.unity:
            src = unitySVG;
            break;

        case SVGIcons.google:
            src = googleSVG;
            break;

        case SVGIcons.buildServer:
            src = buildServerSVG;
            break;
        case SVGIcons.noTestQueued:
            src = noTestQueuedSVG;
            break;

        case SVGIcons.slack:
            src = slackSVG;
            break;
    }

    if (!src) {
        return <HelpIcon fontSize='small' />;
    }

    const isLarge = size && size === SVGSizes.large;

    const iconSize = isLarge ? '1.5em' : '1em';
    const iconStyles = {
        height: iconSize,
        width: iconSize,
        ...sx,
    };

    return (
        <Icon
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...(disableDisplayBehavior && {
                    display: 'flex',
                    padding: '0px',
                    margin: 'auto',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1.5em',
                    width: '1.5em',
                }),
                ...iconStyles,
            }}
            title={title}
            component={src}
        />
    );
}
