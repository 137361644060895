import { IToggleColumn } from '../../commons/interfaces';

export enum TestCasesColumnsId {
    status = 'status',
    testCases = 'testCases',
    started = 'started',
    ended = 'ended',
    duration = 'duration',
    actions = 'actions',
}

export const testCaseColumns: Array<IToggleColumn<TestCasesColumnsId>> = [
    { id: TestCasesColumnsId.status, label: 'Status', align: 'left', width: '10%' },
    { id: TestCasesColumnsId.testCases, label: 'Test Cases', align: 'left', width: '44%' },
    { id: TestCasesColumnsId.started, label: 'Started', align: 'left', width: '12%' },
    { id: TestCasesColumnsId.ended, label: 'Ended', align: 'left', width: '12%' },
    { id: TestCasesColumnsId.duration, label: 'Duration', align: 'left', width: '12%' },
    { id: TestCasesColumnsId.actions, label: 'Actions', align: 'left', width: '10%' },
];
