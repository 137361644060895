import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { getRequest } from '../../api';
import { StepState } from '../buildDetails/enums';
import { IBuildResult } from '../buildDetails/interfaces';
import { SliceStatus } from '../commons/enums';
import getUrlParam, { getUrlFilterValue } from '../commons/getUrlPrams';
import { IFilterAutocomplete, IFilterAutocompleteAuthor, IBuild, IToggleColumn } from '../commons/interfaces';
import * as queryString from '../commons/queryString';
import { getInitialColumnVisibilityState, persistColumnVisibleStateInCookie } from '../commons/toggleableColumnsState';
import { AppBarBuildCategories, defaultCategories } from '../header/enums';
import { EMessageLevel, IMessage } from '../messages/interfaces';
import { BuildsColumnId, TableSize } from './enums';
import { IBuildsSlice, IBuildsSliceState, IFilters, IBuildRow } from './interfaces';

const PAGE_SIZE: number = process.env.REACT_APP_BUILDS_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_BUILDS_PAGE_SIZE)
    : 50;

type BaseBuildFilters = Omit<IBuild, BuildsColumnId.assetRevision>;

export const getBuilds = createAsyncThunk<any, void, { state: IBuildsSliceState }>(
    'builds/getBuilds',
    async (_args, thunkAPI) => {
        const buildsState = thunkAPI.getState().builds;
        const filters = await getBuildFilters(thunkAPI);
        const params = getBuildFiltersParams(filters);

        if (buildsState.page) {
            params.skip = (PAGE_SIZE * buildsState.page).toString();
        }

        return getRequest('/builds', params);
    },
);

export const getBuildsExtras = createAsyncThunk<any, void, { state: IBuildsSliceState }>(
    'builds/getBuildsExtras',
    async (_args, thunkAPI) => {
        const state = thunkAPI.getState();
        const buildIds = state.builds.builds.map((build) => build._id);

        return getRequest('/builds/extra-results', { buildIds });
    },
);

export const getAvailableOptions = createAsyncThunk<any, void, { state: IBuildsSliceState }>(
    'builds/getAvailableOptions',
    async (_args, thunkAPI) => {
        const params = queryString.stringify({
            fields: [
                'projectName',
                'status',
                'author',
                'platform',
                'type',
                'kind',
                'revision',
                BuildsColumnId.assetRevision,
                'versionName',
                'branch',
                'buildAgent',
            ],
        });

        const response = await getRequest('/builds/available-options', params, thunkAPI);
        return response.options;
    },
);

const defaultColumnVisibility = {
    [BuildsColumnId.projectName]: true,
    [BuildsColumnId.status]: true,
    [BuildsColumnId.branch]: true,
    [BuildsColumnId.revision]: true,
    [BuildsColumnId.assetRevision]: true,
    [BuildsColumnId.versionName]: true,
    [BuildsColumnId.platform]: true,
    [BuildsColumnId.type]: true,
    [BuildsColumnId.kind]: true,
    [BuildsColumnId.buildAgent]: false,
    [BuildsColumnId.duration]: true,
    [BuildsColumnId.jiraTasks]: false,
    [BuildsColumnId.author]: true,
    [BuildsColumnId.messages]: true,
    [BuildsColumnId.actions]: true,
};
const columnInitialVisibleState = getInitialColumnVisibilityState('buildsVisibleColumns', defaultColumnVisibility);

const initialState: IBuildsSlice = {
    tableSize: TableSize.medium,
    status: SliceStatus.idle,
    filters: {
        projectName: { options: [], selected: null },
        status: { options: [], selected: null },
        author: { options: [], selected: null },
        platform: { options: [], selected: null },
        type: { options: [], selected: null },
        kind: { options: [], selected: null },
        revision: { options: [], selected: null },
        [BuildsColumnId.assetRevision]: { options: [], selected: null },
        versionName: { options: [], selected: null },
        branch: { options: [], selected: null },
        buildAgent: { options: [], selected: null },
        releaseCandidate: { options: [], selected: false },
        search: { options: [], selected: false },
    },
    columns: [
        {
            id: BuildsColumnId.status,
            label: 'Status',
            align: 'left',
            width: '120px',
            visible: columnInitialVisibleState[BuildsColumnId.status],
        },
        {
            id: BuildsColumnId.projectName,
            label: 'Project / Build ID',
            width: '140px',
            visible: columnInitialVisibleState[BuildsColumnId.projectName],
            disabled: true,
        },
        {
            id: BuildsColumnId.branch,
            label: 'Branch',
            width: '140px',
            visible: columnInitialVisibleState[BuildsColumnId.branch],
        },
        {
            id: BuildsColumnId.revision,
            label: 'Revision',
            width: '90px',
            visible: columnInitialVisibleState[BuildsColumnId.revision],
        },
        {
            id: BuildsColumnId.assetRevision,
            label: 'Asset Revision',
            minWidth: 50,
            width: '120px',
            visible: columnInitialVisibleState[BuildsColumnId.assetRevision],
        },
        {
            id: BuildsColumnId.versionName,
            label: 'Version',
            width: '100px',
            visible: columnInitialVisibleState[BuildsColumnId.versionName],
        },
        {
            id: BuildsColumnId.platform,
            label: 'Platform',
            width: '100px',
            align: 'center',
            visible: columnInitialVisibleState[BuildsColumnId.platform],
        },
        {
            id: BuildsColumnId.type,
            label: 'Type',
            width: '80px',
            visible: columnInitialVisibleState[BuildsColumnId.type],
        },
        {
            id: BuildsColumnId.kind,
            label: 'Kind',
            width: '80px',
            visible: columnInitialVisibleState[BuildsColumnId.kind],
        },
        {
            id: BuildsColumnId.buildAgent,
            label: 'Agent',
            width: '100px',
            visible: columnInitialVisibleState[BuildsColumnId.buildAgent],
        },
        {
            id: BuildsColumnId.duration,
            label: 'Duration / Started',
            align: 'left',
            width: '105px',
            visible: columnInitialVisibleState[BuildsColumnId.duration],
        },
        {
            id: BuildsColumnId.jiraTasks,
            label: 'Jira Tasks',
            align: 'center',
            minWidth: 50,
            width: '80px',
            visible: columnInitialVisibleState[BuildsColumnId.jiraTasks],
        },
        {
            id: BuildsColumnId.author,
            label: 'Ordered by',
            align: 'center',
            width: '110px',
            visible: columnInitialVisibleState[BuildsColumnId.author],
        },
        {
            id: BuildsColumnId.messages,
            label: 'Messages',
            align: 'center',
            width: '100px',
            visible: columnInitialVisibleState[BuildsColumnId.messages],
        },
        {
            id: BuildsColumnId.actions,
            label: 'Actions',
            align: 'center',
            width: '80px',
            visible: columnInitialVisibleState[BuildsColumnId.actions],
        },
    ],
    builds: [],
    newBuildsIds: [],
    error: undefined,
    page: getUrlParam('page') as number,
    totalCount: 0,
};

export const buildsSlice = createSlice({
    name: 'builds',
    initialState,
    reducers: {
        setBuildsColumnVisibility: (state, action: PayloadAction<{ columnId: BuildsColumnId; value: boolean }>) => {
            const { columnId, value } = action.payload;
            const columnIndex = state.columns.findIndex((column) => column.id === columnId);
            state.columns[columnIndex].visible = value;
            persistColumnVisibleStateInCookie('buildsVisibleColumns', state.columns);
        },
        setBuildStatus: (state, { payload }: PayloadAction<string>) => {
            state.status = payload;
        },
        setNewBuilds: (state, action: PayloadAction<string[]>) => {
            return {
                ...initialState,
                newBuildsIds: action.payload,
            };
        },
        removeFromNewBuilds: (state, action: PayloadAction<string>) => {
            state.newBuildsIds = state.newBuildsIds.filter((buildId) => buildId !== action.payload);
        },
        setBuildsPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterProjectName: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.projectName.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterStatus: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.status.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterAuthor: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.author.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterPlatform: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.platform.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterType: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.type.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterKind: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.kind.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterReleaseCandidate: (state, action: PayloadAction<boolean>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.releaseCandidate.selected = !!action.payload;
            setUrlParamsFromState(state);
        },
        setFilterRevision: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.revision.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterAssetRevision: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters[BuildsColumnId.assetRevision].selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterVersionName: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.versionName.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterBranch: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.branch.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterBuildAgent: (state, action: PayloadAction<string[] | null>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.buildAgent.selected = action.payload;
            setUrlParamsFromState(state);
        },
        setFilterSearch: (state, action: PayloadAction<string>) => {
            state.page = 0;
            state.status = SliceStatus.loading;
            state.filters.search.selected = action.payload;
            setUrlParamsFromState(state);
        },
        clearAllFilters: (state) => {
            state.page = 0;
            state.filters.projectName.selected = null;
            state.filters.status.selected = null;
            state.filters.author.selected = null;
            state.filters.platform.selected = null;
            state.filters.type.selected = null;
            state.filters.kind.selected = null;
            state.filters.revision.selected = null;
            state.filters[BuildsColumnId.assetRevision].selected = null;
            state.filters.versionName.selected = null;
            state.filters.branch.selected = null;
            state.filters.buildAgent.selected = null;
            state.filters.releaseCandidate.selected = false;
            setUrlParamsFromState(state);
        },
        addNewBuild: (state, action: PayloadAction<IBuild>) => {
            const index = state.builds.findIndex((build) => build._id === action.payload._id);
            if (index === -1 && buildFitsTheFilters(state.filters, action.payload)) {
                state.builds = [action.payload, ...state.builds];
                state.newBuildsIds = [action.payload._id, ...state.newBuildsIds];
                addBuildToAvailableOptions(state.filters, action.payload);
            }
        },
        updateBuild: (state, action: PayloadAction<IBuild>) => {
            const index = state.builds.findIndex((build) => build._id === action.payload._id);
            if (index !== -1) {
                const updatedBuild: IBuildRow = action.payload;
                if (state.builds[index].mainArtifact) {
                    updatedBuild.mainArtifact = state.builds[index].mainArtifact;
                }

                if (state.builds[index].messages) {
                    updatedBuild.messages = state.builds[index].messages;
                }

                if (state.builds[index].skippedSteps) {
                    updatedBuild.skippedSteps = state.builds[index].skippedSteps;
                }
                state.builds[index] = updatedBuild;
            }
        },
        updateBuildExtras: (state, action: PayloadAction<IBuildResult>) => {
            const index = state.builds.findIndex((build) => build._id === action.payload.build);
            if (index !== -1) {
                const enhancedBuild = state.builds[index];
                enhancedBuild.mainArtifact = action.payload.mainArtifact;
                if (action.payload.steps) {
                    const messages = action.payload.steps
                        .map((step) => step.messages)
                        .flat()
                        .filter(
                            (message) =>
                                message &&
                                (message.level === EMessageLevel.error || message.level === EMessageLevel.warning),
                        ) as IMessage[];
                    if (messages?.length) {
                        enhancedBuild.messages = messages;
                    }

                    const skipped = action.payload.steps.filter((step) => step.state === StepState.skipped);
                    if (skipped.length) {
                        enhancedBuild.skippedSteps = skipped.map((step) => step.name);
                    }
                }
                state.builds[index] = enhancedBuild;
            }
        },
        setFilters: (state) => {
            if (window.location.search) {
                state.page = Number(getUrlParam('page') || 0);
                state.filters.projectName.selected = getUrlFilterValue('projectName');
                state.filters.status.selected = getUrlFilterValue('status');
                state.filters.branch.selected = getUrlFilterValue('branch');
                state.filters.revision.selected = getUrlFilterValue('revision');
                state.filters[BuildsColumnId.assetRevision].selected = getUrlFilterValue(BuildsColumnId.assetRevision);
                state.filters.versionName.selected = getUrlFilterValue('versionName');
                state.filters.type.selected = getUrlFilterValue('type');
                state.filters.kind.selected = getUrlFilterValue('kind');
                state.filters.platform.selected = getUrlFilterValue('platform');
                state.filters.buildAgent.selected = getUrlFilterValue('buildAgent');
                state.filters.author.selected = getUrlFilterValue('author');
                state.filters.releaseCandidate.selected = getUrlParam('releaseCandidate') === 'true';
                state.filters.search.selected = getUrlFilterValue('search');
            } else {
                setUrlParamsFromState(state);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBuilds.pending, (state) => {
                state.status = SliceStatus.loading;
            })
            .addCase(getBuilds.fulfilled, (state, action: PayloadAction<{ builds: IBuild[]; totalCount: number }>) => {
                state.totalCount = action.payload.totalCount;
                state.builds = action.payload.builds;
                state.status = SliceStatus.loaded;
            })
            .addCase(getAvailableOptions.pending, (state) => {
                state.filters.projectName.loading = true;
                state.filters.status.loading = true;
                state.filters.author.loading = true;
                state.filters.platform.loading = true;
                state.filters.type.loading = true;
                state.filters.kind.loading = true;
                state.filters.revision.loading = true;
                state.filters[BuildsColumnId.assetRevision].loading = true;
                state.filters.versionName.loading = true;
                state.filters.branch.loading = true;
                state.filters.buildAgent.loading = true;
            })
            .addCase(getAvailableOptions.fulfilled, (state, action) => {
                const filterFields = Object.keys(action.payload) as unknown as keyof IFilters;
                for (const field of filterFields) {
                    state.filters[field as keyof IFilters].options = action.payload[field] || [];
                    state.filters[field as keyof IFilters].loading = false;
                }
            })
            .addCase(getBuildsExtras.fulfilled, (state, { payload }) => {
                // Enhance builds with extra results
                const enhancedBuilds = state.builds;
                for (const build of enhancedBuilds) {
                    const extraResult = payload?.extraResults ? payload.extraResults[build._id] : null;
                    if (extraResult) {
                        build.mainArtifact = extraResult.mainArtifact;
                        build.messages = extraResult.messages;
                        build.skippedSteps = extraResult.skippedSteps;
                        build.releaseCandidate = extraResult.releaseCandidate;
                    }
                }

                state.builds = enhancedBuilds;
            });
    },
});

export const selectTableSize = (state: IBuildsSliceState): TableSize => state.builds.tableSize;
export const selectAllBuilds = (state: IBuildsSliceState): IBuild[] => state.builds.builds || [];
export const selectNewBuildsIds = (state: IBuildsSliceState): string[] => state.builds.newBuildsIds;
export const selectBuildsStatus = (state: IBuildsSliceState): string => state.builds.status;
export const selectBuildsPage = (state: IBuildsSliceState): number => state.builds.page;
export const selectBuildsTotalCount = (state: IBuildsSliceState): number => state.builds.totalCount || 0;
export const selectFilters = (state: IBuildsSliceState): IFilters => state.builds.filters;
export const selectFilterProjectName = (state: IBuildsSliceState): IFilterAutocomplete =>
    state.builds.filters.projectName;
export const selectFilterStatus = (state: IBuildsSliceState): IFilterAutocomplete => state.builds.filters.status;
export const selectFilterAuthor = (state: IBuildsSliceState): IFilterAutocompleteAuthor => state.builds.filters.author;
export const selectFilterPlatform = (state: IBuildsSliceState): IFilterAutocomplete => state.builds.filters.platform;
export const selectFilterType = (state: IBuildsSliceState): IFilterAutocomplete => state.builds.filters.type;
export const selectFilterKind = (state: IBuildsSliceState): IFilterAutocomplete => state.builds.filters.kind;
export const selectFilterReleaseCandidate = (state: IBuildsSliceState): IFilterAutocomplete =>
    state.builds.filters.releaseCandidate;
export const selectFilterSearch = (state: IBuildsSliceState): IFilterAutocomplete => state.builds.filters.search;
export const selectFilterRevision = (state: IBuildsSliceState): IFilterAutocomplete => state.builds.filters.revision;
export const selectFilterAssetRevision = (state: IBuildsSliceState): IFilterAutocomplete =>
    state.builds.filters[BuildsColumnId.assetRevision];
export const selectFilterVersionName = (state: IBuildsSliceState): IFilterAutocomplete =>
    state.builds.filters.versionName;
export const selectFilterBranch = (state: IBuildsSliceState): IFilterAutocomplete => state.builds.filters.branch;
export const selectFilterBuildAgent = (state: IBuildsSliceState): IFilterAutocomplete =>
    state.builds.filters.buildAgent;
export const selectBuildsColumns = (state: IBuildsSliceState): Array<IToggleColumn<BuildsColumnId>> =>
    state.builds.columns;
export const selectAmountOfActiveBuildsFilters = (state: IBuildsSliceState): number => {
    return Object.keys(state.builds.filters)
        .filter((key) => key !== 'search')
        .reduce((total: number, key: string) => {
            const filter = state.builds.filters[key as keyof IFilters] as
                | IFilterAutocomplete
                | IFilterAutocompleteAuthor;
            if (key === 'releaseCandidate') {
                return total;
            }
            return filter.selected ? total + 1 : total;
        }, 0);
};

export const {
    setNewBuilds,
    removeFromNewBuilds,
    setBuildStatus,
    setBuildsPage,
    setFilterProjectName,
    setFilterStatus,
    setFilterAuthor,
    setFilterPlatform,
    setFilterType,
    setFilterKind,
    setFilterReleaseCandidate,
    setFilterRevision,
    setFilterAssetRevision,
    setFilterVersionName,
    setFilterBranch,
    setFilterBuildAgent,
    setFilterSearch,
    setFilters,
    clearAllFilters,
    addNewBuild,
    updateBuild,
    updateBuildExtras,
    setBuildsColumnVisibility,
} = buildsSlice.actions;
export default buildsSlice.reducer;

function buildFitsTheFilters(filters: IFilters, build: IBuild): boolean {
    for (const filter in filters) {
        if (filter === BuildsColumnId.assetRevision) {
            const selectedAssetRevision = filters[filter].selected;
            if (selectedAssetRevision && selectedAssetRevision !== build?.settings?.assetRevision) {
                return false;
            }
        } else if (
            filters[filter as keyof IFilters].selected &&
            filters[filter as keyof IFilters].selected !== build[filter as keyof BaseBuildFilters]
        ) {
            return false;
        }
    }
    return true;
}

function addBuildToAvailableOptions(filters: IFilters, build: IBuild) {
    for (const filter in filters) {
        if (filter === 'author') {
            if (filters.author.options.findIndex((author) => author._id === build.author._id) === -1) {
                filters.author.options.push(build.author);
            }
        } else if (filter === BuildsColumnId.assetRevision) {
            const buildAssetRevision = build?.settings?.assetRevision as string;
            const valueIsNotInOptions =
                filters[BuildsColumnId.assetRevision].options.findIndex((option) => option === buildAssetRevision) ===
                -1;
            if (buildAssetRevision && valueIsNotInOptions) {
                filters[BuildsColumnId.assetRevision].options.push(buildAssetRevision);
            }
        } else if (
            build[filter as keyof BaseBuildFilters] &&
            filters[filter as keyof IFilters].options.findIndex(
                (option) => option === build[filter as keyof BaseBuildFilters],
            ) === -1
        ) {
            filters[filter as keyof IFilters].options.push(build[filter as keyof BaseBuildFilters]);
        }
    }
}

async function getBuildFilters(
    thunkAPI: BaseThunkAPI<IBuildsSliceState, unknown, any, unknown>,
    retry = 0,
): Promise<IFilters> {
    const filters = thunkAPI.getState().builds.filters;
    if (filters.kind.selected !== AppBarBuildCategories.others) {
        return filters;
    }

    const loading = filters.kind.loading || filters.kind.loading === undefined;
    if (loading && retry < 5) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        return getBuildFilters(thunkAPI, retry + 1);
    }

    const otherKindFilters = filters.kind.options.filter((item) => !defaultCategories.includes(item));
    return { ...filters, kind: { ...filters.kind, selected: otherKindFilters.join(',') } };
}

export function getBuildFiltersParams(filters: IFilters): Record<string, string> {
    const params: Record<string, string> = {};

    for (const key of Object.keys(filters)) {
        if (filters[key as keyof IFilters].selected) {
            params[key as string] = filters[key as keyof IFilters].selected as string;
        }
    }

    return params;
}

function setUrlParamsFromState(buildsState: IBuildsSlice): void {
    const params = queryString.parse(window.location.search);

    for (const filter in buildsState.filters) {
        if (buildsState.filters[filter as keyof IFilters].selected) {
            params[filter] = buildsState.filters[filter as keyof IFilters].selected;
        } else {
            delete params[filter];
        }
    }

    if (buildsState.page) {
        params.page = buildsState.page.toString();
    } else {
        delete params.page;
    }

    const stringParams = queryString.stringify(params);
    window.history.replaceState(null, `set filters filters`, `/builds${stringParams ? `?` : ''}${stringParams}`);
}
