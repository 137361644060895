import { ReactElement } from 'react';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Chip, Popper, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { DataValueBox } from '../commons/DataValueBox';
import { GameModuleCategory } from './enums';
import { IModuleProject } from './interfaces';
import { isSha } from './utils';

interface GameModuleStatusProps {
    project?: IModuleProject;
}

export default function GameModuleStatus({ project }: GameModuleStatusProps): ReactElement {
    if (!project) {
        return <>-</>;
    }

    const moduleBranchIsDifferent = project.category === GameModuleCategory.MODULE && project?.branch !== 'trunk';
    const packageBranchIsDifferent = project.category !== GameModuleCategory.MODULE && isSha(project.version);
    const branchIsDifferent = moduleBranchIsDifferent || packageBranchIsDifferent;
    const versionIsDifferent = project.latestVersion !== 'builtin' && project?.version !== project.latestVersion;

    const versionLabel = project.category === GameModuleCategory.MODULE ? 'Revision' : 'Version';
    const isUpdated = !branchIsDifferent && !versionIsDifferent;

    return (
        <Box>
            <Tooltip
                sx={{ background: blueGray[900] }}
                PopperComponent={(props) => (
                    <Popper
                        {...props}
                        sx={{
                            '.MuiTooltip-tooltip': {
                                background: blueGray[900],
                                minWidth: 300,
                                maxWidth: 500,
                            },
                        }}
                        onClick={(event) => event.stopPropagation()}
                    />
                )}
                title={
                    <Box sx={{ width: '100%' }}>
                        <DataValueBox
                            label={versionLabel}
                            value={project.version}
                            labelSx={{ color: blueGray[500] }}
                            valueSx={{ color: (theme) => theme.palette.common.white }}
                        />
                        <DataValueBox
                            label={`Latest ${versionLabel} at Build Time`}
                            value={project.latestVersion}
                            labelSx={{ color: blueGray[500] }}
                            valueSx={{ color: (theme) => theme.palette.common.white }}
                        />
                        <DataValueBox
                            label='Branch'
                            value={project.branch}
                            labelSx={{ color: blueGray[500] }}
                            valueSx={{ color: (theme) => theme.palette.common.white }}
                        />
                    </Box>
                }
            >
                {isUpdated ? (
                    <CheckRoundedIcon data-testid='project-updated-check' sx={{ fontSize: 24 }} />
                ) : (
                    <Chip
                        sx={{
                            cursor: 'pointer',
                            background: red[50],
                            color: red[700],
                            '.MuiChip-label': { display: 'flex', alignItems: 'center' },
                        }}
                        size='small'
                        label={
                            <>
                                {branchIsDifferent && (
                                    <AccountTreeRoundedIcon data-testid='branch-icon' sx={{ fontSize: 12, mr: 0.5 }} />
                                )}
                                {project?.versionDiff ? `-${project?.versionDiff}` : '0'}
                            </>
                        }
                    />
                )}
            </Tooltip>
        </Box>
    );
}
