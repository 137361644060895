import { ReactElement } from 'react';
import Moment from 'react-moment';
import { TableCell } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import moment from 'moment';
import { MuiAlignSetting } from '../../typings/material-ui';
import Duration from '../commons/Duration';

export default function BuildDuration(props: {
    align?: MuiAlignSetting;
    started?: string;
    completed?: string;
}): ReactElement {
    const { align, started, completed } = props;

    if (!started) {
        return <TableCell align={align} sx={{ padding: 1 }} data-testid='builds-table-build-duration-empty' />;
    }

    return (
        <TableCell align={align} sx={{ padding: 1, minWidth: 60 }} data-testid='builds-table-build-duration'>
            <Duration started={started} completed={completed} />
            <span
                style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: blueGray[600],
                    fontWeight: 400,
                }}
                title={moment(started).format('DD-MM-YYYY HH:mm')}
            >
                <Moment fromNow interval={1000}>
                    {started}
                </Moment>
            </span>
        </TableCell>
    );
}
