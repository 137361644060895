export enum EMessageLevel {
    info = 'info',
    warning = 'warning',
    error = 'error',
}

export interface IMessage {
    level: EMessageLevel;
    text: string;
}
