import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Alert,
    Box,
    Checkbox,
    Chip,
    Collapse,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { AppThunkDispatch } from '../../store';
import BuildNumberLink from '../builds/BuildNumberLink';
import BuildStatusChip from '../commons/BuildStatusChip';
import ConfigurationSettings from '../configurationSettings/ConfigurationSettings';
import { TSetting } from '../configurationSettings/interfaces';
import { IConfigurationBuild } from './interfaces';
import {
    getSimilarBuilds,
    removeBuild,
    selectBuild,
    selectNewBuildConfiguration,
    selectNewBuildConfigurationBuilds,
    selectNewBuildConfigurationSelectedBuilds,
    selectNewBuildConfigurationSelectedSettings,
    selectNewBuildConfigurationSettings,
    selectNewBuildSimilarBuilds,
    setSetting,
} from './newBuildSlice';

export default function NewBuildConfiguration(): ReactElement | null {
    const buildConfiguration = useSelector(selectNewBuildConfiguration);

    if (!buildConfiguration.selected) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                alignItems: 'left',
                my: 2,
            }}
            data-testid='new-build-configuration'
        >
            <SupportedBuilds />
            <AdvancedSettings />
        </Box>
    );
}

function SupportedBuilds(): ReactElement {
    const dispatch = useDispatch();
    const buildList = useSelector(selectNewBuildConfigurationBuilds);
    const builds = buildList?.filter((build) => !build.hidden);
    const selectedBuilds = useSelector(selectNewBuildConfigurationSelectedBuilds);
    const similarBuilds = useSelector(selectNewBuildSimilarBuilds);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, build: IConfigurationBuild) => {
        if (event.target.checked) {
            dispatch(selectBuild(build.id));
        } else {
            dispatch(removeBuild(build.id));
        }
    };

    const buildIsSelected = (build: IConfigurationBuild) => {
        return !!selectedBuilds?.some((selected) => selected === build);
    };

    if (!builds || !builds.length) {
        return <Alert severity='error'>Build configuration has no &quot;builds&quot;</Alert>;
    }

    return (
        <>
            <Typography variant='subtitle1' fontWeight={600} gutterBottom>
                Supported Builds
                <Chip size='small' sx={{ ml: 1 }} label={`${selectedBuilds?.length || 0}/${builds.length}`} />
            </Typography>
            <FormGroup row>
                <Grid container>
                    {builds.map((build, index) => {
                        const similarBuild = similarBuilds.find(
                            (similarBuild) => similarBuild.buildConfigId === build.id,
                        );
                        return (
                            <Grid item key={index} xs={12} sm={3}>
                                <Box display='flex' alignItems='center'>
                                    <FormControlLabel
                                        sx={{ m: 1 }}
                                        label={build.display_name}
                                        control={
                                            <Checkbox
                                                checked={buildIsSelected(build)}
                                                onChange={(e) => handleChange(e, build)}
                                                name={build.display_name}
                                            />
                                        }
                                    />
                                    {similarBuild && (
                                        <>
                                            <BuildNumberLink
                                                projectName={similarBuild.projectName}
                                                buildNumber={similarBuild.buildNumber}
                                                sx={{ mr: 1 }}
                                            />
                                            <BuildStatusChip status={similarBuild.status} forcedMobile size={18} />
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </FormGroup>
        </>
    );
}

function AdvancedSettings(): ReactElement | null {
    const dispatch = useDispatch<AppThunkDispatch>();
    const builds = useSelector(selectNewBuildConfigurationBuilds);
    const settings = useSelector(selectNewBuildConfigurationSettings);
    const selectedSettings = useSelector(selectNewBuildConfigurationSelectedSettings);
    const [showSettings, setShowSettings] = useState(false);

    const handleShowSettingsToggle = () => {
        setShowSettings(!showSettings);
    };

    if (!settings || !Object.keys(settings).length || !builds?.length) {
        return null;
    }

    const selectedSettingsFilter = (value: TSetting) => (Array.isArray(value) ? !!value.length : !!value);
    const settingsCountChipLabel = `${Object.values(selectedSettings || {}).filter(selectedSettingsFilter).length}/${
        Object.values(settings).filter((value) => !value.hidden).length
    }`;

    return (
        <>
            <Typography variant='subtitle1' fontWeight={600} sx={{ mt: 2 }} gutterBottom>
                Advanced Settings
                <Chip size='small' sx={{ ml: 1 }} label={settingsCountChipLabel} />
                <IconButton
                    title={showSettings ? 'hide settings' : 'show settings'}
                    onClick={handleShowSettingsToggle}
                    sx={{ marginLeft: 1, padding: 1 }}
                >
                    {showSettings ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
            </Typography>
            <Collapse in={showSettings}>
                <ConfigurationSettings
                    settings={settings}
                    selectedSettings={selectedSettings}
                    onSettingChange={(setting, value) => {
                        dispatch(setSetting({ setting, value }));
                        dispatch(getSimilarBuilds());
                    }}
                />
            </Collapse>
        </>
    );
}
