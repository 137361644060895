import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import DownloadBuildButton from '../commons/DownloadBuildButton';
import { selectBuildDetailsBuild, selectBuildDetailsResult } from './buildDetailsSlice';

// This is a specific implementation of the download build that uses the buildDetailsSlice
export default function DownloadBuildFromDetailsRowButton(): ReactElement | null {
    const build = useSelector(selectBuildDetailsBuild);
    const buildResult = useSelector(selectBuildDetailsResult);

    if (!build || !buildResult) {
        return null;
    }

    return (
        <DownloadBuildButton
            artifact={buildResult.mainArtifact}
            buildNumber={build.buildNumber}
            buildStatus={build.status}
            projectName={build.projectName}
            isBuildDetails={true}
            variant='outlined'
        />
    );
}
