import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Skeleton, Table, TableCell, TableHead, styled } from '@mui/material';
import { blueGray, useInfiniteScroll } from '@tactileentertainment/core-designsystem';
import { ShowIfCan } from '@tactileentertainment/core-shared.auth-react';
import { EAuthResource } from '../commons/EAuthResource';
import { getAcronym } from '../commons/getAcronym';
import TableBody from '../commons/TableBody';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import GameModuleRow from './GameModuleRow';
import GameModulesLoadingRows from './GameModulesLoadingRows';
import { IModule } from './interfaces';

interface GameModulesTableProps {
    modules: IModule[];
    isLoading: boolean;
    projectList: string[];
    selectedItems: Array<Partial<IModule>>;
    onSelectedChange: (arg: Array<Partial<IModule>>) => void;
}

const pageSize = 50;

export default function GameModulesTable({
    modules,
    isLoading,
    projectList,
    onSelectedChange,
    selectedItems: selected,
}: GameModulesTableProps): ReactElement {
    const tableBodyRef = useRef<HTMLTableElement>(null);
    const [paginatedModules, setPaginatedModules] = useState<IModule[]>([]);
    const [page, setPage] = useState(1);

    const loadMore = async () => {
        const newPage = page + 1;
        setPaginatedModules(modules.slice(0, newPage * pageSize));
        setPage(newPage);
    };

    const hasMore = modules.length !== paginatedModules.length;
    useInfiniteScroll({ list: paginatedModules, loadMore, ref: tableBodyRef, loadingHeight: 230, hasMore });

    useEffect(() => {
        setPage(1);
        setPaginatedModules(modules.slice(0, pageSize));
    }, [modules]);

    const projectListData = useMemo(() => {
        return projectList
            .map((project) => ({ name: project, acronym: getAcronym(project) || '' }))
            .sort((a, b) => a.acronym.localeCompare(b.acronym));
    }, [projectList]);

    return (
        <TableContainer ref={tableBodyRef} style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <Table
                stickyHeader
                aria-label='game modules table'
                style={{ width: '100%', minWidth: 1600, tableLayout: 'fixed' }}
            >
                <TableHead data-testid='game-modules-table-head'>
                    <TableHeaderRow>
                        <ShowIfCan resource={EAuthResource.modules} operation='edit'>
                            <StickyTableCell key='checkbox' sx={{ left: 0, width: 60 }}>
                                <FormControlLabel
                                    control={<Checkbox indeterminate={!!selected.length} checked={false} />}
                                    onChange={() => onSelectedChange([])}
                                    label=''
                                />
                            </StickyTableCell>
                        </ShowIfCan>
                        <StickyTableCell key='name' sx={{ left: { xs: undefined, md: 0 }, width: 450, zIndex: 20 }}>
                            Name
                        </StickyTableCell>
                        <StickyTableCell
                            key='trunk-updated'
                            sx={{ width: 130, left: { xs: undefined, md: 300 }, zIndex: 21, color: blueGray[600] }}
                        >
                            Trunk Updated
                        </StickyTableCell>
                        <StickyTableCell
                            key='latest-version'
                            size='small'
                            sx={{ width: 130, left: { xs: undefined, md: 430 }, zIndex: 22, color: blueGray[600] }}
                        >
                            Latest Version
                        </StickyTableCell>
                        {isLoading && (
                            <TableCell>
                                <Skeleton variant='rectangular' width={'100%'} height={20} />
                            </TableCell>
                        )}
                        {projectListData.map(({ name, acronym }) => {
                            return (
                                <TableCell key={name} align='center' size='small' title={name} sx={{ width: 60 }}>
                                    {acronym}
                                </TableCell>
                            );
                        })}
                        {!isLoading && <TableCell />}
                    </TableHeaderRow>
                </TableHead>
                <TableBody data-testid='game-modules-table-body'>
                    {isLoading ? (
                        <GameModulesLoadingRows />
                    ) : (
                        <>
                            {paginatedModules.map((module) => (
                                <GameModuleRow
                                    key={module.moduleName}
                                    module={module}
                                    selectedItems={selected}
                                    onSelectedChange={onSelectedChange}
                                    projectList={projectListData.map(({ name }) => name)}
                                />
                            ))}
                            {hasMore && (
                                <GameModulesLoadingRows rows={5} projectListSize={projectListData.length + 1} />
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const StickyTableCell = styled(TableCell)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        position: 'sticky',
    },
}));
