import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import CancelTestSuiteAction from '../detail/CancelTestSuiteAction';
import { ITestSuiteBase } from '../interfaces';
import { isTestStatusCompleted } from '../utils';

export default function TestSuiteActionsCell({
    testSuite,
    align,
}: {
    testSuite: ITestSuiteBase;
    align?: MuiAlignSetting;
}): ReactElement {
    return (
        <TableCell align={align}>
            {!isTestStatusCompleted(testSuite.status) && <CancelTestSuiteAction testSuite={testSuite} />}
        </TableCell>
    );
}
