import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Box,
} from '@mui/material';
import { errorToast, successToast } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { setNewBuilds } from '../builds/buildsSlice';
import { IBuild } from '../commons/interfaces';
import { useGetCurrentUserQuery, useSaveToFavouritesMutation } from '../userProfile/usersApi';
import BuildSuccessToastContent from './BuildSuccessToastContent';
import {
    clearNewBuild,
    orderBuilds,
    selectNewBuildBranch,
    selectNewBuildConfiguration,
    selectNewBuildProject,
    selectNewBuildOrderIsValid,
    selectNewBuildConfigurationSelectedBuilds,
    selectNewBuildVersion,
    selectNewBuildSubscribed,
    setSubscribed,
    selectNewBuildConfigurationSelectedSettings,
} from './newBuildSlice';

export default function NewBuildActionButtons(): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch<AppThunkDispatch>();
    const orderIsValid = useSelector(selectNewBuildOrderIsValid);
    const selectedBuilds = useSelector(selectNewBuildConfigurationSelectedBuilds);
    const selectedBuildsLength = selectedBuilds?.length || 0;
    const project = useSelector(selectNewBuildProject);
    const branch = useSelector(selectNewBuildBranch);
    const version = useSelector(selectNewBuildVersion);
    const buildConfiguration = useSelector(selectNewBuildConfiguration);
    const settings = useSelector(selectNewBuildConfigurationSelectedSettings);
    const configFileName = buildConfiguration?.selected?.fileName || '';
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [favouriteName, setFavouriteName] = useState('');
    const [saveToFavourites] = useSaveToFavouritesMutation();
    const { data: userData } = useGetCurrentUserQuery();
    const userPreferences = userData?.user?.preferences;
    const subscribed = useSelector(selectNewBuildSubscribed);

    const handleDialogChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFavouriteName(event.target.value);
    };

    const handleSaveClick = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setFavouriteName('');
    };

    const handleDialogSave = async () => {
        const result = await saveToFavourites({
            projectName: project.selected,
            branch: branch.selected,
            versionName: version,
            name: favouriteName,
            buildConfigFileName: buildConfiguration.selected?.fileName,
            settings: settings || {},
            subscribed,
        });
        if (!('error' in result)) {
            successToast('Saved to favourites');
            setDialogOpen(false);
        }
    };

    const handleOrderBuild = () => {
        setLoading(true);
        dispatch(orderBuilds()).then((action) => {
            const newBuildsIds = action.payload?.builds?.map((build: IBuild) => build._id);
            const newBuilds = action.payload?.builds;
            if (newBuilds && newBuilds.length) {
                newBuilds.forEach((build: IBuild) => {
                    successToast(<BuildSuccessToastContent build={build} />, { duration: 15000 });
                });
                dispatch(setNewBuilds(newBuildsIds));
                setLoading(false);
                history.push('/builds');
            } else {
                errorToast(`Failed to order build${selectedBuildsLength && selectedBuildsLength > 1 ? 's' : ''}`, {
                    variant: 'contained',
                });
                console.error(action);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        return () => {
            dispatch(clearNewBuild());
        };
    }, [dispatch]);

    useEffect(() => {
        if (userPreferences?.subscribeToResult) {
            dispatch(setSubscribed(true));
        }
    }, [userPreferences, dispatch]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} data-testid='new-build-buttons'>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    background: (theme) => theme.palette.common.white,
                    width: '100%',
                    right: 0,
                    py: 2,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    sx={{
                        color: (theme) => theme.palette.common.white,
                        margin: (theme) => theme.spacing(0, 0, 0, 1),
                    }}
                    variant='contained'
                    color='secondary'
                    title='Save To Favourites'
                    disabled={!project.selected || !branch.selected || !buildConfiguration.selected}
                    startIcon={<FavoriteIcon />}
                    onClick={handleSaveClick}
                >
                    Favorite
                </Button>
                <Button
                    sx={{
                        color: (theme) => theme.palette.common.white,
                        margin: (theme) => theme.spacing(0, 0, 0, 1),
                    }}
                    variant='contained'
                    color='primary'
                    disabled={!orderIsValid || selectedBuildsLength < 1 || loading}
                    onClick={handleOrderBuild}
                >
                    Order {selectedBuildsLength && selectedBuildsLength > 1 ? selectedBuildsLength : ''} Build
                    {selectedBuildsLength && selectedBuildsLength > 1 ? 's' : ''}
                    {loading && (
                        <Box sx={{ pl: 1 }}>
                            <CircularProgress size={15} />
                        </Box>
                    )}
                </Button>
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Save to favourites</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please enter a name for your configuration</DialogContentText>
                    <TextField
                        autoFocus
                        onChange={handleDialogChange}
                        margin='dense'
                        label='name'
                        type='text'
                        fullWidth
                        helperText={
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography component='span' variant='caption' color='textSecondary'>
                                    {`${project.selected} - ${branch.selected}${version ? ` - ${version}` : ''}${configFileName ? ` - ${configFileName}` : ''}`}
                                </Typography>
                                {subscribed && (
                                    <Typography component='span' variant='caption' color='textSecondary'>
                                        You will be notified when the build is done
                                    </Typography>
                                )}
                            </span>
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color='secondary'>
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSave} disabled={!favouriteName} color='primary'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
