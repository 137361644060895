import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, Grid } from '@mui/material';
import { AppThunkDispatch } from '../../../store';
import { selectOrderTestDialog, setOrderTestDialogOpen } from '../../buildDetails/buildDetailsSlice';
import { IBuildsSliceState } from '../../builds/interfaces';
import { IBuild } from '../../commons/interfaces';
import { ITestDevice } from '../../testDevices/interfaces';
import { ISelectedTestCase, ITestBranch } from '../interfaces';
import { useOrderTestMutation } from '../testsApi';
import SelectTestBranch from './SelectTestBranch';
import SelectTestCases from './SelectTestCases';
import SelectTestDevice from './SelectTestDevice';
import SubscribeToTestResults from './SubscribeToTestResults';

export default function OrderTestDialog({ build }: { build: IBuild }): ReactElement {
    const { open } = useSelector(selectOrderTestDialog);
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const onClose = () => dispatch(setOrderTestDialogOpen({ open: false }));

    const [testBranch, setTestBranch] = useState<ITestBranch | null>(null);
    const [testDevice, setTestDevice] = useState<ITestDevice | null>(null);
    const [testCases, setTestCases] = useState<ISelectedTestCase[]>([]);
    const [subscribed, setSubscribed] = useState<boolean>(true);

    const [orderTest] = useOrderTestMutation();

    const isValid = (): boolean => {
        const hasSelectedTests = Boolean(testCases.find((test) => test.selected));
        const testOptionsAreValid = Boolean(
            !testCases.find((test) => test.selected && test.selectBuild && !test.customBuild),
        );
        return hasSelectedTests && testOptionsAreValid;
    };

    const handlerOk = () => {
        if (!testBranch) {
            return;
        }
        orderTest({
            build: build._id,
            testDevice: testDevice?._id,
            testBranch: testBranch.branchName,
            commitSha: testBranch.sha,
            testCases: testCases
                .filter((testCase) => testCase.selected)
                .map((testCase) => ({
                    id: testCase.id,
                    testName: testCase.testName,
                    displayName: testCase.displayName,
                    videoRecording: testCase.videoRecording,
                    dependsOn: testCase.dependsOn,
                    customBuild: testCase.customBuild,
                    settings: testCase.selectedSettings,
                })),
            subscribeToResult: subscribed,
        }).then(() => handleClose());
    };

    const handleClose = () => {
        setTestBranch(null);
        setTestDevice(null);
        setTestCases([]);
        setSubscribed(true);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg' data-testid='order-test-dialog'>
            <DialogTitle>Order Tests</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SelectTestBranch
                            projectName={build.projectName}
                            buildBranch={build.branch}
                            testBranch={testBranch}
                            setTestBranch={setTestBranch}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectTestDevice
                            platform={build.platform}
                            testDevice={testDevice}
                            setTestDevice={setTestDevice}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogContent dividers>
                <SelectTestCases
                    build={build}
                    branchName={testBranch?.branchName}
                    testCases={testCases}
                    setTestCases={setTestCases}
                />
            </DialogContent>
            <DialogActions>
                <SubscribeToTestResults subscribed={subscribed} setSubscribed={setSubscribed} />
                <Button autoFocus onClick={handleClose} size='small'>
                    Close
                </Button>
                <Button
                    autoFocus
                    variant='contained'
                    color='primary'
                    size='small'
                    disabled={!isValid()}
                    onClick={handlerOk}
                    data-testid='order-test-button'
                >
                    Order Tests
                </Button>
            </DialogActions>
        </Dialog>
    );
}
