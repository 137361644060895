import { ONE_GIGABYTE, ONE_TERA_BYTE } from '../../utils';
import { IDiskSpace } from '../buildAgents/interfaces';

export default function getDiskInfo(diskInfo?: IDiskSpace, isPercentage?: boolean): string {
    if (!diskInfo) {
        return 'Unknown';
    }

    const freeGB = diskInfo.free / ONE_GIGABYTE;
    const totalGB = diskInfo.total / ONE_GIGABYTE;
    const freeTB = diskInfo.free / ONE_TERA_BYTE;
    const totalTB = diskInfo.total / ONE_TERA_BYTE;

    if (isPercentage) {
        const percentage = (100 * freeGB) / totalGB;
        return `${percentage.toFixed(1)}% free`;
    }

    const freeValue = freeTB >= 1 ? freeTB : freeGB;
    const totalValue = totalTB >= 1 ? totalTB : totalGB;

    return `${freeValue.toFixed(2)}${freeTB > 1 ? 'TB' : 'GB'} / ${totalValue.toFixed(2)}${totalTB > 1 ? 'TB' : 'GB'}`;
}
