import { ReactElement } from 'react';
import DuplicateIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import { TactileTooltip } from '@tactileentertainment/core-designsystem';
import { ISelectedTestCase } from '../interfaces';

interface DuplicateTestCaseButtonProps {
    testCase: ISelectedTestCase;
    onDuplicate: (testCase: ISelectedTestCase) => void;
}

export default function DuplicateTestCaseButton({ testCase, onDuplicate }: DuplicateTestCaseButtonProps): ReactElement {
    return (
        <TactileTooltip title='Duplicate'>
            <Button
                onClick={(event) => {
                    event.stopPropagation();
                    onDuplicate(testCase);
                }}
                color='secondary'
                size='small'
                sx={{ mx: 0, px: 0 }}
            >
                <DuplicateIcon fontSize='small' />
            </Button>
        </TactileTooltip>
    );
}
