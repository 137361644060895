import { ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from '@tactileentertainment/core-shared.auth-react';
import ColumnFilter from '../../commons/filters/ColumnFilter';
import { FilterOption } from '../../commons/filters/interfaces';
import { useGetAvailableOptionsQuery } from '../testsApi';
import { selectAuthorFilter, setTestsFilter } from '../testsSlice';
import { ITestFilterProps } from './TestsFilters';

export default function AuthorSelector({ showOnlyValues }: ITestFilterProps): ReactElement {
    const { user } = useAuthUser();
    const dispatch = useDispatch();
    const selected = useSelector(selectAuthorFilter);
    const { data, isFetching: loading } = useGetAvailableOptionsQuery();

    const sortedOptions = useMemo(
        () =>
            data?.options?.author
                ?.slice()
                .sort((a, b) => {
                    if (user?.email && a.email === user.email) {
                        return -1;
                    }
                    return a.name.localeCompare(b.name);
                })
                .map((item) => ({ label: item.name || item.email, value: item._id }) as FilterOption) || [],
        [data?.options, user],
    );

    const onChange = (newValue: string[] | null) => {
        dispatch(setTestsFilter({ author: newValue || undefined }));
    };

    return (
        <ColumnFilter
            id='author-selector'
            label='Ordered By'
            selected={selected}
            options={sortedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}
