export enum EAuthResource {
    builds = 'builds',
    agents = 'agents',
    tests = 'tests',
    testSuites = 'test-suites',
    testDevices = 'test-devices',
    testAgents = 'test-agents',
    modules = 'modules',
    projects = 'projects',
    scheduledBuilds = 'scheduled-builds',
    unityAccelerators = 'unity-accelerators',
    releaseNotes = 'release-notes',
    appUploader = 'app-uploader',
    accessControl = 'access-control',
}
