import { ReactElement } from 'react';
import { TableCell, useMediaQuery } from '@mui/material';
import { MuiAlignSetting } from '../../typings/material-ui';
import PlatformIcon from '../commons/PlatformIcon';
import { EBuildPlatform } from './enums';

export default function BuildPlatform({
    align,
    platform,
    forcedMobile,
}: {
    align?: MuiAlignSetting;
    platform: EBuildPlatform;
    forcedMobile?: boolean;
}): ReactElement {
    const isMobile = useMediaQuery('(max-width: 1024px)');

    const useMobile = isMobile || forcedMobile;
    const platformIcon = PlatformIcon({ platform, useMobile });

    return (
        <TableCell
            title={platform}
            align={useMobile ? 'center' : align}
            sx={{
                padding: 1,
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
            data-testid='builds-table-build-platform'
        >
            {platformIcon || (
                <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {platform}
                </span>
            )}
        </TableCell>
    );
}
