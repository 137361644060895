import { ChangeEvent, ReactElement, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ITestBranch } from '../interfaces';
import { getBranchName } from '../testsApi';
import { useTestBranches } from './useTestBranches';

export interface ITestBranchOptionsProps {
    projectName: string;
    buildBranch: string;
    testBranch: ITestBranch | null;
    setTestBranch: (branch: ITestBranch | null) => void;
}

export default function SelectTestBranch({
    projectName,
    buildBranch,
    testBranch,
    setTestBranch,
}: ITestBranchOptionsProps): ReactElement {
    const { isFetching, project, testBranches } = useTestBranches(projectName);

    useEffect(() => {
        if (!project) {
            return;
        }
        const branchName = getBranchName(project, buildBranch);
        const defaultBranch =
            testBranches?.find((branch) => branch.branchName === branchName) ||
            testBranches?.find((branch) => branch.branchName === project.defaultBranch);
        if (defaultBranch) {
            setTestBranch(defaultBranch);
        }
    }, [testBranches, project, buildBranch, setTestBranch]);

    return (
        <Autocomplete
            size='small'
            options={testBranches || []}
            getOptionLabel={(option) => option.branchName}
            autoHighlight
            clearOnBlur
            clearOnEscape
            loading={isFetching}
            onChange={(e: ChangeEvent<any>, option: ITestBranch | null) => setTestBranch(option)}
            value={testBranch}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete='off'
                    label='Test branch'
                    placeholder='Select branch'
                    variant='outlined'
                />
            )}
        />
    );
}
