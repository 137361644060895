import { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { Paper, Container, Button, useMediaQuery } from '@mui/material';
import HeaderSection from '../commons/HeaderSection';
import ScheduledBuildDialog from './dialogs/ScheduledBuildDialog';
import { IScheduleBuild } from './interfaces';
import ScheduledBuildsTable from './ScheduledBuildsTable';

export default function ScheduledBuilds(): ReactElement {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBuild, setSelectedBuild] = useState<IScheduleBuild | undefined>(undefined);
    const isMobile = useMediaQuery('(max-width: 760px)');

    const handleClose = () => {
        setOpenDialog(false);
        setSelectedBuild(undefined);
    };

    const isOpen = openDialog || Boolean(selectedBuild);

    return (
        <>
            <Helmet title='Scheduled Builds' />
            <HeaderSection
                title='Scheduled Builds'
                isMobile={isMobile}
                actionBox={
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={() => setOpenDialog(true)}
                        style={{ width: isMobile ? '100%' : '' }}
                    >
                        New Schedule
                    </Button>
                }
            />
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Paper data-testid='scheduled-builds'>
                    <ScheduledBuildsTable onClick={(newSelectedBuild) => setSelectedBuild(newSelectedBuild)} />
                </Paper>
            </Container>
            {isOpen && (
                <ScheduledBuildDialog open={isOpen} selectedBuild={selectedBuild} onClose={() => handleClose()} />
            )}
        </>
    );
}
