import { ReactElement, useEffect, useState } from 'react';
import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SearchField } from '@tactileentertainment/core-designsystem';
import ProjectNameFilter from '../commons/filters/ProjectNameFilter';
import { GameModuleCategory } from './enums';
import { useGetGameModulesQuery, useUpdateManyModulesMutation } from './gameModulesApi';
import { IGameModulesFilterOptions, IModule } from './interfaces';
import ModuleAttributesFilter from './ModuleAttributesFilter';

interface GameModulesFiltersProps {
    filters: IGameModulesFilterOptions;
    onFilterChange: (filters: Partial<IGameModulesFilterOptions>) => void;
    projectList: string[];
    selectedItems: Array<Partial<IModule>>;
    onSelectedChange: (arg: Array<Partial<IModule>>) => void;
}

export default function GameModulesFilters({
    filters,
    onFilterChange,
    projectList,
    selectedItems,
    onSelectedChange,
}: GameModulesFiltersProps): ReactElement {
    return (
        <>
            <Tabs
                orientation='horizontal'
                variant='scrollable'
                value={filters.tab}
                onChange={(event, value) => {
                    onFilterChange({ tab: value as GameModuleCategory, showLoading: true });
                    onSelectedChange([]);
                }}
            >
                <Tab key={GameModuleCategory.MODULE} label='Modules' value={GameModuleCategory.MODULE} />
                <Tab key={GameModuleCategory.PACKAGE} label='Packages' value={GameModuleCategory.PACKAGE} />
                <Tab key={GameModuleCategory.UNITY_PACKAGE} label='Unity' value={GameModuleCategory.UNITY_PACKAGE} />
            </Tabs>
            <Divider />
            {selectedItems.length > 0 ? (
                <SelectionComponents
                    numberOfItems={selectedItems.length}
                    selectedItems={selectedItems}
                    onSelectedChange={onSelectedChange}
                />
            ) : (
                <FilterComponents
                    filters={filters}
                    onFilterChange={onFilterChange}
                    projectList={projectList}
                    selectedItems={selectedItems}
                />
            )}
        </>
    );
}

function FilterComponents({
    filters,
    onFilterChange,
    projectList,
}: Omit<GameModulesFiltersProps, 'refetchModules' | 'onSelectedChange'>): ReactElement {
    return (
        <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: { xs: 'flex-start', md: 'center' },
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <SearchField
                    size='small'
                    placeholder='Search'
                    value={filters.searchText || ''}
                    timeout={200}
                    onSearch={(text) => onFilterChange({ searchText: text as string, showLoading: true })}
                    sx={{
                        width: { xs: '100%', md: 320 },
                        mr: { xs: 0, md: 2 },
                    }}
                />
                <ProjectNameFilter
                    label='Games'
                    projectList={projectList}
                    selectedProjects={filters.selectedProjects}
                    onSelectedProjectsChange={(value) => onFilterChange({ selectedProjects: value })}
                />
                <ModuleAttributesFilter filters={filters} onFilterChange={onFilterChange} />
            </Box>
        </Box>
    );
}

function SelectionComponents({
    numberOfItems,
    selectedItems,
    onSelectedChange,
}: {
    numberOfItems: number;
    selectedItems: Array<Partial<IModule>>;
    onSelectedChange: (arg: Array<Partial<IModule>>) => void;
}): ReactElement {
    const [updateMany] = useUpdateManyModulesMutation();
    const [displayReset, setDisplayReset] = useState<boolean>(false);
    const { refetch: refetchModules } = useGetGameModulesQuery({ includeFields: ['projects'] });

    useEffect(() => {
        const keyOrDeprecatedExist = selectedItems.some((item) => item.primary || item.deprecated);
        setDisplayReset(keyOrDeprecatedExist);
    }, [selectedItems]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: 2,
                ml: 1,
                width: '100%',
            }}
        >
            <Box sx={{ justifyContent: 'flex-start', alignContent: 'center' }}>
                <Typography sx={{ color: grey[600] }}>{numberOfItems} selected</Typography>
            </Box>
            <Box sx={{ justifyContent: 'flex-end' }}>
                {displayReset && (
                    <Button
                        color='secondary'
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                        onClick={async () => {
                            const updateData = selectedItems.map(({ moduleName }) => {
                                return { moduleName, data: { primary: false, deprecated: false } };
                            });
                            updateMany(updateData);
                            refetchModules();
                        }}
                    >
                        Reset
                    </Button>
                )}
                <Button
                    variant='outlined'
                    color='secondary'
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    onClick={async () => {
                        const updateData = selectedItems.map(({ moduleName, deprecated }) => {
                            return { moduleName, data: { deprecated: !deprecated } };
                        });
                        updateMany(updateData);
                        refetchModules();
                        onSelectedChange([]);
                    }}
                >
                    Mark as Deprecated
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    onClick={async () => {
                        const updateData = selectedItems.map(({ moduleName, primary }) => {
                            return { moduleName, data: { primary: !primary } };
                        });
                        updateMany(updateData);
                        refetchModules();
                        onSelectedChange([]);
                    }}
                >
                    Mark as Primary
                </Button>
            </Box>
        </Box>
    );
}
