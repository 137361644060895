import { BuildAgent } from '../buildAgents/interfaces';
import { IBuildAgent } from './interfaces';

export default function getBuildAgentName(buildAgent?: IBuildAgent | BuildAgent): string {
    if (!buildAgent || !buildAgent.hostname || !buildAgent.port) {
        return '';
    }

    const buildServer = buildAgent.hostname.replace(/[^0-9]/g, '');
    const agentNumber = `A${buildAgent?.port?.toString().slice(-2)}`;

    return `B${buildServer}-${agentNumber}`;
}
