import { ReactElement } from 'react';
import { Autocomplete, SxProps, TextField } from '@mui/material';
import { errorToast } from '@tactileentertainment/core-designsystem';

interface JiraTaskInputProps {
    value: string[];
    onChange: (newJiraTasks: string[]) => void;
    disabled?: boolean;
    sx?: SxProps;
    'data-testid'?: string;
}

export default function JiraTaskInput({ value, onChange, disabled, sx, ...props }: JiraTaskInputProps): ReactElement {
    const handleJIRATicketChange = (newValue: string[]) => {
        if (!newValue.length) {
            onChange([]);
            return;
        }

        const jiraTicketRegex = /[A-Za-z,0-9]{2,}-\d+/;
        const latestTicket = newValue.slice().pop();

        if (jiraTicketRegex.test(latestTicket || '')) {
            onChange([...new Set([...newValue.map((ticket) => ticket.toUpperCase())])]);
        } else {
            errorToast(`Invalid JIRA ticket format (${latestTicket}). It should be in the format ABC-123`);
        }
    };

    return (
        <Autocomplete
            {...props}
            sx={sx}
            options={[] as string[]}
            freeSolo
            autoHighlight
            clearOnBlur
            clearOnEscape
            multiple={true}
            value={value}
            onChange={(_, newValues) => handleJIRATicketChange(newValues)}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{ margin: 0, width: '100%' }}
                    onBlur={(e) => {
                        if (e.target.value) {
                            handleJIRATicketChange([...new Set([...value, e.target.value])]);
                        }
                    }}
                    autoFocus
                    error={false}
                    helperText='Press enter to add each JIRA ticket'
                    label='JIRA tasks'
                    placeholder='ABC-123'
                    variant='outlined'
                />
            )}
        />
    );
}
