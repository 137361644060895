import { ReactElement } from 'react';
import { TextField, useMediaQuery } from '@mui/material';
import { svnPathValidator } from '../../../utils';

interface SVNPathInputProps {
    svnPath?: string;
    onChange: (svnPath?: string) => void;
}

export default function SVNPathInput({ svnPath = '', onChange }: SVNPathInputProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const hasError = Boolean(svnPath && !svnPathValidator(svnPath));
    return (
        <TextField
            id='svg-path-input'
            required
            label='SVN Path'
            variant='outlined'
            value={svnPath}
            onChange={(e) => onChange(e.target.value || '')}
            error={hasError}
            helperText={hasError && 'Example: svn@svn-master.tactile.build/ProjectName'}
            style={{ minWidth: isMobile ? '0px' : '350px' }}
        />
    );
}
