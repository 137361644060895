import { ReactElement } from 'react';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { TactileTheme } from '@tactileentertainment/core-designsystem';

export interface IDataValueBoxProps {
    label?: string | React.ReactNode;
    value?: React.ReactNode;
    tooltip?: string;
    sx?: SxProps;
    labelSx?: SxProps<TactileTheme>;
    valueSx?: SxProps<TactileTheme>;
    'data-testid'?: string;
}

export function DataValueBox({
    label,
    value,
    tooltip,
    sx = {},
    labelSx = {},
    valueSx = {},
    ...props
}: IDataValueBoxProps): ReactElement | null {
    if (value === undefined) {
        return null;
    }

    const title = label && (
        <Typography
            component={typeof label === 'string' ? 'p' : 'div'}
            sx={{
                minWidth: 70,
                color: (theme) => theme.palette.tactile.blueGray[500],
                ...labelSx,
            }}
        >
            {label}
        </Typography>
    );

    return (
        <Box
            data-testid={props['data-testid']}
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                ...sx,
            }}
            gap={2}
        >
            {tooltip && title ? (
                <Tooltip title={tooltip} placement='right-end'>
                    {title}
                </Tooltip>
            ) : (
                title
            )}
            {typeof value === 'object' ? (
                value
            ) : (
                <Typography
                    sx={{
                        textAlign: 'right',
                        wordBreak: 'break-all',
                        ...valueSx,
                    }}
                >
                    {typeof value === 'string' ? value.replaceAll(/,(\s+)?/g, ', ') : value}
                </Typography>
            )}
        </Box>
    );
}
