import { IColumn } from '../commons/interfaces';

export enum BuildTestResultsMatrixColumnId {
    test = 'test',
    results = 'results',
}

export const buildTestResultsMatrixColumns: Array<IColumn<BuildTestResultsMatrixColumnId>> = [
    {
        id: BuildTestResultsMatrixColumnId.test,
        label: 'Test',
        align: 'left',
        width: '10%',
    },
    {
        id: BuildTestResultsMatrixColumnId.results,
        label: 'results',
        align: 'left',
        width: '90%',
    },
];
