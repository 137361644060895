import { ReactElement } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Box, Collapse, TableHead, TableBody, TableRow, TableCell, CircularProgress, Table } from '@mui/material';
import { Typography } from '@tactileentertainment/core-designsystem';
import BuildProjectName from '../../builds/BuildProjectName';
import { useGetBuildsQuery } from '../../builds/buildsApi';
import BuildStatusChip from '../../commons/BuildStatusChip';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { selectBuildAgentColumns } from '../buildAgentsSlice';
import { BuildAgent } from '../interfaces';

interface BuildAgentLastBuildsInfoTableProps {
    open: boolean;
    buildAgent: BuildAgent;
}

export default function BuildAgentLastBuildsInfoTable({
    open,
    buildAgent,
}: BuildAgentLastBuildsInfoTableProps): ReactElement {
    const buildAgentColumns = useSelector(selectBuildAgentColumns).filter((column) => column.visible);
    const { data: builds, isLoading } = useGetBuildsQuery({ buildAgent: buildAgent._id, limit: 5 }, { skip: !open });
    const colSpan = buildAgentColumns.length + 1;

    return (
        <TableRow sx={{ background: (theme) => theme.palette.surface.main }}>
            <TableCell sx={{ p: 0 }} colSpan={colSpan}>
                <Collapse in={open} timeout='auto' unmountOnExit>
                    {!builds && isLoading && (
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress style={{ margin: '16px' }} size={20} />
                        </Box>
                    )}
                    {builds && (
                        <Box sx={{ my: 2 }}>
                            <Typography
                                variant='h-5'
                                sx={{ ml: '55px' }}
                                color='secondary'
                                gutterBottom
                                fontWeight={600}
                            >
                                Running Builds
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableHeaderRow
                                        sx={{
                                            '& .MuiTableCell-head.MuiTableCell-root': {
                                                borderColor: (theme) => theme.palette.border.light,
                                            },
                                        }}
                                    >
                                        <TableCell
                                            key='offset-column'
                                            align='left'
                                            size='small'
                                            style={{ minWidth: '48px' }}
                                        />
                                        <TableCell sx={{ width: '15%' }}>Status</TableCell>
                                        <TableCell sx={{ width: '25%' }}>Project / Build Id</TableCell>
                                        <TableCell sx={{ width: '20%' }}>Branch</TableCell>
                                        <TableCell sx={{ width: '20%' }}>Kind</TableCell>
                                        <TableCell sx={{ width: '20%' }}>Started At</TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        '& .MuiTableCell-root': { borderColor: (theme) => theme.palette.border.light },
                                    }}
                                >
                                    {builds?.map((build) => (
                                        <TableRow
                                            key={build._id}
                                            sx={{ background: (theme) => theme.palette.surface.main }}
                                        >
                                            <TableCell></TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                <BuildStatusChip status={build.status} />
                                            </TableCell>
                                            <BuildProjectName
                                                projectName={build.projectName}
                                                buildNumber={build.buildNumber}
                                            />
                                            <TableCell>{build.branch}</TableCell>
                                            <TableCell>{build.kind}</TableCell>
                                            <TableCell>
                                                {build.started && (
                                                    <Moment fromNow interval={1000}>
                                                        {build.started}
                                                    </Moment>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
