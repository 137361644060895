import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Box, Paper, Table, TableCell, TableHead } from '@mui/material';
import ContentLoading from '../commons/ContentLoading';
import HeaderSection from '../commons/HeaderSection';
import NoResultsFound from '../commons/NoResultsFound';
import Page from '../commons/Page';
import TableBody from '../commons/TableBody';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import TablePagination from '../commons/TablePagination';
import { openSocket } from '../sockets';
import TestLoadingRows from '../tests/row/TestLoadingRows';
import TestSuitesFilters, { TestSuiteColumnFilters } from './filters/TestSuitesFilters';
import { ITestSuiteFilter } from './interfaces';
import TestSuiteRow from './row/TestSuiteRow';
import { useGetTestSuitesQuery } from './testSuitesApi';
import { testSuitesListColumns } from './testSuitesColumns';
import { getTestSuitesFiltersFromUrl, setTestSuitesFiltersToUrl } from './utils';

export default function TestSuitesList(): ReactElement {
    const [filters, setFilters] = useState<ITestSuiteFilter>(getTestSuitesFiltersFromUrl());

    const onFiltersChange = (newFilters: ITestSuiteFilter) => {
        setTestSuitesFiltersToUrl(newFilters);
        setFilters(newFilters);
    };

    const { data, isFetching, refetch } = useGetTestSuitesQuery(filters, { skip: !filters });
    const { testSuites, totalCount } = data || { testSuites: [], totalCount: 0 };
    const isLoading = isFetching;

    useEffect(() => {
        const socket = openSocket('tests');
        socket.on('new-test-suite', refetch);

        return () => {
            socket.off('new-test-suite', refetch);
        };
    }, [refetch]);

    return (
        <Suspense fallback={<ContentLoading />}>
            <Page>
                <HeaderSection title='Test Suites' />
                <Paper
                    data-testid='tests'
                    sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
                >
                    <TestSuitesFilters filters={filters} onFiltersChange={onFiltersChange} showOnlyValues />
                    <TableContainer sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Table
                            stickyHeader
                            aria-label='test suites table'
                            sx={{ width: '100%', tableLayout: 'fixed', minWidth: 1600 }}
                        >
                            <TableHead data-testsuitesid='testsuites-table-head'>
                                <TableHeaderRow>
                                    {testSuitesListColumns.map((column, i) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            size='small'
                                            sx={{
                                                width: column.width,
                                                minWidth: column.minWidth,
                                                display: column.visible ? undefined : 'none',
                                                px: { xs: i ? 1 : 2, sm: i ? 1 : 4 },
                                            }}
                                        >
                                            <Box>
                                                {column.label}
                                                {TestSuiteColumnFilters[column.id]?.({
                                                    filters,
                                                    onFiltersChange,
                                                    showOnlyValues: false,
                                                })}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableHeaderRow>
                            </TableHead>
                            {(isLoading || testSuites.length > 0) && (
                                <TableBody data-testsuitesid='testsuites-table-body'>
                                    {isLoading ? (
                                        <TestLoadingRows rows={10} />
                                    ) : (
                                        testSuites.map((testSuite: any) => (
                                            <TestSuiteRow
                                                key={testSuite._id}
                                                testSuite={testSuite}
                                                columns={testSuitesListColumns.filter((column) => column.visible)}
                                            />
                                        ))
                                    )}
                                </TableBody>
                            )}
                        </Table>
                        {!isLoading && !testSuites.length && (
                            <NoResultsFound text="We couldn't find any test suites with the selected filters" />
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={totalCount}
                        rowsPerPage={50}
                        page={Number(filters?.page || 0)}
                        onPageChange={(_event: unknown, newPage: number) =>
                            onFiltersChange({
                                ...filters,
                                page: newPage,
                            })
                        }
                        data-testsuitesid='testsuites-table-pagination'
                    />
                </Paper>
            </Page>
        </Suspense>
    );
}
