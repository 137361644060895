import { ReactElement } from 'react';
import { Tab, Table, TableCell, TableContainer, TableHead, Tabs, useTheme } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import TableBody from '../../commons/TableBody';
import TableHeaderRow from '../../commons/TableHeaderRow';
import GameModuleProjectRow from '../details/GameModuleProjectRow';
import { GameModuleCategory } from '../enums';
import { useGetGameModulesQuery } from '../gameModulesApi';
import { IModuleProject } from '../interfaces';

interface BuildGameModulesTableProps {
    projectModules: IModuleProject[] | undefined;
    category: GameModuleCategory;
    onCategoryChange: (category: GameModuleCategory) => void;
}

export default function BuildGameModulesTable({
    projectModules = [],
    category,
    onCategoryChange,
}: BuildGameModulesTableProps): ReactElement {
    const theme = useTheme();
    const modules = projectModules.slice().sort((a, b) => a.moduleName.localeCompare(b.moduleName));
    const { data: allModules } = useGetGameModulesQuery({ includeFields: [] });

    return (
        <>
            <Tabs
                orientation='horizontal'
                variant='scrollable'
                value={category}
                sx={{
                    pb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '.MuiTab-root': {
                        px: 2,
                        borderRadius: '6px',
                        padding: '8px 12px',
                        fontWeight: 600,
                        m: 0,
                        mr: 1,
                        minHeight: 36,
                        '&.Mui-selected': {
                            background: blueGray[400],
                            color: blueGray[800],
                        },
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                }}
                onChange={(event, value) => onCategoryChange(value as GameModuleCategory)}
            >
                <Tab key={GameModuleCategory.MODULE} label='Modules' value={GameModuleCategory.MODULE} />
                <Tab key={GameModuleCategory.PACKAGE} label='Packages' value={GameModuleCategory.PACKAGE} />
                <Tab key={GameModuleCategory.UNITY_PACKAGE} label='Unity' value={GameModuleCategory.UNITY_PACKAGE} />
            </Tabs>
            <TableContainer
                sx={{
                    '.MuiTableCell-root': { fontWeight: 500 },
                    background: (theme) => theme.palette.common.white,
                    [theme.breakpoints.up('sm')]: {
                        height: 'calc(100vh - 410px)',
                    },
                }}
            >
                <Table
                    stickyHeader
                    aria-label='build game modules table'
                    style={{ width: '100%', minWidth: 1000, tableLayout: 'fixed' }}
                >
                    <TableHead data-testid='build-game-modules-table-head'>
                        <TableHeaderRow>
                            <TableCell key='name' sx={{ width: 300 }}>
                                {category === GameModuleCategory.MODULE && 'Module Name'}
                                {category === GameModuleCategory.PACKAGE && 'Package Name'}
                                {category === GameModuleCategory.UNITY_PACKAGE && 'Unity Package Name'}
                            </TableCell>
                            <TableCell key='branch' sx={{ width: 100 }}>
                                Branch
                            </TableCell>
                            <TableCell key='latest-version' sx={{ width: 100 }}>
                                Latest Version
                            </TableCell>
                            <TableCell key='current-version' sx={{ width: 100 }}>
                                Current Version
                            </TableCell>
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody data-testid='build-game-modules-table-body'>
                        {allModules &&
                            modules?.map((project) => {
                                const updated = structuredClone(project);
                                const current = allModules.modules[category].find(
                                    (mod) => mod.moduleName === project.moduleName,
                                );
                                updated.primary = current?.primary;
                                updated.deprecated = current?.deprecated;

                                return (
                                    <GameModuleProjectRow
                                        key={project.moduleName}
                                        project={updated}
                                        name={project.moduleName}
                                        redirectUrl={`/modules/${encodeURIComponent(project.moduleName)}`}
                                        homepage={project.homepage}
                                    />
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
