import React, { ReactElement } from 'react';
import { Grid, GridSize, Typography, useTheme, Box, SxProps } from '@mui/material';
import { MuiAlignSetting } from '../../typings/material-ui';

export interface IGridItemProps {
    label: string;
    align?: MuiAlignSetting;
    xs?: GridSize;
    children: string | React.ReactNode;
    color?: 'primary' | 'secondary';
    title?: string;
    sx?: SxProps;
}

export default function GridItem({ label, align, children, xs, color, title, sx }: IGridItemProps): ReactElement {
    const theme = useTheme();
    const style = {
        alignContent: align,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text[color || 'primary'],
        fontSize: '14px',
        fontWeight: 700,
    };

    return (
        <Grid item xs={xs} title={title} sx={sx}>
            <Typography variant='caption' sx={{ color: theme.palette.text.disabled }}>
                {label}
            </Typography>
            <Box style={style}>{children}</Box>
        </Grid>
    );
}
