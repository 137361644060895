import React, { ReactElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDropDown, ArrowDropUp, Check } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Typography } from '@mui/material';
import { successToast } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { EBuildStatus } from '../commons/enums';
import { IBuild } from '../commons/interfaces';
import ConfigurationSettings from '../configurationSettings/ConfigurationSettings';
import { getDefaultSettings } from '../configurationSettings/getDefaultSettings';
import { IConfigurationSettings, ISelectedSettings, ISetting } from '../configurationSettings/interfaces';
import updateSelectedSettings from '../configurationSettings/updateSelectedSettings';
import { ActionTrigger, IConfiguration, IConfigurationAction } from '../newBuild/interfaces';
import { executeAction, selectBuildDetailsResult, selectBuildStatus } from './buildDetailsSlice';
import { IBuildDetailsSlice, IBuildResult } from './interfaces';

export default function BuildActions({
    build,
    buildConfiguration,
}: {
    build: IBuild;
    buildConfiguration?: IConfiguration;
}): ReactElement | null {
    const buildStatus = useSelector(selectBuildStatus);
    const buildResult = useSelector(selectBuildDetailsResult);

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const openMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const availableActions: IConfigurationAction[] | undefined = useMemo(() => {
        const buildState = { ...build, status: buildStatus as EBuildStatus };
        return buildConfiguration?.actions?.filter((action) => matchAction(buildState, buildResult, action));
    }, [buildConfiguration, build, buildResult, buildStatus]);

    if (!availableActions?.length) {
        return null;
    }

    return (
        <>
            <Button
                variant='outlined'
                color='secondary'
                endIcon={anchorEl ? <ArrowDropUp fontSize='small' /> : <ArrowDropDown fontSize='small' />}
                onClick={openMenu}
                sx={{ mr: 1.5, py: 1.25, pl: 2, my: 1 }}
            >
                Actions
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                disableScrollLock
            >
                {availableActions.map((action) => (
                    <BuildAction key={action.name} action={action} build={build} onClick={closeMenu} />
                ))}
            </Menu>
        </>
    );
}

function BuildAction({
    action,
    build,
    onClick,
}: {
    action: IConfigurationAction;
    build: IBuild;
    onClick: () => void;
}): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildDetailsSlice>>();
    const [openSettingsDialog, setOpenSettingsDialog] = useState<boolean>(false);
    const [selectedSettings, setSelectedSettings] = useState<ISelectedSettings>();
    const [error, setError] = useState<string | undefined>();

    const processAction = () => {
        if (!action.settings || !Object.keys(action.settings).length) {
            execute();
        } else {
            setError(undefined);
            setSelectedSettings(getDefaultSettings(action.settings, { build }));
            setOpenSettingsDialog(true);
        }
        onClick();
    };

    const execute = () => {
        dispatch(executeAction({ buildId: build._id, action, settings: selectedSettings })).then(({ payload }) => {
            if (payload.error?.message) {
                setError(payload.error?.message);
            } else {
                setOpenSettingsDialog(false);
                successToast(action.display_name);
            }
        });
    };

    const cancel = () => {
        setOpenSettingsDialog(false);
        onClick();
    };

    const setSetting = (setting: ISetting, value: string | string[] | boolean | null) => {
        setSelectedSettings(updateSelectedSettings(selectedSettings || {}, setting, value));
    };

    const isValid = validateSettings(action.settings, selectedSettings);

    return (
        <>
            <MenuItem onClick={processAction}>
                {action.trigger === ActionTrigger.automatic && build.automaticActions?.includes(action.name) ? (
                    <div style={{ lineHeight: 1.2 }}>
                        <Typography>{action.display_name}</Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <Check fontSize='inherit' /> Automatically Executed
                        </Typography>
                    </div>
                ) : (
                    action.display_name
                )}
            </MenuItem>
            {openSettingsDialog && (
                <Dialog open={openSettingsDialog} maxWidth='md' fullWidth>
                    <DialogTitle>{action.display_name}</DialogTitle>
                    <DialogContent>
                        <ConfigurationSettings
                            settings={action.settings}
                            selectedSettings={selectedSettings}
                            onSettingChange={setSetting}
                        />
                    </DialogContent>
                    {error && (
                        <DialogContent>
                            <Typography color='error'>{error}</Typography>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button autoFocus onClick={cancel}>
                            Cancel
                        </Button>
                        <Button color='primary' variant='contained' disabled={!isValid} onClick={execute}>
                            Execute
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

function matchAction(build: IBuild, buildResult: IBuildResult | undefined, action: IConfigurationAction): boolean {
    const availableStates = action.available_states || [EBuildStatus.finished];
    if (!availableStates.includes(build.status)) {
        return false;
    }
    return matchPrerequisites(build, buildResult, action);
}

function matchPrerequisites(
    build: IBuild,
    buildResult: IBuildResult | undefined,
    action: IConfigurationAction,
): boolean {
    if (!action.prerequisites) {
        return true;
    }
    for (const [key, value] of Object.entries(action.prerequisites)) {
        if (build[key as keyof IBuild] !== value && buildResult?.[key as keyof IBuildResult] !== value) {
            return false;
        }
    }
    return true;
}

function validateSettings(settings?: IConfigurationSettings, selectedSettings?: ISelectedSettings): boolean {
    if (!settings) {
        return true;
    }
    for (const [settingKey, setting] of Object.entries(settings)) {
        if (setting.required && selectedSettings?.[settingKey] === undefined) {
            return false;
        }
    }
    return true;
}
