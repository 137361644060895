import { ReactElement } from 'react';
import { Box, Chip, Grid, TableCell, Tooltip, useTheme } from '@mui/material';
import { TactileTheme } from '@tactileentertainment/core-designsystem';
import { MuiAlignSetting } from '../../../typings/material-ui';
import TestDeviceLink from '../../testDevices/TestDeviceLink';
import { ITestSuiteBase, ITestSuiteDevice } from '../interfaces';

export default function TestSuiteDevicesCell({
    testSuite,
    maxDevices = 1,
    align,
}: {
    testSuite: ITestSuiteBase;
    maxDevices?: number;
    align?: MuiAlignSetting;
}): ReactElement {
    const theme = useTheme<TactileTheme>();
    const shownDevices = testSuite.devices.slice(0, maxDevices);
    const hiddenDevices = testSuite.devices.slice(maxDevices);

    const getDeviceLink = (device: ITestSuiteDevice) => (
        <TestDeviceLink key={device.uuid} uuid={device.uuid} title={device.name} />
    );

    return (
        <TableCell align={align} id='table-cell-test-device' data-testsuiteid='tests-table-test-device'>
            <Grid container spacing={0.5} alignItems={'baseline'} gap={1}>
                {shownDevices.map((device) => (
                    <Grid item key={device.uuid} sx={{ maxWidth: '100%', py: 0.5 }}>
                        {getDeviceLink(device)}
                    </Grid>
                ))}
                {hiddenDevices.length ? (
                    <Grid item>
                        <Tooltip
                            title={
                                <Box
                                    sx={{
                                        p: 2,
                                        m: -1,
                                        display: 'flex',
                                        flexFlow: 'column',
                                        maxWidth: '250px',
                                        backgroundColor: 'background.default',
                                        border: `1px solid ${theme.palette.secondary.light}`,
                                    }}
                                >
                                    {hiddenDevices.map((device) => (
                                        <Grid item key={device.uuid} sx={{ maxWidth: '100%' }}>
                                            {getDeviceLink(device)}
                                        </Grid>
                                    ))}
                                </Box>
                            }
                        >
                            <Box>
                                <Chip label={`+${hiddenDevices.length}`} />
                            </Box>
                        </Tooltip>
                    </Grid>
                ) : null}
            </Grid>
        </TableCell>
    );
}
