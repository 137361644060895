import React, { ReactElement, useMemo } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, FormControlLabel, Checkbox } from '@mui/material';
import { Typography } from '@tactileentertainment/core-designsystem';
import { useGetBuildAgentsQuery } from '../../buildAgents/buildAgentsApi';
import { BuildAgent } from '../../buildAgents/interfaces';
import getBuildAgentName from '../../commons/getBuildAgentName';
import TableBody from '../../commons/TableBody';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import AgentsSelectorBuildAgentCell from './AgentsSelectorBuildAgentCell';

interface AgentsSelectorTableProps {
    selectedAgents: string[];
    onChange: (selectedValues: string[]) => void;
    style: React.CSSProperties;
}

export default function AgentsSelectorTable({
    selectedAgents,
    onChange,
    style,
}: AgentsSelectorTableProps): ReactElement {
    const { data: agents = [] } = useGetBuildAgentsQuery();

    const hostList = useMemo(() => [...new Set(agents.map((item) => item.hostname))], [agents]);
    const portList = useMemo(() => [...new Set(agents.map((item) => item.port))].sort(), [agents]);

    const getAgentByHostnameAndPort = (hostname: string, port: string): BuildAgent | undefined => {
        return agents.find((item) => item.hostname === hostname && item.port.toString() === port);
    };

    const getAgentByHostname = (hostname: string): BuildAgent | undefined => {
        return agents.find((item) => item.hostname === hostname);
    };

    const handleClick = (id: string) => {
        if (selectedAgents.includes(id)) {
            const newList = selectedAgents.filter((item) => item !== id);
            return onChange(newList);
        }

        const newList = [...selectedAgents, id];
        onChange(newList);
    };

    return (
        <>
            <Box sx={{ mt: 4, mx: 3, mb: 0.5 }}>
                <Typography variant='subtitle1' fontWeight={700}>
                    Select Agents
                </Typography>
                <Typography variant='body-2' color='light'>
                    {`${selectedAgents?.length || 0} agents selected`}
                </Typography>
            </Box>
            <TableContainer style={style}>
                <Table stickyHeader aria-label='simple table'>
                    <TableHead>
                        <TableHeaderRow>
                            <TableCell sx={{ fontWeight: 500, px: 3, width: '50%' }}>Servers</TableCell>
                            {portList.map((port) => (
                                <TableCell key={port} align='center' style={{ fontWeight: 500 }}>
                                    {`Agent${port.toString().slice(-2)}`}
                                </TableCell>
                            ))}
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                        {hostList.map((hostname) => {
                            const buildAgent = getAgentByHostname(hostname);
                            return (
                                <TableRow key={hostname}>
                                    <AgentsSelectorBuildAgentCell key={hostname} buildAgent={buildAgent} />
                                    {portList.map((port) => {
                                        const agent = getAgentByHostnameAndPort(hostname, port.toString());
                                        return (
                                            <TableCell key={port} align='center'>
                                                {agent && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedAgents.includes(agent._id)}
                                                                onChange={() => handleClick(agent._id)}
                                                                color='primary'
                                                            />
                                                        }
                                                        label={
                                                            <Typography variant='body2' color='textPrimary'>
                                                                {getBuildAgentName(agent)}
                                                            </Typography>
                                                        }
                                                    />
                                                )}
                                                {!agent && (
                                                    <Typography color='textPrimary' variant='body2'>
                                                        Not Available
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
