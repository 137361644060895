import { ReactElement } from 'react';
import {
    PhonelinkOff as OfflineIcon,
    PowerSettingsNew as IdleIcon,
    CheckCircle as DoneIcon,
} from '@mui/icons-material';
import { CircularProgress, Chip } from '@mui/material';
import { ETestDeviceState } from './interfaces';

const icons: { [state in ETestDeviceState]: ReactElement } = {
    [ETestDeviceState.idle]: <IdleIcon fontSize='small' />,
    [ETestDeviceState.running]: <CircularProgress size={20} color='inherit' />,
    [ETestDeviceState.done]: <DoneIcon fontSize='small' />,
    [ETestDeviceState.offline]: <OfflineIcon fontSize='small' />,
};

export default function TestDeviceState({ state }: { state: ETestDeviceState }): ReactElement {
    return (
        <Chip
            sx={{
                borderRadius: 5,
                fontWeight: 600,
                color: (theme) => theme.palette.common.white,
                maxHeight: 28,
                textTransform: 'capitalize',
                '& .MuiChip-icon': {
                    fontSize: 18,
                    color: (theme) => theme.palette.common.white,
                    path: {
                        color: (theme) => theme.palette.common.white,
                    },
                },
                '& .MuiChip-label': {
                    color: (theme) => theme.palette.common.white,
                },
                ...(state === ETestDeviceState.idle && {
                    backgroundColor: (theme) => theme.palette.tactile.blueGray[700],
                }),
                ...(state === ETestDeviceState.running && {
                    backgroundColor: (theme) => theme.palette.tactile.blue[700],
                }),
                ...(state === ETestDeviceState.done && { backgroundColor: (theme) => theme.palette.success.main }),
                ...(state === ETestDeviceState.offline && {
                    backgroundColor: (theme) => theme.palette.tactile.blueGray[500],
                }),
            }}
            color='primary'
            icon={icons[state]}
            label={state}
        />
    );
}
