import { ReactElement, useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import BuildAgentChip from '../../buildAgents/table/BuildAgentChip';
import ExpandIcon from '../../commons/ExpandIcon';
import { IToggleColumn } from '../../commons/interfaces';
import TableTextCell from '../../commons/TableTextCell';
import TestDeviceRow from '../../testDevices/list/TestDeviceRow';
import { TestDevicesColumnId } from '../../testDevices/list/testDevicesColumns';
import { ITestAgent } from '../interfaces';
import TestAgentDisabledIcon from '../TestAgentDisabledIcon';
import TestAgentActionsCell from './TestAgentActionsCell';
import { TestAgentsColumnId } from './testAgentsColumns';
import TestAgentStateCell from './TestAgentStateCell';
import TestAgentVersionCell from './TestAgentVersionCell';

export default function TestAgentRow({
    testAgent,
    columns,
    deviceColumns,
}: {
    testAgent: ITestAgent;
    columns: Array<IToggleColumn<TestAgentsColumnId>>;
    deviceColumns: Array<IToggleColumn<TestDevicesColumnId>>;
}): ReactElement {
    const [showDevices, setShowDevices] = useState<boolean>(false);

    return (
        <>
            <TableRow
                sx={{
                    ...((testAgent.offline || testAgent.manuallyDisabled) && {
                        backgroundColor: (theme) => theme.palette.tactile.gray[300],
                        '& *': {
                            color: (theme) => theme.palette.tactile.gray[500],
                        },
                    }),
                }}
                key={testAgent._id}
                data-testid='test-agent-row'
            >
                {columns.map((column) => {
                    const key = column.id;
                    switch (column.id) {
                        case TestAgentsColumnId.expandDevices:
                            return (
                                <TableCell key={key}>
                                    {testAgent.devices?.length > 0 && (
                                        <IconButton
                                            size='small'
                                            onMouseUp={() => setShowDevices(!showDevices)}
                                            sx={{ mx: 1 }}
                                        >
                                            <ExpandIcon expanded={showDevices} mode='tree' />
                                        </IconButton>
                                    )}
                                </TableCell>
                            );
                        case TestAgentsColumnId.state:
                            return <TestAgentStateCell key={key} testAgent={testAgent} align={column.align} />;

                        case TestAgentsColumnId.name:
                            return (
                                <TableCell key={key} align={column.align}>
                                    <span>{testAgent.hostname}</span>
                                    {testAgent.manuallyDisabled && (
                                        <TestAgentDisabledIcon
                                            disabledReason={testAgent.disabledReason}
                                            disabledDate={testAgent.disabledDate}
                                            updatedBy={testAgent.updatedBy}
                                        />
                                    )}
                                </TableCell>
                            );

                        case TestAgentsColumnId.version:
                            return <TestAgentVersionCell key={key} align={column.align} version={testAgent.version} />;

                        case TestAgentsColumnId.xcode:
                        case TestAgentsColumnId.webDriverAgent:
                        case TestAgentsColumnId.python:
                            return <BuildAgentChip key={column.id} values={testAgent.executables[column.id]} />;

                        case TestAgentsColumnId.os:
                            return <BuildAgentChip key={column.id} values={testAgent.os.version} />;

                        case TestAgentsColumnId.actions:
                            return <TestAgentActionsCell key={column.id} testAgent={testAgent} align={column.align} />;

                        default:
                            return <TableTextCell key={key} align={column.align} text={testAgent[column.id]} />;
                    }
                })}
            </TableRow>
            {showDevices &&
                testAgent.devices.map((device) => (
                    <TestDeviceRow key={device._id} testDevice={device} columns={deviceColumns} nestedRow />
                ))}
        </>
    );
}
