import { ChangeEvent, ReactElement, useMemo } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ITestDevice } from '../../testDevices/interfaces';
import TestDeviceOption from '../../testDevices/TestDeviceOption';
import { useGetActiveTestDevicesQuery } from '../../testDevices/testDevicesApi';
import { useTestSuitesQueue } from '../useTestSuitesQueue';

export default function SelectTestDevices({
    platform,
    devices,
    onDevicesChange,
}: {
    platform: string;
    devices: ITestDevice[] | null;
    onDevicesChange: (device: ITestDevice[]) => void;
}): ReactElement {
    const { data: testDevicesData, isFetching } = useGetActiveTestDevicesQuery();
    const testsQueue = useTestSuitesQueue();

    const sortedDevices = useMemo(() => {
        return testDevicesData?.slice().sort((a: ITestDevice, b: ITestDevice) => {
            const testAgentA = a.testAgent;
            const testAgentB = b.testAgent;
            if (!testAgentA || !testAgentB) {
                return testAgentA ? -1 : 1;
            } else {
                return testAgentA.hostname > testAgentB.hostname ? 1 : -1;
            }
        });
    }, [testDevicesData]);

    return (
        <Autocomplete
            style={{ width: '90%' }}
            size='small'
            options={sortedDevices?.filter((device: { platform: string }) => device.platform === platform) || []}
            autoHighlight
            clearOnBlur
            clearOnEscape
            multiple
            loading={isFetching}
            groupBy={(option) => option.testAgent?.hostname || 'No Agent'}
            onChange={(e: ChangeEvent<any>, option: ITestDevice[]) => onDevicesChange(option)}
            renderOption={(props, option: ITestDevice) => (
                <Box component='li' {...props}>
                    <TestDeviceOption option={option} testsQueue={testsQueue} />
                </Box>
            )}
            getOptionLabel={(option) => option.name}
            value={devices || undefined}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete='off'
                    label='Select test devices'
                    placeholder='Select devices'
                    variant='outlined'
                />
            )}
        />
    );
}
