import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    ExpandMore as ExpandMoreIcon,
    InsertDriveFileOutlined as DriveFileIcon,
    AttachFile as AttachFileIcon,
    InsertDriveFile as InsertDriveFileIcon,
    Description as DescriptionIcon,
    Folder as FolderIcon,
    Apple as AppleIcon,
    Android as AndroidIcon,
    FormatAlignLeft as FormatAlignLeftIcon,
    FormatListBulleted as FormatListBulletedIcon,
    LaunchOutlined as LaunchIcon,
    Code as CodeIcon,
    WebAsset as WebAssetIcon,
    ViewList as ViewListIcon,
} from '@mui/icons-material';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
    CircularProgress,
    Box,
    useMediaQuery,
} from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { SVGIcon, SVGIcons } from '../../assets/SVGIcon';
import { AppThunkDispatch } from '../../store';
import { sizeBytesToHuman } from '../../utils';
import ArtifactContentRenderer from './ArtifactContentRenderer';
import {
    getBuildResultArtifactContent,
    preventAndStopClick,
    selectBuildDetailsBuild,
    setBuildResultTabAccordion,
    isTabAccordionActive,
} from './buildDetailsSlice';
import DownloadArtifactBuildDetailsButton from './DownloadArtifactBuildDetailsButton';
import { IArtifact } from './interfaces';
import { ARTIFACT_LINE_HEIGHT } from './StepsAccordion';

export default function ArtifactsAccordion({ artifacts }: { artifacts: IArtifact[] }): ReactElement | null {
    if (!artifacts.length) {
        return (
            <Box sx={{ width: '100%' }} data-testid='build-details-artifacts-accordion'>
                <Typography
                    variant='body1'
                    color='textSecondary'
                    align='center'
                    gutterBottom
                    data-testid='build-details-artifacts-accordion-no-files'
                >
                    This group has no files
                </Typography>
            </Box>
        );
    }

    const sortedArtifacts = artifacts.slice().sort((a, b) => {
        const [fileNameA, fileExtensionA] = a.file.split('.');
        const [fileNameB, fileExtensionB] = b.file.split('.');

        if (!fileExtensionA || !fileExtensionB) {
            return 1;
        }

        return fileExtensionA === fileExtensionB
            ? fileNameA.localeCompare(fileNameB)
            : fileExtensionA.localeCompare(fileExtensionB);
    });

    return (
        <Box data-testid='build-details-artifacts-accordion'>
            {sortedArtifacts.map((artifact, index) => (
                <AccordionArtifact key={index} artifact={artifact} />
            ))}
        </Box>
    );
}

export function FileIcon({ fileExtension }: { fileExtension: string | undefined }): ReactElement {
    switch (fileExtension) {
        case 'log':
            return <FormatAlignLeftIcon sx={{ color: (theme) => theme.palette.warning.main }} />;
        case 'md':
        case 'markdown':
            return <DescriptionIcon sx={{ color: (theme) => theme.palette.error.main }} />;
        case 'json':
            return <InsertDriveFileIcon sx={{ color: (theme) => theme.palette.success.main }} />;
        case 'txt':
            return <DriveFileIcon sx={{ color: (theme) => theme.palette.success.main }} />;
        case 'folder':
        case 'zip':
            return <FolderIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
        case 'apple':
        case 'ipa':
        case 'plist':
        case 'mobileprovision':
            return <AppleIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
        case 'android':
        case 'apk':
        case 'aab':
            return <AndroidIcon sx={{ color: (theme) => theme.palette.tactile.green[500] }} />;
        case 'list':
            return <FormatListBulletedIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
        case 'sh':
        case 'rsp':
            return <CodeIcon sx={{ color: (theme) => theme.palette.info.main }} />;
        case 'xml':
        case 'html':
            return <WebAssetIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
        case 'xlsx':
            return <ViewListIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
        case 'gradle':
            return <SVGIcon icon={SVGIcons.gradle} disableDisplayBehavior />;
        default:
            return <AttachFileIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
    }
}

export function AccordionArtifact({ artifact }: { artifact: IArtifact }): ReactElement | null {
    const dispatch = useDispatch<AppThunkDispatch>();
    const ref = useRef<HTMLDivElement | null>(null);
    const [expanded, setExpanded] = useState(false);
    const [loadingContent, setLoadingContent] = useState(false);
    const [contentLines, setContentLines] = useState(0);
    const [content, setContent] = useState('');
    const isMobile = useMediaQuery('(max-width: 760px)');

    const loadContent = useCallback(() => {
        setLoadingContent(true);
        dispatch(getBuildResultArtifactContent(artifact.file)).then((action) => {
            setContentLines(action.payload?.split('\n').length || 0);
            setContent(action.payload);
            setLoadingContent(false);
        });
    }, [dispatch, artifact.file]);

    useEffect(() => {
        if (isTabAccordionActive(artifact.file) && ref?.current) {
            window.scrollTo(0, ref?.current?.offsetTop);

            setExpanded(true);
            loadContent();
        }
    }, [ref, artifact.file, loadContent]);

    const fileExtension = artifact.file.split('.')?.pop();

    const handleExpandChange = () => {
        const newExpanded = !expanded;
        setExpanded(newExpanded);

        if (!content && artifact.file) {
            loadContent();
        }

        dispatch(setBuildResultTabAccordion(newExpanded ? artifact.file : undefined));
    };

    return (
        <Accordion
            ref={ref}
            sx={{ backgroundColor: (theme) => theme.palette.common.white, mx: 0 }}
            expanded={expanded}
            onClick={handleExpandChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    '.MuiAccordionSummary-content': {
                        display: 'flex',
                        alignItems: 'center',
                        mr: 1,
                        '&.Mui-expanded': {
                            mr: 1,
                        },
                    },
                    '.MuiAccordionSummary-expandIconWrapper': {
                        color: blueGray[800],
                    },
                    '&:hover': {
                        background: (theme) => theme.palette.grey[200],
                    },
                }}
            >
                <FileIcon fileExtension={fileExtension} />
                <Typography
                    component='span'
                    variant='body2'
                    color='textPrimary'
                    sx={{
                        ml: 1,
                        flexGrow: 1,
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '20vw',
                    }}
                >
                    {artifact.file || 'filename not available'}
                    {!isMobile && (
                        <Typography
                            component='span'
                            variant='body2'
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                margin: (theme) => theme.spacing(0, 1),
                            }}
                        >
                            {artifact.step}
                        </Typography>
                    )}
                </Typography>

                <ArtifactSize size={artifact.size} />

                <AccordionButtons artifact={artifact} />
            </AccordionSummary>

            {!content && (
                <AccordionDetails
                    onClick={preventAndStopClick}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                            margin: 1,
                        },
                    }}
                >
                    {loadingContent ? (
                        <CircularProgress variant='indeterminate' size={20} />
                    ) : (
                        <Typography>This artifact has no displayable content</Typography>
                    )}
                </AccordionDetails>
            )}
            {content && (
                <AccordionDetails sx={{ p: 0 }} onClick={preventAndStopClick}>
                    <Box
                        sx={{
                            overflow: 'auto',
                            maxHeight: '70vh',
                            width: '100%',
                            height: `${4 * ARTIFACT_LINE_HEIGHT + contentLines * ARTIFACT_LINE_HEIGHT}px`,
                        }}
                    >
                        <ArtifactContentRenderer content={content} type={fileExtension} />
                    </Box>
                </AccordionDetails>
            )}
        </Accordion>
    );
}

export function ArtifactSize({ size }: { size?: number }): ReactElement {
    return (
        <Typography
            sx={{
                color: blueGray[800],
                width: { xs: '65px', sm: '80px', md: '100px' },
                display: 'flex',
                mr: { xs: 1, sm: 5 },
                justifyContent: 'flex-end',
            }}
            variant='body2'
        >
            {size ? sizeBytesToHuman(size) : null}
        </Typography>
    );
}

export function AccordionButtons({ artifact }: { artifact: IArtifact }): ReactElement | null {
    const build = useSelector(selectBuildDetailsBuild);
    const fileExtension = artifact.file.split('.')?.pop() || '';

    // The readableFiles are shared with the server to know what we can render
    const readableFiles = ['log', 'md', 'markdown', 'json', 'txt', 'sh', 'xml', 'html', 'gradle', 'rsp'];

    return (
        <Box sx={{ ml: 1, width: { xs: '56px', sm: '80px' } }}>
            <DownloadArtifactBuildDetailsButton artifact={artifact} />
            {artifact.size && readableFiles.includes(fileExtension) ? (
                <IconButton
                    title='View Artifact'
                    sx={{
                        color: blueGray[800],
                        ml: { xs: 1, sm: 3 },
                        padding: 0,
                    }}
                    component={Link}
                    to={`/builds/${build?.projectName}/${build?.buildNumber}/${artifact.file}`}
                >
                    <LaunchIcon fontSize='small' />
                </IconButton>
            ) : null}
        </Box>
    );
}
