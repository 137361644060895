import { ReactElement } from 'react';
import { TableBody, TableBodyProps } from '@mui/material';

export default function TactileTableBody({ sx, children, ...props }: TableBodyProps): ReactElement {
    return (
        <TableBody
            sx={{
                '.MuiTableCell-root:first-of-type': {
                    pl: { xs: 2, sm: 4 },
                },
                ...sx,
            }}
            {...props}
        >
            {children}
        </TableBody>
    );
}
