import { ReactElement, useState } from 'react';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Button, Divider, Grid, Paper } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { ITestDevice } from '../interfaces';
import DeviceAdvancedInfo from './DeviceAdvancedInfo';
import DeviceModelInfo from './DeviceModelInfo';

export default function TestDeviceInfo({ testDevice }: { testDevice: ITestDevice }): ReactElement {
    const [expandMore, setExpandMore] = useState(false);

    return (
        <Paper variant='outlined' elevation={0} style={{ padding: 8 }}>
            <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                sx={{ px: 1, display: { xs: 'block', sm: 'flex' } }}
            >
                <DeviceModelInfo testDevice={testDevice} />
                <DeviceStatusAdvancedInfo expandMore={expandMore} setExpandMore={setExpandMore} />
            </Grid>
            {expandMore && (
                <>
                    <Divider orientation='horizontal' sx={{ width: 'calc(100% + 8px)', my: 2 }} />
                    <Grid item container sx={{ px: 1 }}>
                        <DeviceAdvancedInfo deviceInfo={testDevice.deviceInfo} />
                    </Grid>
                </>
            )}
        </Paper>
    );
}

function DeviceStatusAdvancedInfo({
    expandMore,
    setExpandMore,
}: {
    expandMore: boolean;
    setExpandMore: (more: boolean) => void;
}): ReactElement {
    return (
        <Button
            variant='text'
            size='small'
            endIcon={expandMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setExpandMore(!expandMore)}
            sx={{ color: blueGray[700], ml: -1.5, mt: { xs: 1, sm: 0 } }}
            data-testid='test-device-status-info'
        >
            {expandMore ? 'Hide' : ''} advanced device info
        </Button>
    );
}
