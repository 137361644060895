import { ReactElement } from 'react';
import { UpdateDisabled } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';

export default function TestDeviceNotEligibleIcon({ size }: { size?: 'large' | 'medium' | 'small' }): ReactElement {
    return (
        <Tooltip
            title={<Typography variant='subtitle2'>This test device is not eligible for scheduled tests.</Typography>}
        >
            <UpdateDisabled fontSize={size} sx={{ verticalAlign: 'bottom' }} />
        </Tooltip>
    );
}
