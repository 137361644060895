import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { BuildAgent } from '../buildAgents/interfaces';

export const buildAgentsApi = createApi({
    reducerPath: 'buildAgentsApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getBuildAgents: builder.query<BuildAgent[], void>({
            query: () => ({
                url: `/build-agents`,
            }),
            transformResponse: (response: { buildAgents: BuildAgent[] }): BuildAgent[] => response.buildAgents,
        }),
        getBuildAgentsProjects: builder.query<string[], void>({
            query: () => ({
                url: `/build-agents/projects`,
            }),
            transformResponse: (response: { projects: string[] }): string[] => response.projects,
        }),
        getProjectBranches: builder.query<string[], { projectName: string }>({
            query: ({ projectName }) => ({
                url: `/version-control-access/project-branches?projectName=${projectName}`,
            }),
            transformResponse: (response: { branches: string[] }): string[] => response.branches,
        }),
    }),
});

export const { useGetBuildAgentsQuery, useGetBuildAgentsProjectsQuery, useGetProjectBranchesQuery } = buildAgentsApi;
