import { ReactElement, useEffect, useMemo, useState } from 'react';
import { debounce, TextField, TextFieldProps } from '@mui/material';

export function DebounceTextField(props: TextFieldProps & { timeout?: number }): ReactElement {
    const DEFAULT_TIMEOUT = 500;
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = useMemo(
        () => props.onChange && debounce(props.onChange, props.timeout || DEFAULT_TIMEOUT),
        [props.onChange, props.timeout],
    );

    return (
        <TextField
            {...props}
            value={value}
            onKeyUp={(event: any) => {
                if (event.key === 'Enter') {
                    event.target.value = value;
                    onChange?.clear();
                    if (props.onChange) {
                        props.onChange(event);
                    }
                }
            }}
            onChange={(event) => {
                setValue(event.target.value);
                if (onChange) {
                    onChange(event);
                }
            }}
        />
    );
}
