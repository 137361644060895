import queryString from 'query-string';

export function parse(query: string): Record<string, any> {
    return queryString.parse(query, { decode: true, parseBooleans: true });
}

export function stringify(params: Record<string, any>): string {
    const urlParams: Record<string, any> = {};

    for (const filter in params) {
        const value = params[filter];
        if (!Array.isArray(value) && typeof value === 'object') {
            for (const [key, keyValue] of Object.entries(value || {})) {
                urlParams[`${filter}[${key}]`] = keyValue;
            }
        } else if (value) {
            urlParams[filter] = value;
        }
    }

    return queryString.stringify(urlParams, {
        arrayFormat: 'none',
        sort: false,
        skipNull: true,
        skipEmptyString: true,
    });
}
