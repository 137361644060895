import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Autocomplete,
    CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Typography,
    Box,
    SxProps,
    useTheme,
} from '@mui/material';
import { AppThunkDispatch } from '../../store';
import { BuildAgent } from '../buildAgents/interfaces';
import getBuildAgentName from '../commons/getBuildAgentName';
import JiraTaskInput from '../commons/inputs/JiraTaskInput';
import { SlackRecipients } from '../commons/SlackRecipients';
import {
    getBuildConfigurations,
    selectNewBuildProject,
    selectNewBuildBranch,
    setNewBuildBranch,
    setNewBuildProject,
    selectNewBuildRevision,
    setNewBuildRevision,
    selectNewBuildHeadRevision,
    selectNewBuildStatus,
    getProjects,
    getBranches,
    getRevisions,
    setVersion,
    setJiraTasks,
    selectNewBuildJiraTasks,
    selectNewBuildSubscribers,
    setSubscribers,
    getBuildAgents,
    selectNewBuildAgent,
    setBuildAgent,
    setExpedited,
    selectNewBuildVersion,
    selectNewBuildSubscribed,
    setSubscribed,
    selectNewBuildConfiguration,
    setNewBuildConfiguration,
    getViableAgentsCount,
    getSimilarBuilds,
} from '../newBuild/newBuildSlice';
import { NewBuildStatus } from './enums';
import { IKnownRevisions } from './interfaces';

const autocompleteStyles: SxProps = {
    width: '50%',
    flexGrow: 1,
    minWidth: '200px',
    padding: { xs: '4px', sm: '16px' },
};

export default function SelectNewBuildConfiguration(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const theme = useTheme();
    const status = useSelector(selectNewBuildStatus);

    useEffect(() => {
        const isLoading = [NewBuildStatus.loading, NewBuildStatus.loaded].includes(status);
        if (!isLoading) {
            dispatch(getProjects());
            dispatch(getBuildAgents());
        }
    }, [status, dispatch]);

    return (
        <>
            <Box
                data-testid='select-new-build-configuration'
                sx={{
                    width: '100%',
                    py: 2,
                    backgroundColor: (theme) => theme.palette.common.white,
                    display: 'flex',
                    flexWrap: 'wrap',
                    [theme.breakpoints.down('sm')]: {
                        padding: (theme) => theme.spacing(1, 0.5),
                    },
                }}
            >
                <ProjectSelector />
                <BranchSelector />
                <RevisionSelector />
                <ConfigSelector />
                <JIRATicketInput />
                <Box
                    sx={{
                        width: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        padding: 2,
                        flexGrow: 1,
                        minWidth: '200px',
                        [theme.breakpoints.down('sm')]: {
                            padding: 0.5,
                        },
                    }}
                >
                    <SubscribeToResults />
                </Box>
                <NewBuildSlackRecipients />
            </Box>
            <AdvancedOptions />
        </>
    );
}

export function ProjectSelector(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected, options, loading } = useSelector(selectNewBuildProject);
    const defaultExcludedProjects = ['AppUploader'];

    const handleProjectChange = (newProject: string | null) => {
        const defaultBranch = 'trunk';

        dispatch(setNewBuildProject(newProject));
        if (newProject) {
            dispatch(getBranches()).then((result: any) => {
                if (!result.error) {
                    dispatch(setNewBuildBranch(defaultBranch));
                    dispatch(getRevisions()).then((result: any) => {
                        if (!result.error) {
                            dispatch(setNewBuildRevision());
                            const selectedRevision = result.payload?.[0]?.revision || '';
                            dispatch(
                                getBuildConfigurations({
                                    projectName: newProject,
                                    branch: defaultBranch,
                                    revision: selectedRevision,
                                }),
                            ).then(() => {
                                dispatch(getViableAgentsCount());
                                dispatch(getSimilarBuilds());
                            });
                        }
                    });
                }
            });
        }
    };

    return (
        <Autocomplete
            data-testid='select-new-build-configuration-project'
            sx={{ ...autocompleteStyles }}
            options={options.filter((opt) => !defaultExcludedProjects.includes(opt))}
            id='new-build-project-selector'
            autoHighlight
            clearOnBlur
            clearOnEscape
            disabled={loading}
            value={selected as string}
            popupIcon={loading ? <CircularProgress size={20} /> : undefined}
            onChange={(_, newValue) => handleProjectChange(String(newValue || ''))}
            renderInput={(params) => (
                <TextField {...params} required sx={{ margin: 0, width: '100%' }} label='Project' variant='outlined' />
            )}
        />
    );
}

export function BranchSelector(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected: projectName } = useSelector(selectNewBuildProject);
    const { disabled, selected, options, loading } = useSelector(selectNewBuildBranch);

    const handleBranchChange = (newBranch: string) => {
        dispatch(setNewBuildBranch(newBranch));
        if (newBranch) {
            dispatch(getRevisions()).then(({ payload }) => {
                setNewBuildRevision();
                const selectedRevision = payload?.[0]?.revision || '';
                dispatch(getBuildConfigurations({ projectName, branch: newBranch, revision: selectedRevision })).then(
                    () => {
                        dispatch(getViableAgentsCount());
                        dispatch(getSimilarBuilds());
                    },
                );
            });
        }
    };

    return (
        <Autocomplete
            data-testid='select-new-build-configuration-branch'
            sx={{ ...autocompleteStyles }}
            options={options}
            id='new-build-branch-selector'
            autoHighlight
            clearOnBlur
            clearOnEscape
            disabled={disabled}
            value={selected}
            popupIcon={loading ? <CircularProgress size={20} /> : undefined}
            onChange={(_, newValue) => handleBranchChange(String(newValue || ''))}
            renderInput={(params) => (
                <TextField {...params} required sx={{ margin: 0, width: '100%' }} label='Branch' variant='outlined' />
            )}
        />
    );
}

export function RevisionSelector(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { selected: projectName } = useSelector(selectNewBuildProject);
    const { selected: selectedBranch } = useSelector(selectNewBuildBranch);
    const { disabled, selected, options, loading } = useSelector(selectNewBuildRevision);
    const headRevision = useSelector(selectNewBuildHeadRevision);

    const handleRevisionChange = (newRevision: IKnownRevisions) => {
        dispatch(setNewBuildRevision(newRevision));

        if (newRevision) {
            dispatch(
                getBuildConfigurations({ projectName, branch: selectedBranch, revision: newRevision.revision }),
            ).then(() => {
                dispatch(getViableAgentsCount());
                dispatch(getSimilarBuilds());
            });
        }
    };

    return (
        <Autocomplete
            data-testid='select-new-build-configuration-revision'
            sx={{ ...autocompleteStyles }}
            options={options}
            getOptionLabel={(option: IKnownRevisions) =>
                String(option.revision) +
                (option.revision === headRevision ? ' (HEAD) - ' : ' - ') +
                option.commitMessage
            }
            isOptionEqualToValue={(option: IKnownRevisions, value: IKnownRevisions) =>
                option.revision === value.revision
            }
            id='new-build-revision-selector'
            autoHighlight
            disableClearable
            clearOnBlur
            clearOnEscape
            disabled={disabled}
            value={selected}
            popupIcon={loading ? <CircularProgress size={20} /> : undefined}
            onChange={(_, newValue) => handleRevisionChange(newValue as IKnownRevisions)}
            renderInput={(params) => (
                <TextField {...params} required sx={{ margin: 0, width: '100%' }} label='Revision' variant='outlined' />
            )}
        />
    );
}

export function ConfigSelector(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { disabled, selected, options, loading } = useSelector(selectNewBuildConfiguration);

    const handleConfigChange = (newConfigFileName: string | null) => {
        const selectedConfig = options?.find((config) => config.fileName === newConfigFileName);
        dispatch(setNewBuildConfiguration(selectedConfig));

        setTimeout(() => {
            dispatch(getViableAgentsCount());
        }, 300);
    };

    const handleOpenBuildConfiguration = () => {
        const buildConfigHTML = '<pre>' + JSON.stringify(selected?.configuration, null, 2) + '</pre>';
        const tab = window.open('about:blank', '_blank');
        if (tab) {
            tab.document.write(buildConfigHTML);
            tab.document.close();
        }
    };

    return (
        <Box sx={{ width: { xs: '100%', sm: '50%' }, display: 'flex' }}>
            <Autocomplete
                data-testid='select-new-build-configuration-file'
                sx={{ ...autocompleteStyles, width: '100%' }}
                options={options?.map((item) => item.fileName) || []}
                id='new-build-configuration-selector'
                autoHighlight
                disableClearable
                clearOnBlur
                clearOnEscape
                disabled={disabled}
                value={selected?.fileName || ''}
                popupIcon={loading ? <CircularProgress size={20} /> : undefined}
                onChange={(_, newValue) => handleConfigChange(newValue)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        sx={{ margin: 0, width: '100%' }}
                        label='Config'
                        variant='outlined'
                    />
                )}
            />
            {selected && (
                <IconButton
                    title='Open Raw Build Configuration JSON In New Tab'
                    size='medium'
                    disableRipple
                    onClick={handleOpenBuildConfiguration}
                    sx={{ pl: 0 }}
                >
                    <OpenInNewIcon fontSize='inherit' />
                </IconButton>
            )}
        </Box>
    );
}

export function JIRATicketInput(): ReactElement {
    const dispatch = useDispatch();
    const { selected } = useSelector(selectNewBuildRevision);
    const jiraTasks = useSelector(selectNewBuildJiraTasks);

    const handleChange = (newValue: string[]) => {
        dispatch(setJiraTasks(newValue));
    };

    return (
        <JiraTaskInput
            data-testid='select-new-build-configuration-jira'
            sx={{ ...autocompleteStyles }}
            value={jiraTasks}
            onChange={(newValues) => handleChange(newValues)}
            disabled={!selected}
        />
    );
}

export function VersionInput(): ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { selected } = useSelector(selectNewBuildRevision);
    const version = useSelector(selectNewBuildVersion);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setVersion(event.target.value));
    };

    return (
        <Box
            data-testid='select-new-build-configuration-version'
            sx={{
                width: '50%',
                display: 'inline-block',
                padding: (theme) => theme.spacing(0.5, 2),
                [theme.breakpoints.down('sm')]: {
                    display: 'block',
                    width: '100%',
                    padding: (theme) => theme.spacing(1, 0.5),
                },
            }}
        >
            <TextField
                disabled={!selected}
                onChange={handleChange}
                sx={{ margin: 0, width: '100%' }}
                value={version || ''}
                label='Version'
                variant='outlined'
            />
        </Box>
    );
}

export function NewBuildSlackRecipients(): ReactElement {
    const dispatch = useDispatch();
    const subscribers = useSelector(selectNewBuildSubscribers);

    const handleChange = (newValue: string[]) => {
        dispatch(setSubscribers(newValue));
    };

    return (
        <Box sx={{ px: { xs: 0.5, sm: 2 }, width: '100%' }}>
            <SlackRecipients
                otherSubscribers={subscribers || []}
                setOtherSubscribers={handleChange}
                sx={{ width: { xs: '100%', sm: '50%' } }}
            />
        </Box>
    );
}

export function BuildAgentInput(): ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { disabled, selected, options, loading } = useSelector(selectNewBuildAgent);

    const handleAgentChange = (newAgent: BuildAgent | null) => {
        dispatch(setBuildAgent(newAgent));
    };

    return (
        <Autocomplete
            sx={{
                ...autocompleteStyles,
                display: 'inline-block',
                padding: (theme) => theme.spacing(0.5, 2),
                [theme.breakpoints.down('sm')]: {
                    display: 'block',
                    width: '100%',
                    padding: (theme) => theme.spacing(1, 0.5),
                },
            }}
            data-testid='select-new-build-configuration-agent'
            options={options}
            getOptionLabel={(option) => getBuildAgentName(option as BuildAgent)}
            renderOption={(props, option) => (
                <Box component='li' {...props}>
                    <span style={{ flexGrow: 1 }}>{getBuildAgentName(option as BuildAgent)}</span>
                    <Typography component='span' color='textSecondary'>
                        {option?.enabled ? option?.state : 'disabled'}
                    </Typography>
                </Box>
            )}
            isOptionEqualToValue={(option: BuildAgent) => option._id === selected?._id}
            id='new-build-agent-selector'
            autoHighlight
            clearOnBlur
            clearOnEscape
            disabled={disabled}
            value={selected}
            popupIcon={loading && !disabled ? <CircularProgress size={20} /> : undefined}
            onChange={(_, newValue) => handleAgentChange(newValue as BuildAgent | null)}
            renderInput={(params) => (
                <TextField {...params} sx={{ margin: 0, width: '100%' }} label='Build agent' variant='outlined' />
            )}
        />
    );
}

function AdvancedOptions(): ReactElement | null {
    const [showSettings, setShowSettings] = useState(false);

    const handleShowSettingsToggle = () => {
        setShowSettings(!showSettings);
    };

    return (
        <Box sx={{ display: 'block', width: '100%' }}>
            <Typography variant='subtitle1' fontWeight={600} gutterBottom>
                More Options
                <IconButton
                    title={showSettings ? 'hide settings' : 'show settings'}
                    onClick={handleShowSettingsToggle}
                    sx={{ marginLeft: 1, padding: 1 }}
                >
                    {showSettings ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
            </Typography>
            <Collapse in={showSettings}>
                <>
                    <VersionInput />
                    <BuildAgentInput />
                    <ExpediteBuildsToggle />
                </>
            </Collapse>
        </Box>
    );
}

function ExpediteBuildsToggle(): ReactElement {
    const dispatch = useDispatch();

    const [isExpedited, toggleExpedited] = useState(false);

    const handleToggle = (event: React.ChangeEvent<any>) => {
        toggleExpedited(event.target.checked);
        dispatch(setExpedited(event.target.checked));
    };

    return (
        <FormControlLabel
            sx={{ ml: 1, mt: 2 }}
            control={<Switch size='small' checked={isExpedited} onChange={handleToggle} />}
            label='Expedited build'
        />
    );
}

function SubscribeToResults(): ReactElement {
    const dispatch = useDispatch();
    const subscribed = useSelector(selectNewBuildSubscribed);

    const handleChange = (checked: boolean) => {
        dispatch(setSubscribed(checked));
    };

    return (
        <FormControl sx={{ flexGrow: 1 }}>
            <FormControlLabel
                control={
                    <Switch
                        color='primary'
                        checked={subscribed}
                        onChange={(event: React.ChangeEvent<any>) => handleChange(event.target.checked)}
                    />
                }
                label='Notify me when build is done'
            />
        </FormControl>
    );
}
