import { ReactElement } from 'react';
import ColumnFilter from '../../commons/filters/ColumnFilter';
import { parseFilterOptions } from '../../commons/filters/utils';
import { useGetAvailableOptionsQuery } from '../testSuitesApi';
import { ITestSuitesFilterProps } from './TestSuitesFilters';

export default function BuildVersionSelector({
    filters,
    onFiltersChange,
    showOnlyValues,
}: ITestSuitesFilterProps): ReactElement {
    const selected = filters.build?.versionName;
    const { data, isFetching: loading } = useGetAvailableOptionsQuery();

    const parsedOptions = parseFilterOptions(data?.options?.build?.versionName || []);

    const handleFiltersChange = (newValue: string[] | null) => {
        onFiltersChange({
            ...filters,
            build: {
                ...filters.build,
                versionName: newValue || undefined,
            },
        });
    };
    return (
        <ColumnFilter
            id='buildVersion-selector'
            label='Version'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={handleFiltersChange}
            showOnlyValues={showOnlyValues}
        />
    );
}
