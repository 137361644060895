import { ReactElement } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import FullWidthPopper from '../FullWidthPopper';
import { ResponsiveAutocomplete } from './commons';

export interface IAutocompleteFilterProps {
    id: string;
    selected?: string | null;
    options: string[];
    loading?: boolean;
    onChange: (newValue?: string) => void;
    placeholder: string;
    size?: 'small' | 'xSmall' | 'medium';
    disabled?: boolean;
}

export default function AutocompleteFilter({
    id,
    selected,
    options,
    loading,
    onChange,
    placeholder,
    size,
    disabled,
}: IAutocompleteFilterProps): ReactElement {
    return (
        <ResponsiveAutocomplete
            id={id}
            data-testid={id}
            size={size}
            options={options}
            getOptionLabel={(option) => option?.toString() || ''}
            autoHighlight
            clearOnBlur
            clearOnEscape
            disabled={loading || disabled}
            value={selected || null}
            popupIcon={loading ? <CircularProgress size={20} /> : undefined}
            onChange={(_event: any, newValue) => {
                onChange((newValue || undefined) as string | undefined);
            }}
            renderInput={(params) => (
                <TextField {...params} variant='outlined' size='small' placeholder={placeholder} />
            )}
            PopperComponent={FullWidthPopper}
        />
    );
}
