import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest } from '../../api';
import { SliceStatus } from '../commons/enums';
import { AcceleratorColumnId } from './enums';
import { IAcceleratorColumn, IAcceleratorDetails, IAcceleratorsSlice } from './interfaces';

export const registerAccelerator = createAsyncThunk<any, { ip: string; port: number }>(
    'accelerators/register',
    async (payload, thunkAPI) => {
        return postRequest('/accelerator/register', payload, thunkAPI);
    },
);

export const getAccelerators = createAsyncThunk<any, void, { state: any }>('accelerators/getAccelerators', async () => {
    return getRequest('/accelerator');
});

export const removeNamespace = createAsyncThunk<
    any,
    { ip: string; port: number; containerName: string; namespace: string }
>('accelerators/removeNamespace', async (payload, thunkAPI) => {
    return postRequest('/accelerator/remove-namespace', payload, thunkAPI);
});

export const removeKey = createAsyncThunk<
    any,
    { ip: string; port: number; containerName: string; namespace: string; keyToRemove: string }
>('accelerators/removeKey', async (payload, thunkAPI) => {
    return postRequest('/accelerator/remove-key', payload, thunkAPI);
});

export const deleteAccelerator = createAsyncThunk<any, string>(
    'accelerators/deleteAccelerator',
    async (acceleratorId, thunkAPI) => {
        return deleteRequest(`/accelerator/${acceleratorId}`, thunkAPI);
    },
);

const initialState: IAcceleratorsSlice = {
    status: SliceStatus.idle,
    accelerators: [],
    acceleratorDialog: {
        open: false,
    },
    acceleratorOptionsDialog: {
        open: false,
        selectedAccelerator: undefined,
    },
    columns: [
        { id: AcceleratorColumnId.serviceName, label: 'Service name', width: '25%', align: 'left' },
        { id: AcceleratorColumnId.host, label: 'Host', width: '25%', align: 'left' },
        { id: AcceleratorColumnId.address, label: 'IP:Port', width: '15%', align: 'left' },
        { id: AcceleratorColumnId.games, label: 'Games', width: '15%', align: 'left' },
        { id: AcceleratorColumnId.actions, label: 'Actions', width: '10%', align: 'justify' },
    ],
};

export const acceleratorSlice = createSlice({
    name: 'accelerators',
    initialState,
    reducers: {
        toggleRegisterAcceleratorDialog: (state: IAcceleratorsSlice) => {
            state.acceleratorDialog.open = !state.acceleratorDialog.open;
        },
        toggleAcceleratorOptionsDialog: (
            state: IAcceleratorsSlice,
            action: PayloadAction<{ accelerator?: IAcceleratorDetails }>,
        ) => {
            state.acceleratorOptionsDialog.open = !state.acceleratorOptionsDialog.open;
            state.acceleratorOptionsDialog.selectedAccelerator = action.payload.accelerator;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccelerators.pending, (state) => {
                if (!state.accelerators?.length) {
                    state.status = SliceStatus.loading;
                }
            })
            .addCase(getAccelerators.fulfilled, (state, action: PayloadAction<{ accelerators: [] }>) => {
                state.status = SliceStatus.loaded;
                state.accelerators = action.payload.accelerators;
            });
    },
});

export const selectAcceleratorDialogOpen = (state: { accelerators: IAcceleratorsSlice }): boolean =>
    state.accelerators.acceleratorDialog.open;
export const selectAcceleratorOptionsDialog = (state: { accelerators: IAcceleratorsSlice }): boolean =>
    state.accelerators.acceleratorOptionsDialog.open;
export const selectAllAccelerators = (state: { accelerators: IAcceleratorsSlice }): IAcceleratorDetails[] =>
    state.accelerators.accelerators;
export const selectAcceleratorColumns = (state: { accelerators: IAcceleratorsSlice }): IAcceleratorColumn[] =>
    state.accelerators.columns;
export const selectAcceleratorStatus = (state: { accelerators: IAcceleratorsSlice }): SliceStatus =>
    state.accelerators.status;
export const selectCurrentAccelerator = (state: {
    accelerators: IAcceleratorsSlice;
}): IAcceleratorDetails | undefined => state.accelerators.acceleratorOptionsDialog.selectedAccelerator;

export const { toggleRegisterAcceleratorDialog, toggleAcceleratorOptionsDialog } = acceleratorSlice.actions;

export default acceleratorSlice.reducer;
