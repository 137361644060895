import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { indigo } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { setSelectedMenu } from './headerSlice';
import { IAppBarMenuItem } from './interfaces';

interface NavButtonProps {
    item: IAppBarMenuItem;
    selected?: { text: string; to: string };
}

export default function NavButton({ item, selected }: NavButtonProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();

    const handleOnClick = () => {
        dispatch(setSelectedMenu({ to: item.to, text: item.text }));
    };

    const isSelected = item.text === selected?.text;

    return (
        <Button
            component={Link}
            key={item.to}
            to={item.to}
            sx={{
                textTransform: 'none',
                marginRight: 1,
                letterSpacing: 0,
                color: (theme) => theme.palette.common.white,
                ...(isSelected && {
                    background: indigo[1000],
                }),
                '&:hover': {
                    background: indigo[600],
                },
            }}
            onClick={handleOnClick}
        >
            {isSelected ? <span style={{ fontWeight: 600 }}> {item.text} </span> : item.text}
        </Button>
    );
}
