import { ReactElement } from 'react';
import Moment from 'react-moment';
import { TableCell } from '@mui/material';
import moment from 'moment';
import { MuiAlignSetting } from '../../typings/material-ui';

export default function BuildStarted(props: { align?: MuiAlignSetting; started?: string }): ReactElement {
    const { align, started } = props;

    return (
        <TableCell
            title={moment(started).format('DD-MM-YYYY HH:mm')}
            align={align}
            sx={{ padding: 1 }}
            data-testid='builds-table-build-started'
        >
            <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {started && (
                    <Moment fromNow interval={1000}>
                        {started}
                    </Moment>
                )}
            </span>
        </TableCell>
    );
}
