import { ISelectedSettings, ISetting, SettingType } from './interfaces';

export default function updateSelectedSettings(
    selectedSettings: ISelectedSettings,
    setting: ISetting,
    value: string | string[] | boolean | null,
): ISelectedSettings {
    const newSelectedSettings = { ...selectedSettings };
    if (setting.type === SettingType.checkbox && setting.multi) {
        const values = Array.isArray(newSelectedSettings[setting.settingKey as string])
            ? (newSelectedSettings[setting.settingKey as string] as any[])
            : [];
        if (value) {
            values.push(value as string);
            newSelectedSettings[setting.settingKey as string] = values;
        } else {
            newSelectedSettings[setting.settingKey as string] = values.filter((setting) => setting !== value);
        }
    } else {
        newSelectedSettings[setting.settingKey as string] = value;
    }
    return newSelectedSettings;
}
