import { ReactElement } from 'react';
import ColumnFilter from '../../commons/filters/ColumnFilter';
import { parseFilterOptions } from '../../commons/filters/utils';
import { useGetAvailableOptionsQuery } from '../testSuitesApi';
import { ITestSuitesFilterProps } from './TestSuitesFilters';

export default function TestBranchSelector({
    filters,
    onFiltersChange,
    showOnlyValues,
}: ITestSuitesFilterProps): ReactElement {
    const selected = filters.testBranch;
    const { data, isFetching: loading } = useGetAvailableOptionsQuery();

    const parsedOptions = parseFilterOptions(data?.options?.testBranch || []);

    const handleFiltersChange = (newValue: string[] | null) => {
        onFiltersChange({
            ...filters,
            testBranch: newValue || undefined,
        });
    };
    return (
        <ColumnFilter
            id='testBranch-selector'
            label='Test Branch'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={handleFiltersChange}
            showOnlyValues={showOnlyValues}
        />
    );
}
