import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRequest, getTextRequest, patchRequest, postRequest } from '../../api';
import { SliceStatus } from '../commons/enums';
import getUrlParam from '../commons/getUrlPrams';
import { IBuild } from '../commons/interfaces';
import * as queryString from '../commons/queryString';
import { ISelectedSettings } from '../configurationSettings/interfaces';
import { IProjectGameModules } from '../gameModules/interfaces';
import { IConfigurationAction } from '../newBuild/interfaces';
import { StepState } from './enums';
import {
    IBuildDetailsSlice,
    IArtifactGroup,
    IBuildDialog,
    IBuildResult,
    IStep,
    IArtifact,
    IMessageCount,
    IAppUploaderPayload,
    IGetBuildResponse,
} from './interfaces';

export const getBuildResult = createAsyncThunk(
    'builds/getBuildResult',
    async ({ projectName, buildNumber }: { projectName: string; buildNumber: string }) => {
        return getRequest('/builds/build-result', { projectName, buildNumber });
    },
);

export const getBuildResultById = createAsyncThunk<{ build: IBuild; buildResult?: IBuildResult }, string>(
    'builds/getBuildResultById',
    async (buildDocumentId: string) => {
        return getRequest('builds/build-result', { _id: buildDocumentId });
    },
);

export const getBuildViableAgents = createAsyncThunk('builds/getBuildViableAgents', async (buildDocumentId: string) => {
    return getRequest(`builds/${buildDocumentId}/viable-agents`);
});

export const getBuildResultArtifact = createAsyncThunk(
    'builds/getBuildResultArtifact',
    async (filename: string, thunkAPI) => {
        const state = thunkAPI.getState() as { buildDetails: IBuildDetailsSlice };
        const build = state.buildDetails.build;
        if (!build) {
            return;
        }

        return getRequest(`/builds/build-result/${build._id}/get-artifact`, { filename });
    },
);

export const getBuildResultArtifactContent = createAsyncThunk(
    'builds/getBuildResultArtifactContent',
    async (filename: string, thunkAPI) => {
        const state = thunkAPI.getState() as { buildDetails: IBuildDetailsSlice };
        const build = state.buildDetails.build;
        if (!build) {
            return;
        }

        const res = await getTextRequest(
            `/builds/build-result/${build._id}/get-artifact-content`,
            { filename },
            thunkAPI,
        );

        if (res.payload?.error) {
            return res.payload.error.message;
        }

        return res;
    },
);

export const updateBuildJiraTasks = createAsyncThunk(
    'builds/updateBuildJiraTasks',
    async (jiraTasks: string[], thunkAPI) => {
        const state = thunkAPI.getState() as { buildDetails: IBuildDetailsSlice };
        const build = state.buildDetails.build;
        if (!build) {
            return;
        }

        return patchRequest(`/builds/${build._id}`, { jiraTasks }, thunkAPI);
    },
);

export const expediteBuild = createAsyncThunk(
    'buildDetails/expediteBuild',
    async (payload: { buildId: string; buildAgentIp: string | undefined; expediteState: boolean }, thunkAPI) => {
        const { buildId, buildAgentIp, expediteState } = payload;
        return postRequest(`/builds/expedite`, { buildId, buildAgentIp, expediteState }, thunkAPI);
    },
);

export const uploadApp = createAsyncThunk('buildDetails/uploadApp', async (payload: IAppUploaderPayload, thunkAPI) => {
    const { parentBuild, ...uploadData } = payload;
    return postRequest(`/builds/build-result/${parentBuild}/store-upload`, uploadData, thunkAPI);
});

export const executeAction = createAsyncThunk(
    'builds/executeAction',
    async (payload: { buildId: string; action: IConfigurationAction; settings?: ISelectedSettings }, thunkAPI) => {
        const { buildId, action, settings } = payload;
        return postRequest(`/builds/${buildId}/action`, { action, settings }, thunkAPI);
    },
);

const initialState: IBuildDetailsSlice = {
    dialog: { open: false },
    orderTestDialog: { open: false },
    appUploaderDialog: { open: false },
    changeLogDialog: { open: false },
    status: SliceStatus.idle,
    activeTab: 'steps',
    error: undefined,
    messagesCount: { error: 0, warning: 0, info: 0, setting: 0, skipped: 0, flag: 0 },
};

export const buildDetailsSlice = createSlice({
    name: 'buildDetails',
    initialState,
    reducers: {
        setBuildResultTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload;
            state.activeTabAccordion = undefined;

            setUrlParamsFromState(state);
        },
        setBuildResultTabAccordion: (state, action: PayloadAction<string | undefined>) => {
            state.activeTabAccordion = action.payload;

            setUrlParamsFromState(state);
        },
        setBuildDialog: (state, action: PayloadAction<IBuildDialog>) => {
            state.dialog.artifact = action.payload.artifact;
            state.dialog.buildConfiguration = action.payload.buildConfiguration;
            state.dialog.jiraTasks = action.payload.jiraTasks;
            state.dialog.share = action.payload.share;
            state.dialog.title = action.payload.title;
            state.dialog.open = action.payload.open || false;

            setUrlParamsFromState(state);
        },
        setBuildDialogOpen: (state, action?: PayloadAction<boolean>) => {
            state.dialog.open = action?.payload || !state.dialog.open;
        },
        setOrderTestDialogOpen: (state, action?: PayloadAction<{ open: boolean }>) => {
            state.orderTestDialog.open = action?.payload.open || !state.orderTestDialog.open;
        },
        setAppUploaderDialogOpen: (state, action?: PayloadAction<boolean>) => {
            state.appUploaderDialog.open = action?.payload || !state.appUploaderDialog.open;
        },
        setChangeLogOpen: (state, action?: PayloadAction<{ open: boolean }>) => {
            state.changeLogDialog.open = action?.payload.open || !state.changeLogDialog.open;
        },
        clearBuildDetails: () => initialState,
        updateBuild: (state, action: PayloadAction<IBuild>) => {
            state.build = action.payload;
        },
        updateBuildResult: (
            state,
            action: PayloadAction<{
                buildResult: IBuildResult;
                artifactsNotInSteps: IArtifact[];
                airTestsFile: IArtifact;
            }>,
        ) => {
            state.buildResult = action.payload.buildResult;
            state.artifactsNotInSteps = action.payload.artifactsNotInSteps;
            state.airTestsFile = action.payload.airTestsFile;
            state.artifactsByGroup = getBuildDetailsArtifactGroups(
                action.payload.buildResult,
                action.payload.artifactsNotInSteps,
            );
            state.featuredArtifacts = getBuildDetailsFeaturedArtifacts(action.payload.buildResult);
            state.messagesCount = getBuildDetailsMessagesCount(action.payload.buildResult, state.build?.settings);
        },
        updateChildBuild: (state, action: PayloadAction<IBuild>) => {
            if (state.build) {
                const childIndex = state.build.childBuilds?.findIndex((build) => build._id === action.payload._id);
                if (state.build.childBuilds && childIndex !== undefined && childIndex >= 0) {
                    state.build.childBuilds[childIndex] = action.payload;
                } else if (!state.build.childBuilds) {
                    state.build.childBuilds = [action.payload];
                } else {
                    state.build.childBuilds.push(action.payload);
                }
            }
        },
        setRunningStepAsCancelled: (state) => {
            if (state.buildResult?.steps) {
                const runningStepIndex = state.buildResult.steps.findIndex((step) => step.state === StepState.running);
                if (runningStepIndex > -1) {
                    state.buildResult.steps[runningStepIndex].state = StepState.cancelled;
                    state.buildResult.steps[runningStepIndex].end = new Date().toString();
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBuildResult.pending, (state) => {
                state.status = SliceStatus.loading;
            })
            .addCase(getBuildResult.fulfilled, (state, action: PayloadAction<IGetBuildResponse>) => {
                state.status = SliceStatus.loaded;
                state.build = action.payload.build;
                state.buildResult = action.payload.buildResult;
                state.modules = action.payload.modules;
                state.artifactsNotInSteps = action.payload.artifactsNotInSteps;
                state.airTestsFile = action.payload.airTestsFile;
                state.artifactsByGroup = getBuildDetailsArtifactGroups(
                    action.payload.buildResult,
                    action.payload.artifactsNotInSteps,
                );
                state.featuredArtifacts = getBuildDetailsFeaturedArtifacts(action.payload.buildResult);
                state.messagesCount = getBuildDetailsMessagesCount(
                    action.payload.buildResult,
                    action.payload.build?.settings,
                );
                const share = getUrlParam('share') as string;
                if (share) {
                    state.dialog.open = true;
                    state.dialog.share = true;
                    state.dialog.artifact = {
                        file: share,
                    };
                }
                const [activeTab, activeTabAccordion] = getActiveTab();
                state.activeTab = activeTab || 'steps';
                state.activeTabAccordion = activeTabAccordion;
            })
            .addCase(updateBuildJiraTasks.fulfilled, (state, action: PayloadAction<{ build: IBuild }>) => {
                if (state.build && action.payload.build) {
                    state.build.jiraTasks = action.payload.build.jiraTasks;
                }
            });
    },
});

export const selectBuildDetailsStatus = (state: { buildDetails: IBuildDetailsSlice }): SliceStatus =>
    state.buildDetails.status;
export const selectBuildDetailsTab = (state: { buildDetails: IBuildDetailsSlice }): string =>
    state.buildDetails.activeTab;
export const selectBuildDetailsBuild = (state: { buildDetails: IBuildDetailsSlice }): IBuild | undefined =>
    state.buildDetails.build;
export const selectBuildDetailsBuildId = (state: { buildDetails: IBuildDetailsSlice }): string | undefined =>
    state.buildDetails.build?._id;
export const selectBuildDetailsProjectModules = (state: {
    buildDetails: IBuildDetailsSlice;
}): IProjectGameModules | undefined => state.buildDetails.modules;
export const selectBuildDetailsResult = (state: { buildDetails: IBuildDetailsSlice }): IBuildResult | undefined =>
    state.buildDetails.buildResult;
export const selectBuildDetailsResultSteps = (state: { buildDetails: IBuildDetailsSlice }): IStep[] | undefined =>
    state.buildDetails.buildResult?.steps;
export const selectBuildDetailsResultMessagesCount = (state: { buildDetails: IBuildDetailsSlice }): IMessageCount =>
    state.buildDetails.messagesCount;
export const selectBuildDetailsArtifactGroups = (state: {
    buildDetails: IBuildDetailsSlice;
}): IArtifactGroup | undefined => state.buildDetails.artifactsByGroup;
export const selectBuildDetailsFeaturedArtifacts = (state: {
    buildDetails: IBuildDetailsSlice;
}): IArtifact[] | undefined => state.buildDetails.featuredArtifacts;
export const selectBuildDetailsSettings = (state: { buildDetails: IBuildDetailsSlice }): any | undefined =>
    state.buildDetails.build?.settings;
export const selectBuildDetailsBuildReleaseCandidate = (state: {
    buildDetails: IBuildDetailsSlice;
}): boolean | undefined => state.buildDetails.buildResult?.releaseCandidate;
export const selectBuildStatus = (state: { buildDetails: IBuildDetailsSlice }): string | undefined =>
    state.buildDetails.build?.status;
export const selectBuildDialog = (state: { buildDetails: IBuildDetailsSlice }): IBuildDialog =>
    state.buildDetails.dialog;
export const selectOrderTestDialog = (state: { buildDetails: IBuildDetailsSlice }): { open: boolean } =>
    state.buildDetails.orderTestDialog;
export const selectAppUploaderDialog = (state: { buildDetails: IBuildDetailsSlice }): { open: boolean } =>
    state.buildDetails.appUploaderDialog;
export const selectAirTestsFile = (state: { buildDetails: IBuildDetailsSlice }): IArtifact | undefined =>
    state.buildDetails.airTestsFile;
export const selectChangeLogDialog = (state: { buildDetails: IBuildDetailsSlice }): { open: boolean } =>
    state.buildDetails.changeLogDialog;

export const {
    setBuildResultTab,
    setBuildResultTabAccordion,
    setBuildDialog,
    setBuildDialogOpen,
    setOrderTestDialogOpen,
    setAppUploaderDialogOpen,
    clearBuildDetails,
    updateBuild,
    updateBuildResult,
    updateChildBuild,
    setRunningStepAsCancelled,
    setChangeLogOpen,
} = buildDetailsSlice.actions;

export default buildDetailsSlice.reducer;

export function preventAndStopClick(event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();
}

export function preventAndStopLinkClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
}

export function getBuildDetailsArtifactGroups(
    buildResult?: IBuildResult,
    artifactsNotInSteps?: IArtifact[],
): IArtifactGroup | undefined {
    const artifactsByGroup =
        buildResult?.steps?.reduce((byGroup: IArtifactGroup, step: IStep) => {
            if (step.logFile) {
                const group = step.logFile.group || 'Other';
                const logFileData = {
                    ...step.logFile,
                    group,
                    step: step.name,
                };
                byGroup[group] = byGroup[group] ? byGroup[group].concat([logFileData]) : [logFileData];
            }

            if (!step.artifacts) {
                return byGroup;
            }

            for (const artifact of step.artifacts) {
                const group = artifact.group || 'Other';

                const artifactData = {
                    ...artifact,
                    group,
                    step: step.name,
                };
                byGroup[group] = byGroup[group] ? byGroup[group].concat([artifactData]) : [artifactData];
            }

            return byGroup;
        }, {}) || {};

    if (artifactsNotInSteps?.length) {
        const artifactsNotInStepsWithGroup = artifactsNotInSteps.map((artifact) => {
            return {
                ...artifact,
                group: 'Other',
            };
        });
        artifactsByGroup.Other = artifactsByGroup.Other
            ? artifactsByGroup.Other.concat(artifactsNotInStepsWithGroup)
            : artifactsNotInStepsWithGroup;
    }

    return artifactsByGroup;
}

export function getBuildDetailsFeaturedArtifacts(buildResult?: IBuildResult): IArtifact[] | undefined {
    return buildResult?.steps?.reduce((featuredArtifacts: IArtifact[], step: IStep) => {
        const stepFeaturedArtifacts =
            step.artifacts?.filter((artifact) => artifact.featuredIcon || artifact.featuredText) || [];
        return featuredArtifacts.concat(stepFeaturedArtifacts);
    }, []);
}

export function getBuildDetailsMessagesCount(
    buildResult?: IBuildResult,
    buildSettings?: { precompiler_directives: string[] },
): IMessageCount {
    const emptyMessages = { error: 0, warning: 0, info: 0, setting: 0, flag: 0 };

    const messages =
        buildResult?.steps?.reduce((messages, step: IStep) => {
            if (step.messages) {
                for (const message of step.messages) {
                    messages[message.level] += 1;
                }
            }
            return messages;
        }, emptyMessages) || emptyMessages;

    const skippedCount: number = buildResult?.steps?.filter((step) => step.state === StepState.skipped).length || 0;
    const flagsCount = buildSettings?.precompiler_directives?.length || 0;
    return {
        ...messages,
        skipped: skippedCount,
        flag: flagsCount,
    };
}

export function isTabAccordionActive(name: string): boolean {
    const [, activeAccordion] = getActiveTab();
    return activeAccordion === name;
}

function getActiveTab(): [string | undefined, string | undefined] {
    const params = queryString.parse(window.location.search);
    let tab;
    let tabAccordion;
    for (const [key, value] of Object.entries(params)) {
        if (key !== 'share') {
            tab = key;
            tabAccordion = value as string;
        }
    }
    return [tab, tabAccordion];
}

function setUrlParamsFromState(buildDetailsState: IBuildDetailsSlice): void {
    if (
        buildDetailsState.dialog.open &&
        buildDetailsState.dialog.share &&
        buildDetailsState.buildResult?.mainArtifact
    ) {
        const params = queryString.stringify({ share: buildDetailsState.buildResult.mainArtifact.file });

        window.history.replaceState(null, `set share flag`, `?${params}`);
    } else if (buildDetailsState.activeTabAccordion) {
        const params = queryString.stringify({ [buildDetailsState.activeTab]: buildDetailsState.activeTabAccordion });

        window.history.replaceState(null, `set opened tab accordion`, `?${params}`);
    } else {
        window.history.replaceState(null, `set opened tab`, `?${buildDetailsState.activeTab}`);
    }
}
