import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Box, Typography, useTheme } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import Breadcrumbs from '../commons/Breadcrumbs';

interface HeaderSectionProps {
    title: string;
    titleAdornment?: ReactElement;
    subTitle?: string;
    helmetTitle?: string;
    actionBox?: ReactElement;
    children?: ReactElement;
    filters?: ReactElement;
    breadcrumbs?: IBreadcrumbData[];
    isMobile?: boolean;
    headerStyle?: React.CSSProperties;
}

export default function HeaderSection({
    title,
    titleAdornment,
    subTitle,
    helmetTitle,
    actionBox,
    children,
    breadcrumbs,
    headerStyle,
    filters,
}: HeaderSectionProps): ReactElement {
    const theme = useTheme();

    return (
        <>
            <Helmet title={helmetTitle || title} />
            <Container
                maxWidth={false}
                style={{
                    flexDirection: 'column',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                    backgroundColor: theme.palette.grey[100],
                    ...headerStyle,
                }}
            >
                <Box sx={{ mx: 1 }}>
                    <BreadCrumbs breadcrumbs={breadcrumbs} />
                    <Box
                        style={{
                            width: '100%',
                            minHeight: 42,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '50px' }}>
                            <Box display='flex' alignItems='center'>
                                <Typography variant='h4' style={{ fontWeight: 600 }}>
                                    {title}
                                </Typography>
                                {titleAdornment}
                            </Box>
                            {subTitle && (
                                <Typography variant='body1' style={{ color: blueGray[600] }}>
                                    {subTitle}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{actionBox}</Box>
                    </Box>
                    <Box sx={{ width: '100%', display: { xs: 'flex', md: 'none' } }}>
                        {actionBox && <Box style={{ paddingTop: 8, width: '100%' }}>{actionBox}</Box>}
                    </Box>
                    {filters && <Box>{filters}</Box>}
                </Box>
                {children}
            </Container>
        </>
    );
}

export interface IBreadcrumbData {
    text: string;
    href?: string;
}

function BreadCrumbs({ breadcrumbs = [] }: { breadcrumbs?: IBreadcrumbData[] }): ReactElement {
    if (!breadcrumbs?.length) {
        return <></>;
    }

    const links = breadcrumbs.slice(0, breadcrumbs.length - 1);
    const lastItem = breadcrumbs[breadcrumbs.length - 1];

    return (
        <Breadcrumbs sx={{ mb: 2 }}>
            {links.map(({ text, href }: IBreadcrumbData) => {
                if (!href) {
                    <React.Fragment key={text} />;
                }

                return (
                    <Typography
                        key={text}
                        color='inherit'
                        component={RouterLink}
                        to={href || ''}
                        sx={{ color: (theme) => theme.palette.primary.main }}
                    >
                        {text}
                    </Typography>
                );
            })}

            <Typography color='textSecondary'>{lastItem.text}</Typography>
        </Breadcrumbs>
    );
}
