import { ReactElement, useState } from 'react';
import { Chip, Divider, Grid, TableCell, Typography } from '@mui/material';
import { MuiAlignSetting } from '../../typings/material-ui';
import { DataValueBox, IDataValueBoxProps } from '../commons/DataValueBox';
import ExtraInfoPopover from '../commons/ExtraInfoPopover';
import PlatformIcon from '../commons/PlatformIcon';
import { IScheduleBuild } from './interfaces';

interface PlatformCellProps {
    scheduledBuild: IScheduleBuild;
    maxTags?: number;
    align?: MuiAlignSetting;
}

export default function PlatformCell({ scheduledBuild, maxTags = 4, align }: PlatformCellProps): ReactElement {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorElement);

    const shownBuilds = scheduledBuild?.buildConfigurations.slice(0, maxTags);
    const hiddenBuilds = scheduledBuild?.buildConfigurations.slice(maxTags);

    return (
        <TableCell
            align={align}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            onMouseEnter={(event) => setAnchorElement(event.currentTarget)}
            onMouseLeave={() => setAnchorElement(null)}
        >
            <Grid container spacing={1}>
                {shownBuilds.map((build, index) => {
                    return (
                        <Grid item key={index}>
                            <Chip
                                icon={
                                    <PlatformIcon
                                        platform={build.platform}
                                        useMobile
                                        disableDisplayBehavior
                                        sx={{ marginLeft: '4px', marginRight: '-6px' }}
                                    />
                                }
                                label={build.type}
                                variant='outlined'
                                size='medium'
                            />
                        </Grid>
                    );
                })}
                {Boolean(hiddenBuilds.length) && (
                    <Grid item key='hidden-tag'>
                        <Chip label={`+${hiddenBuilds.length}`} variant='outlined' size='medium' />
                    </Grid>
                )}
            </Grid>

            <ExtraInfoPopover
                id='mouse-over-popover'
                open={open}
                anchorEl={anchorElement}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorElement(null)}
                disableRestoreFocus
                disableScrollLock
                sx={{
                    minWidth: 350,
                    maxWidth: 460,
                }}
            >
                <Typography variant='subtitle2' style={{ fontWeight: 600 }}>
                    Build Details
                </Typography>
                <DataValueBoxWrapper label='Game Name' value={scheduledBuild.project.name} />
                <DataValueBoxWrapper label='Branch' value={scheduledBuild.branch} />
                <Divider style={{ marginTop: '8px' }} />
                <Typography variant='subtitle2' style={{ fontWeight: 600, marginTop: '8px' }}>
                    Platforms
                </Typography>
                <Grid container spacing={1} style={{ marginTop: '8px' }}>
                    {scheduledBuild?.buildConfigurations.map((build, index) => (
                        <Grid item key={index}>
                            <Chip
                                icon={
                                    <PlatformIcon
                                        platform={build.platform}
                                        useMobile
                                        disableDisplayBehavior
                                        sx={{ marginLeft: '4px', marginRight: '-6px' }}
                                    />
                                }
                                label={build.type}
                                variant='outlined'
                                size='medium'
                            />
                        </Grid>
                    ))}
                </Grid>
            </ExtraInfoPopover>
        </TableCell>
    );
}

function DataValueBoxWrapper(props: IDataValueBoxProps): ReactElement | null {
    return <DataValueBox {...props} sx={{ mt: 1 }} valueSx={{ ...props.valueSx, mt: 1, fontWeight: 700 }} />;
}
