import { ReactElement } from 'react';
import Moment from 'react-moment';
import moment from 'moment';

export default function Duration({
    started,
    completed,
}: {
    started?: Date | string;
    completed?: Date | string;
}): ReactElement | null {
    if (!started) {
        return <span style={{ display: 'block' }}>-</span>;
    }

    const durationTitle = `${moment(started).format('DD/MM/YYYY, HH:mm:ss')}${completed ? '  -  ' + moment(completed).format('DD/MM/YYYY, HH:mm:ss') : ''}`;

    return (
        <span
            style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            title={durationTitle}
        >
            {completed ? (
                <Moment duration={started} date={completed} trim />
            ) : (
                <Moment date={started} durationFromNow interval={1000} />
            )}
        </span>
    );
}
