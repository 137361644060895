import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getAccessTokenFromCache } from '@tactileentertainment/core-shared.auth-react';
import * as queryString from './components/commons/queryString';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3500';

interface IApiError {
    status: number;
    message: string;
    type: string;
}

export function createServerUrl(url: string, params?: string | Record<string, string>): string {
    const serverUrl = new URL(url, API_URL);

    if (params) {
        serverUrl.search = typeof params === 'string' ? params : queryString.stringify(params);
    }

    return serverUrl.toString();
}

export function createSharableServerUrl(url: string, params?: Record<string, string>): string {
    const serverUrl = new URL(url, API_URL);

    const queryParams = {
        ...(params || {}),
        apiToken: getAccessTokenFromCache(),
    };

    serverUrl.search = queryString.stringify(queryParams);

    return serverUrl.toString();
}

export async function getRequest(
    url: string,
    params?: string | Record<string, any> | undefined,
    thunkAPI?: { rejectWithValue(value: any): any },
    json = true,
): Promise<any> {
    const getUrl = createServerUrl(url, params);

    const response = await authorizedFetch(getUrl, {
        method: 'GET',
        headers: json ? { 'Content-Type': 'application/json' } : {},
    });

    if (response.status === 200) {
        return json ? response.json() : response.text();
    }

    if (thunkAPI) {
        return thunkAPI.rejectWithValue(await response.json());
    }

    console.error(await response.json());
    return json ? {} : '';
}

export async function getTextRequest(
    url: string,
    params?: string | Record<string, string> | undefined,
    thunkAPI?: { rejectWithValue(value: any): any },
): Promise<any> {
    return getRequest(url, params, thunkAPI, false);
}

export async function postRequest(
    url: string,
    data?: Record<string, any>,
    thunkAPI?: { rejectWithValue(value: any): any },
): Promise<any> {
    const postUrl = createServerUrl(url);

    const response = await authorizedFetch(postUrl.toString(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 200) {
        return response.json();
    }

    if (thunkAPI) {
        return thunkAPI.rejectWithValue(await response.json());
    }

    console.error(await response.json());
    return {};
}

export async function deleteRequest(url: string, thunkAPI?: { rejectWithValue(value: any): any }): Promise<any> {
    const postUrl = createServerUrl(url);

    const response = await authorizedFetch(postUrl.toString(), {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 200) {
        return response.json();
    }

    if (thunkAPI) {
        return thunkAPI.rejectWithValue(await response.json());
    }

    console.error(await response.json());
    return {};
}

export async function patchRequest(
    url: string,
    data?: Record<string, any>,
    thunkAPI?: { rejectWithValue(value: any): any },
): Promise<any> {
    const postUrl = createServerUrl(url);

    const response = await authorizedFetch(postUrl.toString(), {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 200) {
        return response.json();
    }

    if (thunkAPI) {
        return thunkAPI.rejectWithValue(await response.json());
    }

    console.error(await response.json());
    return {};
}

export function apiBaseQuery(): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> {
    const baseQuery = fetchBaseQuery({ baseUrl: API_URL });
    const fetch = async (
        args: FetchArgs,
        api: BaseQueryApi,
        extraOptions: any,
    ): Promise<QueryReturnValue<IApiError, FetchBaseQueryError, any>> => {
        try {
            return (await baseQuery(args, api, extraOptions)) as QueryReturnValue<any, any, any>;
        } catch (err) {
            return { error: { status: 500, data: err } };
        }
    };

    return async (args: FetchArgs, api: BaseQueryApi, extraOptions: any) => {
        const token = getAccessTokenFromCache();
        const queryArgs: FetchArgs = {
            ...args,
            credentials: includeCookiesAuthorization(args.url) ? 'include' : 'omit',
            headers: {
                ...(args.headers || {}),
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
        };

        const result: any = await fetch(queryArgs, api, extraOptions);

        if (result.error) {
            console.error(result.error);
        }

        return result;
    };
}

async function authorizedFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    const token = getAccessTokenFromCache();
    const response = await fetch(input, {
        ...(init || {}),
        headers: {
            ...(init?.headers || {}),
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
    });

    if (response.status === 401) {
        window.location.reload();
    }

    return response;
}

function includeCookiesAuthorization(url: string): boolean {
    return /\/tests\/[^/]+\/artifact\/[^/]+/.test(url);
}
