import { ReactElement } from 'react';
import { Cron } from 'react-js-cron';
import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    Box,
    DialogContent,
    Button,
    Divider,
    Typography,
    CircularProgress,
    TextField,
    useTheme,
} from '@mui/material';
import { useGetProjectBranchesQuery } from '../../buildAgents/buildAgentsApi';
import { CodeEditor } from '../../commons/codeEditor/CodeEditor';
import { useGetProjectsQuery } from '../../projects/projectsApi';
import { IUpdateBuildSchedule } from '../interfaces';

interface ScheduledBuildContentProps {
    buildSchedule: IUpdateBuildSchedule;
    buildConfigurations: string;
    invalidConfig: boolean;
    onChange: (newValue: Partial<IUpdateBuildSchedule>) => void;
    onConfigChange: (newConfig: string) => void;
}

export const defaultCronPeriod = '* * * * *';
const defaultBranch = 'trunk';

export default function ScheduledBuildContent({
    buildSchedule,
    buildConfigurations,
    invalidConfig,
    onChange,
    onConfigChange,
}: ScheduledBuildContentProps): ReactElement {
    const { project: projectId, branch, description, cron } = buildSchedule;
    const { data: projects = [], isFetching: fetchingProjects } = useGetProjectsQuery();
    const projectName = projects?.find((project) => project._id === projectId)?.name || '';
    const theme = useTheme();

    const { data: branchOptions = [], isFetching: fetchingBranches } = useGetProjectBranchesQuery(
        { projectName },
        { skip: !projectId || !projectName },
    );

    return (
        <>
            <DialogContent sx={{ pb: 3, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <Autocomplete
                        data-testid='select-project'
                        sx={{ width: '320px', mr: '16px' }}
                        id='project-selector'
                        size='small'
                        getOptionLabel={(option) => option?.name || ''}
                        options={projects}
                        autoHighlight
                        clearOnBlur
                        clearOnEscape
                        disabled={fetchingProjects}
                        value={projects.find((item) => item._id === projectId) || null}
                        popupIcon={fetchingProjects ? <CircularProgress size={20} /> : undefined}
                        onChange={(_, newProject) => {
                            const branch = newProject ? defaultBranch : '';
                            onChange({ project: newProject?._id || '', branch });
                        }}
                        renderInput={(params) => <TextField {...params} required label='Project' variant='outlined' />}
                    />
                    <Autocomplete
                        data-testid='select-branch'
                        sx={{ width: '320px' }}
                        size='small'
                        options={branchOptions}
                        id='branch-selector'
                        autoHighlight
                        clearOnBlur
                        clearOnEscape
                        disabled={!projectId || fetchingBranches || branchOptions.length === 0}
                        value={branch}
                        popupIcon={fetchingBranches ? <CircularProgress size={20} /> : undefined}
                        onChange={(_, newBranch) => onChange({ branch: newBranch || '' })}
                        renderInput={(params) => <TextField {...params} required label='Branch' variant='outlined' />}
                    />
                </Box>
                <Box sx={{ display: 'flex', marginTop: '24px' }}>
                    <TextField
                        value={description || ''}
                        onChange={(e) => onChange({ description: e.target.value || '' })}
                        required
                        size='small'
                        label='Description'
                        type='text'
                        variant='outlined'
                        sx={{ width: '100%' }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', sm: 'row' },
                        marginTop: '24px',
                    }}
                >
                    <Box sx={{ width: { xs: '100%', sm: 'fit-content' } }}>
                        <Cron
                            value={cron}
                            setValue={(newCron: string) => onChange({ cron: newCron })}
                            className='tactile-cron'
                            clearButton={false}
                        />
                    </Box>
                    <Button
                        sx={{ marginBottom: '10px', marginLeft: '4px', minWidth: '90px' }}
                        color='inherit'
                        onClick={() => onChange({ cron: defaultCronPeriod })}
                    >
                        <CloseIcon sx={{ marginRight: '8px', fontSize: '20px' }} />
                        Reset
                    </Button>
                </Box>
            </DialogContent>
            <Divider />
            <DialogContent sx={{ py: 3, px: 4 }}>
                <Typography variant='h6'>Configure Build with JSON</Typography>
                <CodeEditor
                    language='json'
                    value={buildConfigurations}
                    onChange={(newConfig) => onConfigChange(newConfig)}
                    style={{
                        height: '350px',
                        marginTop: '8px',
                        ...(invalidConfig && { borderColor: theme.palette.tactile.red[500] }),
                    }}
                />
            </DialogContent>
        </>
    );
}
