import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceStatus } from '../commons/enums';
import { IHeaderSlice } from './interfaces';
import { getInitialMenuItem } from './menuItems';

const initialState: IHeaderSlice = {
    drawerOpen: false,
    status: SliceStatus.idle,
    selectedMenu: getInitialMenuItem()?.to
        ? { to: getInitialMenuItem()?.to || '', text: getInitialMenuItem()?.text || '' }
        : undefined,
};

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setDrawerOpen: (state: any, action) => {
            state.drawerOpen = action.payload;
        },
        setSelectedMenu: (state: IHeaderSlice, action: PayloadAction<{ to: string; text: string } | undefined>) => {
            state.selectedMenu = action.payload;
        },
    },
});

export const selectDrawerOpen = (state: { header: IHeaderSlice }): boolean => state.header.drawerOpen;
export const selectAppBarStatus = (state: { header: IHeaderSlice }): string => state.header.status;
export const selectSelectedMenu = (state: { header: IHeaderSlice }): { to: string; text: string } | undefined =>
    state.header.selectedMenu;

export const { setDrawerOpen, setSelectedMenu } = headerSlice.actions;
export default headerSlice.reducer;
