import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import ViewColumnIcon from '@mui/icons-material/MoreVert';
import { Button, Checkbox, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { setAgentsColumnVisibility } from '../buildAgents/buildAgentsSlice';
import { AgentsColumnId } from '../buildAgents/enums';
import { setBuildsColumnVisibility } from '../builds/buildsSlice';
import { BuildsColumnId } from '../builds/enums';
import { TestsColumnId } from '../tests/testsColumns';
import { setTestsColumnVisibility } from '../tests/testsSlice';
import ExpandIcon from './ExpandIcon';
import { IToggleColumn } from './interfaces';

type ColumnId = BuildsColumnId | AgentsColumnId | TestsColumnId;
type Target = 'builds' | 'agents' | 'tests';

interface ColumnToggleProps {
    columns: Array<IToggleColumn<ColumnId>>;
    target: Target;
    mode?: 'icon' | 'button';
}

export function ColumnToggle({ columns, target, mode = 'icon' }: ColumnToggleProps): ReactElement {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (columnId: BuildsColumnId | AgentsColumnId | TestsColumnId, value: boolean) => () => {
        const payload = { columnId, value };
        switch (target) {
            case 'builds':
                dispatch(setBuildsColumnVisibility(payload as { columnId: BuildsColumnId; value: boolean }));
                break;
            case 'agents':
                dispatch(setAgentsColumnVisibility(payload as { columnId: AgentsColumnId; value: boolean }));
                break;
            case 'tests':
                dispatch(setTestsColumnVisibility(payload as { columnId: TestsColumnId; value: boolean }));
                break;
        }
    };

    return (
        <>
            {mode === 'icon' ? (
                <IconButton
                    sx={{
                        color: (theme) => theme.palette.common.white,
                        margin: 0.5,
                        padding: 0.5,
                    }}
                    onClick={handleClick}
                    title='Show Column Selector'
                >
                    <ViewColumnIcon />
                </IconButton>
            ) : (
                <Button
                    variant='outlined'
                    size='large'
                    color='secondary'
                    aria-controls={open ? 'columns-dropdown-list' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        justifyContent: 'space-between',
                        width: { xs: '100%', md: 'auto' },
                        m: 0,
                    }}
                    endIcon={<ExpandIcon expanded={open} />}
                    data-testid='account-devices-dropdown'
                >
                    Columns Filter
                </Button>
            )}
            <Popover
                id={!anchorEl ? undefined : 'columns-dropdown-list'}
                onClose={handleClose}
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ sx: { width: { xs: '100%', sm: 'auto' } } }}
            >
                <List sx={{ maxHeight: { xs: 350, sm: 500 } }}>
                    {columns.map((column, index) => {
                        return (
                            <ListItem
                                dense={true}
                                key={index}
                                button
                                disabled={column.disabled}
                                onClick={handleToggle(column.id, !column.visible)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge='start'
                                        checked={column.visible}
                                        tabIndex={-1}
                                        disableRipple
                                        disabled={column.disabled}
                                        inputProps={{ 'aria-labelledby': column.label }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={column.label} />
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </>
    );
}
