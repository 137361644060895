import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { postRequest } from '../../api';
import { IBuildOrder } from '../newBuild/interfaces';
import { IUserPreferences } from '../userProfile/interfaces';
import { BuildDialogActionType } from './enums';
import { IActionConfirmationDialog, IBuild, ICommonsSlice, ICommonsSliceState } from './interfaces';

export const cancelBuild = createAsyncThunk<Promise<any>, IBuild>('builds/cancelBuild', async (build, thunkAPI) => {
    return postRequest(`/builds/${build._id}/cancel`, undefined, thunkAPI);
});

export const reorderBuild = createAsyncThunk<any, { build: IBuild; userPreferences?: IUserPreferences }>(
    'builds/reorderBuild',
    async ({ build, userPreferences }, thunkAPI) => {
        const newBuild: IBuildOrder = {
            projectName: build.projectName,
            branch: build.branch,
            revision: build.revision,
            buildConfigId: build.buildConfigId,
            buildConfigFileName: build.buildConfigFileName,
            expedited: build.expedited,
            type: build.type,
            platform: build.platform,
            subscribeToResult: userPreferences?.subscribeToResult,
            jiraTasks: build.jiraTasks,
        };

        if (build.settings) {
            newBuild.settings = build.settings;
        }

        if (build.versionName) {
            newBuild.versionName = build.versionName;
        }

        return postRequest(`/builds`, [newBuild], thunkAPI);
    },
);

const initialState: ICommonsSlice = {
    actionConfirmationDialog: {
        show: false,
    },
};

export const commonsSlice = createSlice({
    name: 'commons',
    initialState,
    reducers: {
        toggleShowActionConfirmationDialog: (
            state,
            action: PayloadAction<{ build?: IBuild; action?: BuildDialogActionType }>,
        ) => {
            state.actionConfirmationDialog.show = !state.actionConfirmationDialog.show;
            state.actionConfirmationDialog.build = action.payload?.build;
            state.actionConfirmationDialog.action = action.payload?.action;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(reorderBuild.rejected, (state, action: any) => {
                state.actionConfirmationDialog.error = action.payload?.error?.message;
            })
            .addCase(cancelBuild.rejected, (state, action: any) => {
                state.actionConfirmationDialog.error = action.payload?.error?.message;
            });
    },
});

export const selectActionConfirmationDialog = (state: ICommonsSliceState): IActionConfirmationDialog =>
    state.commons.actionConfirmationDialog;

export const { toggleShowActionConfirmationDialog } = commonsSlice.actions;

export default commonsSlice.reducer;
