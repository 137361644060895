import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import * as queryString from '../commons/queryString';
import { IGameModuleResponse, IModule, IProjectGameModules } from './interfaces';

export const gameModulesApi = createApi({
    reducerPath: 'gameModulesApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        compareGameModules: builder.query<
            IGameModuleResponse,
            { projectA: string; branchA?: string; projectB: string; branchB?: string }
        >({
            query: ({ projectA, branchA, projectB, branchB }) => ({
                url: `/modules/compare-modules?projectA=${projectA}&projectB=${projectB}${branchA ? `&branchA=${branchA}` : ''}${branchB ? `&branchB=${branchB}` : ''}`,
            }),
            transformResponse: (response: IGameModuleResponse): IGameModuleResponse => response,
        }),
        getGameModules: builder.query<IGameModuleResponse, { includeFields: string[] }>({
            query: ({ includeFields }) => ({
                url: `/modules?${queryString.stringify({ includeFields })}`,
            }),
            transformResponse: (response: IGameModuleResponse): IGameModuleResponse => response,
        }),
        getGameModulesByProject: builder.query<IProjectGameModules, { projectName: string }>({
            query: ({ projectName }) => ({
                url: `/modules/projects/${projectName}`,
            }),
            transformResponse: (response: { modules: IProjectGameModules }): IProjectGameModules => response.modules,
        }),
        getGameModule: builder.query<IModule, { moduleName: string }>({
            query: ({ moduleName }) => ({
                url: `/modules/${moduleName}`,
            }),
            transformResponse: (response: IModule): IModule => response,
        }),
        updateManyModules: builder.mutation<void, Array<{ moduleName?: string; data: Partial<IModule> }>>({
            query: (payload) => ({
                method: 'PATCH',
                url: '/modules',
                body: payload,
            }),
        }),
    }),
});

export const {
    useCompareGameModulesQuery,
    useGetGameModulesQuery,
    useGetGameModulesByProjectQuery,
    useGetGameModuleQuery,
    useUpdateManyModulesMutation,
} = gameModulesApi;
