import { ReactElement, useMemo } from 'react';
import { Box, Chip, Skeleton, Typography } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { SelectedFilter, FilterOption } from './interfaces';

interface SelectedFiltersProps {
    name: string;
    options: FilterOption[];
    selected?: SelectedFilter;
    loading?: boolean;
    onDelete?: (value: string[] | null) => void;
}

export default function SelectedFilters({
    name,
    options = [],
    selected = [],
    loading,
    onDelete,
}: SelectedFiltersProps): ReactElement {
    const selectedValues = useMemo(() => (selected || [])?.map((item) => String(item)), [selected]);

    if (!selected || !selected.length) {
        return <></>;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: 'fit-content',
                padding: '0px 12px 4px 12px',
                background: blueGray[300],
                borderRadius: '16px',
                mr: 1,
                mb: 1,
            }}
        >
            <Typography variant='caption' sx={{ fontWeight: 500, color: blueGray[800], mt: '4px' }}>
                {name}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {selectedValues.map((item) => {
                    const option = options.find((option: FilterOption) => String(option.value) === item);

                    if (loading) {
                        return (
                            <Skeleton
                                key={item}
                                variant='rectangular'
                                sx={{ width: 100, height: 24, borderRadius: '16px', ml: 1, mt: '4px' }}
                            />
                        );
                    }

                    return (
                        <Chip
                            size='small'
                            color='secondary'
                            variant='outlined'
                            key={item}
                            label={option?.label || item}
                            sx={{
                                ml: 1,
                                mt: '4px',
                                fontSize: '12px',
                                background: (theme) => theme.palette.common.white,
                                borderColor: (theme) => theme.palette.common.white,
                                color: blueGray[800],
                            }}
                            onDelete={() => {
                                const newValue = selectedValues.filter((value) => value !== item);
                                onDelete?.(newValue?.length > 0 ? newValue : null);
                            }}
                        />
                    );
                })}
            </Box>
        </Box>
    );
}
