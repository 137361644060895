import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import TestNumberLink from '../TestNumberLink';

export default function TestNumberCell({
    align,
    projectName,
    testNumber,
}: {
    align?: MuiAlignSetting;
    projectName?: string;
    testNumber?: number;
}): ReactElement {
    return (
        <TableCell align={align} sx={{ p: 1 }} id='table-cell-test-number' data-testid='tests-table-test-number'>
            {projectName && testNumber && <TestNumberLink projectName={projectName} testNumber={testNumber} />}
        </TableCell>
    );
}
