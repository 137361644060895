import React, { ReactElement } from 'react';
import { Box, TextField } from '@mui/material';

interface DeleteOptionsProps {
    namespace?: string;
    hasKey?: boolean;
    onChangeKey: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeNamespace: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function DeleteOptions({
    namespace,
    hasKey,
    onChangeKey,
    onChangeNamespace,
}: DeleteOptionsProps): ReactElement {
    return (
        <Box style={{ marginTop: 10 }}>
            <TextField
                type='text'
                variant='outlined'
                autoComplete='off'
                label='Namespace'
                placeholder='2020.3.30f1_Example_Namespace'
                value={namespace}
                onChange={onChangeNamespace}
            />
            {hasKey ? (
                <TextField
                    style={{ marginLeft: 10 }}
                    type='text'
                    variant='outlined'
                    autoComplete='off'
                    label='Key to delete'
                    placeholder='8d71d5006046bb9d5e4a7172eec3f3b2'
                    onChange={onChangeKey}
                />
            ) : null}
        </Box>
    );
}
