import { IToggleColumn } from '../commons/interfaces';
import { getInitialColumnVisibilityState } from '../commons/toggleableColumnsState';

export enum TestsColumnId {
    testNumber = 'testNumber',
    projectName = 'projectName',
    status = 'status',
    testBranch = 'testBranch',
    buildBranch = 'buildBranch',
    buildNumber = 'buildNumber',
    version = 'version',
    platform = 'platform',
    buildType = 'buildType',
    testDevice = 'testDevice',
    runtime = 'runtime',
    author = 'author',
    actions = 'actions',
}

const defaultColumnVisibility = {
    [TestsColumnId.projectName]: true,
    [TestsColumnId.status]: true,
    [TestsColumnId.testBranch]: true,
    [TestsColumnId.buildBranch]: true,
    [TestsColumnId.buildNumber]: true,
    [TestsColumnId.version]: true,
    [TestsColumnId.platform]: true,
    [TestsColumnId.buildType]: true,
    [TestsColumnId.testDevice]: true,
    [TestsColumnId.runtime]: true,
    [TestsColumnId.author]: true,
    [TestsColumnId.actions]: true,
};

export const COOKIE_NAME = 'testsVisibleColumns';
const columnInitialVisibleState = getInitialColumnVisibilityState(COOKIE_NAME, defaultColumnVisibility);
export const columns: Array<IToggleColumn<TestsColumnId>> = [
    {
        id: TestsColumnId.status,
        label: 'Status',
        align: 'left',
        width: '10%',
        visible: columnInitialVisibleState[TestsColumnId.status],
    },
    {
        id: TestsColumnId.projectName,
        label: 'Project / Test ID',
        align: 'left',
        width: '8%',
        visible: columnInitialVisibleState[TestsColumnId.projectName],
        disabled: true,
    },
    {
        id: TestsColumnId.testBranch,
        label: 'Test Branch',
        align: 'left',
        width: '8%',
        visible: columnInitialVisibleState[TestsColumnId.testBranch],
    },
    {
        id: TestsColumnId.buildBranch,
        label: 'Build Branch',
        width: '8%',
        visible: columnInitialVisibleState[TestsColumnId.buildBranch],
    },
    {
        id: TestsColumnId.buildType,
        label: 'Build Type',
        align: 'left',
        width: '7%',
        visible: columnInitialVisibleState[TestsColumnId.buildType],
    },
    {
        id: TestsColumnId.buildNumber,
        label: 'Build ID',
        width: '4%',
        align: 'center',
        visible: columnInitialVisibleState[TestsColumnId.buildNumber],
    },
    {
        id: TestsColumnId.version,
        label: 'Version',
        width: '6%',
        align: 'center',
        visible: columnInitialVisibleState[TestsColumnId.version],
    },
    {
        id: TestsColumnId.platform,
        label: 'Platform',
        width: '6%',
        align: 'center',
        visible: columnInitialVisibleState[TestsColumnId.platform],
    },
    {
        id: TestsColumnId.testDevice,
        label: 'Device',
        width: '10%',
        align: 'left',
        visible: columnInitialVisibleState[TestsColumnId.testDevice],
    },
    {
        id: TestsColumnId.runtime,
        label: 'Duration / Started',
        width: '8%',
        visible: columnInitialVisibleState[TestsColumnId.runtime],
    },
    {
        id: TestsColumnId.author,
        label: 'User',
        align: 'center',
        width: '5%',
        visible: columnInitialVisibleState[TestsColumnId.author],
    },
    {
        id: TestsColumnId.actions,
        label: 'Actions',
        align: 'center',
        width: '5%',
        visible: columnInitialVisibleState[TestsColumnId.actions],
    },
];
