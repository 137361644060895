import { ReactElement, useMemo, useState } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import AodIcon from '@mui/icons-material/Aod';
import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, Typography } from '@mui/material';
import { preventAndStopClick } from '../buildDetails/buildDetailsSlice';
import { useGetTestDevicesQuery } from '../testDevices/testDevicesApi';
import BuildTestResultsMatrixRow from './BuildTestResultsMatrixRow';
import { useGetTestResultsMatrixQuery } from './testsApi';

export default function BuildTestResultsMatrix({ buildId }: { buildId: string }): ReactElement | null {
    const [expanded, setExpanded] = useState<boolean>(false);
    const query = useMemo(() => ({ build: { _id: [buildId] } }), [buildId]);
    const resultsMatrix = useGetTestResultsMatrixQuery(query);
    const devicesResponse = useGetTestDevicesQuery();

    const testCases = resultsMatrix.data || [];
    const devices = devicesResponse.data || [];
    if (!testCases.length) {
        return null;
    }

    return (
        <Box sx={{ width: '100%', padding: 1, borderRadius: (theme) => theme.shape.borderRadius }}>
            <Accordion
                expanded={expanded}
                elevation={0}
                sx={{ backgroundColor: (theme) => theme.palette.common.white }}
            >
                <AccordionSummary
                    sx={{
                        '& > .MuiAccordionSummary-content': {
                            margin: '4px 0',
                        },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: (theme) => theme.palette.text.secondary,
                                p: 1,
                                pl: 0,
                            }}
                        >
                            <AodIcon />
                        </Box>
                        <Box>
                            <Typography variant='body2'>Device Results</Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, overflowX: 'auto' }} onClick={preventAndStopClick}>
                    <Table>
                        <TableBody
                            sx={{
                                borderTop: '1px solid',
                                borderColor: (theme) => theme.palette.grey[200],
                            }}
                        >
                            {testCases.map((testCase) => (
                                <BuildTestResultsMatrixRow
                                    key={testCase.testName}
                                    testCase={testCase}
                                    devices={devices}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
