import React, { ReactElement } from 'react';
import { SxProps } from '@mui/material';
import CustomLink from '../commons/CustomLink';

interface BuildNumberLinkProps {
    projectName: string;
    buildNumber: string;
    newTab?: boolean;
    sx?: SxProps;
}

export default function BuildNumberLink({
    projectName,
    buildNumber,
    newTab,
    sx = {},
}: BuildNumberLinkProps): ReactElement {
    return (
        <CustomLink
            sx={{ ...sx }}
            to={`/builds/${projectName}/${buildNumber}`}
            target={newTab ? '_blank' : undefined}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
            onMouseUp={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
            data-testid='build-number-link'
        >
            {`#${buildNumber}`}
        </CustomLink>
    );
}
