import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, useMediaQuery, capitalize } from '@mui/material';
import Breadcrumbs from '../../commons/Breadcrumbs';
import { getAcronym } from '../../commons/getAcronym';
import { ITest } from '../interfaces';

export default function TestBreadcrumbs({ test, tab }: { test?: ITest; tab?: string }): ReactElement | null {
    const isMobile = useMediaQuery('(max-width: 1280px)');

    return (
        <Breadcrumbs sx={{ mb: { xs: 2, sm: 0 } }}>
            <Link color='inherit' component={RouterLink} to='/tests'>
                Tests
            </Link>
            {test && (
                <Link color='inherit' component={RouterLink} to={`/tests?projectName=${test.projectName}`}>
                    {isMobile ? getAcronym(test.projectName) : test.projectName}
                </Link>
            )}
            {test && test.testBranch && (
                <Link
                    color='inherit'
                    component={RouterLink}
                    to={`/tests?projectName=${test.projectName}&testBranch=${test.testBranch}`}
                >
                    {test.testBranch}
                </Link>
            )}
            {test && tab && (
                <Link color='inherit' component={RouterLink} to={`/tests/${test.projectName}/${test.testNumber}`}>
                    <Typography
                        component='span'
                        sx={{
                            margin: (theme) => theme.spacing(0, 1, 0, 0.5),
                            color: (theme) => theme.palette.text.secondary,
                        }}
                    >
                        #{test.testNumber}
                    </Typography>
                </Link>
            )}
            {test && !tab && (
                <Typography color='textPrimary'>
                    <Typography
                        component='span'
                        sx={{
                            margin: (theme) => theme.spacing(0, 1, 0, 0.5),
                            color: (theme) => theme.palette.text.secondary,
                        }}
                    >
                        #{test.testNumber}
                    </Typography>
                </Typography>
            )}
            {tab && (
                <Typography color='textPrimary'>
                    <span>{capitalize(tab)}</span>
                </Typography>
            )}
        </Breadcrumbs>
    );
}
