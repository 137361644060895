import { ReactElement } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';

export interface ISelectTagsProps {
    tags: string[];
    selectedTags: string[];
    onTagsChange: (tags: string[]) => void;
}

export default function SelectTags({ tags, selectedTags, onTagsChange }: ISelectTagsProps): ReactElement {
    return (
        <Autocomplete
            options={tags}
            autoHighlight
            clearOnBlur
            clearOnEscape
            multiple
            value={selectedTags}
            onChange={(_event: unknown, newValue) => {
                onTagsChange(newValue || []);
            }}
            renderInput={(params) => (
                <TextField {...params} variant='outlined' size='small' label='Select based on Tags' />
            )}
            renderTags={(values, props) =>
                values.map((value, i) => <Chip {...props({ index: i })} key={i} label={value} style={{ height: 22 }} />)
            }
            style={{ maxWidth: 500, flexGrow: 2 }}
        />
    );
}
