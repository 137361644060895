import { ReactElement } from 'react';
import { Construction as MaintenanceIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { Typography } from 'antd';

export default function RebootCard({ hostname }: { hostname?: string }): ReactElement {
    return (
        <Box
            border={`1px solid ${blueGray[100]}`}
            borderRadius={2}
            p={1}
            height={300}
            minWidth={330}
            bgcolor={blueGray[100]}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <IconButton
                size='small'
                title='Build Agent Maintenance'
                aria-label='download'
                sx={{ color: blueGray[500], justifyContent: 'center' }}
            >
                <MaintenanceIcon />
            </IconButton>
            <Typography
                style={{ color: blueGray[500], textAlign: 'center' }}
            >{`Rebooting will restart ${hostname || 'the machine'} terminating all processes off all hosted agents`}</Typography>
        </Box>
    );
}
