import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { IProject, IProjectDraft } from './interfaces';

export const projectsApi = createApi({
    reducerPath: 'projectsApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getProjects: builder.query<IProject[], void>({
            query: () => ({
                url: '/projects',
            }),
            transformResponse: (response: { allProjects: IProject[] }): IProject[] => response.allProjects,
        }),
        getProjectNames: builder.query<string[], void>({
            query: () => ({
                url: '/projects/names',
            }),
            transformResponse: (response: { projects: string[] }): string[] => response.projects,
        }),
        getProject: builder.query<IProject, { projectId: string }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}`,
            }),
            transformResponse: (response: { project: IProject }): IProject => response.project,
        }),
        addProject: builder.mutation<IProject, { projectDraft: IProjectDraft }>({
            query: ({ projectDraft }) => ({
                method: 'POST',
                url: `/projects`,
                body: projectDraft,
            }),
            transformResponse: (response: { project: IProject }): IProject => response.project,
        }),
        updateProject: builder.mutation<IProject, { projectDraft: Partial<IProjectDraft> }>({
            query: ({ projectDraft }) => ({
                method: 'PATCH',
                url: `/projects/${projectDraft._id}`,
                body: projectDraft,
            }),
            transformResponse: (response: { project: IProject }): IProject => response.project,
        }),
        deleteProject: builder.mutation<void, { projectId: string }>({
            query: ({ projectId }) => ({
                method: 'DELETE',
                url: `/projects/${projectId}`,
            }),
        }),
    }),
});

export const {
    useGetProjectsQuery,
    useGetProjectNamesQuery,
    useGetProjectQuery,
    useAddProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} = projectsApi;
