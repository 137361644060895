import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../typings/material-ui';
import BuildNumberLink from './BuildNumberLink';

export default function BuildNumber({
    align,
    projectName,
    buildNumber,
}: {
    align?: MuiAlignSetting;
    projectName?: string;
    buildNumber?: string;
}): ReactElement {
    return (
        <TableCell align={align} sx={{ padding: 1 }} data-testid='builds-table-build-number'>
            {projectName && buildNumber && <BuildNumberLink projectName={projectName} buildNumber={buildNumber} />}
        </TableCell>
    );
}
