import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { IBuild } from '../commons/interfaces';
import * as queryString from '../commons/queryString';
import {
    ITest,
    ITestBranch,
    ITestCaseConfig,
    ITestFilter,
    ITestFilterOptions,
    ITestProject,
    ITestPayload,
    ITestCaseResults,
} from './interfaces';

const PAGE_SIZE: number = Number(process.env.REACT_APP_BUILDS_PAGE_SIZE || 50);

export const testsApi = createApi({
    reducerPath: 'testsApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getTestDetails: builder.query<ITest, { projectName: string; testNumber: number }>({
            query: (filter: { projectName: string; testNumber: number }) => ({
                url: `tests?${queryString.stringify(filter)}`,
            }),
            transformResponse: (response: { tests: ITest[] }): ITest => response.tests[0],
        }),
        getTests: builder.query<{ tests: ITest[]; totalCount: number }, ITestFilter | undefined>({
            query: (filter?: ITestFilter) => {
                const { page, ...params } = filter || {};
                if (page) {
                    params.skip = PAGE_SIZE * Number(page);
                }
                return {
                    url: `tests?${queryString.stringify(params)}`,
                };
            },
        }),
        getAvailableOptions: builder.query<{ options: ITestFilterOptions }, void>({
            query: () => ({
                url: 'tests/available-options',
                params: {
                    fields: [
                        'author',
                        'testNumber',
                        'testDevice',
                        'testBranch',
                        'projectName',
                        'build[versionName]',
                        'build[branch]',
                        'build[platform]',
                        'build[type]',
                    ],
                },
            }),
        }),
        getArtifactContent: builder.query<string, { testId: string; filename: string }>({
            query: ({ testId, filename }) => ({
                url: `tests/${testId}/artifact/${filename}`,
                responseHandler: 'text',
            }),
        }),
        cancelTest: builder.mutation<void, string>({
            query: (testId) => ({
                method: 'POST',
                url: `tests/${testId}/cancel`,
            }),
        }),
        orderTest: builder.mutation<ITest, ITestPayload>({
            query: (payload) => ({
                method: 'POST',
                url: `tests`,
                body: payload,
            }),
            transformResponse: (response: { test: ITest }): ITest => response.test,
        }),
        getTestProjects: builder.query<ITestProject[], void>({
            query: () => ({
                url: `tests/projects`,
            }),
            transformResponse: (response: { projects: ITestProject[] }): ITestProject[] => response.projects,
        }),
        getTestBranches: builder.query<ITestBranch[], string>({
            query: (projectName: string) => ({
                url: `tests/${projectName}/branches`,
            }),
            transformResponse: (response: { branches: ITestBranch[] }): ITestBranch[] => response.branches,
        }),
        getTestCasesConfig: builder.query<ITestCaseConfig[], { build: IBuild; branchName: string }>({
            query: ({ build, branchName }) => ({
                url: `tests/${build.projectName}/testcases/${branchName}?buildType=${build.type}&platform=${build.platform}`,
            }),
            transformResponse: (response: { testCases: ITestCaseConfig[] }): ITestCaseConfig[] => response.testCases,
        }),
        getTestResultsMatrix: builder.query<ITestCaseResults[], ITestFilter>({
            query: (params: ITestFilter) => ({
                url: `tests/results-per-device?${queryString.stringify(params)}`,
            }),
            transformResponse: (response: { testCases: ITestCaseResults[] }): ITestCaseResults[] => response.testCases,
        }),
    }),
});

export function getBranchName(project: ITestProject, buildBranch: string): string {
    if (!buildBranch) {
        return project.defaultBranch;
    }
    const match = buildBranch.match(project.releaseBranchPattern);
    return match ? match[0] : project.defaultBranch;
}

export const {
    useGetTestsQuery,
    useGetTestDetailsQuery,
    useGetAvailableOptionsQuery,
    useGetArtifactContentQuery,
    useCancelTestMutation,
    useOrderTestMutation,
    useGetTestProjectsQuery,
    useGetTestBranchesQuery,
    useGetTestCasesConfigQuery,
    useGetTestResultsMatrixQuery,
} = testsApi;
