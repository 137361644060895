import { ReactElement } from 'react';
import { Chip, TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import formatMemoryString from '../../commons/formatMemoryString';

export default function BuildAgentMemory({
    memoryInfo,
    align,
}: {
    memoryInfo: string;
    align?: MuiAlignSetting;
}): ReactElement {
    return (
        <TableCell sx={{ py: 2, px: 1 }} align={align}>
            <Chip
                size='small'
                title={formatMemoryString(memoryInfo)}
                sx={{ display: 'inline-grid', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                label={formatMemoryString(memoryInfo)}
            />
        </TableCell>
    );
}
