import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AsyncThunk } from '@reduxjs/toolkit';
import { AppThunkDispatch } from '../../store';

export function useRefreshPage(dispatchFunction: AsyncThunk<any, void, any>, timeout = 10000): void {
    const dispatch = useDispatch<AppThunkDispatch>();

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(dispatchFunction());
        }, timeout);
        return () => clearTimeout(timer);
    }, [dispatch, dispatchFunction, timeout]);
}
