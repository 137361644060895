import { ReactElement } from 'react';
import BuildSnapshotStatsGraph from './BuildSnapshotStatsGraph';
import { BuildGraphItem } from './BuildStats';
import BuildStatsSkeleton from './BuildStatsSkeleton';
import { useGetSnapshotGraphQuery } from './getBuildStatsGraphApi';
import { IBuildStatsParams } from './interfaces';

export default function BuildSnapshotStats(
    params: Omit<IBuildStatsParams, 'status'> & { env: 'staging' | 'production' },
): ReactElement {
    const { startedAt, stoppedAt, hostname, env, width, height } = params;
    const start = Math.round(new Date(startedAt).getTime() / 1000);
    const stop = Math.round(new Date(stoppedAt).getTime() / 1000);
    const graphParams = { start, stop, hostname, width, height };
    const { data } = useGetSnapshotGraphQuery(graphParams);

    if (!data?.urls.length) {
        return <BuildStatsSkeleton />;
    }

    return (
        <>
            {data?.urls.map((url, key) => (
                <BuildGraphItem key={key}>
                    <BuildSnapshotStatsGraph
                        {...{ url, startedAt, stoppedAt, width, height, hostname, env }}
                        key={key}
                    />
                </BuildGraphItem>
            ))}
        </>
    );
}
