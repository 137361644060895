import { ReactElement } from 'react';
import { Card, Typography } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { useTestSuitesQueue } from '../../testSuites/useTestSuitesQueue';
import { ITestDevice } from '../interfaces';
import TestSuiteSummaryList from './TestSuiteSummaryList';

export default function TestDeviceQueue({ testDevice }: { testDevice: ITestDevice }): ReactElement {
    const testsQueue = useTestSuitesQueue();
    const testDeviceQueue = testsQueue.get(testDevice._id) || { testSuites: [], queue: 0 };

    return (
        <Card sx={{ mb: 4 }} data-testid='test-device-queue'>
            <Typography variant='h6' sx={{ m: 3, color: blueGray[700], fontSize: '18px' }}>
                Queue ({testDeviceQueue.queue})
            </Typography>
            <TestSuiteSummaryList testSuites={testDeviceQueue.testSuites} />
        </Card>
    );
}
