import { getTimeZoneDiff } from './getTimeZoneDiff';

const HOUR_INDEX = 2;
const MAX_HOUR = 23;

const convertHours = (cron: string, toUTC = false) => {
    const currentCronArray = cron.split(' ');
    const timeZoneDiff = getTimeZoneDiff();
    const hourOffset = timeZoneDiff * (toUTC ? -1 : 1);

    currentCronArray[HOUR_INDEX].match(/\d+/g)
        ?.reverse()
        .forEach((hour) => {
            const rawHours = (Number(hour) + hourOffset) % MAX_HOUR; // modulo to get base amount of hours to add or subtract
            const hoursCarriedOver = ((Number(hour) + hourOffset) / MAX_HOUR) | 0; // rawHours may be > 24 therefore account for the 1h carried over

            let newHour = rawHours - hoursCarriedOver; // result in case we are adding hours
            if (newHour < 0) {
                newHour = Math.abs(MAX_HOUR + newHour) + 1; // result in case we are turning back time
            }

            currentCronArray[HOUR_INDEX] = currentCronArray[HOUR_INDEX].replace(hour, newHour.toString());
        });

    return currentCronArray.join(' ');
};

export const parse6To5Cron = (cron?: string): string => {
    if (!cron) {
        return '';
    }

    const convertCronHoursToLocal = (cron: string) => convertHours(cron, false);

    const newCron = convertCronHoursToLocal(cron);
    return newCron.substring(2) || '';
};

export const parse5To6Cron = (cron?: string): string => {
    const newCron = `0 ${cron?.trim()}`;

    const convertCronHoursToUTC = (cron: string) => convertHours(cron, true);
    return convertCronHoursToUTC(newCron);
};
