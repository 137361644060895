import React, { ReactElement } from 'react';
import { Cancel } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { SquareButton, blueGray } from '@tactileentertainment/core-designsystem';
import { ITest } from '../interfaces';
import { useCancelTestMutation } from '../testsApi';

export default function CancelTestAction({
    test,
    variant,
}: {
    test: ITest;
    variant?: ButtonProps['variant'];
}): ReactElement {
    const [cancelTest] = useCancelTestMutation();

    const onCancelTest = (event: React.MouseEvent) => {
        event.stopPropagation();
        cancelTest(test._id);
    };

    return (
        <div data-testid='cancel-test-action'>
            <SquareButton
                variant={variant}
                title='Cancel Test'
                aria-label='cancel'
                onMouseUp={onCancelTest}
                color='secondary'
                sx={{ p: 1.25 }}
            >
                <Cancel sx={{ color: blueGray[800] }} />
            </SquareButton>
        </div>
    );
}
