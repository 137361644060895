export default function isMarkdown(content: string): boolean {
    const hasLinks = /\[.+\]\(\S+\)/.test(content);
    const hasHeaders = /\n#+.+\n/.test(content);
    const hasBold = /__[^_\n]+__/.test(content) || /\*\*[^*\n]+\*\*/.test(content);
    const hasList =
        /\n[0-9]+\..+\n/.test(content) ||
        /\n-.+\n/.test(content) ||
        /\n\*.+\n/.test(content) ||
        /\n\+.+\n/.test(content);
    return hasLinks || hasHeaders || hasBold || hasList;
}
