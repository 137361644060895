import { ReactElement } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton, successToast } from '@tactileentertainment/core-designsystem';
import { IReleaseNote } from './interfaces';
import { useDeleteReleaseNotesMutation } from './releaseNotesApi';

interface ReleaseNotesDeleteDialogProps {
    open: boolean;
    releaseNote: IReleaseNote;
    onClose: () => void;
}

export default function ReleaseNotesDeleteDialog({
    open,
    releaseNote,
    onClose,
}: ReleaseNotesDeleteDialogProps): ReactElement {
    const [deleteReleaseNote] = useDeleteReleaseNotesMutation();

    const handleDelete = async () => {
        const result = await deleteReleaseNote(releaseNote);
        if (!('error' in result)) {
            successToast('Release note deleted');
        }

        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Delete Release Note</DialogTitle>
            <DialogContent>
                Do you really want to delete <b>{releaseNote.title}</b> release note from{' '}
                <b>{releaseNote.projectName}</b>?
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={onClose} variant='text'>
                    Cancel
                </Button>
                <LoadingButton
                    variant='contained'
                    color='error'
                    size='small'
                    startIcon={<DeleteIcon />}
                    onClick={handleDelete}
                >
                    Delete
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
