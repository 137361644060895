import { ReactElement } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Chip, Link, TableCell, TableRow, Typography } from '@mui/material';
import { blue, red } from '@tactileentertainment/core-designsystem';
import ClickableTableRow from '../../commons/ClickableTableRow';
import GameModuleStatus from '../GameModuleStatus';
import { IModuleProject } from '../interfaces';
import { isSha } from '../utils';

interface GameModuleRowProps {
    name: string;
    redirectUrl?: string;
    project: IModuleProject;
    homepage?: string;
}

export default function GameModuleProjectRow({
    name,
    redirectUrl,
    project,
    homepage,
}: GameModuleRowProps): ReactElement {
    if (!redirectUrl) {
        return (
            <TableRow>
                <RowContent name={name} project={project} homepage={homepage} />
            </TableRow>
        );
    }

    return (
        <ClickableTableRow redirectUrl={redirectUrl}>
            <RowContent name={name} project={project} homepage={homepage} />
        </ClickableTableRow>
    );
}

function RowContent({ name, project, homepage }: GameModuleRowProps): ReactElement {
    const isBuiltIn = project.latestVersion === 'builtin';
    const isProjectVersionSha = isSha(project.version);
    return (
        <>
            <TableCell>
                <Box display='flex' alignItems='center'>
                    <Typography noWrap>{name}</Typography>
                    {homepage && (
                        <Link
                            target='_blank'
                            href={homepage}
                            onMouseUp={(event) => event.stopPropagation()}
                            sx={{ ml: 1, display: 'flex' }}
                        >
                            <OpenInNewIcon fontSize='small' />
                        </Link>
                    )}
                    {project.deprecated && (
                        <Chip
                            sx={{ ml: 1, background: red[200], color: red[700], width: '15%' }}
                            size='small'
                            label='Deprecated'
                        ></Chip>
                    )}
                    {project.primary && (
                        <Chip
                            sx={{
                                ml: 1,
                                background: blue[200],
                                color: blue[700],
                                width: '15%',
                            }}
                            size='small'
                            label='Primary'
                        ></Chip>
                    )}
                </Box>
            </TableCell>
            <TableCell>
                <Typography noWrap>{project.branch || '--'}</Typography>
            </TableCell>
            <TableCell>
                <Typography noWrap color={isBuiltIn ? 'textSecondary' : 'default'}>
                    {isBuiltIn ? 'Built-in' : project.latestVersion}
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ProjectVersion
                        isProjectVersionSha={isProjectVersionSha}
                        version={project.version}
                        homepage={homepage}
                    />
                    <GameModuleStatus project={project} />
                </Box>
            </TableCell>
        </>
    );
}

export const ProjectVersion = ({
    isProjectVersionSha,
    version,
    homepage,
}: {
    isProjectVersionSha: boolean;
    version?: string;
    homepage?: string;
}) => {
    return (
        <Typography
            component={isProjectVersionSha ? 'a' : 'p'}
            noWrap
            {...(isProjectVersionSha && {
                href: `${homepage}/commits/${version}`,
                target: '_blank',
                rel: 'noreferrer',
            })}
            sx={{ mr: 1, cursor: isProjectVersionSha ? 'pointer' : 'default', textDecoration: 'none' }}
        >
            {version}
        </Typography>
    );
};
