import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

export default function ContentLoading(): ReactElement {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <CircularProgress style={{ margin: '8px' }} size={20} />
        </div>
    );
}
