import { ReactElement } from 'react';
import CloneIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { blueGray, red } from '@tactileentertainment/core-designsystem';
import TableTextCell from '../commons/TableTextCell';
import { defaultLanguage } from './appUploaderLanguages';
import { IReleaseNote } from './interfaces';
import { columns, ReleaseNotesColumnId } from './releaseNotesColumns';

export interface IReleaseNoteRowProps {
    releaseNote: IReleaseNote;
    onClone: () => void;
    onEdit: () => void;
    onDelete: () => void;
}

export default function ReleaseNoteRow({ releaseNote, onClone, onEdit, onDelete }: IReleaseNoteRowProps): ReactElement {
    const localizedMessage =
        releaseNote.localizedMessages.find((message) => message.language === defaultLanguage) ||
        releaseNote.localizedMessages[0];
    const languages = releaseNote.localizedMessages.map((message) => message.language);

    return (
        <TableRow data-testid='release-notes-row'>
            {columns.map((column) => {
                switch (column.id) {
                    case ReleaseNotesColumnId.projectName:
                        return (
                            <TableTextCell
                                key={column.id}
                                align={column.align}
                                text={releaseNote.projectName === 'all' ? 'All Projects' : releaseNote.projectName}
                                style={{ fontWeight: 600 }}
                            />
                        );

                    case ReleaseNotesColumnId.title:
                        return <TableTextCell key={column.id} align={column.align} text={releaseNote.title} />;

                    case ReleaseNotesColumnId.message:
                        return (
                            <TableCell key={column.id} align={column.align} sx={{ p: 1 }}>
                                <Typography sx={{ whiteSpace: 'pre-line' }} fontWeight={400}>
                                    {localizedMessage.message}
                                </Typography>
                            </TableCell>
                        );

                    case ReleaseNotesColumnId.languages:
                        return (
                            <TableCell key={column.id} align={column.align} sx={{ p: 1 }}>
                                {languages.join(', ')}
                            </TableCell>
                        );

                    case ReleaseNotesColumnId.actions:
                        return (
                            <TableCell key={column.id} align={column.align} sx={{ py: 1, px: 0 }}>
                                <IconButton onClick={onEdit} sx={{ color: blueGray[800] }}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={onClone} sx={{ color: blueGray[800] }}>
                                    <CloneIcon />
                                </IconButton>
                                <IconButton onClick={onDelete} sx={{ color: red[800] }}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        );

                    default:
                        return <TableTextCell key={column.id} align={column.align} text={releaseNote[column.id]} />;
                }
            })}
        </TableRow>
    );
}
