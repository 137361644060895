export default function isAddressValid(address: string): boolean {
    if (!address) {
        return false;
    }

    const [hostname, port] = address.split(':');
    const isValidHostname = validateHostname(hostname);
    const isValidPort = validatePort(port);

    return isValidHostname && isValidPort;
}

function validateHostname(ipOrDomainAddress: string): boolean {
    return /^[a-z\d-]+(\.[a-z\d-]+)*$/.test(ipOrDomainAddress);
}

function validatePort(port: string): boolean {
    return port ? !isNaN(parseInt(port)) : true;
}
