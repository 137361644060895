import { ReactElement, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Paper, SxProps } from '@mui/material';
import { FullWidthPopper, TactileTheme } from '@tactileentertainment/core-designsystem';
import { DataValueBox } from '../commons/DataValueBox';
import ConfigurationSettings from './ConfigurationSettings';
import { IConfigurationSettings, ISelectedSettings } from './interfaces';

export default function SelectedSettings({
    settings,
    selectedSettings,
    editEnabled,
    onEdit,
    sx,
}: {
    settings?: IConfigurationSettings;
    selectedSettings?: ISelectedSettings;
    editEnabled?: boolean;
    onEdit?: (newValue: ISelectedSettings) => void;
    sx?: SxProps<TactileTheme>;
}): ReactElement | null {
    const contentRef = useRef<HTMLElement>();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const editMode = Boolean(editEnabled && anchorEl);

    if (!selectedSettings) {
        return null;
    }
    return (
        <Box display='flex' justifyContent='space-between' alignItems='center' ref={contentRef}>
            <Box>
                {Object.keys(selectedSettings).map((settingKey) => (
                    <DataValueBox
                        key={settingKey}
                        label={settings?.[settingKey]?.display_name || settingKey}
                        value={selectedSettings[settingKey]?.toString()}
                        labelSx={sx}
                        valueSx={{ ...sx, fontWeight: 400 }}
                    />
                ))}
            </Box>
            {editEnabled && (
                <Button
                    onClick={(event) => {
                        event.stopPropagation();
                        if (onEdit) {
                            setAnchorEl(anchorEl ? null : contentRef?.current || null);
                        }
                    }}
                    color='secondary'
                    size='small'
                >
                    {editMode ? <CheckIcon fontSize='small' /> : <EditIcon fontSize='small' />}
                </Button>
            )}
            <FullWidthPopper
                open={editMode}
                anchorEl={anchorEl}
                placement='bottom-end'
                sx={{ zIndex: 1300 }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <Paper>
                    <ConfigurationSettings
                        settings={settings}
                        selectedSettings={selectedSettings}
                        onSettingChange={(setting, value) => {
                            const newValue = {
                                ...selectedSettings,
                                [setting.settingKey || setting.display_name]: value,
                            };
                            if (onEdit) {
                                onEdit(newValue);
                            }
                        }}
                    />
                </Paper>
            </FullWidthPopper>
        </Box>
    );
}
