import { ReactElement } from 'react';
import { SxProps, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { IBuild } from '../commons/interfaces';

const dataNameStyle: SxProps = { width: '35%', fontWeight: 'bold' };
const textEllipsisStyle: SxProps = {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export function BuildInfoTable({ build }: { build: IBuild | undefined }): ReactElement {
    return (
        <Table size='small' padding='none' style={{ width: '90%', margin: 'auto', tableLayout: 'fixed' }}>
            <TableBody
                sx={{
                    '& td,th': {
                        borderBottom: 'unset',
                    },
                }}
            >
                <TableRow>
                    <TableCell sx={dataNameStyle}>Project</TableCell>
                    <TableCell sx={textEllipsisStyle}>{build?.projectName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={dataNameStyle}>Branch</TableCell>
                    <TableCell sx={textEllipsisStyle}>{build?.branch}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={dataNameStyle}>Revision</TableCell>
                    <TableCell sx={textEllipsisStyle}>{build?.revision}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={dataNameStyle}>Build Id</TableCell>
                    <TableCell sx={textEllipsisStyle}>{build?.buildConfigId}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={dataNameStyle}>Platform</TableCell>
                    <TableCell sx={textEllipsisStyle}>{build?.platform}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={dataNameStyle}>Kind</TableCell>
                    <TableCell sx={textEllipsisStyle}>{build?.kind}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={dataNameStyle}>Type</TableCell>
                    <TableCell sx={textEllipsisStyle}>{build?.type}</TableCell>
                </TableRow>
                {build?.settings && (
                    <TableRow>
                        <TableCell sx={dataNameStyle}>Settings</TableCell>
                        <TableCell sx={textEllipsisStyle}>
                            <pre>{JSON.stringify(build?.settings, null, 2)}</pre>
                        </TableCell>
                    </TableRow>
                )}
                {build?.requirements && (
                    <TableRow>
                        <TableCell sx={dataNameStyle}>Requirements</TableCell>
                        <TableCell sx={textEllipsisStyle}>
                            <pre>{JSON.stringify(build?.requirements, null, 2)}</pre>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}
