import React, { ReactElement } from 'react';
import CustomLink from '../commons/CustomLink';

export default function TestAgentsLink({ title }: { title: string }): ReactElement {
    return (
        <CustomLink
            to='/test-agents'
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
            onMouseUp={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
        >
            {title}
        </CustomLink>
    );
}
