export enum AcceleratorColumnId {
    serviceName = 'serviceName',
    host = 'host', // machine name
    address = 'address', // ip port
    games = 'games',
    actions = 'actions',
}

export enum AcceleratorOptions {
    deleteNamespace = 'delete-namespace',
    deleteKey = 'delete-key',
}
