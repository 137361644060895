import { ReactElement } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { defaultLanguage } from './appUploaderLanguages';
import { IReleaseNote } from './interfaces';
import { useGetReleaseNotesQuery } from './releaseNotesApi';

interface SelectReleaseNoteProps {
    projectName: string;
    releaseNote?: IReleaseNote;
    onSelectReleaseNote: (releaseNote?: IReleaseNote) => void;
}

export default function SelectReleaseNote({
    projectName,
    releaseNote,
    onSelectReleaseNote,
}: SelectReleaseNoteProps): ReactElement {
    const { data: releaseNotes } = useGetReleaseNotesQuery({ projectName: [projectName] });
    return (
        <Autocomplete
            size='medium'
            options={releaseNotes || []}
            getOptionLabel={(option) => option.title}
            renderOption={(props, option) => (
                <Box key={option._id} component='li' {...props}>
                    <Box display='flex' flexDirection='column' justifyContent='flex-start'>
                        <Typography>{option.title}</Typography>
                        <Typography variant='caption'>
                            {option.localizedMessages.find((msg) => msg.language === defaultLanguage)?.message}
                        </Typography>
                    </Box>
                </Box>
            )}
            autoHighlight
            clearOnBlur
            clearOnEscape
            onChange={(event, option) => onSelectReleaseNote(option || undefined)}
            value={releaseNote || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete='off'
                    label='Release Notes'
                    placeholder='Select release notes'
                    variant='outlined'
                />
            )}
            fullWidth
        />
    );
}
