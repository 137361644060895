import React, { ReactElement, createElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as MaterialIcons from '@mui/icons-material';
import {
    Box,
    Collapse,
    FormControlLabel,
    Grid,
    Button as MuiButton,
    Switch,
    styled,
    useMediaQuery,
} from '@mui/material';
import { useLogin } from '@tactileentertainment/core-shared.auth-react';
import { AppThunkDispatch } from '../../store';
import { IBuildAgentSliceState } from '../buildAgents/interfaces';
import { semanticVersionPattern } from '../changeLog/utils/semanticVersionPattern';
import { EBuildStatus } from '../commons/enums';
import ExpandIcon from '../commons/ExpandIcon';
import SubscribeButton from '../subscriptions/SubscribeButton';
import BuildConfigurationTags from './BuildConfigurationTags';
import {
    expediteBuild,
    selectBuildDetailsBuild,
    selectBuildDetailsFeaturedArtifacts,
    selectBuildStatus,
    setBuildDialog,
    setChangeLogOpen,
} from './buildDetailsSlice';
import BuildStats from './BuildStats';
import { IArtifact } from './interfaces';

export const Button = styled(MuiButton)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 'fit-content',
    maxHeight: '30px',
    opacity: '75%',
}));

export default function BuildResultButtonRow(): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const dispatch = useDispatch();
    const build = useSelector(selectBuildDetailsBuild);
    const buildStatus = useSelector(selectBuildStatus);
    const hasConfiguration = build && (build.buildConfigId || build.builder || build.settings || build.requirements);
    const [isStatsExpanded, setIsStatsExpanded] = useState(false);
    const { isLoggedIn, handleLogin } = useLogin();

    const handleShowBuildDetailsConfiguration = () => {
        if (hasConfiguration) {
            dispatch(
                setBuildDialog({
                    open: true,
                    buildConfiguration: {
                        buildConfigId: build?.buildConfigId,
                        builder: build?.builder,
                        configurationFile: build?.configurationFile,
                        kind: build?.kind,
                        settings: build?.settings,
                        requirements: build?.requirements,
                        expedited: build?.expedited,
                    },
                    title: 'Build Configuration',
                }),
            );
        }
    };

    const onChangeLogClick = () => {
        if (isLoggedIn) {
            dispatch(setChangeLogOpen({ open: true }));
        } else {
            handleLogin();
        }
    };

    return (
        <>
            <Grid container sx={{ overflowX: 'auto', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {build?.started && build?.buildAgent?.hostname && (
                        <Button
                            variant='outlined'
                            color='inherit'
                            size='small'
                            sx={{
                                marginLeft: 0,
                                backgroundColor: !isStatsExpanded ? (theme) => theme.palette.common.white : 'inherit',
                            }}
                            endIcon={<ExpandIcon expanded={isStatsExpanded} />}
                            onClick={() => setIsStatsExpanded(!isStatsExpanded)}
                        >
                            Server Stats
                        </Button>
                    )}
                    {hasConfiguration && (
                        <Button
                            variant='outlined'
                            color='inherit'
                            size='small'
                            sx={{ ml: 0, backgroundColor: (theme) => theme.palette.common.white }}
                            onClick={handleShowBuildDetailsConfiguration}
                        >
                            configuration
                        </Button>
                    )}
                    <BuildConfigurationTags />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {(buildStatus === EBuildStatus.running || buildStatus === EBuildStatus.queued) && (
                        <ExpediteBuildsToggle />
                    )}
                    {build && [EBuildStatus.queued, EBuildStatus.running].includes(build.status) && (
                        <SubscribeButton
                            event={{
                                namespace: 'builds',
                                projectName: build.projectName,
                                buildNumber: build.buildNumber,
                            }}
                        />
                    )}
                    {build?.branch && semanticVersionPattern.test(build?.branch) && (
                        <Button
                            variant='outlined'
                            color='inherit'
                            aria-label='orderTest'
                            sx={{ ml: 0, backgroundColor: (theme) => theme.palette.common.white }}
                            onClick={onChangeLogClick}
                        >
                            Change Log
                        </Button>
                    )}
                    {!isMobile && <FeaturedArtifacts />}
                </Box>
                {isMobile && (
                    <Box>
                        <FeaturedArtifacts />
                    </Box>
                )}
            </Grid>
            {build?.started && build?.buildAgent?.hostname && (
                <Collapse in={isStatsExpanded}>
                    <BuildStats
                        id={build._id}
                        startedAt={build.started}
                        stoppedAt={build.completed || ''}
                        status={build.status}
                        hostname={build.buildAgent?.hostname}
                        width={450}
                        height={200}
                    />
                </Collapse>
            )}
        </>
    );
}

function FeaturedArtifacts(): ReactElement {
    const dispatch = useDispatch();
    const featuredArtifacts = useSelector(selectBuildDetailsFeaturedArtifacts);

    const handleOpenArtifactDialog = (artifact: IArtifact) => {
        if (artifact.file) {
            dispatch(setBuildDialog({ open: true, artifact, title: artifact.featuredText }));
        }
    };

    return (
        <>
            {featuredArtifacts?.map((artifact, index) => (
                <Button
                    key={index}
                    sx={{
                        ...(index === featuredArtifacts.length - 1 && {
                            marginRight: 0,
                        }),
                        ml: 0,
                    }}
                    startIcon={artifact.featuredText && getArtifactIcon(artifact.featuredIcon)}
                    onClick={() => handleOpenArtifactDialog(artifact)}
                    variant='outlined'
                    color='secondary'
                    size='small'
                >
                    {!artifact.featuredText && getArtifactIcon(artifact.featuredIcon)}
                    {artifact.featuredText}
                </Button>
            ))}
        </>
    );
}

function getArtifactIcon(featuredIcon?: string): ReactElement | null {
    if (!featuredIcon) {
        return null;
    }

    // horrible type hack
    const artifactIcon = (MaterialIcons as Record<string, any>)[featuredIcon];
    return createElement(artifactIcon);
}

function ExpediteBuildsToggle(): ReactElement | null {
    const dispatch = useDispatch<AppThunkDispatch<IBuildAgentSliceState>>();
    const build = useSelector(selectBuildDetailsBuild);

    const [isExpedited, setExpedited] = useState(build?.expedited);

    const handleToggle = (event: React.ChangeEvent<any>) => {
        if (build) {
            dispatch(
                expediteBuild({
                    buildId: build._id,
                    buildAgentIp: build.buildAgent?.ip || undefined,
                    expediteState: event.target.checked,
                }),
            );
            setExpedited(event.target.checked);
        }
    };

    // builds on already reserved agents cannot be expedited
    // exception: the expedited build can always be toggled off
    if (build?.buildAgent?.reserved && !build.expedited) {
        return null;
    }

    return (
        <FormControlLabel
            sx={{ ml: 0, mr: 1 }}
            control={<Switch size='small' checked={isExpedited} onChange={handleToggle} />}
            label='Expedited build'
        />
    );
}
