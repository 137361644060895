import { ReactElement } from 'react';
import { Dialog, successToast } from '@tactileentertainment/core-designsystem';
import { ITestDevice } from '../../interfaces';
import { useDeleteTestDeviceMutation } from '../../testDevicesApi';

interface IDeleteTestDeviceDialogProps {
    testDevice: ITestDevice;
    open: boolean;
    handleClose: () => void;
}

export default function DeleteTestDeviceDialog({
    testDevice,
    open,
    handleClose,
}: IDeleteTestDeviceDialogProps): ReactElement {
    const [deleteTestDevice] = useDeleteTestDeviceMutation();

    const handleDelete = async () => {
        const result = await deleteTestDevice(testDevice);
        if (!('error' in result)) {
            successToast('Deleted test device');
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            variant='confirmation'
            icon='delete'
            title='Delete test device'
            content='This will remove the device from the system.'
            primaryButton={{
                text: 'Delete',
                color: 'error',
                onClick: handleDelete,
            }}
        />
    );
}
