import { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ContentCopy as CopyIcon, Check as CheckIcon, Refresh as NewToken } from '@mui/icons-material';
import {
    Card,
    Button,
    CircularProgress,
    IconButton,
    Typography,
    Box,
    useTheme,
    Divider,
    TextField,
} from '@mui/material';
import { AvatarChip } from '@tactileentertainment/core-designsystem';
import { useAuthUser, useLogout } from '@tactileentertainment/core-shared.auth-react';
import copy from 'copy-to-clipboard';
import { createSharableServerUrl } from '../../api';
import UserPreferences from './UserPreferences';
import { useGetCurrentUserQuery, useUpdateAuthorizationTokenMutation } from './usersApi';

export default function UserProfile(): ReactElement | null {
    const theme = useTheme();
    const { user } = useAuthUser();
    const { data: userData } = useGetCurrentUserQuery();
    const [updateAuthorizationToken] = useUpdateAuthorizationTokenMutation();
    const [copiedAuthorization, setCopiedAuthorization] = useState(false);
    const [loading, setLoading] = useState(false);
    const logout = useLogout();

    const handleCopyAuthorization = () => {
        if (userData?.user?.authorization) {
            copy(userData?.user?.authorization);
            setCopiedAuthorization(true);

            setTimeout(() => {
                setCopiedAuthorization(false);
            }, 3000);
        }
    };

    const handleNewAuthorization = async () => {
        setLoading(true);
        await updateAuthorizationToken();
        setLoading(false);
        setCopiedAuthorization(false);
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <Helmet title='Profile' />
            <Box sx={{ p: 3, height: '90vh' }}>
                <Box
                    sx={{
                        my: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AvatarChip label={user.email} altLabel={user.name} src={user.imageUrl} size='xl' avatarOnly />
                        <Box sx={{ ml: 2, mt: 0.5 }}>
                            <Typography variant='h4' fontWeight={600}>
                                {user.name}
                            </Typography>
                            <Typography color='textSecondary' sx={{}}>
                                {user.email}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: { xs: 3, sm: 0 } }}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            target='_blank'
                            sx={{ mr: 2, ml: 0 }}
                            href={createSharableServerUrl(`/open-api`)}
                        >
                            OpenAPI Specification
                        </Button>
                        <Button variant='contained' color='primary' onClick={() => logout()}>
                            {'Log Out'}
                        </Button>
                    </Box>
                </Box>
                <Card sx={{ p: 3, mt: 3 }}>
                    <Typography variant='h6' gutterBottom>
                        Settings
                    </Typography>
                    <Divider sx={{ mx: -3 }} />
                    <Box sx={{ display: 'flex', mt: 2 }}>
                        <TextField
                            value={userData?.user?.authorization || 'No authorization - please generate'}
                            label='Authorization Token'
                            variant='outlined'
                            disabled
                            inputProps={{
                                style: { textOverflow: 'ellipsis' },
                            }}
                            sx={{ minWidth: { xs: '260px', sm: '450px' } }}
                        />
                        <IconButton
                            sx={{ p: 0, my: 0, mx: 2 }}
                            color='secondary'
                            onClick={handleCopyAuthorization}
                            title='Copy authorization token'
                        >
                            {copiedAuthorization ? (
                                <CheckIcon fontSize='small' style={{ fill: 'green' }} />
                            ) : (
                                <CopyIcon fontSize='small' />
                            )}
                        </IconButton>
                        {loading ? (
                            <CircularProgress sx={{ p: 0 }} size={15} variant='indeterminate' />
                        ) : (
                            <IconButton
                                sx={{ p: 0, my: 0 }}
                                color='secondary'
                                title='Generate new authorization token'
                                onClick={handleNewAuthorization}
                            >
                                <NewToken fontSize='small' />
                            </IconButton>
                        )}
                    </Box>
                    <Box
                        sx={{ display: 'flex', my: 1, [theme.breakpoints.down('sm')]: { flexWrap: 'wrap', margin: 0 } }}
                    >
                        <UserPreferences />
                    </Box>
                </Card>
            </Box>
            <footer>
                <Typography color='textSecondary' align='center'>
                    {process.env.REACT_APP_CIRCLE_BRANCH} - {process.env.REACT_APP_GIT_SHA_FULL} -{' '}
                    {process.env.REACT_APP_DEPLOY_DATE}
                </Typography>
            </footer>
        </>
    );
}
