export function getUpsourceLink(projectName: string, branchName: string, revisionNumber: string): string {
    projectName = encodeURIComponent(projectName);
    branchName = encodeURIComponent(branchName);
    revisionNumber = encodeURIComponent(revisionNumber);

    if (branchName === 'trunk') {
        return `https://upsource.tactile.build/${projectName}/revision/${branchName}-${revisionNumber}`;
    }
    return `https://upsource.tactile.build/${projectName}/revision/branches%2F${branchName}-${revisionNumber}`;
}
