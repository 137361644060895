import { ReactElement, useEffect, useState } from 'react';
import { LazyLog } from 'react-lazylog';
import ReactMarkdown from 'react-markdown';
import XMLViewer from 'react-xml-viewer-2';
import { CircularProgress } from '@mui/material';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import isMarkdown from './isMarkdown';

// Colors from Dracula theme
const customTheme = {
    // eslint-disable-next-line @tactileentertainment/tactile/no-color-literal
    attributeKeyColor: '#BABABA',
    // eslint-disable-next-line @tactileentertainment/tactile/no-color-literal
    attributeValueColor: '#6A8759',
    // eslint-disable-next-line @tactileentertainment/tactile/no-color-literal
    cdataColor: '#1D781D',
    // eslint-disable-next-line @tactileentertainment/tactile/no-color-literal
    commentColor: '#808080',
    // eslint-disable-next-line @tactileentertainment/tactile/no-color-literal
    separatorColor: '#E8BF6A',
    // eslint-disable-next-line @tactileentertainment/tactile/no-color-literal
    tagColor: '#E8BF6A',
    // eslint-disable-next-line @tactileentertainment/tactile/no-color-literal
    textColor: '#333',
};

export default function ArtifactContentRenderer({ content, type }: { content: string; type?: string }): ReactElement {
    const [renderingContent, setRenderingContent] = useState(true);

    useEffect(() => {
        setRenderingContent(false);
    }, [setRenderingContent]);

    if (renderingContent) {
        return <CircularProgress variant='indeterminate' size={20} />;
    }

    switch (type) {
        case 'md':
        case 'markdown':
            return isMarkdown(content) ? (
                <ReactMarkdown
                    className='markdown-content-renderer'
                    rehypePlugins={[rehypeRaw]} // rehype plugins work with HTML
                    remarkPlugins={[gfm]} // remark plugins work with markdown
                >
                    {content}
                </ReactMarkdown>
            ) : (
                <LazyLog text={content} selectableLines />
            );
        case 'xml':
            return (
                <XMLViewer className='markdown-content-renderer' xml={content} theme={customTheme} collapsible={true} />
            );
        case 'html':
            return <iframe width='100%' height='100%' frameBorder='0' title='Html artifact' srcDoc={content} />;
        default:
            return <LazyLog text={content} extraLines={1} enableSearch caseInsensitive selectableLines follow />;
    }
}
