import { ReactElement } from 'react';
import { Divider, Grid, Paper } from '@mui/material';
import { ITestSuiteDetails } from '../interfaces';
import TestSuiteInfoBuildRow from './TestSuiteInfoBuild';
import TestSuiteInfoStats from './TestSuiteInfoStats';

export default function TestSuiteInfo({ testSuite }: { testSuite: ITestSuiteDetails }): ReactElement {
    return (
        <Paper variant='outlined' elevation={0} sx={{ px: 2, py: 1 }}>
            <Grid container justifyContent='space-between' spacing={1}>
                <Grid item container wrap='nowrap'>
                    <TestSuiteInfoBuildRow testSuite={testSuite} />
                </Grid>
                <Divider orientation='horizontal' sx={{ width: 'calc(100% + 8px)', mt: 1 }} />
                <Grid item container wrap='nowrap'>
                    <TestSuiteInfoStats testSuite={testSuite} />
                </Grid>
            </Grid>
        </Paper>
    );
}
