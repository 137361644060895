import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import { ITestDevice } from '../../testDevices/interfaces';
import TestDeviceLink from '../../testDevices/TestDeviceLink';

export default function TestDeviceCell({
    device,
    align,
}: {
    device?: ITestDevice;
    align?: MuiAlignSetting;
}): ReactElement {
    return (
        <TableCell align={align} id='table-cell-test-device' data-testid='tests-table-test-device'>
            {device && (
                <TestDeviceLink
                    uuid={device.uuid}
                    title={device.name || device.deviceInfo?.manufacturer + ' ' + device.deviceInfo.model}
                />
            )}
        </TableCell>
    );
}
