import { useHistory } from 'react-router-dom';

export default function useOpenLink(redirectUrl: string): (isNewTab: boolean) => void {
    const history = useHistory();
    return (openNewTab: boolean) => {
        if (openNewTab) {
            window.open(redirectUrl, '_blank');
        } else {
            history.push(redirectUrl);
        }
    };
}
