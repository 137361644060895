import { SelectedFilter } from '../commons/filters/interfaces';
import { IAuthor, IBuild } from '../commons/interfaces';
import { ISelectedSettings } from '../configurationSettings/interfaces';
import { ITestDevice } from '../testDevices/interfaces';
import { ETestStatus, ITestCase, ITestCustomBuild } from '../tests/interfaces';
import { IUser } from '../userProfile/interfaces';

export interface ITestSuiteBase {
    _id: string;
    testSuiteNumber: number;
    type: ETestSuiteAllocationType;
    build: IBuild;
    testBranch: string;
    commitSha?: string;
    devices: ITestSuiteDevice[];
    maxDevices: number;
    author: IUser;

    createdAt: Date;
    updatedAt: Date;
    startedAt?: Date;
    queuedAt?: Date;
    completedAt?: Date;

    status: ETestStatus;
    resultsCount: ITestSuiteResultsCount;
    resultsCountPerDevice: ITestSuiteDeviceResultsCount;
}

export interface ITestSuiteListItem extends ITestSuiteBase {
    tests: string[];
}
export interface ITestSuiteDetails extends ITestSuiteBase {
    tests: ITestSuiteTest[];
    updatedBy?: IUser;
}

export interface ITestSuiteDevice {
    _id: string;
    uuid: string;
    name: string;
    isDisabled: boolean;
    isElegibleForQueuedTests: boolean;
    deviceInfo: {
        manufacturer?: string;
        model?: string;
    };
    gameDeviceId?: string;
}

export interface ITestSuiteTest {
    _id: string;
    status: ETestStatus;
    testDevice?: string;
    startedAt?: string;
    completedAt?: string;
    testCases?: ITestCase[];
    logFile?: string;
    gameDeviceId?: string;
}

export interface ITestSuiteCase extends ITestCase {
    parentTest: ITestSuiteTest;
    device?: ITestSuiteDevice;
    gameDeviceId?: string;
}

export interface ITestSuiteFilter {
    page?: number;
    skip?: number;
    author?: SelectedFilter;
    status?: SelectedFilter;
    devices?: SelectedFilter;
    testBranch?: SelectedFilter;
    testSuiteNumber?: SelectedFilter;
    build?: {
        _id?: SelectedFilter;
        projectName?: SelectedFilter;
        versionName?: SelectedFilter;
        platform?: SelectedFilter;
        type?: SelectedFilter;
        branch?: SelectedFilter;
    };
    sort?: string;
}

export interface ITestSuiteFilterOptions {
    author?: IAuthor[];
    testSuiteNumber?: number[];
    devices?: ITestDevice[];
    testBranch?: string[];
    build?: {
        projectName?: string[];
        versionName?: string[];
        branch?: string[];
        platform?: string[];
        type?: string[];
    };
}

export interface ITestSuitePayload {
    type?: ETestSuiteAllocationType;
    build: string;
    devices: string[];
    maxDevices: number;
    testBranch: string;
    commitSha?: string;
    testCases: ITestCasePayload[];
    subscribeProjectToResult?: boolean;
}

export interface ITestCasePayload {
    id: string;
    testName: string;
    displayName: string;
    videoRecording: boolean;
    dependsOn: string[];
    customBuild?: ITestCustomBuild;
    settings?: ISelectedSettings;
}

export enum ETestSuiteAllocationType {
    divide = 'divide',
    replicate = 'replicate',
    sequential = 'sequential',
}

export enum ETestSuiteTestCaseLogType {
    testLogs = 'testLogs',
    caseLogs = 'caseLogs',
    caseReport = 'caseReport',
}
export type ITestSuiteResultsCount = {
    [key in ETestStatus]: number;
};

export type ITestSuiteDeviceResultsCount = {
    [deviceId: string]: ITestSuiteResultsCount;
};

export interface IScheduledTestSuitePayload {
    type: ETestSuiteAllocationType;
    testTags: string[];
    numberOfDevices: number;
    subscribeProjectToResult?: boolean;
}
