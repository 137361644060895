import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    CircularProgress,
    Alert,
    Box,
    Divider,
} from '@mui/material';
import { successToast } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../../store';
import {
    removeKey,
    removeNamespace,
    selectAcceleratorOptionsDialog,
    selectCurrentAccelerator,
    toggleAcceleratorOptionsDialog,
} from '../acceleratorSlice';
import { AcceleratorOptions } from '../enums';
import { IAcceleratorDetails, IAcceleratorsSlice } from '../interfaces';
import DeleteOptions from './DeleteOptions';

export default function AcceleratorOptionsDialog(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IAcceleratorsSlice>>();
    const open = useSelector(selectAcceleratorOptionsDialog);
    const selectedAccelerator = useSelector(selectCurrentAccelerator);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [action, setAction] = useState<string>();
    const [namespace, setNamespace] = useState<string>('');
    const [keyToRemove, setKeyToRemove] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (action === AcceleratorOptions.deleteNamespace) {
            setDisabled(!namespace);
        } else if (action === AcceleratorOptions.deleteKey) {
            setDisabled(!namespace || !keyToRemove);
        }
    }, [action, namespace, keyToRemove, setDisabled]);

    const handleActionChange = (event: React.MouseEvent) => {
        setAction((event.target as HTMLInputElement).value);
        setKeyToRemove('');
    };

    const handleClose = () => {
        dispatch(toggleAcceleratorOptionsDialog({ accelerator: undefined }));
        setAction('');
        setNamespace('');
        setKeyToRemove('');
        setError(undefined);
        setLoading(false);
    };

    const handleNamespaceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNamespace(e.target.value);
    };

    const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyToRemove(e.target.value);
    };

    const getAcceleratorPayload = (accelerator?: IAcceleratorDetails) => {
        if (accelerator) {
            return {
                ip: accelerator.ip,
                port: parseInt(accelerator.port),
                containerName: accelerator.serviceName,
                namespace,
                keyToRemove,
            };
        }
        return null;
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(undefined);
        const data = getAcceleratorPayload(selectedAccelerator);
        if (action === AcceleratorOptions.deleteKey && data) {
            const { payload } = await dispatch(removeKey(data));
            if (payload.status === 400) {
                setError(payload.message);
            } else {
                successToast(`Key removed`);
                toggleAcceleratorOptionsDialog({ accelerator: undefined });
            }
        }
        if (action === AcceleratorOptions.deleteNamespace && data) {
            const { payload } = await dispatch(removeNamespace(data));
            if (payload.status === 400) {
                setError(payload.message);
            } else {
                successToast(`Namespace removed`);
                toggleAcceleratorOptionsDialog({ accelerator: undefined });
            }
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ my: -1 }}>Edit Unity Accelerator</DialogTitle>
            <Divider />
            <DialogContent sx={{ my: 1 }}>
                <RadioGroup>
                    <FormControlLabel
                        value={AcceleratorOptions.deleteNamespace}
                        label='Delete accelerator namespace'
                        control={<Radio />}
                        onClick={handleActionChange}
                    />
                    <FormControlLabel
                        value={AcceleratorOptions.deleteKey}
                        label='Delete a key'
                        control={<Radio />}
                        onClick={handleActionChange}
                    />
                </RadioGroup>
                {action === AcceleratorOptions.deleteNamespace && (
                    <DeleteOptions
                        namespace={namespace}
                        onChangeKey={handleKeyChange}
                        onChangeNamespace={handleNamespaceChange}
                    />
                )}
                {action === AcceleratorOptions.deleteKey && (
                    <DeleteOptions
                        namespace={namespace}
                        onChangeKey={handleKeyChange}
                        onChangeNamespace={handleNamespaceChange}
                        hasKey
                    />
                )}
                {error && (
                    <Alert style={{ marginTop: 8 }} severity='error'>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ mt: -1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Button onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        disabled={disabled}
                        color='error'
                        variant='contained'
                        style={{ minWidth: '80px' }}
                    >
                        {loading ? <CircularProgress size={15} variant='indeterminate' /> : 'Delete'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
