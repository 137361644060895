import { ChangeEvent, ReactElement } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ETestSuiteAllocationType } from '../interfaces';

export default function SelectTestAllocation({
    testAllocation,
    onTestAllocationChange,
}: {
    testAllocation: ETestSuiteAllocationType;
    onTestAllocationChange: (testAllocation: ETestSuiteAllocationType) => void;
}): ReactElement {
    return (
        <Autocomplete
            size='small'
            options={[ETestSuiteAllocationType.divide, ETestSuiteAllocationType.replicate]}
            autoHighlight
            clearOnBlur
            clearOnEscape
            onChange={(e: ChangeEvent<any>, option: string | null) =>
                onTestAllocationChange((option as ETestSuiteAllocationType) || ETestSuiteAllocationType.divide)
            }
            getOptionLabel={(option) => {
                switch (option) {
                    case ETestSuiteAllocationType.replicate:
                        return 'Replicate (All the devices will run the selected tests)';
                    case ETestSuiteAllocationType.divide:
                    default:
                        return 'Divide (Tests will be divided between the selected devices)';
                }
            }}
            value={testAllocation}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete='off'
                    label='Test device'
                    placeholder='Select device'
                    variant='outlined'
                />
            )}
        />
    );
}
