import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { ColumnToggle } from '../commons/ColumnToggle';
import { selectBuildsColumns } from './buildsSlice';

export default function BuildActionBox(): ReactElement {
    const buildColumns = useSelector(selectBuildsColumns);

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                alignItems: { xs: 'flex-start', md: 'center' },
                flexDirection: { xs: 'column', md: 'row' },
            }}
        >
            <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 2, md: 0 }, ml: 'auto' }}>
                <ColumnToggle columns={buildColumns} target='builds' mode='button' />
            </Box>
        </Box>
    );
}
