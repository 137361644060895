import { ReactElement } from 'react';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import { Tooltip, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import { useCheckBatteryIsLow } from '../useCheckDeviceThresholds';

const BatteryIcon = {
    0: Battery0BarIcon,
    15: Battery1BarIcon,
    30: Battery2BarIcon,
    45: Battery3BarIcon,
    60: Battery4BarIcon,
    75: Battery5BarIcon,
    90: Battery6BarIcon,
    100: BatteryFullIcon,
};

export default function BatteryLevel({
    battery,
    isOffline,
    showOnlyOnError,
    size,
}: {
    battery?: number;
    showOnlyOnError?: boolean;
    isOffline?: boolean;
    size?: 'large' | 'medium' | 'small';
}): ReactElement | null {
    const isBatteryLow = useCheckBatteryIsLow(battery, isOffline);

    if (!battery || battery < 0 || (showOnlyOnError && !isBatteryLow)) {
        return null;
    }

    const Icon = getBatteryIcon(battery);
    let iconColor: 'secondary' | 'disabled' | 'error' = 'secondary';
    if (isOffline) {
        iconColor = 'disabled';
    } else if (isBatteryLow) {
        iconColor = 'error';
    }

    return (
        <Tooltip
            title={
                <Typography variant='subtitle2'>
                    {battery}%
                    {isBatteryLow
                        ? ' - The battery on this device is too low and it will not start new tests until charged to the minimum level.'
                        : undefined}
                </Typography>
            }
        >
            <Icon
                fontSize={size}
                color={iconColor}
                sx={iconColor === 'error' ? { fill: (theme) => theme.palette.error.main } : {}}
            />
        </Tooltip>
    );
}

function getBatteryIcon(battery: number): typeof SvgIcon {
    const level = battery === 100 ? battery : ((Math.floor(battery / 15) * 15) as keyof typeof BatteryIcon);
    return BatteryIcon[level];
}
