import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Slider,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { blueGray, successToast } from '@tactileentertainment/core-designsystem';
import moment from 'moment';
import { AppThunkDispatch } from '../../store';
import getBuildAgentName from '../commons/getBuildAgentName';
import isAddressValid from '../commons/isAddressValid';
import {
    deleteBuildAgent,
    editBuildAgent,
    getAvailableOptions,
    getBuildAgents,
    registerBuildAgents,
    selectBuildAgentDialog,
    toggleShowBuildAgentDialog,
} from './buildAgentsSlice';
import { AgentDialogActionType } from './enums';
import { BuildAgent, IBuildAgentSliceState } from './interfaces';

export default function BuildAgentDialog(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildAgentSliceState>>();
    const { open, action, buildAgent } = useSelector(selectBuildAgentDialog);

    const [address, setAddress] = useState('');
    const [priority, setPriority] = useState(25);
    const [enabled, setEnabled] = useState(true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [manuallyDisabled, setManuallyDisabled] = useState(false);
    const [disabledReason, setDisabledReason] = useState('');

    useEffect(() => {
        if (buildAgent) {
            setAddress(`${buildAgent.ip}:${buildAgent.port}`);
            setPriority(buildAgent.priority);
            setEnabled(buildAgent.enabled);
        } else {
            setAddress('');
            setPriority(25);
            setEnabled(true);
        }
    }, [buildAgent]);

    const handleClose = () => {
        dispatch(toggleShowBuildAgentDialog({ action, buildAgent }));
        setTimeout(() => {
            setLoading(false);
            setError('');
            if (buildAgent) {
                setPriority(buildAgent.priority);
                setEnabled(buildAgent.enabled);
            }
        }, 1000);
    };

    const handleSetPriority = (_event: any, newValue: number | number[]) => {
        setPriority(newValue as number);
    };

    const handleSetEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabled(event.target.checked);
        setManuallyDisabled(!event.target.checked);
    };

    const handleSetAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    const handleSetDisabledReason = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisabledReason(event.target.value);
    };

    const refreshPageAfterRequest = () => {
        dispatch(getAvailableOptions());
        dispatch(getBuildAgents());
    };

    const handleSubmit = () => {
        setLoading(true);
        setError('');

        if (!isAddressValid(address)) {
            setError('Invalid Address');
            setLoading(false);
            return;
        }

        switch (action) {
            case AgentDialogActionType.edit:
                editAgentRequest();
                break;
            case AgentDialogActionType.register:
                registerAgentRequest();
                break;
        }
    };

    const editAgentRequest = () => {
        if (buildAgent) {
            dispatch(editBuildAgent({ priority, enabled, buildAgentId: buildAgent._id, disabledReason }))
                .then(({ payload }) => {
                    setLoading(false);
                    if (payload?.buildAgent) {
                        successToast(`Build agent ${payload.buildAgent.hostname} has been saved`);
                        dispatch(toggleShowBuildAgentDialog({ buildAgent, action }));
                        setManuallyDisabled(false);
                        refreshPageAfterRequest();
                    } else {
                        setError(
                            payload?.error?.message
                                ? payload.error.message
                                : 'There was an error editing the build agent',
                        );
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setError('Could not edit the build agent: ' + error.message);
                    console.error(error);
                });
        }
    };

    const registerAgentRequest = () => {
        const [ip, port] = address.split(':');
        const registerPayload: { ip: string; port?: number; priority?: number; enabled?: boolean } = { ip };
        if (port) {
            registerPayload.port = parseInt(port);
        }
        if (priority) {
            registerPayload.priority = priority;
        }
        if (enabled) {
            registerPayload.enabled = enabled;
        }

        dispatch(registerBuildAgents(registerPayload))
            .then(({ payload }) => {
                setLoading(false);
                if (payload?.buildAgents?.length > 0) {
                    dispatch(toggleShowBuildAgentDialog({ buildAgent, action }));
                    successToast(`${payload.buildAgents.length} build agent(s) registered`);
                    refreshPageAfterRequest();
                } else {
                    setError(
                        payload?.error?.message
                            ? payload.error.message
                            : 'There was an error registering the build agent(s)',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                setError('There was an error registering the build agent(s): ' + error.message);
                console.error(error);
            });
    };

    const handleDelete = () => {
        setLoading(true);

        if (!isAddressValid(address)) {
            setError('Invalid Address');
            setLoading(false);
            return;
        } else if (!buildAgent) {
            setError('Build Agent ID not found');
            setLoading(false);
            return;
        }

        dispatch(deleteBuildAgent(buildAgent._id))
            .then(() => {
                setLoading(false);
                dispatch(toggleShowBuildAgentDialog({ buildAgent, action }));
                successToast(`Build agent deleted`);
                refreshPageAfterRequest();
            })
            .catch((error) => {
                setLoading(true);
                setError('There was an error deleting the build agent: ' + error.message);
                console.error(error);
            });
    };

    const { title, text, confirmText } = getTextFromAction(action, buildAgent);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ color: blueGray[800] }}>
                {title}
                {buildAgent?.updatedBy && (
                    <>
                        <Typography variant='subtitle1' color='text.secondary'>
                            Last Updated By {buildAgent?.updatedBy?.name}
                        </Typography>
                        <Typography variant='subtitle1' color='text.secondary'>
                            {`(${buildAgent?.updatedBy?.email})`}
                        </Typography>
                    </>
                )}
                <Divider sx={{ mx: -4, mt: 2 }} />
            </DialogTitle>
            <DialogContent>
                <>
                    <TextField
                        sx={{ my: 1, width: '-webkit-fill-available' }}
                        autoFocus={action === AgentDialogActionType.register}
                        disabled={action === AgentDialogActionType.edit}
                        value={address}
                        onChange={handleSetAddress}
                        required
                        label='Address'
                        placeholder='192.168.2.X:9300'
                        type='text'
                        variant='outlined'
                        autoComplete='off'
                    />
                    <Typography variant='body1' color='text.secondary'>
                        {text}
                    </Typography>
                    <Box sx={{ mt: 3, mb: 1 }}>
                        <Slider
                            defaultValue={50}
                            value={priority as number}
                            onChange={handleSetPriority}
                            valueLabelDisplay='auto'
                            step={5}
                            marks={[
                                { value: 5, label: 'Low' },
                                { value: 25, label: 'Medium' },
                                { value: 45, label: 'High' },
                            ]}
                            min={0}
                            max={50}
                            sx={{ color: (theme) => theme.palette.primary.main }}
                        />
                    </Box>
                    <FormControlLabel
                        control={<Switch checked={enabled} onChange={handleSetEnabled} />}
                        label='Allow new builds on agent'
                    />
                    <Collapse in={manuallyDisabled}>
                        <TextField
                            sx={{ margin: 1, width: '-webkit-fill-available' }}
                            onChange={handleSetDisabledReason}
                            label='Disabled reason'
                            placeholder='agent maintenance..'
                            type='text'
                            variant='outlined'
                            autoComplete='off'
                        />
                    </Collapse>
                    {error && <Alert severity='error'>{error}</Alert>}
                    {(buildAgent?.manuallyDisabled || buildAgent?.disabledReason) && (
                        <Alert severity='info'>
                            {buildAgent?.manuallyDisabled && (
                                <>
                                    This agent was manually disabled on{' '}
                                    {moment(buildAgent.disabledDate).format('DD-MM-YYYY HH:mm')}.
                                </>
                            )}

                            {buildAgent?.manuallyDisabled && buildAgent?.disabledReason && <br />}

                            {buildAgent?.disabledReason && <>Disabled reason: {buildAgent.disabledReason}</>}
                        </Alert>
                    )}
                </>
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        {!!buildAgent && (
                            <Button onClick={handleDelete} color='error'>
                                Delete
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button onClick={handleClose} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            variant='contained'
                            disabled={!address || loading}
                            color='primary'
                            style={{ minWidth: '80px' }}
                        >
                            {loading ? <CircularProgress size={15} variant='indeterminate' /> : confirmText}
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}

function getTextFromAction(
    action: AgentDialogActionType,
    buildAgent?: BuildAgent,
): { title: string; text: string; confirmText: string } {
    switch (action) {
        case AgentDialogActionType.edit:
            return {
                title: `Edit ${buildAgent ? getBuildAgentName(buildAgent) : ''}`,
                text: 'To change the address, please delete and re-register with the agent.',
                confirmText: 'Save',
            };
        case AgentDialogActionType.register:
            return {
                title: `Register Agent(s)`,
                text: 'Provide ip/domain address to register agent to the build server',
                confirmText: 'Register',
            };
        default:
            return {
                title: '',
                text: '',
                confirmText: '',
            };
    }
}
