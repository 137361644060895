import { ReactElement } from 'react';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { SxProps } from '@mui/material';
import { SVGIcon, SVGIcons, SVGSizes } from '../../assets/SVGIcon';
import { EBuildPlatform } from '../builds/enums';

interface PlatformIconProps {
    platform: EBuildPlatform | string;
    useMobile?: boolean;
    disableDisplayBehavior?: boolean;
    sx?: SxProps;
}

export default function PlatformIcon({
    platform,
    useMobile,
    disableDisplayBehavior = true,
    sx,
}: PlatformIconProps): ReactElement | null {
    switch (platform) {
        case EBuildPlatform.ios:
            return (
                <AppleIcon
                    sx={{
                        color: (theme) => theme.palette.tactile.blueGray[800],
                        verticalAlign: 'middle',
                        ...sx,
                    }}
                    fontSize={useMobile ? 'small' : 'medium'}
                />
            );

        case EBuildPlatform.android:
            return (
                <AndroidIcon
                    sx={{
                        color: (theme) => theme.palette.tactile.green[600],
                        verticalAlign: 'middle',
                        ...sx,
                    }}
                    fontSize={useMobile ? 'small' : 'medium'}
                />
            );

        case EBuildPlatform.macos:
        case EBuildPlatform.osx:
        case EBuildPlatform.kindle:
        case EBuildPlatform.appletv:
        case EBuildPlatform.tvos:
        case EBuildPlatform.webgl:
        case EBuildPlatform.udp:
            return (
                <SVGIcon
                    icon={platform as unknown as SVGIcons}
                    size={useMobile ? SVGSizes.small : SVGSizes.large}
                    disableDisplayBehavior={disableDisplayBehavior}
                    sx={{ ...sx, height: 'auto', width: 'auto' }}
                />
            );

        default:
            return null;
    }
}
