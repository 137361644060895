import { useEffect, useMemo } from 'react';
import { openSocket } from '../sockets';
import { ITest, runningTestStatus } from './interfaces';
import { getTestsQueue, TestQueue } from './testQueue';
import { useGetTestsQuery } from './testsApi';

export function useTestsQueue(): TestQueue {
    const { data: testsData, refetch: refetchRunningTests } = useGetTestsQuery({ status: runningTestStatus });
    const testsQueue: TestQueue = useMemo(() => getTestsQueue(testsData?.tests), [testsData]);

    useEffect(() => {
        const handleTestUpdate = ({ test }: { test: ITest }) => {
            const runningTest = testsData?.tests?.find((item) => item._id === test._id);
            if (runningTest && runningTest.status !== test.status) {
                refetchRunningTests();
            }
        };

        const socket = openSocket('tests');
        socket.on('new-test', refetchRunningTests);
        socket.on('update-test', handleTestUpdate);

        return () => {
            socket.off('new-test', refetchRunningTests);
            socket.off('update-test', handleTestUpdate);
        };
    }, [testsData, refetchRunningTests]);

    return testsQueue;
}
