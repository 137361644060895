import { ReactElement, useEffect, useState } from 'react';
import BuildDuration from '../../builds/BuildDuration';
import BuildNumber from '../../builds/BuildNumber';
import BuildPlatform from '../../builds/BuildPlatform';
import BuildProjectName from '../../builds/BuildProjectName';
import Author from '../../commons/Author';
import ClickableTableRow from '../../commons/ClickableTableRow';
import { IToggleColumn } from '../../commons/interfaces';
import TableTextCell from '../../commons/TableTextCell';
import { openSocket } from '../../sockets';
import { ITest } from '../interfaces';
import { TestsColumnId } from '../testsColumns';
import TestActionsCell from './TestActionsCell';
import TestDeviceCell from './TestDeviceCell';
import TestNumberCell from './TestNumberCell';
import TestStatusCell from './TestStatusCell';

export interface ITestRowProps {
    test: ITest;
    onTestUpdated?: (test: ITest) => void;
    columns: Array<IToggleColumn<TestsColumnId>>;
}

export default function TestRow(props: ITestRowProps): ReactElement {
    const { columns, onTestUpdated } = props;
    const [test, setTest] = useState<ITest>(props.test);

    useEffect(() => {
        setTest(props.test);
    }, [props.test]);

    useEffect(() => {
        const socket = openSocket('tests');
        const handleTestUpdate = ({ test }: { test: ITest }) => {
            if (test._id === props.test._id) {
                setTest(test);
                if (onTestUpdated) {
                    onTestUpdated(test);
                }
            }
        };

        socket.on('update-test', handleTestUpdate);

        return () => {
            socket.off('update-test', handleTestUpdate);
        };
    }, [props.test, onTestUpdated]);

    return (
        <ClickableTableRow redirectUrl={`/tests/${test.projectName}/${test.testNumber}`} data-testid='test-row'>
            {columns.map((column) => {
                switch (column.id) {
                    case TestsColumnId.testNumber:
                        return (
                            <TestNumberCell
                                key={column.id}
                                align={column.align}
                                projectName={test.projectName}
                                testNumber={test.testNumber}
                            />
                        );

                    case TestsColumnId.projectName:
                        return (
                            <BuildProjectName
                                key={column.id}
                                align={column.align}
                                projectName={test.projectName}
                                testNumber={test.testNumber}
                            />
                        );

                    case TestsColumnId.status:
                        return <TestStatusCell key={column.id} test={test} />;

                    case TestsColumnId.testBranch:
                        return <TableTextCell key={column.id} align={column.align} text={test.testBranch || '-'} />;

                    case TestsColumnId.buildBranch:
                        return <TableTextCell key={column.id} align={column.align} text={test.build.branch} />;

                    case TestsColumnId.buildType:
                        return <TableTextCell key={column.id} align={column.align} text={test.build.type} />;

                    case TestsColumnId.buildNumber:
                        return (
                            <BuildNumber
                                key={column.id}
                                align={column.align}
                                projectName={test.build.projectName}
                                buildNumber={test.build.buildNumber}
                            />
                        );

                    case TestsColumnId.version:
                        return <TableTextCell key={column.id} align={column.align} text={test.build.versionName} />;

                    case TestsColumnId.platform:
                        return <BuildPlatform key={column.id} align={column.align} platform={test.build.platform} />;

                    case TestsColumnId.testDevice:
                        return <TestDeviceCell key={column.id} align={column.align} device={test.testDevice} />;

                    case TestsColumnId.runtime:
                        return (
                            <BuildDuration
                                key={column.id}
                                align={column.align}
                                started={test.startedAt}
                                completed={test.completedAt}
                            />
                        );

                    case TestsColumnId.author:
                        return <Author key={column.id} align={column.align} author={test.author} />;

                    case TestsColumnId.actions:
                        return <TestActionsCell key={column.id} align={column.align} test={test} />;

                    default:
                        return <TableTextCell key={column.id} align={column.align} text={test[column.id]} />;
                }
            })}
        </ClickableTableRow>
    );
}
