import React, { ReactElement, useState } from 'react';
import { Box, Chip, Popover, TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../typings/material-ui';
import TestNumberLink from '../tests/TestNumberLink';
import BuildNumberLink from './BuildNumberLink';

interface BuildProjectNameProps {
    align?: MuiAlignSetting;
    projectName: string;
    expedited?: boolean;
    childBuilds?: number;
    buildNumber?: string;
    testNumber?: number;
}

export default function BuildProjectName(props: BuildProjectNameProps): ReactElement {
    const { align, projectName, expedited, childBuilds, buildNumber, testNumber } = props;
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

    const open = Boolean(anchorElement);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorElement(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorElement(null);
    };

    return (
        <TableCell
            align={align}
            sx={{ padding: 1 }}
            data-testid='builds-table-build-projectName'
            aria-owns={open ? 'mouse-over-project-name' : undefined}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Box sx={{ display: 'block' }}>
                <Box style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {projectName}

                    {expedited && (
                        <>
                            <Popover
                                id='mouse-over-project-name'
                                sx={{
                                    pointerEvents: 'none',
                                    '.MuiPaper-root': {
                                        padding: 1,
                                    },
                                }}
                                open={open}
                                anchorEl={anchorElement}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                                disableScrollLock
                            >
                                Expedited build
                            </Popover>
                        </>
                    )}
                    {childBuilds && <Chip label={childBuilds} size='small' sx={{ ml: 0.5 }} />}
                </Box>
                {projectName && buildNumber && <BuildNumberLink projectName={projectName} buildNumber={buildNumber} />}
                {projectName && testNumber && <TestNumberLink projectName={projectName} testNumber={testNumber} />}
            </Box>
        </TableCell>
    );
}
