import { ReactElement } from 'react';
import { Divider, Grid, Paper } from '@mui/material';
import { AvatarChip } from '@tactileentertainment/core-designsystem';
import moment from 'moment';
import BuildNumberLink from '../../builds/BuildNumberLink';
import BuildStatusChip from '../../commons/BuildStatusChip';
import Duration from '../../commons/Duration';
import GridItem from '../../commons/GridItem';
import { IBuild } from '../../commons/interfaces';
import TestAgentsLink from '../../testAgents/TestAgentsLink';
import { ITestDevice, ETestDeviceState } from '../../testDevices/interfaces';
import TestDeviceLink from '../../testDevices/TestDeviceLink';
import { ETestStatus, ITest } from '../interfaces';
import TestBranchLink from '../TestBranchLink';
import CancelTestAction from './CancelTestAction';

export default function TestInfo({ test }: { test: ITest }): ReactElement {
    return (
        <Paper variant='outlined' elevation={0} sx={{ px: 2, py: 1 }}>
            <Grid container justifyContent='space-between' spacing={1}>
                <Grid item container wrap='nowrap'>
                    <BuildInfo build={test.build} />
                    <TestStatusInfo test={test} />
                </Grid>
                <Divider orientation='horizontal' sx={{ width: 'calc(100% + 8px)', mt: 1 }} />
                <Grid item container wrap='nowrap'>
                    <TestDeviceInfo testDevice={test.testDevice} />
                    <Divider orientation='vertical' flexItem sx={{ mx: 4, my: -1 }} />
                    <TestRuntimeInfo test={test} />
                </Grid>
            </Grid>
        </Paper>
    );
}

function BuildInfo({ build }: { build: IBuild }): ReactElement {
    return (
        <Grid
            container
            item
            spacing={1}
            justifyContent='space-between'
            data-testid='test-details-build-info'
            sx={{ mr: 6 }}
        >
            <GridItem label='Project'>{build.projectName}</GridItem>
            <GridItem label='Build Branch'>{build.branch}</GridItem>
            <GridItem label='Build Type'>{build.type}</GridItem>
            <GridItem label='Build ID'>
                <BuildNumberLink projectName={build.projectName} buildNumber={build.buildNumber} sx={{ mr: 1 }} />
                <BuildStatusChip status={build.status} forcedMobile size={18} />
            </GridItem>
            <GridItem label='Revision'>{build.revision}</GridItem>
            <GridItem label='Version'>{build.versionName}</GridItem>
        </Grid>
    );
}

function TestStatusInfo({ test }: { test: ITest }): ReactElement {
    return (
        <Grid
            container
            item
            spacing={1}
            sx={{ flexFlow: { xs: 'wrap', sm: 'nowrap' } }}
            justifyContent='space-between'
            data-testid='test-details-status-info'
        >
            <GridItem label='Test Branch' xs={12}>
                {test.testBranch ? (
                    <TestBranchLink
                        projectName={test.projectName}
                        testBranch={test.testBranch}
                        commitSha={test.commitSha}
                    />
                ) : (
                    '-'
                )}
            </GridItem>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                {[ETestStatus.waiting, ETestStatus.queued, ETestStatus.running].includes(test.status) && (
                    <>
                        <CancelTestAction test={test} variant='outlined' />
                    </>
                )}
            </Grid>
        </Grid>
    );
}

function TestDeviceInfo({ testDevice }: { testDevice?: ITestDevice }): ReactElement {
    if (!testDevice) {
        return (
            <Grid container item spacing={1} justifyContent='space-between'>
                <GridItem label='UUID' color='secondary'>
                    Not selected yet
                </GridItem>
                <GridItem label='Model' color='secondary'>
                    -
                </GridItem>
                <GridItem label='Manufacturer' color='secondary'>
                    -
                </GridItem>
                <GridItem label='Platform' color='secondary'>
                    -
                </GridItem>
                <GridItem label='Test Agent' color='secondary'>
                    -
                </GridItem>
                <GridItem label='Connected' color='secondary'>
                    -
                </GridItem>
            </Grid>
        );
    }

    return (
        <Grid
            container
            item
            spacing={1}
            xs={5}
            md={12}
            justifyContent='space-between'
            data-testid='test-details-device-info'
        >
            <GridItem label='UUID' sx={{ overflow: 'hidden' }}>
                <TestDeviceLink uuid={testDevice.uuid} />
            </GridItem>
            <GridItem label='Model'>{testDevice.deviceInfo.model}</GridItem>
            <GridItem label='Manufacturer'>{testDevice.deviceInfo.manufacturer}</GridItem>
            <GridItem label='Platform'>{testDevice.platform}</GridItem>
            <GridItem label='Test Agent'>
                {testDevice.testAgent && <TestAgentsLink title={testDevice.testAgent.hostname} />}
            </GridItem>
            <GridItem label='Connected'>{testDevice.state === ETestDeviceState.offline ? 'No' : 'Yes'}</GridItem>
        </Grid>
    );
}

function TestRuntimeInfo({ test }: { test: ITest }): ReactElement {
    return (
        <Grid container item spacing={1} justifyContent='space-between' data-testid='test-details-runtime-info'>
            {test.status === ETestStatus.waiting && <GridItem label='Created'>{formatDate(test.createdAt)}</GridItem>}
            {test.status === ETestStatus.queued && (
                <GridItem label='Queued'>{formatDate(test.queuedAt || test.createdAt)}</GridItem>
            )}
            <GridItem label='Started' color={test.startedAt ? 'primary' : 'secondary'}>
                {test.startedAt ? formatDate(test.startedAt) : '-'}
            </GridItem>
            <GridItem label='Ended' color={test.completedAt ? 'primary' : 'secondary'}>
                {test.completedAt ? formatDate(test.completedAt) : '-'}
            </GridItem>
            <GridItem label='Duration' color={test.startedAt ? 'primary' : 'secondary'}>
                {test.startedAt ? <Duration started={test.startedAt} completed={test.completedAt} /> : '-'}
            </GridItem>
            <GridItem label='Created By' align='center'>
                <AvatarChip
                    label={test.author.email}
                    altLabel={test.author.name}
                    src={test.author.imageUrl}
                    sx={{ maxWidth: { xs: '120px', sm: 'fit-content' } }}
                />
            </GridItem>
            {test.updatedBy && (
                <GridItem label='Updated By' align='center'>
                    <AvatarChip
                        label={test.updatedBy.email}
                        altLabel={test.updatedBy.name}
                        src={test.updatedBy.imageUrl}
                        sx={{ maxWidth: { xs: '120px', sm: 'fit-content' } }}
                    />
                </GridItem>
            )}
        </Grid>
    );
}

function formatDate(date?: string | Date): string | undefined {
    return date ? moment(date).format('DD-MM-YYYY HH:mm') : undefined;
}
