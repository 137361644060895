import { ESelectedProperty, ISelectedTestCase } from '../../../interfaces';

export function selectAllTestCases(
    testCases: ISelectedTestCase[], // expects array of test cases already sorted by dependencies
    property: ESelectedProperty,
    value: boolean,
): ISelectedTestCase[] {
    const selectedTestCases = [...testCases];

    if (property === ESelectedProperty.videoRecording) {
        return selectedTestCases.map((testCase) => {
            if (testCase.visible) {
                return {
                    ...testCase,
                    [property]: value,
                };
            }
            return testCase;
        });
    }

    if (value) {
        selectAllWithDependencies(selectedTestCases);
    } else {
        unselectAllWithDependencies(selectedTestCases);
    }

    return selectedTestCases;
}

function selectAllWithDependencies(testCases: ISelectedTestCase[]) {
    const selectedDependencies: string[] = [];
    for (let index = testCases.length - 1; index >= 0; index -= 1) {
        const testCase = testCases[index];
        const isDependencySelected = selectedDependencies.includes(testCase.testName);
        if (testCase.selected || isDependencySelected || testCase.visible) {
            testCases[index] = {
                ...testCase,
                selected: true,
            };
            selectedDependencies.push(testCase.testName, ...testCase.dependsOn);
        }
    }
}

function unselectAllWithDependencies(testCases: ISelectedTestCase[]) {
    const unselectedDependencies: string[] = [];
    for (let index = 0; index < testCases.length; index += 1) {
        const testCase = testCases[index];
        const isDependencyUnselected = Boolean(
            testCase.dependsOn.find((deps) => unselectedDependencies.includes(deps)),
        );
        if (!testCase.selected || isDependencyUnselected || testCase.visible) {
            testCases[index] = {
                ...testCase,
                selected: false,
            };
            unselectedDependencies.push(testCase.testName);
        }
    }
}
