import { ReactElement } from 'react';
import { Skeleton, TableRow, TableCell } from '@mui/material';

export default function GameModuleProjectsLoadingRows({ rows = 10 }: { rows?: number }): ReactElement {
    const array = [...Array(rows)].map((_value, index) => index);

    return (
        <>
            {array.map((key) => (
                <LoadingRow key={key} />
            ))}
        </>
    );
}

function LoadingRow(): ReactElement {
    const bigRand = Math.random() * 0.5 + 0.5;

    return (
        <TableRow data-testid='game-module-projects-loading-row'>
            <TableCell>
                <Skeleton variant='rectangular' width={`${bigRand * 200}px`} />
            </TableCell>
            <TableCell>
                <Skeleton variant='rectangular' width={`${bigRand * 150}px`} />
            </TableCell>
            <TableCell>
                <Skeleton variant='rectangular' sx={{ maxWidth: 75 }} />
            </TableCell>
            <TableCell>
                <Skeleton variant='rectangular' sx={{ height: 24, maxWidth: 100 }} />
            </TableCell>
        </TableRow>
    );
}
