import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { TableCell, TableRow, Skeleton } from '@mui/material';
import { TestsColumnId } from '../testsColumns';
import { selectTestsColumns } from '../testsSlice';

export default function TestLoadingRows({ rows = 5 }: { rows?: number }): ReactElement {
    const array = [...Array(rows)].map((_value, index) => index);

    return (
        <>
            {array.map((key) => (
                <LoadingRow key={key} random={Math.random() * 0.5 + 0.5} />
            ))}
        </>
    );
}

function LoadingRow({ random }: { random: number }): ReactElement {
    const columns = useSelector(selectTestsColumns);

    return (
        <TableRow data-testid='test-loading-row' sx={{ height: 62 }}>
            {columns
                .filter((col) => col.visible)
                .map((column, index) => {
                    switch (column.id) {
                        case TestsColumnId.status:
                            return (
                                <TableCell key={index} sx={{ px: { xs: 2, sm: 4 } }}>
                                    <Skeleton
                                        variant='rectangular'
                                        width={`${random * 60}%`}
                                        height={30}
                                        style={{ borderRadius: '16px' }}
                                    />
                                </TableCell>
                            );
                        case TestsColumnId.platform:
                        case TestsColumnId.author:
                            return (
                                <TableCell key={index} sx={{ padding: 1 }}>
                                    <Skeleton
                                        variant='rounded'
                                        width={32}
                                        height={32}
                                        sx={{ mx: 'auto', borderRadius: '50%' }}
                                    />
                                </TableCell>
                            );

                        case TestsColumnId.actions:
                            return (
                                <TableCell key={index} sx={{ padding: 1 }}>
                                    <Skeleton variant='rectangular' width={32} height={32} sx={{ mx: 'auto' }} />
                                </TableCell>
                            );

                        default:
                            return (
                                <TableCell key={index} style={{ padding: 1 }}>
                                    <Skeleton variant='rectangular' width={`${random * 85}%`} height={15} />
                                </TableCell>
                            );
                    }
                })}
        </TableRow>
    );
}
