import { ReactElement } from 'react';
import { Chip, TableCell } from '@mui/material';

export default function BuildAgentChip({ values }: { values: string[] | string }): ReactElement {
    values = Array.isArray(values) ? values : [values];

    if (!values || values.length < 1) {
        return <TableCell />;
    }

    const sortedValues = [...values].sort();

    return (
        <TableCell sx={{ py: 2, px: 1 }} align='left'>
            {sortedValues.map((value) => (
                <Chip size='small' sx={{ m: 0.25 }} key={value} label={value} />
            ))}
        </TableCell>
    );
}
