import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableCell } from '@mui/material';
import { blueGray, errorToast, successToast } from '@tactileentertainment/core-designsystem';
import { SVGIcon, SVGIcons } from '../../assets/SVGIcon';
import { AppThunkDispatch } from '../../store';
import { deleteAccelerator, toggleAcceleratorOptionsDialog } from './acceleratorSlice';
import { IAcceleratorDetails, IAcceleratorsSlice } from './interfaces';

export default function AcceleratorActions({
    acceleratorDetails,
}: {
    acceleratorDetails: IAcceleratorDetails;
}): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IAcceleratorsSlice>>();
    const handleClick = () => dispatch(toggleAcceleratorOptionsDialog({ accelerator: acceleratorDetails }));
    const handleDeleteClick = () => {
        dispatch(deleteAccelerator(acceleratorDetails._id))
            .then(() => {
                window.location.reload();
                successToast(`Accelerator deleted`);
            })
            .catch((error: { message: string }) => {
                errorToast('There was an error deleting the accelerator: ' + error.message);
            });
    };
    const { ip, port } = acceleratorDetails;
    const address = `${ip}:${port}`;

    return (
        <TableCell>
            <IconButton
                size='small'
                title='View Accelerator Dashboard'
                target={'_blank'}
                href={`http://${address}/dashboard/#/metrics-page/`}
            >
                <SVGIcon icon={SVGIcons.unity} />
            </IconButton>
            <IconButton
                size='small'
                title='Edit Accelerator'
                onClick={handleClick}
                sx={{
                    color: blueGray[800],
                    ml: 3,
                }}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                size='small'
                title='Delete Accelerator'
                onClick={handleDeleteClick}
                sx={{
                    color: blueGray[800],
                    ml: 3,
                }}
            >
                <DeleteIcon />
            </IconButton>
        </TableCell>
    );
}
