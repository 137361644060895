import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MoreVertRounded as KebabIcon } from '@mui/icons-material';
import { Box, Button, FormControlLabel, ListItemButton, ListItemText, Menu, Switch } from '@mui/material';
import { SquareButton, errorToast, successToast } from '@tactileentertainment/core-designsystem';
import { IProjectDraft } from '../interfaces';
import { useAddProjectMutation, useDeleteProjectMutation, useUpdateProjectMutation } from '../projectsApi';
import DeleteProjectConfirmDialog from './DeleteProjectConfirmDialog';

export interface IEditProjectActionsProps {
    isNew: boolean;
    projectDraft: IProjectDraft;
    onEnableChange: (value: boolean) => void;
}

export default function EditProjectActions({
    isNew,
    projectDraft,
    onEnableChange,
}: IEditProjectActionsProps): ReactElement {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [addProject] = useAddProjectMutation();
    const [updateProject] = useUpdateProjectMutation();
    const [deleteProject] = useDeleteProjectMutation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClose = (): void => setAnchorEl(null);
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const disableSendButton = !projectDraft.name || !projectDraft.svnPath || !projectDraft.agents;

    const handleSaveProject = async () => {
        const result = await (isNew ? addProject : updateProject)({ projectDraft });

        if (!('error' in result)) {
            successToast('Project saved');
            history.push('/projects');
        } else {
            errorToast('Could not save the project');
        }
    };

    const handleDeleteProject = async () => {
        const result = await deleteProject({ projectId: projectDraft._id || '' });

        if (!('error' in result)) {
            successToast('Project deleted');
            history.push('/projects');
        } else {
            errorToast('Could not delete the project');
        }
        setOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 2,
                px: 0.5,
            }}
        >
            {!isNew && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={projectDraft.isEnabled}
                            onChange={(event) => onEnableChange(event.target.checked as boolean)}
                            color='primary'
                        />
                    }
                    label='Enable Project'
                />
            )}
            <Button variant='contained' color='primary' disabled={disableSendButton} onClick={handleSaveProject}>
                Save
            </Button>
            {!isNew && (
                <>
                    <DeleteProjectConfirmDialog
                        open={open}
                        onConfirmClick={handleDeleteProject}
                        onClose={() => setOpen(false)}
                    />
                    <SquareButton
                        color='secondary'
                        variant='outlined'
                        sx={{ backgroundColor: 'background.default', zIndex: 1201, ml: 2 }}
                        onClick={handleMenuOpen}
                    >
                        <KebabIcon />
                    </SquareButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={handleMenuClose}
                        disableAutoFocusItem
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        sx={{
                            borderRadius: 0.75,
                            zIndex: 1200,
                            '& .MuiPaper-root': {
                                backgroundColor: (theme) => theme.palette.background.default,
                                color: (theme) => theme.palette.primary.main,
                            },
                        }}
                    >
                        <span onMouseLeave={handleMenuClose}>
                            <ListItemButton onClick={() => setOpen(true)}>
                                <ListItemText primary='Delete Project' />
                            </ListItemButton>
                        </span>
                    </Menu>
                </>
            )}
        </Box>
    );
}
