import { ReactElement } from 'react';
import { TextField } from '@mui/material';

export default function MaxDevicesNumber({
    maxDevices,
    onMaxDevicesChange,
}: {
    maxDevices: number;
    onMaxDevicesChange: (maxDevices: number) => void;
}): ReactElement {
    return (
        <TextField
            style={{ width: '10%' }}
            id='max-devices-input'
            label='Max Devices'
            variant='outlined'
            size='small'
            type='number'
            value={maxDevices}
            onChange={(e) => onMaxDevicesChange(parseInt(e.target.value) || 0)}
        />
    );
}
