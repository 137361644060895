import { useGetTestDevicesSettingsQuery } from './testDevicesApi';

export function useCheckBatteryIsLow(battery?: number, isOffline?: boolean): boolean {
    const { data: devicesSettings } = useGetTestDevicesSettingsQuery();

    const isBatteryLow =
        !isOffline && battery !== undefined && devicesSettings && battery < devicesSettings.minBatteryLevel;

    return Boolean(isBatteryLow);
}

export function useCheckTemperatureIsHigh(temperature?: number, isOffline?: boolean): boolean {
    const { data: devicesSettings } = useGetTestDevicesSettingsQuery();

    const isTemperatureHigh =
        !isOffline && temperature !== undefined && devicesSettings && temperature > devicesSettings.maxTemperature;

    return Boolean(isTemperatureHigh);
}
