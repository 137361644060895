import { ReactElement } from 'react';
import {
    Check as CheckIcon,
    ErrorOutlineOutlined as ErrorIcon,
    InfoOutlined as InfoIcon,
    FlagOutlined as FlagIcon,
    ReportProblemOutlined as WarningIcon,
    SkipNextOutlined as SkipIcon,
} from '@mui/icons-material';
import { SvgIconProps, SxProps } from '@mui/material';
import { TactileTheme } from '@tactileentertainment/core-designsystem';

type MessageIconType = 'error' | 'warning' | 'info' | 'flag' | 'skip' | 'check';
export default function MessageIcon({
    icon,
    fontSize = 'medium',
    title,
    sx = {},
}: {
    icon: MessageIconType;
    fontSize?: SvgIconProps['fontSize'];
    title?: string;
    sx?: SxProps<TactileTheme>;
}): ReactElement {
    switch (icon) {
        case 'error':
            return (
                <ErrorIcon
                    titleAccess={title}
                    fontSize={fontSize}
                    sx={{ ...sx, color: (theme) => theme.palette.error.main }}
                />
            );
        case 'warning':
            return (
                <WarningIcon
                    titleAccess={title}
                    fontSize={fontSize}
                    sx={{ ...sx, color: (theme) => theme.palette.warning.main }}
                />
            );
        case 'info':
            return (
                <InfoIcon
                    titleAccess={title}
                    fontSize={fontSize}
                    sx={{ ...sx, color: (theme) => theme.palette.info.main }}
                />
            );
        case 'flag':
            return (
                <FlagIcon
                    titleAccess={title}
                    fontSize={fontSize}
                    sx={{ ...sx, color: (theme) => theme.palette.warning.main }}
                />
            );
        case 'skip':
            return (
                <SkipIcon
                    titleAccess={title}
                    fontSize={fontSize}
                    sx={{ ...sx, color: (theme) => theme.palette.info.main }}
                />
            );
        case 'check':
            return (
                <CheckIcon
                    titleAccess={title}
                    fontSize={fontSize}
                    sx={{ ...sx, color: (theme) => theme.palette.success.main }}
                />
            );
        default:
            return <></>;
    }
}
