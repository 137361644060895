import { ReactElement } from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useAuthUser } from '@tactileentertainment/core-shared.auth-react';
import SlackIdSelector from './SlackIdSelector';
import { useGetCurrentUserQuery, useUpdatePreferencesMutation } from './usersApi';

export default function UserPreferences(): ReactElement | null {
    const { user } = useAuthUser();
    const { data: userData } = useGetCurrentUserQuery();
    const preferences = userData?.user?.preferences;
    const [updatePreferences] = useUpdatePreferencesMutation();

    const handleSubscribeToResult = (event: React.ChangeEvent<HTMLInputElement>) => {
        updatePreferences({
            ...(preferences || {}),
            subscribeToResult: Boolean(event.target.checked),
        });
    };

    const handleSlackIdChange = (slackId?: string) => {
        updatePreferences({
            ...(preferences || {}),
            slackId,
        });
    };

    if (!user) {
        return null;
    }

    return (
        <Box>
            <Box display='flex' alignItems='center' sx={{ my: 1 }}>
                <SlackIdSelector slackId={preferences?.slackId} email={user.email} onChange={handleSlackIdChange} />
            </Box>
            <Box sx={{ ml: 0.25, mt: 2 }}>
                <FormControlLabel
                    control={<Switch checked={preferences?.subscribeToResult} onChange={handleSubscribeToResult} />}
                    label={
                        <>
                            <Typography>Slack Notifications</Typography>
                            <Typography variant='caption'>
                                Receive notifications on Slack when my builds or tests are done
                            </Typography>
                        </>
                    }
                    labelPlacement='end'
                />
            </Box>
        </Box>
    );
}
