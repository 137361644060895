import { ESelectedProperty, ISelectedTestCase } from '../../../interfaces';

interface SelectTestCaseOptions {
    testCases: ISelectedTestCase[];
    id: string;
    property: ESelectedProperty;
    value: ISelectedTestCase[ESelectedProperty];
}

export function selectTestCase({ testCases, id, property, value }: SelectTestCaseOptions): ISelectedTestCase[] {
    const selectedTestCase = testCases.find((testCase) => testCase.id === id);
    if (!selectedTestCase) {
        return testCases;
    }

    const selectedTestCases = [...testCases];
    for (let index = 0; index < selectedTestCases.length; index += 1) {
        const testCase = selectedTestCases[index];

        const isSelectedByDependency =
            selectedTestCase.dependsOn.includes(testCase.testName) && property === ESelectedProperty.selected && value;
        const isUnselectedByDependency =
            testCase.dependsOn.includes(selectedTestCase.testName) && property === ESelectedProperty.selected && !value;

        if (testCase.id === selectedTestCase.id || isSelectedByDependency || isUnselectedByDependency) {
            selectedTestCases[index] = {
                ...testCase,
                [property]: value,
            };
        }
    }

    return selectedTestCases;
}
