import { Box, Typography } from '@mui/material';
import { IBuild } from '../commons/interfaces';

interface BuildSuccessToastContentProps {
    build: IBuild;
}

export default function BuildSuccessToastContent({ build }: BuildSuccessToastContentProps) {
    return (
        <>
            {`The build ${build.buildNumber} ordered successfully`}
            <Box display='flex' gap={1} alignItems='center'>
                Check your build here:
                <Typography
                    component='a'
                    target='_blank'
                    rel='noreferrer'
                    href={`${window.origin}/builds/${build.projectName}/${build.buildNumber}`}
                    sx={{ textDecoration: 'none' }}
                    color='primary'
                    fontWeight={600}
                >
                    {`#${build.buildNumber}`}
                </Typography>
            </Box>
        </>
    );
}
