import { ReactElement } from 'react';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import { DebounceTextField } from '../DebounceTextField';

interface SearchFieldProps {
    value?: string;
    timeout?: number;
    onSearch: (value?: string) => void;
}

export default function SearchField({
    value,
    timeout,
    onSearch,
    ...props
}: SearchFieldProps & TextFieldProps): ReactElement {
    const TextFieldComponent = timeout ? DebounceTextField : TextField;

    return (
        <TextFieldComponent
            data-testid='search-field'
            id='search-field'
            type='text'
            variant='outlined'
            autoComplete='off'
            timeout={timeout}
            onClick={(e) => e.stopPropagation()}
            {...props}
            style={{ ...props.style }}
            value={value}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
                ...(props.InputProps || {}),
                endAdornment: (
                    <InputAdornment
                        style={props.disabled ? undefined : { cursor: 'pointer' }}
                        position='end'
                        onClick={() => value && onSearch(undefined)}
                    >
                        {value ? <ClearIcon /> : <SearchIcon />}
                    </InputAdornment>
                ),
            }}
        />
    );
}
