import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableHead, useMediaQuery } from '@mui/material';
import ContentLoading from '../../commons/ContentLoading';
import HeaderSection from '../../commons/HeaderSection';
import Page from '../../commons/Page';
import TableBody from '../../commons/TableBody';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { openSocket } from '../../sockets';
import { ETestStatus } from '../../tests/interfaces';
import { useGetTestsQuery } from '../../tests/testsApi';
import { addTestSuitesQueueInfo, useTestSuitesQueue } from '../../testSuites/useTestSuitesQueue';
import { ETestDeviceState, ITestDevice } from '../interfaces';
import TestDevicesSettingsButton from '../settings/TestDevicesSettingsButton';
import { useGetTestDevicesQuery } from '../testDevicesApi';
import TestDeviceLoadingRows from './TestDeviceLoadingRows';
import TestDeviceRow from './TestDeviceRow';
import { columns, mobileColumns } from './testDevicesColumns';

const deviceStatePriority: Record<ETestDeviceState, number> = {
    done: 0,
    running: 1,
    idle: 2,
    offline: 3,
};

export default function TestDevicesList(): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const tableColumns = isMobile ? columns.filter((column) => mobileColumns.includes(column.id)) : columns;

    const { data: testDevicesData, isFetching, refetch: refetchTestDevices } = useGetTestDevicesQuery();
    const [testDevices, setTestDevices] = useState<ITestDevice[] | undefined>(testDevicesData);

    const { data: runningTests } = useGetTestsQuery({ status: [ETestStatus.running] });

    const testsQueue = useTestSuitesQueue();

    useEffect(() => {
        const devicesWithQueueInfo = addTestSuitesQueueInfo(testDevicesData, testsQueue, runningTests?.tests || []);
        const sortedDevices = devicesWithQueueInfo
            ?.slice()
            .sort((a: ITestDevice, b: ITestDevice) => deviceStatePriority[a.state] - deviceStatePriority[b.state]);
        setTestDevices(sortedDevices);
    }, [testDevicesData, testsQueue, runningTests]);

    useEffect(() => {
        const socket = openSocket('test-devices');
        socket.on('update-device', refetchTestDevices);

        return () => {
            socket.off('update-device', refetchTestDevices);
        };
    }, [refetchTestDevices]);

    const isLoading = isFetching && !testDevices;

    return (
        <Suspense fallback={<ContentLoading />}>
            <Page>
                <HeaderSection
                    title='Devices'
                    breadcrumbs={[
                        { text: 'Tests', href: '/tests' },
                        { text: 'Devices', href: '/test-devices' },
                    ]}
                    helmetTitle='Test Devices'
                    actionBox={<TestDevicesSettingsButton />}
                />
                <Paper
                    data-testid='test-devices'
                    sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
                >
                    <TableContainer>
                        <Table
                            stickyHeader
                            aria-label='test devices table'
                            style={{ width: '100%', tableLayout: 'fixed' }}
                        >
                            <TableHead data-testid='test-devices-table-head'>
                                <TableHeaderRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            size='small'
                                            sx={{ width: column.width, minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody data-testid='test-devices-table-body'>
                                {isLoading ? (
                                    <TestDeviceLoadingRows columns={tableColumns} />
                                ) : (
                                    testDevices?.map((testDevice) => (
                                        <TestDeviceRow
                                            key={testDevice.uuid}
                                            columns={tableColumns}
                                            testDevice={testDevice}
                                        />
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Page>
        </Suspense>
    );
}
