import { ReactElement } from 'react';
import { EBuildStatus } from '../commons/enums';
import { ETestStatus } from '../tests/interfaces';
import { ITestSuiteBase } from './interfaces';
import { TestStatusChip, TestSuiteResults } from './TestSuiteResults';

export default function TestSuiteStatus({
    testSuite,
    detailed,
}: {
    testSuite: ITestSuiteBase;
    detailed?: boolean;
}): ReactElement {
    if (isBuildCancelledOrFailed(testSuite.build.status)) {
        return <TestStatusChip status={testSuite.status} label={`Build ${testSuite.status}`} />;
    }

    if (isTestSuiteWaitingOrQueued(testSuite.status)) {
        return <TestStatusChip status={testSuite.status} />;
    }

    const results = { ...testSuite.resultsCount };
    if (isTestSuiteRunningOrFinishing(testSuite.status)) {
        results.running += results.waiting;
        results.running += results.queued;
        results.waiting = 0;
        results.queued = 0;
    }
    return <TestSuiteResults results={results} detailed={detailed} />;
}

const isTestSuiteRunningOrFinishing = (status: ETestStatus) =>
    [ETestStatus.running, ETestStatus.finishing].includes(status);

const isTestSuiteWaitingOrQueued = (status: ETestStatus) => [ETestStatus.waiting, ETestStatus.queued].includes(status);

const isBuildCancelledOrFailed = (status: EBuildStatus): boolean =>
    [EBuildStatus.failed, EBuildStatus.cancelled].includes(status);
