import { IConfigurationSettings, ISelectedSettings } from './interfaces';

export function getDefaultSettings(
    settings: IConfigurationSettings | undefined,
    templateVariables?: Record<string, any>,
): ISelectedSettings {
    if (!settings) {
        return {};
    }
    const defaultSettings: ISelectedSettings = {};
    for (const [settingKey, setting] of Object.entries(settings)) {
        if (setting.default !== undefined) {
            defaultSettings[settingKey] =
                typeof setting.default === 'string'
                    ? resolveTemplateVariables(setting.default, templateVariables || {})
                    : setting.default;
        }
    }
    return defaultSettings;
}

/**
 * Substitutes template variables `<<source.property>>` to real values in the string.
 * @param template - e.g. `<<build.buildNumber>>`
 * @param values - dictionary of available variable values
 */
function resolveTemplateVariables(template: string, values: Record<string, any>): string {
    let value = template;

    const matchVariables = template.matchAll(/<<([^<<>>]+)>>/g);

    let match = matchVariables.next();
    while (!match.done) {
        const [variableTemplate, variable] = match.value;
        const [source, property] = variable.split('.');
        if (!values[source]) {
            return template;
        }
        value =
            template === variableTemplate
                ? values[source][property]
                : value.replace(variableTemplate, values[source][property]);

        match = matchVariables.next();
    }

    return value;
}
