import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { IMessage, EMessageLevel } from './interfaces';
import MessageIcon from './MessageIcon';

export default function MessagesIcons({ messages }: { messages?: IMessage[] }): ReactElement {
    const errorExists = messages?.find((message) => message.level === EMessageLevel.error);
    const warningExists = messages?.find((message) => message.level === EMessageLevel.warning);
    const infoExists = messages?.find((message) => message.level === EMessageLevel.info);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '30px', sm: '50px' } }}>
            {errorExists && (
                <MessageIcon
                    icon='error'
                    title='Error'
                    sx={{
                        margin: (theme) => theme.spacing(0, 0.5),
                        fontSize: '19px',
                    }}
                />
            )}
            {warningExists && (
                <MessageIcon
                    icon='warning'
                    title='Warning'
                    sx={{
                        margin: (theme) => theme.spacing(0, 0.5),
                        fontSize: '19px',
                    }}
                />
            )}
            {infoExists && (
                <MessageIcon
                    icon='info'
                    title='Info'
                    sx={{
                        margin: (theme) => theme.spacing(0, 0.5),
                        fontSize: '19px',
                    }}
                />
            )}
        </Box>
    );
}
