import { VersionService } from '@tactileentertainment/targeting-utils';
import { FilterOption } from './interfaces';

export const parseFilterOptions = (options: string[] | number[] = []): FilterOption[] => {
    return options
        .map((option) => ({
            value: option,
            label: String(option || ''),
        }))
        .filter((option) => option.value);
};

export const sortNumberOptions = (options: FilterOption[], type: 'desc' | 'asc'): FilterOption[] => {
    return options.sort((a, b) => {
        const aNumber = Number(a.value) || 0;
        const bNumber = Number(b.value) || 0;

        if (type === 'desc') {
            return bNumber - aNumber;
        }

        return aNumber - bNumber;
    });
};

const versionService = new VersionService();
export function sortVersionOptions(options: string[], type: 'desc' | 'asc'): FilterOption[] {
    const sortedOptions = versionService.sortVersions(options, type);
    return parseFilterOptions(sortedOptions);
}
