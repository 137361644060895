import { HTMLAttributes, ReactElement, useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, createFilterOptions, Popover, SxProps, TextField, Typography } from '@mui/material';
import { FullWidthPopper, infoToast, TactileTheme } from '@tactileentertainment/core-designsystem';
import BuildNumberLink from '../../builds/BuildNumberLink';
import { useGetBuildsQuery, useLazyGetBuildsQuery } from '../../builds/buildsApi';
import { EBuildStatus } from '../../commons/enums';
import { IBuild } from '../../commons/interfaces';
import { compareVersions } from '../../commons/utils/compareVersions';
import { ESelectedProperty, ISelectedTestCase } from '../interfaces';
import { getBranchName } from '../testsApi';
import { useTestBranches } from './useTestBranches';

const filter = createFilterOptions<IBuild & { searchTerm?: string }>();

interface SelectBuildProps {
    build: IBuild;
    testCase: ISelectedTestCase;
    onSelect: (id: string, property: ESelectedProperty, value: ISelectedTestCase[ESelectedProperty]) => void;
}

export default function SelectBuild({ build, testCase, onSelect }: SelectBuildProps): ReactElement {
    const autocompleteRef = useRef<Element>();
    const [buildOptions, setBuildOptions] = useState<Array<IBuild & { searchTerm?: string }>>([]);
    const [searchMode, setSearchMode] = useState<boolean>(false);
    const [searchOptions, setSearchOptions] = useState<IBuild[]>([]);

    const selectedBuildOption = testCase.customBuild
        ? buildOptions.find((build) => build._id === testCase.customBuild?.build) || null
        : null;

    const { data: existingBuilds } = useGetBuildsQuery({
        projectName: build.projectName,
        platform: build.platform,
        type: build.type,
        kind: build.kind,
        status: EBuildStatus.finished,
        releaseBranch: true,
        limit: 100,
    });
    const [searchBuilds] = useLazyGetBuildsQuery();

    const { project, testBranches } = useTestBranches(build.projectName);

    useEffect(() => {
        setBuildOptions(filterByLowerVersion(existingBuilds, build.versionName));
    }, [existingBuilds, build.versionName]);

    const onSearchBuilds = async (searchTerm: string) => {
        setSearchMode(true);

        const { data: builds } = await searchBuilds({
            buildNumber: searchTerm,
            projectName: build.projectName,
            platform: build.platform,
            type: build.type,
            kind: build.kind,
            status: EBuildStatus.finished,
        });
        if (builds?.length) {
            setSearchOptions(builds);
        } else {
            infoToast(`Nothing found for "${searchTerm}"`);
            cancelSearch();
        }
    };

    const onSelectBuild = (option: IBuild | undefined) => {
        if (!option) {
            return onSelect(testCase.id, ESelectedProperty.customBuild, undefined);
        }

        const branchName = project ? getBranchName(project, option.branch) : option.branch;
        const testBranch = testBranches?.find((branch) => branch.branchName === branchName);

        onSelect(testCase.id, ESelectedProperty.customBuild, {
            build: option._id,
            testBranch: testBranch?.branchName,
            commitSha: testBranch?.sha,
        });
    };

    const onSelectSearchOption = (option: IBuild) => {
        setBuildOptions((options) => {
            if (options.find((build) => build._id === option._id)) {
                return options;
            }
            return [option, ...options];
        });
        onSelectBuild(option);
        setSearchMode(false);
        setSearchOptions([]);
    };

    const cancelSearch = () => {
        onSelect(testCase.id, ESelectedProperty.customBuild, undefined);
        setSearchMode(false);
        setSearchOptions([]);
    };

    return (
        <Box
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <Autocomplete
                size='small'
                ref={autocompleteRef}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disabled={searchMode}
                value={selectedBuildOption}
                options={buildOptions || []}
                getOptionLabel={(option) => {
                    if (typeof option === 'string' || (option && 'searchTerm' in option)) {
                        const searchTerm = typeof option === 'string' ? option : option.searchTerm;
                        return `Searching "${searchTerm}" ...`;
                    }
                    return `${option.branch} (${option.revision}) #${option.buildNumber}`;
                }}
                isOptionEqualToValue={(option, value) => option._id === value?._id}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '' && !filtered.length) {
                        filtered.push({
                            searchTerm: params.inputValue,
                            buildNumber: '',
                            versionName: '',
                        } as IBuild & { searchTerm?: string });
                    }

                    return filtered;
                }}
                renderOption={(props, option) => <BuildOption {...props} option={option} />}
                renderInput={(params) => <TextField {...params} label='Build' variant='outlined' required />}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string' || (newValue && 'searchTerm' in newValue)) {
                        const searchTerm = typeof newValue === 'string' ? newValue : newValue.searchTerm;
                        onSearchBuilds(searchTerm as string);
                    } else {
                        onSelectBuild(newValue as IBuild);
                    }
                }}
                fullWidth
                PopperComponent={FullWidthPopper}
            />
            <Popover
                open={searchMode && searchOptions.length > 0}
                anchorEl={autocompleteRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={cancelSearch}
            >
                {searchOptions.map((option, index) => (
                    <BuildOption
                        key={index}
                        option={option}
                        onClick={() => onSelectSearchOption(option)}
                        sx={{
                            display: 'block',
                            cursor: 'pointer',
                            padding: 1,
                            minWidth: '15vw',
                            '&:hover': {
                                background: (theme) => theme.palette.tactile.blueGray[300],
                            },
                        }}
                    />
                ))}
            </Popover>
        </Box>
    );
}

function BuildOption(
    props: { option: IBuild; sx?: SxProps<TactileTheme> } & HTMLAttributes<HTMLElement>,
): ReactElement {
    const { option, sx = {}, ...elProps } = props;
    return (
        <Box
            component='li'
            {...elProps}
            sx={{
                borderBottom: '1px solid',
                borderTop: '1px solid',
                borderColor: (theme) => theme.palette.tactile.blueGray[200],
                background: (theme) => theme.palette.background.default,
                ...sx,
            }}
        >
            <Box display='flex'>
                <Typography variant='body1' fontWeight={600}>
                    {option.branch}
                </Typography>
                &nbsp;
                <Typography variant='body1'>{`(${option.revision})`}</Typography>
                &nbsp;
                <BuildNumberLink projectName={option.projectName} buildNumber={option.buildNumber} newTab />
            </Box>
        </Box>
    );
}

function filterByLowerVersion(builds?: IBuild[], versionName?: string): IBuild[] {
    if (!builds) {
        return [];
    }
    const sortedBuilds = builds
        .filter((item) => compareVersions(item.versionName, versionName) < 0)
        .sort((itemA, itemB) => {
            const compareByVersion = compareVersions(itemB.versionName, itemA.versionName);
            const compareByBuild = Number(itemB.buildNumber) - Number(itemA.buildNumber);
            return compareByVersion === 0 ? compareByBuild : compareByVersion;
        });

    const uniqueBuilds: IBuild[] = [];
    const uniqueBranches = new Set<string>();
    for (const build of sortedBuilds) {
        if (uniqueBranches.has(build.branch)) {
            continue;
        }
        uniqueBranches.add(build.branch);
        uniqueBuilds.push(build);
    }

    return uniqueBuilds;
}
