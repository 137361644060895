import { ReactElement, useState } from 'react';
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { errorToast } from '@tactileentertainment/core-designsystem';
import moment from 'moment/moment';
import isAddressValid from '../../commons/isAddressValid';
import { ITestAgent } from '../interfaces';
import { useDeleteTestAgentMutation, useRegisterTestAgentMutation, useUpdateTestAgentMutation } from '../testAgentsApi';

interface EditTestAgentDialogProps {
    open: boolean;
    handleClose: () => void;
    testAgent?: ITestAgent;
}

export default function EditTestAgentDialog({ open, handleClose, testAgent }: EditTestAgentDialogProps): ReactElement {
    const isNew = Boolean(!testAgent);

    const [registerTestAgent] = useRegisterTestAgentMutation();
    const [updateTestAgent] = useUpdateTestAgentMutation();
    const [deleteTestAgent] = useDeleteTestAgentMutation();

    const [address, setAddress] = useState<string | null>(testAgent ? `${testAgent.ip}:${testAgent.port}` : null);
    const [enabled, setEnabled] = useState<boolean>(!testAgent?.manuallyDisabled);
    const [disabledReason, setDisabledReason] = useState<string | null>(null);
    const isValid = !isNew || isAddressValid(address || '');

    const handleRegister = async () => {
        const [ip, port] = address?.split(':') || [];
        const result = await registerTestAgent({
            ip,
            port: Number(port),
            manuallyDisabled: !enabled,
            disabledReason: disabledReason || undefined,
        });
        if ('error' in result) {
            errorToast((result.error as any).data?.error?.message || 'Failed to register agent');
        } else {
            handleClose();
        }
    };

    const handleEdit = async () => {
        const result = await updateTestAgent({
            _id: testAgent?._id || '',
            manuallyDisabled: !enabled,
            disabledReason: disabledReason || undefined,
        });
        if ('error' in result) {
            errorToast((result.error as any).data?.error?.message || 'Failed to update agent');
        } else {
            handleClose();
        }
    };

    const handleDelete = async () => {
        const result = await deleteTestAgent(testAgent?._id || '');
        if ('error' in result) {
            errorToast((result.error as any).data?.error?.message || 'Failed to delete agent');
        } else {
            handleClose();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ color: (theme) => theme.palette.tactile.blueGray[800] }}>
                {isNew ? 'Register Test Agent' : 'Edit Test Agent'}
                {testAgent?.updatedBy && (
                    <>
                        <Typography variant='subtitle1' color='text.secondary'>
                            Last Updated By {testAgent?.updatedBy}
                        </Typography>
                    </>
                )}
                <Divider sx={{ mx: -4, mt: 2 }} />
            </DialogTitle>
            <DialogContent>
                <>
                    <TextField
                        sx={{ my: 1, width: '-webkit-fill-available' }}
                        autoFocus={isNew}
                        disabled={!isNew}
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        required
                        label='Address'
                        placeholder='192.168.2.X:9400'
                        type='text'
                        variant='outlined'
                        autoComplete='off'
                    />
                    {!isNew && (
                        <Typography variant='body1' color='text.secondary'>
                            To change the address, please delete and re-register the agent.
                        </Typography>
                    )}
                    <FormControlLabel
                        control={
                            <Switch checked={enabled} onChange={() => setEnabled(!enabled)} sx={{ ml: 1.5, mt: 1 }} />
                        }
                        label='Allow new tests on agent'
                    />
                    <Collapse in={!enabled && !testAgent?.manuallyDisabled}>
                        <TextField
                            sx={{ my: 1, width: '-webkit-fill-available' }}
                            value={disabledReason}
                            onChange={(event) => setDisabledReason(event.target.value)}
                            label='Disabled reason'
                            placeholder='agent maintenance..'
                            type='text'
                            variant='outlined'
                            autoComplete='off'
                        />
                    </Collapse>
                    {(testAgent?.manuallyDisabled || testAgent?.disabledReason) && (
                        <Alert severity='info'>
                            {testAgent?.manuallyDisabled && (
                                <>
                                    This agent was manually disabled on{' '}
                                    {moment(testAgent.disabledDate).format('DD-MM-YYYY HH:mm')}.
                                </>
                            )}

                            {testAgent?.manuallyDisabled && testAgent?.disabledReason && <br />}

                            {testAgent?.disabledReason && <>Disabled reason: {testAgent.disabledReason}</>}
                        </Alert>
                    )}
                </>
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        {!isNew && (
                            <Button onClick={handleDelete} color='error'>
                                Delete
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button onClick={handleClose} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={isNew ? handleRegister : handleEdit}
                            variant='contained'
                            disabled={!isValid}
                            color='primary'
                            style={{ minWidth: '80px' }}
                        >
                            {isNew ? 'Register' : 'Save'}
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}
