import React, { useState, useEffect, ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
import { GetApp as DownloadIcon, AddToHomeScreen as InstallIcon } from '@mui/icons-material';
import { Button, IconButton, useMediaQuery, styled } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { createSharableServerUrl } from '../../api';
import { IArtifact } from '../buildDetails/interfaces';

const DownloadMobileButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1.5),
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    height: '30px',
    width: 'fit-content',
    '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
    },
    '&:focus': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
    },
    '&:active': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
    },
}));

interface DownloadArtifactButtonProps {
    buildId: string;
    artifact?: IArtifact;
    isInstall?: boolean;
    isBuildDetails?: boolean;
    isIconButton?: boolean;
}

export default function DownloadArtifactButton({
    artifact,
    buildId,
    isInstall,
    isBuildDetails,
    isIconButton = true,
}: DownloadArtifactButtonProps): ReactElement | null {
    const [link, setLink] = useState('');
    const isSmallScreen = useMediaQuery('(max-width: 1280px)');

    const handleDownloadClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        event.stopPropagation();
    };

    useEffect(() => {
        if (artifact) {
            const link = createSharableServerUrl(`/builds/build-result/${buildId}/download-artifact`, {
                filename: artifact.file,
            });
            setLink(link);
        }
    }, [artifact, buildId]);

    if (!buildId || !artifact || !link) {
        return null;
    }

    const downloadIcon = isInstall ? <InstallIcon fontSize='small' /> : <DownloadIcon fontSize='small' />;
    const downloadText = isInstall ? 'Install' : 'Download';

    if ((isSmallScreen || isMobile) && isBuildDetails) {
        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <DownloadMobileButton
                    variant='contained'
                    title={downloadText}
                    href={link}
                    onClick={handleDownloadClick}
                    endIcon={downloadIcon}
                >
                    {downloadText}
                </DownloadMobileButton>
            </div>
        );
    }

    return isIconButton ? (
        <IconButton
            sx={{
                color: blueGray[800],
                margin: (theme) => theme.spacing(0, 0.5),
                padding: 0,
            }}
            title={downloadText}
            href={link}
            onClick={handleDownloadClick}
        >
            {downloadIcon}
        </IconButton>
    ) : (
        <Button
            title={downloadText}
            href={link}
            onClick={handleDownloadClick}
            color='primary'
            variant='contained'
            sx={{ ml: 3 }}
        >
            Download
        </Button>
    );
}
