import { ReactElement } from 'react';
import { Table, Hidden, TableHead, TableCell } from '@mui/material';
import TableBody from '../commons/TableBody';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import { IScheduleBuild } from './interfaces';
import { scheduledBuildColumns } from './ScheduledBuildColumns';
import ScheduledBuildLoadingRows from './ScheduledBuildLoadingRows';
import ScheduledBuildRow from './ScheduledBuildRow';
import { useGetScheduledBuildsQuery } from './scheduledBuildsApi';

interface ScheduledBuildsTableProps {
    onClick: (selected: IScheduleBuild) => void;
}

export default function ScheduledBuildsTable({ onClick }: ScheduledBuildsTableProps): ReactElement {
    const { data = [], isLoading } = useGetScheduledBuildsQuery();

    return (
        <TableContainer>
            <Table
                stickyHeader
                aria-label='scheduled builds table'
                sx={{ width: '100%', tableLayout: 'fixed', minWidth: 1000 }}
            >
                <Hidden>
                    <TableHead data-testid='scheduled-builds-table-head'>
                        <TableHeaderRow>
                            {scheduledBuildColumns
                                .filter((column) => column.visible)
                                .map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        size='small'
                                        sx={{ width: column.width, minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                        </TableHeaderRow>
                    </TableHead>
                </Hidden>
                <TableBody data-testid='scheduled-builds-table-body'>
                    {isLoading ? (
                        <ScheduledBuildLoadingRows />
                    ) : (
                        data.map((scheduledBuild) => (
                            <ScheduledBuildRow
                                key={scheduledBuild._id}
                                scheduledBuild={scheduledBuild}
                                onClick={onClick}
                            />
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
