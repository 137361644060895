import React, { useState, useEffect, useMemo, ReactElement } from 'react';
import { isMacOs } from 'react-device-detect';
import { GetApp as DownloadIcon } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { SquareButton } from '@tactileentertainment/core-designsystem';
import { createSharableServerUrl } from '../../api';
import { IArtifact } from '../buildDetails/interfaces';
import { EBuildStatus } from './enums';

interface DownloadArtifactButtonProps {
    projectName: string;
    buildNumber: string;
    buildStatus: EBuildStatus;
    artifact?: IArtifact;
    isInstall?: boolean;
    isBuildDetails?: boolean;
    variant?: ButtonProps['variant'];
}

export default function DownloadBuildButton({
    artifact,
    projectName,
    buildNumber,
    buildStatus,
    variant,
}: DownloadArtifactButtonProps): ReactElement | null {
    const [link, setLink] = useState('');
    const isIpad = useMemo(() => isMacOs && navigator?.maxTouchPoints > 2, []); // newer iPads report as MacOs

    const handleDownloadClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        event.stopPropagation();
    };

    useEffect(() => {
        if (artifact) {
            const params: any = { filename: artifact.file };
            if (isIpad) {
                params.isIpad = 'true';
            }

            const link = createSharableServerUrl(`/builds/${projectName}/${buildNumber}/download-artifact`, params);
            setLink(link);
        }
    }, [artifact, projectName, buildNumber, isIpad]);

    if (!projectName || !buildNumber || !artifact?.size || buildStatus !== EBuildStatus.finished || !link) {
        return null;
    }

    return (
        <SquareButton
            title='Install'
            href={link}
            variant={variant}
            onMouseUp={handleDownloadClick}
            color='secondary'
            sx={{ p: 1.25 }}
        >
            <DownloadIcon fontSize='small' />
        </SquareButton>
    );
}
