import { ReactElement } from 'react';
import { TableCell, useTheme } from '@mui/material';
import { ShowIfCan } from '@tactileentertainment/core-shared.auth-react';
import { MuiAlignSetting } from '../../../typings/material-ui';
import DatadogLink, { buildInfrastructureUrl } from '../../commons/DatadogLink';
import { EAuthResource } from '../../commons/EAuthResource';
import EditTestAgentButton from '../edit/EditTestAgentButton';
import { ITestAgent } from '../interfaces';

export default function TestAgentActionsCell({
    testAgent,
    align,
}: {
    testAgent: ITestAgent;
    align?: MuiAlignSetting;
}): ReactElement {
    const theme = useTheme();

    return (
        <TableCell
            align={align}
            sx={{
                padding: (theme) => theme.spacing(2, 1),
                [theme.breakpoints.down('sm')]: {
                    padding: (theme) => theme.spacing(1, 0.5),
                    width: '30%',
                },
            }}
        >
            <DatadogLink
                url={buildInfrastructureUrl(testAgent.hostname, ['role:test-agent'], 'logs')}
                sx={{ p: 1.25 }}
            />
            <ShowIfCan resource={EAuthResource.testAgents} operation='edit'>
                <EditTestAgentButton testAgent={testAgent} />
            </ShowIfCan>
        </TableCell>
    );
}
