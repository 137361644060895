import { ReactElement } from 'react';
import { Skeleton } from '@mui/material';
import { BuildGraphItem } from './BuildStats';

const skeletonStyle = { maxWidth: 500, p: 1, mx: 'auto' };

export default function BuildStatsSkeleton(): ReactElement {
    return (
        <>
            <BuildGraphItem>
                <Skeleton animation='wave' variant='rectangular' width='100%' height={200} sx={skeletonStyle} />
            </BuildGraphItem>
            <BuildGraphItem>
                <Skeleton animation='wave' variant='rectangular' width='100%' height={200} sx={skeletonStyle} />
            </BuildGraphItem>
            <BuildGraphItem>
                <Skeleton animation='wave' variant='rectangular' width='100%' height={200} sx={skeletonStyle} />
            </BuildGraphItem>
            <BuildGraphItem>
                <Skeleton animation='wave' variant='rectangular' width='100%' height={200} sx={skeletonStyle} />
            </BuildGraphItem>
        </>
    );
}
