import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import CancelTestSuiteAction from '../../testSuites/detail/CancelTestSuiteAction';
import { ITestSuiteListItem } from '../../testSuites/interfaces';
import CancelTestAction from '../details/CancelTestAction';
import { ITest, runningTestStatus } from '../interfaces';

export default function TestActionsCell({
    test,
    testSuite,
    align,
}: {
    test?: ITest;
    testSuite?: ITestSuiteListItem;
    align?: MuiAlignSetting;
}): ReactElement {
    return (
        <TableCell align={align}>
            {test && runningTestStatus.includes(test.status) && <CancelTestAction test={test} />}
            {testSuite && runningTestStatus.includes(testSuite.status) && (
                <CancelTestSuiteAction testSuite={testSuite} />
            )}
        </TableCell>
    );
}
