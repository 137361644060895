import { ReactElement } from 'react';
import { Popover, PopoverProps } from '@mui/material';

export default function ExtraInfoPopover({ sx, ...props }: PopoverProps): ReactElement {
    return (
        <Popover
            onClick={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            {...props}
            sx={{
                pointerEvents: 'none',
                transform: 'translateY(-10px)',
            }}
            slotProps={{
                paper: {
                    sx: {
                        pointerEvents: 'auto',
                        padding: 2,
                        border: '1px solid',
                        borderColor: (theme) => theme.palette.divider,
                        ...(sx || {}),
                    },
                },
            }}
        />
    );
}
