import { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import ContentLoading from '../commons/ContentLoading';
import { getAcronym } from '../commons/getAcronym';
import NoContent from '../commons/NoContent';
import { openSocket } from '../sockets';
import LogFile from './details/LogFile';
import TestBreadcrumbs from './details/TestBreadcrumbs';
import TestInfo from './details/TestInfo';
import TestResults from './details/TestResults';
import { ETestStatus, ITest } from './interfaces';
import { useGetTestDetailsQuery } from './testsApi';
import TestStatus from './TestStatus';

export default function TestDetails(): ReactElement {
    const { projectName, testNumber, tab } = useParams<{ projectName: string; testNumber: string; tab?: string }>();
    const { data, isFetching } = useGetTestDetailsQuery({ projectName, testNumber: Number(testNumber) });

    const [test, setTest] = useState<ITest | undefined>(data);

    useEffect(() => {
        setTest(data);
    }, [data]);

    useEffect(() => {
        const socket = openSocket('tests');
        const handleTestUpdate = ({ test }: { test: ITest }) => {
            if (test._id === data?._id) {
                setTest(test);
            }
        };

        socket.on('update-test', handleTestUpdate);

        return () => {
            socket.off('update-test', handleTestUpdate);
        };
    }, [data]);

    return (
        <>
            <Helmet title={`${getAcronym(projectName)} #${testNumber} ${test?.status}`} />
            <Container
                maxWidth={false}
                sx={{
                    height: 'unset',
                    minHeight: 'calc(100vh - 70px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                data-testid='test-details'
            >
                <Box sx={{ p: 1, my: 2, display: { xs: 'block', sm: 'flex' }, justifyContent: 'space-between' }}>
                    <TestBreadcrumbs test={test} tab={tab} />
                    {test && <TestStatus test={test} detailed />}
                </Box>

                {isFetching && <ContentLoading />}

                {!test && !isFetching && <NoContent text='No tests found' />}

                {test && <TestDetailsContent test={test} tab={tab} />}
            </Container>
        </>
    );
}

function TestDetailsContent({ test, tab }: { test: ITest; tab?: string }): ReactElement {
    if (!tab) {
        return (
            <>
                <TestInfo test={test} />
                <TestResults test={test} />
            </>
        );
    }

    if (tab === 'log' && test.logFile) {
        return (
            <>
                <Typography variant='h6'>{test.logFile}</Typography>
                <LogFile
                    testId={test._id}
                    logFile={test.logFile}
                    scrollFollow={test.status === ETestStatus.running}
                    maxHeight='85vh'
                />
            </>
        );
    }

    return <NoContent text='There is no displayable content' />;
}
