import { ReactElement } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { defaultLanguage, languages } from './appUploaderLanguages';

interface SelectLanguageProps {
    language?: string;
    disabledLanguages: string[];
    setLanguage: (language: string | null) => void;
}

export default function SelectLanguage({
    language,
    setLanguage,
    disabledLanguages,
}: SelectLanguageProps): ReactElement {
    const value = languages.find((lang) => lang.code === language);
    return (
        <Autocomplete
            size='medium'
            options={languages}
            getOptionLabel={(option) => `${option.name} - ${option.code}`}
            getOptionDisabled={(option) => option.code !== language && disabledLanguages.includes(option.code)}
            autoHighlight
            clearOnBlur
            clearOnEscape
            disableClearable
            onChange={(event, option) => setLanguage(option?.code || null)}
            value={value}
            disabled={language === defaultLanguage}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete='off'
                    label='Language'
                    placeholder='Select language'
                    variant='outlined'
                />
            )}
            fullWidth
        />
    );
}
