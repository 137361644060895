import { ReactElement } from 'react';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

export default function SearchField({
    search,
    onSearchChange,
}: {
    search: string;
    onSearchChange: (search: string) => void;
}): ReactElement {
    return (
        <TextField
            variant='outlined'
            size='small'
            placeholder='Search'
            style={{ paddingRight: 8 }}
            value={search}
            onChange={(event) => onSearchChange(event.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        style={{ cursor: 'pointer' }}
                        position='end'
                        onClick={() => search && onSearchChange('')}
                    >
                        {search ? <ClearIcon /> : <SearchIcon />}
                    </InputAdornment>
                ),
            }}
        />
    );
}
