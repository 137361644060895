import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Container, useTheme } from '@mui/material';
import ContentLoading from '../../commons/ContentLoading';
import HeaderSection from '../../commons/HeaderSection';
import { useGetCurrentUserQuery } from '../../userProfile/usersApi';
import { IProjectDraft } from '../interfaces';
import { useGetProjectQuery } from '../projectsApi';
import EditProjectActions from './EditProjectActions';
import EditProjectContent from './EditProjectContent';

export default function NewProject(): ReactElement {
    const theme = useTheme();
    const { data: userData } = useGetCurrentUserQuery();
    const { id: projectId } = useParams<{ id: string }>();
    const isNew = projectId === 'new';
    const { data: project, isFetching } = useGetProjectQuery({ projectId }, { skip: isNew });

    const [projectDraft, setProjectDraft] = useState<IProjectDraft>({
        name: '',
        svnPath: '',
        isEnabled: true,
        isOrderable: true,
        managers: userData?.user?._id ? [userData?.user?._id] : [],
        agents: [],
        minDiskSpaceRequirement: 1,
        defaultNotificationChannel: '',
    });

    const handleChange = (update: Partial<IProjectDraft>) => {
        setProjectDraft((draft) => ({ ...draft, ...update }));
    };

    useEffect(() => {
        if (project?._id) {
            setProjectDraft({
                _id: project._id,
                name: project.name,
                svnPath: project.svnPath,
                isEnabled: project.isEnabled,
                isOrderable: project.isOrderable,
                managers: project.managers.map((item) => item._id),
                agents: project.agents.map((item) => item._id),
                accelerator: project.accelerator,
                minDiskSpaceRequirement: project.minDiskSpaceRequirement,
                defaultNotificationChannel: project.defaultNotificationChannel,
            });
        }
    }, [project]);

    const title = isNew ? 'New Project' : 'Edit Project';

    return (
        <Suspense fallback={<ContentLoading />}>
            <Helmet title={title} />
            <Container
                maxWidth={false}
                style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '16px',
                    backgroundColor: theme.palette.grey[100],
                    marginTop: 1,
                    padding: 0,
                }}
            >
                <EditProjectHeader
                    title={title}
                    actionBox={
                        <EditProjectActions
                            isNew={isNew}
                            projectDraft={projectDraft}
                            onEnableChange={(isEnabled) => handleChange({ isEnabled })}
                        />
                    }
                />
                {isFetching ? (
                    <ContentLoading />
                ) : (
                    <EditProjectContent projectDraft={projectDraft} onChange={handleChange} />
                )}
            </Container>
        </Suspense>
    );
}

function EditProjectHeader({ title, actionBox }: { title: string; actionBox?: ReactElement }): ReactElement {
    return (
        <>
            <HeaderSection
                title={title}
                actionBox={actionBox}
                breadcrumbs={[{ text: 'Projects', href: '/projects' }, { text: 'Project Settings' }]}
            />
        </>
    );
}
