import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { AvatarChip } from '@tactileentertainment/core-designsystem';
import { Typography } from 'antd';
import Duration from '../../commons/Duration';
import GridItem from '../../commons/GridItem';
import formatDate from '../../tests/details/formatDate';
import { ITestSuiteDetails } from '../interfaces';
import TestSuiteTypeIcon from '../row/TestSuiteTypeIcon';

export default function TestSuiteInfoStats({ testSuite }: { testSuite: ITestSuiteDetails }): ReactElement {
    return (
        <Grid
            container
            item
            spacing={1}
            justifyContent='space-between'
            data-testid='test-suite-details-suite-info'
            sx={{ mr: 6 }}
        >
            <GridItem label='Test Suite ID'>{testSuite.testSuiteNumber}</GridItem>
            <GridItem label='Type'>
                <TestSuiteTypeIcon type={testSuite.type} />
                <Typography style={{ marginLeft: 4 }}>{testSuite.type.toUpperCase()}</Typography>
            </GridItem>

            <GridItem label='Started' color={testSuite.startedAt ? 'primary' : 'secondary'}>
                {testSuite.startedAt ? formatDate(testSuite.startedAt) : '-'}
            </GridItem>
            <GridItem label='Ended' color={testSuite.completedAt ? 'primary' : 'secondary'}>
                {testSuite.completedAt ? formatDate(testSuite.completedAt) : '-'}
            </GridItem>
            <GridItem label='Duration' color={testSuite.startedAt ? 'primary' : 'secondary'}>
                {testSuite.startedAt ? (
                    <Duration started={testSuite.startedAt} completed={testSuite.completedAt} />
                ) : (
                    '-'
                )}
            </GridItem>
            <GridItem label='Created By' align='center'>
                <AvatarChip
                    label={testSuite.author.email}
                    altLabel={testSuite.author.name}
                    src={testSuite.author.imageUrl}
                    sx={{ maxWidth: { xs: '120px', sm: 'fit-content' } }}
                />
            </GridItem>
            {testSuite.updatedBy && (
                <GridItem label='Updated By' align='center'>
                    <AvatarChip
                        label={testSuite.updatedBy.email}
                        altLabel={testSuite.updatedBy.name}
                        src={testSuite.updatedBy.imageUrl}
                        sx={{ maxWidth: { xs: '120px', sm: 'fit-content' } }}
                    />
                </GridItem>
            )}
        </Grid>
    );
}
