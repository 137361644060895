import React, { ReactElement } from 'react';
import { Grid, useTheme } from '@mui/material';

export default function NoContent({ text, icon }: { text: string; icon?: React.ReactNode }): ReactElement {
    const theme = useTheme();

    return (
        <Grid
            container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                background: theme.palette.background.default,
            }}
            data-testid='no-content'
        >
            <div style={{ textAlign: 'center', color: theme.palette.text.disabled }}>
                {icon}
                <div>{text}</div>
            </div>
        </Grid>
    );
}
