import { ReactElement } from 'react';
import { Grid, TableCell, TableRow } from '@mui/material';
import { ITestDevice } from '../testDevices/interfaces';
import TestDeviceLink from '../testDevices/TestDeviceLink';
import TestDeviceStatus from '../testSuites/detail/TestDeviceStatus';
import { BuildTestResultsMatrixColumnId, buildTestResultsMatrixColumns } from './BuildTestResultsMatrixColumns';
import { ITestCaseResults } from './interfaces';

export interface IBuildTestResultsMatrixRowProps {
    testCase: ITestCaseResults;
    devices: ITestDevice[];
}

export default function BuildTestResultsMatrixRow(props: IBuildTestResultsMatrixRowProps): ReactElement {
    const { testCase, devices } = props;

    return (
        <TableRow
            key={testCase.testName}
            sx={{
                verticalAlign: 'center',
            }}
        >
            {buildTestResultsMatrixColumns.map((column) => {
                switch (column.id) {
                    case BuildTestResultsMatrixColumnId.test:
                        return (
                            <TableCell
                                key={column.id}
                                sx={{
                                    width: column.width,
                                }}
                            >
                                {testCase.testName}
                            </TableCell>
                        );

                    case BuildTestResultsMatrixColumnId.results:
                        return (
                            <TableCell key={column.id}>
                                <Grid
                                    container
                                    sx={{
                                        width: column.width,
                                        padding: 2,
                                    }}
                                >
                                    {Object.keys(testCase.resultsCountPerDevice).map((deviceId) => {
                                        const device = devices.find((device) => deviceId === device._id);
                                        if (!device) {
                                            return null;
                                        }
                                        return (
                                            <Grid key={deviceId} container sx={{ padding: 1 }}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        flexGrow: 1,
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        paddingRight: 2,
                                                    }}
                                                >
                                                    <TestDeviceStatus
                                                        key={deviceId}
                                                        results={testCase.resultsCountPerDevice[deviceId]}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    sx={{ flexGrow: 2, display: 'flex', alignItems: 'center' }}
                                                >
                                                    {device && (
                                                        <TestDeviceLink uuid={device.uuid} title={device.name} />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </TableCell>
                        );
                }
            })}
        </TableRow>
    );
}
