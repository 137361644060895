import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, useMediaQuery } from '@mui/material';
import Breadcrumbs from '../../commons/Breadcrumbs';
import { getAcronym } from '../../commons/getAcronym';
import { ITestSuiteBase } from '../interfaces';

export default function TestSuiteBreadcrumbs({ testSuite }: { testSuite?: ITestSuiteBase }): ReactElement | null {
    const isMobile = useMediaQuery('(max-width: 1280px)');
    return testSuite ? (
        <Breadcrumbs sx={{ mb: { xs: 2, sm: 0 } }}>
            <Link color='inherit' component={RouterLink} to='/test-suites'>
                Test Suites
            </Link>
            <Link
                color='inherit'
                component={RouterLink}
                to={`/test-suites?build%5BprojectName%5D=${testSuite.build.projectName}`}
            >
                {isMobile ? getAcronym(testSuite.build.projectName) : testSuite.build.projectName}
            </Link>
            <Link
                color='inherit'
                component={RouterLink}
                to={`/test-suites?build%5BprojectName%5D=${testSuite.build.projectName}&testBranch=${testSuite.testBranch}`}
            >
                {testSuite.testBranch}
            </Link>
            <Typography color='textPrimary'>
                <Typography
                    component='span'
                    sx={{
                        margin: (theme) => theme.spacing(0, 1, 0, 0.5),
                        color: (theme) => theme.palette.text.secondary,
                    }}
                >
                    #{testSuite.testSuiteNumber}
                </Typography>
            </Typography>
        </Breadcrumbs>
    ) : null;
}
