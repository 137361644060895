import React, { ReactElement, useState } from 'react';
import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ITestAgent } from '../interfaces';
import EditTestAgentDialog from './EditTestAgentDialog';

export default function EditTestAgentButton({ testAgent }: { testAgent: ITestAgent }): ReactElement {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <React.Fragment>
            <IconButton size='small' color='secondary' onClick={() => setOpenDialog(true)}>
                <EditIcon />
            </IconButton>
            {openDialog && (
                <EditTestAgentDialog testAgent={testAgent} open={openDialog} handleClose={() => setOpenDialog(false)} />
            )}
        </React.Fragment>
    );
}
