import { ReactElement } from 'react';
import DependencyIcon from '@mui/icons-material/LowPriority';
import { Box } from '@mui/material';
import { TactileTooltip } from '@tactileentertainment/core-designsystem';
import { ITestCase } from '../interfaces';
import TestStatusChip from '../TestStatusChip';

export default function TestCaseStatusInfo({ testCase }: { testCase: ITestCase }): ReactElement {
    return (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            <TestStatusChip status={testCase.status} noLabel />
            {testCase.dependsOn && testCase.dependsOn.length > 0 && (
                <TactileTooltip title={`conditioned by ${testCase.dependsOn.join(', ')}`}>
                    <DependencyIcon />
                </TactileTooltip>
            )}
        </Box>
    );
}
