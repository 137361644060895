import { ReactElement } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { IMessage, EMessageLevel } from './interfaces';
import MessageIcon from './MessageIcon';

export default function MessageTableRow({ message }: { message: IMessage }): ReactElement {
    return (
        <TableRow>
            <TableCell align='right' sx={{ width: '85px', p: 1 }}>
                {message.level}
            </TableCell>
            <TableCell align='center' sx={{ width: '40px', p: 1 }}>
                {message.level === EMessageLevel.error && (
                    <MessageIcon
                        icon='error'
                        sx={{
                            verticalAlign: 'middle',
                            fontSize: '19px',
                        }}
                    />
                )}
                {message.level === EMessageLevel.warning && (
                    <MessageIcon
                        icon='warning'
                        sx={{
                            verticalAlign: 'middle',
                            fontSize: '19px',
                        }}
                    />
                )}
                {message.level === EMessageLevel.info && (
                    <MessageIcon
                        icon='info'
                        sx={{
                            verticalAlign: 'middle',
                            fontSize: '19px',
                        }}
                    />
                )}
            </TableCell>
            <TableCell
                sx={{
                    p: 1,
                    whiteSpace: 'break-spaces',
                    wordWrap: 'break-word',
                    color: (theme) => theme.palette.text.secondary,
                }}
                align='left'
            >
                {message.text}
            </TableCell>
        </TableRow>
    );
}
