import { ReactElement } from 'react';
import { Popper, PopperProps } from '@mui/material';

export default function FullWidthPopper(props: PopperProps): ReactElement {
    const style = props.style ? { ...props.style } : {};
    style.minWidth = style.width;
    style.maxWidth = '100vw';
    style.width = undefined;
    return <Popper {...props} style={style} />;
}
