import { styled, TableContainer } from '@mui/material';

export default styled(TableContainer)(({ theme }) => ({
    height: '100%',
    '.MuiTable-root': {
        backgroundColor: theme.palette.common.white,
    },
    '.MuiTableCell-root': {
        fontWeight: 500,
    },
}));
