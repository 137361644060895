import { Fragment, ReactElement } from 'react';
import { Grid } from '@mui/material';
import ConfigurationSetting from './ConfigurationSetting';
import { IConfigurationSettings, ISelectedSettings, ISetting, ISettingsByGroup } from './interfaces';

export interface IConfigurationSettingsProps {
    settings?: IConfigurationSettings;
    selectedSettings?: ISelectedSettings;
    onSettingChange: (setting: ISetting, value: string | string[] | boolean | null) => void;
}

export default function ConfigurationSettings({
    settings,
    selectedSettings,
    onSettingChange,
}: IConfigurationSettingsProps): ReactElement | null {
    if (!settings || !Object.keys(settings).length) {
        return null;
    }

    const settingsByGroup = getSettingsByGroup(settings);

    return (
        <>
            {Object.keys(settingsByGroup).map((group, groupIndex) => {
                return (
                    <Fragment key={groupIndex}>
                        <Grid container>
                            {settingsByGroup[group].map((setting: ISetting, settingIndex: number) => {
                                return setting.hidden ? null : (
                                    <Grid
                                        item
                                        key={settingIndex}
                                        xs={12}
                                        sm={settingsByGroup[group].length > 1 ? 3 : 6}
                                    >
                                        <ConfigurationSetting
                                            setting={setting}
                                            selectedSettings={selectedSettings}
                                            setSetting={onSettingChange}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Fragment>
                );
            })}
        </>
    );
}

export function getSettingsByGroup(settings: IConfigurationSettings): ISettingsByGroup {
    return Object.keys(settings).reduce((byGroup: any, settingKey: string) => {
        const group = settings[settingKey].group || 'Other';

        byGroup[group] = byGroup[group] ? byGroup[group].concat([settings[settingKey]]) : [settings[settingKey]];

        return byGroup;
    }, {});
}
