import { ReactElement } from 'react';
import { AvatarGroup, TableCell } from '@mui/material';
import { AvatarChip } from '@tactileentertainment/core-designsystem';
import { IAuthor } from '../../commons/interfaces';

interface ProjectAuthorsCellProps {
    authors: IAuthor[];
}

export default function ProjectAuthorsCell({ authors }: ProjectAuthorsCellProps): ReactElement {
    return (
        <TableCell align='left' id='table-cell-authors'>
            <AvatarGroup max={4} sx={{ flexDirection: 'row' }}>
                {authors.map((author) => (
                    <AvatarChip key={author._id} label={author.email} altLabel={author.name} src={author.imageUrl} />
                ))}
            </AvatarGroup>
        </TableCell>
    );
}
