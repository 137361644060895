import { ReactElement, useState } from 'react';
import { OpenInNew as OpenInNewTabIcon, FeedOutlined as LogIcon } from '@mui/icons-material';
import { Table, TableCell, TableHead, TableBody, IconButton, TableRow } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { createSharableServerUrl } from '../../../api';
import DatadogLink, { buildLogsUrl } from '../../commons/DatadogLink';
import Duration from '../../commons/Duration';
import ExpandIcon from '../../commons/ExpandIcon';
import NoContent from '../../commons/NoContent';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import MessagesTable from '../../messages/MessagesTable';
import { ETestStatus, ITest, ITestCase } from '../interfaces';
import formatDate from './formatDate';
import LogFile from './LogFile';
import Report from './Report';
import { testCaseColumns } from './testCaseColumns';
import TestCaseName from './TestCaseName';
import TestCaseStatusInfo from './TestCaseStatusInfo';

export const tableMinWidth = 1400;

export default function TestCaseList({ test }: { test: ITest }): ReactElement {
    return (
        <TableContainer>
            <Table stickyHeader aria-label='test cases table' sx={{ minWidth: tableMinWidth }}>
                <TableHead data-testid='test-case-table-head'>
                    <TableHeaderRow>
                        <TableCell key='expand-column' align='left' size='small'></TableCell>
                        {testCaseColumns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                size='small'
                                style={{
                                    width: column.width,
                                    minWidth: column.minWidth,
                                }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableHeaderRow>
                </TableHead>
                <TableBody data-testid='test-case'>
                    {!test.testCases?.length && (
                        <TableRow>
                            <TableCell colSpan={8}>
                                <NoContent text='There are no test cases in this test' />
                            </TableCell>
                        </TableRow>
                    )}
                    {test.testCases?.map((testCase) => (
                        <TestCaseRow key={testCase.id || testCase.testName} test={test} testCase={testCase} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export interface ITestCaseRowProps {
    test: ITest;
    testCase: ITestCase;
}

function TestCaseRow({ test, testCase }: ITestCaseRowProps): ReactElement {
    const [expandedContent, setExpandedContent] = useState<'logs' | 'report' | undefined>();
    const expandContent = (content: 'logs' | 'report') => {
        if (!testCase.logFile && !testCase.reportFile) {
            return;
        }
        setExpandedContent(expandedContent === content ? undefined : content);
    };

    const handleExpandButtonToggle = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (expandedContent) {
            setExpandedContent(undefined);
        } else {
            expandContent('report');
        }
    };

    const openReportInNewTab = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(createSharableServerUrl(`/tests/${test._id}/artifact/${testCase.reportFile}`), '_blank');
    };

    const onExpandContent = (event: React.MouseEvent, content: 'logs' | 'report') => {
        event.preventDefault();
        event.stopPropagation();
        expandContent(content);
    };

    return (
        <>
            <TableRow
                data-testid='test-case-row'
                sx={{ cursor: 'pointer' }}
                onClick={(e) => onExpandContent(e, 'report')}
            >
                <TableCell>
                    <IconButton
                        disabled={!testCase.reportFile}
                        aria-label='expand row'
                        size='small'
                        onClick={(e) => handleExpandButtonToggle(e)}
                        sx={{ color: blueGray[800] }}
                    >
                        <ExpandIcon expanded={!!expandedContent} mode='tree' />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <TestCaseStatusInfo testCase={testCase} />
                </TableCell>
                <TableCell>
                    <TestCaseName testCase={testCase} />
                </TableCell>
                <TableCell>{formatDate(testCase.startedAt) || '-'}</TableCell>
                <TableCell>{formatDate(testCase.completedAt) || '-'}</TableCell>
                <TableCell>
                    <Duration started={testCase.startedAt} completed={testCase.completedAt} />
                </TableCell>
                <TableCell>
                    <IconButton
                        disabled={!testCase.logFile}
                        title='Logs'
                        size='small'
                        onClick={(e) => onExpandContent(e, 'logs')}
                    >
                        <LogIcon sx={{ color: blueGray[700] }} />
                    </IconButton>
                    <IconButton
                        disabled={!testCase.reportFile}
                        title='View report in new tab'
                        size='small'
                        sx={{ ml: 1 }}
                        onMouseUp={openReportInNewTab}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <OpenInNewTabIcon sx={{ color: blueGray[700] }} />
                    </IconButton>
                    {(test.gameDeviceId || test.testDevice?.gameDeviceId) && (
                        <DatadogLink
                            url={buildLogsUrl(
                                { '@device.uid': test.gameDeviceId || test.testDevice?.gameDeviceId || '' },
                                ['host', 'service', '@url'],
                                testCase.startedAt,
                                testCase.completedAt,
                            )}
                            sx={{ ml: 1 }}
                        />
                    )}
                </TableCell>
            </TableRow>
            {expandedContent && testCase.messages && (
                <TableRow>
                    <TableCell colSpan={8} sx={{ py: 0 }}>
                        <MessagesTable messages={testCase.messages} />
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell colSpan={8} sx={{ py: 0 }}>
                    {expandedContent === 'logs' && testCase.logFile && (
                        <LogFile
                            testId={test._id}
                            scrollFollow={testCase.status === ETestStatus.running}
                            logFile={testCase.logFile}
                        />
                    )}
                    {expandedContent === 'report' && testCase.reportFile && (
                        <Report testId={test._id} reportFile={testCase.reportFile} />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
}
