import React, { ReactElement } from 'react';
import { capitalize, Grid, Typography } from '@mui/material';
import GridItem from '../../commons/GridItem';
import { ITestDeviceInfo } from '../interfaces';

export default function DeviceAdvancedInfo({ deviceInfo }: { deviceInfo: ITestDeviceInfo }): ReactElement {
    return (
        <Grid
            container
            item
            spacing={1}
            columnGap={4}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
            data-testid='test-device-advanced-info'
        >
            {deviceInfo.sdkversion && <GridItem label='SDK Version'>{deviceInfo.sdkversion}</GridItem>}
            {deviceInfo.display && (
                <GridItem label='Display'>
                    <PrettyJson json={deviceInfo.display} />
                </GridItem>
            )}
            {deviceInfo.cpuabi && <GridItem label='CPU'>{deviceInfo.cpuabi}</GridItem>}
            {deviceInfo.cpufreq && <GridItem label='CPU Frequency'>{deviceInfo.cpufreq}</GridItem>}
            {deviceInfo.gpu && (
                <GridItem label='GPU'>
                    <PrettyJson json={deviceInfo.gpu} />
                </GridItem>
            )}
            {deviceInfo.memory && <GridItem label='Memory'>{deviceInfo.memory}</GridItem>}
            {deviceInfo.storage && <GridItem label='Storage'>{deviceInfo.storage}</GridItem>}
            {deviceInfo.battery && <GridItem label='Battery'>{deviceInfo.battery}%</GridItem>}
            {deviceInfo.temperature && <GridItem label='Temperature'>{deviceInfo.temperature}°</GridItem>}
        </Grid>
    );
}

function PrettyJson({ json }: { json: any }): ReactElement {
    const keys = Object.keys(json);
    return (
        <span>
            {keys.map((key) => (
                <React.Fragment key={key}>
                    <Typography variant='caption' color='textSecondary' display='inline'>
                        {capitalize(key)}:{' '}
                    </Typography>
                    <span style={{ marginRight: 4 }}>{json[key]}</span>
                </React.Fragment>
            ))}
        </span>
    );
}
