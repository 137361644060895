import { ReactElement } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { preventAndStopClick } from '../buildDetails/buildDetailsSlice';
import { IMessage } from './interfaces';
import MessageTableRow from './MessageTableRow';

export default function MessagesTable({ messages }: { messages?: IMessage[] }): ReactElement | null {
    if (!messages) {
        return null;
    }
    return (
        <TableContainer onClick={preventAndStopClick}>
            <Table>
                <TableBody>
                    {messages.map((message, index) => (
                        <MessageTableRow key={index} message={message} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
