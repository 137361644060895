import { ReactElement } from 'react';
import TemperatureIcon from '@mui/icons-material/DeviceThermostat';
import { Box, Tooltip, Typography } from '@mui/material';
import { useCheckTemperatureIsHigh } from '../useCheckDeviceThresholds';

export default function Temperature({
    temperature,
    isOffline,
    showOnlyOnError,
    size,
}: {
    temperature?: number;
    isOffline?: boolean;
    showOnlyOnError?: boolean;
    size?: 'large' | 'medium' | 'small';
}): ReactElement | null {
    const isTemperatureHigh = useCheckTemperatureIsHigh(temperature, isOffline);

    if (!temperature || temperature < 0 || (showOnlyOnError && !isTemperatureHigh)) {
        return null;
    }

    return (
        <Box display='flex' alignItems='center' justifyContent='center'>
            {!showOnlyOnError && (
                <Typography sx={{ pl: isTemperatureHigh ? 3 : 0 }} color={isOffline ? 'textSecondary' : 'inherit'}>
                    {temperature}°
                </Typography>
            )}
            {isTemperatureHigh && (
                <Tooltip
                    title={
                        <Typography variant='subtitle2'>
                            This device is overheated and will not start new tests until cooled down.
                        </Typography>
                    }
                >
                    <TemperatureIcon fontSize={size} color='error' sx={{ fill: (theme) => theme.palette.error.main }} />
                </Tooltip>
            )}
        </Box>
    );
}
