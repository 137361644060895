import { ReactElement, useState } from 'react';
import { ReportProblemOutlined as WarningIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { IBuild } from '../../commons/interfaces';
import { ITestDevice } from '../../testDevices/interfaces';
import { ISelectedTestCase, ITestBranch } from '../../tests/interfaces';
import SelectTestBranch from '../../tests/newTest/SelectTestBranch';
import SelectTestCases from '../../tests/newTest/SelectTestCases';
import { ETestSuiteAllocationType } from '../interfaces';
import { useCreateTestSuiteMutation } from '../testSuitesApi';
import MaxDevicesNumber from './MaxDevicesNumber';
import SelectTestDevices from './SelectTestDevices';
import SelectTestExecutionOptions from './SelectTestExecutionOptions';
import SubscribeProjectToResult from './SubscribeProjectToResult';
export interface IOrderTestSuiteDialogProps {
    open: boolean;
    onClose: () => void;
    build: IBuild;
}

export default function OrderTestSuiteDialog({ open, onClose, build }: IOrderTestSuiteDialogProps): ReactElement {
    const [testBranch, setTestBranch] = useState<ITestBranch | null>(null);
    const [devices, setDevices] = useState<ITestDevice[]>([]);
    const [testAllocation, setTestAllocation] = useState<ETestSuiteAllocationType>(ETestSuiteAllocationType.divide);
    const [testCases, setTestCases] = useState<ISelectedTestCase[]>([]);
    const [maxDevices, setMaxDevices] = useState<number>(1);
    const [subscribed, setSubscribed] = useState<boolean>(true);

    const [createTestSuite] = useCreateTestSuiteMutation();

    const onOrderTestsClick = () => {
        if (!testBranch) {
            return;
        }
        createTestSuite({
            type: maxDevices > 1 ? testAllocation : ETestSuiteAllocationType.sequential,
            build: build._id,
            devices: devices.map((device) => device._id),
            maxDevices,
            testBranch: testBranch.branchName,
            commitSha: testBranch.sha,
            testCases: testCases
                .filter((testCase) => testCase.selected)
                .map((testCase) => ({
                    id: testCase.id,
                    testName: testCase.testName,
                    displayName: testCase.displayName,
                    videoRecording: testCase.videoRecording,
                    dependsOn: testCase.dependsOn,
                    customBuild: testCase.customBuild,
                    settings: testCase.selectedSettings,
                })),
            subscribeProjectToResult: subscribed,
        }).then(() => handleClose());
    };

    const handleClose = () => {
        setTestBranch(null);
        setDevices([]);
        setTestCases([]);
        setSubscribed(true);
        onClose();
    };

    const getSelectedTestCases = () => {
        return testCases.filter((testCase) => testCase.selected);
    };

    const onMaxDevicesUpdate = (newValue: number) => {
        if (devices.length > 0) {
            setMaxDevices(newValue < devices.length ? devices.length : newValue);
        } else {
            setMaxDevices(newValue < 1 ? 1 : newValue);
        }
    };

    const onDevicesUpdate = (devices: ITestDevice[]) => {
        if (devices.length > maxDevices) {
            setMaxDevices(devices.length);
        }
        setDevices(devices);
    };

    const isFormInvalid = () => {
        const testOptionsAreInvalid = Boolean(
            testCases.find((test) => test.selected && test.selectBuild && !test.customBuild),
        );
        return getSelectedTestCases().length === 0 || testOptionsAreInvalid;
    };

    const selectedDisabledDevices = () => {
        for (const device of devices) {
            const hasNonEligibleDevicesInDivide =
                !device.isElegibleForQueuedTests &&
                testAllocation === ETestSuiteAllocationType.divide &&
                maxDevices > 1;

            if (hasNonEligibleDevicesInDivide || device.isDisabled) {
                return true;
            }
        }
        return false;
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg' data-testid='order-test-dialog'>
            <DialogTitle>Order new test suite</DialogTitle>
            <DialogContent sx={{ overflowY: 'unset' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SelectTestBranch
                            projectName={build.projectName}
                            buildBranch={build.branch}
                            testBranch={testBranch}
                            setTestBranch={setTestBranch}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                        <MaxDevicesNumber maxDevices={maxDevices} onMaxDevicesChange={onMaxDevicesUpdate} />
                        <SelectTestDevices
                            platform={build.platform}
                            devices={devices}
                            onDevicesChange={onDevicesUpdate}
                        />
                    </Grid>
                    {maxDevices > 1 ? (
                        <SelectTestExecutionOptions
                            testAllocation={testAllocation}
                            onTestAllocationChange={setTestAllocation}
                        />
                    ) : null}
                    {selectedDisabledDevices() && (
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.warning.main,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <WarningIcon />
                                Some of the selected devices are disabled or can&#39;t run this Test Suite type.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogContent dividers>
                <SelectTestCases
                    build={build}
                    branchName={testBranch?.branchName}
                    testCases={testCases}
                    setTestCases={setTestCases}
                />
            </DialogContent>
            <DialogActions>
                <SubscribeProjectToResult subscribed={subscribed} onSubscribedChange={setSubscribed} />
                <Button autoFocus onClick={handleClose} size='small'>
                    Close
                </Button>
                <Button
                    autoFocus
                    variant='contained'
                    color='primary'
                    size='small'
                    disabled={isFormInvalid()}
                    onClick={onOrderTestsClick}
                    data-testid='order-test-button'
                >
                    Order Tests
                </Button>
            </DialogActions>
        </Dialog>
    );
}
