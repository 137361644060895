import { ReactElement } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';

interface ClearFiltersButtonProps {
    onClick: () => void;
}

export default function ClearFiltersButton({ onClick }: ClearFiltersButtonProps): ReactElement {
    return (
        <IconButton size='small' title='clear all filters' onClick={onClick} sx={{ mr: 1, mb: 1 }}>
            <ClearIcon />
        </IconButton>
    );
}
