import { ReactElement } from 'react';
import { Chip, TableCell, useMediaQuery } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';

export default function BuildAgentCPU({ cpuInfo, align }: { cpuInfo: string; align?: MuiAlignSetting }): ReactElement {
    const isMobile = useMediaQuery('(max-width: 1820px)');
    const hasMatch = cpuInfo.match(/\d+.\d+GHz/);
    const smallText = hasMatch ? hasMatch[0] : cpuInfo;

    return (
        <TableCell sx={{ py: 2, px: 1 }} align={align}>
            <Chip
                size='small'
                sx={{ m: 0.25, border: 'none' }}
                title={cpuInfo}
                label={String(isMobile ? smallText : cpuInfo)}
            />
        </TableCell>
    );
}
