import { IToggleColumn } from '../commons/interfaces';

export enum TestSuitesColumnId {
    status = 'status',
    testSuiteNumber = 'testSuiteNumber',
    projectName = 'projectName',
    testBranch = 'testBranch',
    buildBranch = 'buildBranch',
    buildType = 'buildType',
    buildNumber = 'buildNumber',
    version = 'version',
    platform = 'platform',
    devices = 'devices',
    runtime = 'runtime',
    author = 'author',
    actions = 'actions',
}

export const testSuitesListColumns: Array<IToggleColumn<TestSuitesColumnId>> = [
    {
        id: TestSuitesColumnId.status,
        label: 'Status',
        align: 'left',
        width: '7%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.testSuiteNumber,
        label: 'Test Suite ID',
        align: 'center',
        width: '5%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.projectName,
        label: 'Project',
        align: 'left',
        width: '5%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.testBranch,
        label: 'Test Branch',
        align: 'center',
        width: '5%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.buildBranch,
        label: 'Build Branch',
        align: 'center',
        width: '5%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.buildType,
        label: 'Build Type',
        align: 'center',
        width: '5%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.buildNumber,
        label: 'Build ID',
        width: '5%',
        align: 'center',
        visible: true,
    },
    {
        id: TestSuitesColumnId.version,
        label: 'Version',
        width: '5%',
        align: 'center',
        visible: true,
    },
    {
        id: TestSuitesColumnId.platform,
        label: 'Platform',
        width: '5%',
        align: 'center',
        visible: true,
    },
    {
        id: TestSuitesColumnId.devices,
        label: 'Devices',
        width: '12%',
        align: 'left',
        visible: true,
    },
    {
        id: TestSuitesColumnId.runtime,
        label: 'Duration / Started',
        width: '5%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.author,
        label: 'User',
        align: 'center',
        width: '3%',
        visible: true,
    },
    {
        id: TestSuitesColumnId.actions,
        label: 'Actions',
        align: 'center',
        width: '3%',
        visible: true,
    },
];
