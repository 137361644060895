import { IToggleColumn } from '../../commons/interfaces';
import { TestDevicesColumnId } from '../../testDevices/list/testDevicesColumns';

export enum TestAgentsColumnId {
    expandDevices = 'expandDevices',
    state = 'state',
    name = 'name',
    version = 'version',
    xcode = 'xcode',
    webDriverAgent = 'webDriverAgent',
    python = 'python',
    os = 'os',
    actions = 'actions',
}

export const columns: Array<IToggleColumn<TestAgentsColumnId>> = [
    { id: TestAgentsColumnId.expandDevices, label: '', align: 'center', width: '4%' },
    { id: TestAgentsColumnId.state, label: 'Status', align: 'left', width: '8%' },
    { id: TestAgentsColumnId.name, label: 'Name', align: 'left', width: '13%' },
    { id: TestAgentsColumnId.version, label: 'Release Version', align: 'left', width: '10%' },
    { id: TestAgentsColumnId.os, label: 'OS', align: 'left', width: '10%' },
    { id: TestAgentsColumnId.xcode, label: 'Xcode', align: 'left', width: '15%' },
    { id: TestAgentsColumnId.webDriverAgent, label: 'Web Driver Agent', align: 'left', width: '15%' },
    { id: TestAgentsColumnId.python, label: 'Python', align: 'left', width: '15%' },
    { id: TestAgentsColumnId.actions, label: 'Actions', align: 'left', width: '10%' },
];

export const mobileColumns: Array<IToggleColumn<TestAgentsColumnId>> = columns.filter((column) =>
    [
        TestAgentsColumnId.expandDevices,
        TestAgentsColumnId.state,
        TestAgentsColumnId.name,
        TestAgentsColumnId.version,
        TestAgentsColumnId.actions,
    ].includes(column.id),
);

export const deviceColumns: Array<IToggleColumn<TestDevicesColumnId>> = [
    TestDevicesColumnId.dummy,
    TestDevicesColumnId.state,
    TestDevicesColumnId.name,
    TestDevicesColumnId.uuid,
    TestDevicesColumnId.platform,
    TestDevicesColumnId.dummy,
    TestDevicesColumnId.dummy,
    TestDevicesColumnId.testRunning,
    TestDevicesColumnId.actions,
].map((columnId) => ({ id: columnId, label: '' }));

export const mobileDeviceColumns: Array<IToggleColumn<TestDevicesColumnId>> = [
    TestDevicesColumnId.dummy,
    TestDevicesColumnId.state,
    TestDevicesColumnId.name,
    TestDevicesColumnId.testRunning,
    TestDevicesColumnId.actions,
].map((columnId) => ({ id: columnId, label: '' }));
