import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, useMediaQuery } from '@mui/material';
import ContentLoading from '../../commons/ContentLoading';
import HeaderSection from '../../commons/HeaderSection';
import Page from '../../commons/Page';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { openSocket } from '../../sockets';
import { ITestDevice } from '../../testDevices/interfaces';
import { addTestsQueueInfo, TestQueue } from '../../tests/testQueue';
import { useTestsQueue } from '../../tests/useTestsQueue';
import RegisterTestAgentButton from '../edit/RegisterTestAgentButton';
import { ITestAgent, ITestAgentShortInfo } from '../interfaces';
import { useGetTestAgentsQuery } from '../testAgentsApi';
import TestAgentLoadingRows from './TestAgentLoadingRows';
import TestAgentRow from './TestAgentRow';
import { columns, deviceColumns, mobileColumns, mobileDeviceColumns } from './testAgentsColumns';

export default function TestAgentsList(): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const tableColumns = isMobile ? mobileColumns : columns;
    const deviceTableColumns = isMobile ? mobileDeviceColumns : deviceColumns;

    const { data: testAgentsData, isFetching, refetch: refetchTestAgents } = useGetTestAgentsQuery();
    const [testAgents, setTestAgents] = useState<ITestAgent[] | undefined>(testAgentsData);

    const testsQueue: TestQueue = useTestsQueue();

    useEffect(() => {
        if (testAgentsData) {
            const testAgentsCopy = JSON.parse(JSON.stringify(testAgentsData));
            for (const testAgent of testAgentsCopy) {
                const testAgentShortInfo: ITestAgentShortInfo = {
                    _id: testAgent._id,
                    hostname: testAgent.hostname,
                    manuallyDisabled: testAgent.manuallyDisabled,
                    disabledReason: testAgent.disabledReason,
                };
                testAgent.devices = addTestsQueueInfo(testAgent.devices, testsQueue);
                testAgent.devices.forEach((device: ITestDevice) => {
                    device.testAgent = testAgentShortInfo;
                });
            }
            setTestAgents(testAgentsCopy);
        }
    }, [testAgentsData, testsQueue]);

    useEffect(() => {
        const socket = openSocket('test-devices');
        socket.on('update-device', refetchTestAgents);

        return () => {
            socket.off('update-device', refetchTestAgents);
        };
    }, [refetchTestAgents]);

    const isLoading = isFetching && !testAgents;

    return (
        <Suspense fallback={<ContentLoading />}>
            <Page>
                <HeaderSection
                    title='Test Agents'
                    breadcrumbs={[
                        { text: 'Tests', href: '/tests' },
                        { text: 'Test Agents', href: '/test-agents' },
                    ]}
                    helmetTitle='Test Agents'
                    actionBox={<RegisterTestAgentButton />}
                />
                <Paper
                    data-testid='test-agents'
                    sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
                >
                    <TableContainer>
                        <Table
                            stickyHeader
                            aria-label='test agents table'
                            style={{ width: '100%', tableLayout: 'fixed' }}
                        >
                            <TableHead data-testid='test-agents-table-head'>
                                <TableHeaderRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            size='small'
                                            sx={{ width: column.width, minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody data-testid='test-agents-table-body'>
                                {isLoading ? (
                                    <TestAgentLoadingRows columns={tableColumns} />
                                ) : (
                                    testAgents?.map((testAgent) => (
                                        <TestAgentRow
                                            key={testAgent._id}
                                            columns={tableColumns}
                                            testAgent={testAgent}
                                            deviceColumns={deviceTableColumns}
                                        />
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Page>
        </Suspense>
    );
}
