import { ReactElement } from 'react';
import { SxProps } from '@mui/material';
import CustomLink from '../commons/CustomLink';

interface TestBranchLinkProps {
    projectName: string;
    testBranch: string;
    commitSha?: string;
    sx?: SxProps;
}

export default function TestBranchLink({ projectName, testBranch, commitSha, sx }: TestBranchLinkProps): ReactElement {
    const shortSha = commitSha?.substring(0, 7);
    const branchLink =
        testBranch &&
        `https://github.com/tactilegames/qa-automation-${projectName.toLowerCase()}/tree/${commitSha || testBranch}`;

    const linkText = testBranch + (shortSha ? ` #${shortSha}` : '');

    return (
        <CustomLink
            to={{ pathname: branchLink }}
            target='_blank'
            rel='noreferrer'
            onClick={(event) => event.stopPropagation()}
            onMouseUp={(event) => event.stopPropagation()}
            sx={sx}
        >
            {linkText}
        </CustomLink>
    );
}
