import { ReactElement, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { Dialog, successToast } from '@tactileentertainment/core-designsystem';
import { IReleaseNote } from './interfaces';
import LocalizedMessages from './LocalizedMessages';
import { useCreateReleaseNotesMutation } from './releaseNotesApi';
import SelectProjectName from './SelectProjectName';

interface ReleaseNotesEditDialogProps {
    open: boolean;
    isNew?: boolean;
    isClone?: boolean;
    releaseNote: IReleaseNote;
    onClose: () => void;
}

export default function ReleaseNotesEditDialog({
    open,
    isNew,
    isClone,
    onClose,
    releaseNote: initialReleaseNote,
}: ReleaseNotesEditDialogProps): ReactElement {
    const [createReleaseNote] = useCreateReleaseNotesMutation();
    const [releaseNote, setReleaseNote] = useState({ ...initialReleaseNote });

    const updateReleaseNote = (data: Partial<IReleaseNote>) => {
        setReleaseNote((value) => ({ ...value, ...data }));
    };

    const saveReleaseNote = async () => {
        if (isNew || isClone) {
            delete releaseNote._id;
        }

        const result = await createReleaseNote(releaseNote);
        if (!('error' in result)) {
            successToast('Release note created');
        }

        onClose();
    };

    const isValid = (): boolean => {
        if (!releaseNote.projectName || !releaseNote.title || releaseNote.localizedMessages.length === 0) {
            return false;
        }
        for (const msg of releaseNote.localizedMessages) {
            if (!msg.language || !msg.message) {
                return false;
            }
        }
        return true;
    };

    const title = useMemo(() => {
        if (isNew) {
            return 'Add Release Note';
        }
        if (isClone) {
            return 'Clone Release Note';
        }
        if (!isClone && !isNew) {
            return 'Edit Release Note';
        }
        return '';
    }, [isNew, isClone]);

    return (
        <Dialog
            open={open}
            title={title}
            variant='custom'
            onClose={onClose}
            content={
                <>
                    <SelectProjectName
                        projectName={releaseNote.projectName}
                        setProjectName={(projectName) => updateReleaseNote({ projectName })}
                    />
                    <TextField
                        label='Title'
                        size='medium'
                        fullWidth
                        value={releaseNote.title}
                        onChange={(event) => updateReleaseNote({ title: event.target.value })}
                        sx={{ mt: 1 }}
                    />
                    <LocalizedMessages
                        localizedMessages={releaseNote.localizedMessages}
                        setLocalizedMessages={(localizedMessages) => updateReleaseNote({ localizedMessages })}
                    />
                </>
            }
            primaryButton={{
                text: 'Save',
                disabled: !isValid(),
                onClick: saveReleaseNote,
            }}
        />
    );
}
