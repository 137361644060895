import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { CenteredFlexBox, Typography } from '@tactileentertainment/core-designsystem';
import { getAcronym } from '../../commons/getAcronym';
import TactileTableBody from '../../commons/TableBody';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { ProjectVersion } from '../details/GameModuleProjectRow';
import { GameModuleCategory } from '../enums';
import GameModuleStatus from '../GameModuleStatus';
import { IGameModules } from '../interfaces';
import { isSha } from '../utils';

interface CompareModuleTableProps {
    modulesDiffData: IGameModules;
    tab: GameModuleCategory;
    primaryProject: string;
    secondaryProject: string;
    primaryBranch: string;
    secondaryBranch: string;
}

export const CompareModulesTable = ({
    modulesDiffData,
    tab,
    primaryProject,
    secondaryProject,
    primaryBranch,
    secondaryBranch,
}: CompareModuleTableProps) => {
    return (
        <TableContainer>
            <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                <TableHead sx={{ height: 44 }}>
                    <TableHeaderRow>
                        <TableCell>Module Name</TableCell>
                        <TableCell>Latest Version</TableCell>
                        <TableCell>{`${getAcronym(primaryProject)}/${primaryBranch || 'trunk'}`}</TableCell>
                        <TableCell>{`${getAcronym(secondaryProject)}/${secondaryBranch || 'trunk'}`}</TableCell>
                    </TableHeaderRow>
                </TableHead>
                <TactileTableBody>
                    {modulesDiffData[tab].map((module, i) => (
                        <TableRow key={i}>
                            <TableCell sx={{ width: '40%' }}>
                                <Typography variant='body-2'>{module.moduleName}</Typography>
                            </TableCell>
                            <TableCell>
                                <CenteredFlexBox sx={{ justifyContent: 'flex-start' }}>
                                    <ProjectVersion
                                        isProjectVersionSha={isSha(module.version)}
                                        version={module.version}
                                        homepage={module.homepage}
                                    />
                                </CenteredFlexBox>
                            </TableCell>
                            {[primaryProject, secondaryProject].map((selected, index) => {
                                const projectModule = module.projects[index];
                                const isProjectVersionSha = projectModule?.version
                                    ? isSha(projectModule?.version)
                                    : false;

                                return (
                                    <TableCell key={`${selected}-${index}`}>
                                        <CenteredFlexBox sx={{ gap: 1, justifyContent: 'flex-start' }}>
                                            <ProjectVersion
                                                isProjectVersionSha={isProjectVersionSha}
                                                version={projectModule?.version}
                                                homepage={projectModule?.homepage}
                                            />
                                            <GameModuleStatus project={projectModule} />
                                        </CenteredFlexBox>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TactileTableBody>
            </Table>
        </TableContainer>
    );
};
