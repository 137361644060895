import { ReactElement, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Chip, LinearProgress, Tooltip, Typography } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { ONE_GIGABYTE } from '../../../utils';
import { getAcronym } from '../../commons/getAcronym';
import getBuildAgentName from '../../commons/getBuildAgentName';
import { BuildAgent } from '../interfaces';

export default function AgentCard({ buildAgent }: { buildAgent?: BuildAgent }): ReactElement {
    const agentName = getBuildAgentName(buildAgent);
    const [diskCapacity, setDiskCapacity] = useState<number>(0);
    const [freeDiskSpace, setFreeDiskSpace] = useState<number>(0);
    const [toCheckout, setToCheckout] = useState<string[]>([]);
    const [toRemove, setToRemove] = useState<string[]>([]);
    const [showAcronym, setShowAcronym] = useState(true);
    const removeTitle =
        'External Assets associated with projects below will also be removed. Additionally, any orphaned external asset folders will also be removed.';

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        setShowAcronym(!showAcronym);
    };

    useEffect(() => {
        const total = toGigaBytes(buildAgent?.agentInfo.disk_space.total || 0);
        const free = toGigaBytes(buildAgent?.agentInfo.disk_space.free || 0);
        setDiskCapacity(total);
        setFreeDiskSpace(free);

        const projectToCheckout = buildAgent?.provisionedCheckouts?.filter(
            (project) => !buildAgent.supportedProjects.includes(project),
        );
        setToCheckout(projectToCheckout || []);
        setToRemove(buildAgent?.dynamicCheckouts || []);
    }, [buildAgent]);

    return (
        <Box
            sx={{
                display: 'block',
                border: `1px solid ${blueGray[300]}`,
                borderRadius: 2,
                p: 1,
                height: 300,
                minWidth: 310,
            }}
        >
            <Box>
                <Typography variant='body1' marginBottom={2}>
                    {agentName}
                </Typography>
                <LinearProgress
                    style={{
                        borderRadius: '15px',
                    }}
                    variant='determinate'
                    value={(freeDiskSpace / diskCapacity) * 100}
                />
                <Typography variant='body1' align='left' marginBottom={2}>
                    {freeDiskSpace.toFixed(2) + ' / ' + diskCapacity.toFixed(2) + ' GB'}
                </Typography>
                <Typography variant='h6'>Checking out</Typography>
                <Box>
                    {toCheckout.length ? (
                        toCheckout.map((project) => (
                            <Chip
                                size='small'
                                title='Click to toggle project full name and acronym'
                                sx={{
                                    m: 0.25,
                                    display: 'inline-grid',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    border: `none`,
                                }}
                                key={project}
                                label={showAcronym ? getAcronym(project) : project}
                                onClick={handleClick}
                            />
                        ))
                    ) : (
                        <Chip
                            size='small'
                            sx={{
                                m: 0.25,
                                display: 'inline-grid',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                border: `none`,
                            }}
                            label={showAcronym ? 'N/A' : 'Nothing to checkout'}
                            onClick={handleClick}
                        />
                    )}
                </Box>
            </Box>
            <Box>
                <Box mt={4}>
                    <Tooltip title={removeTitle}>
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                            <Typography variant='h6'>Removing</Typography>
                            <InfoIcon sx={{ cursor: 'pointer', color: blueGray[500] }} />
                        </Box>
                    </Tooltip>
                </Box>
                <Box mt={1}>
                    {toRemove.length ? (
                        toRemove.map((project) => (
                            <Chip
                                size='small'
                                title='Click to toggle project full name and acronym'
                                sx={{
                                    m: 0.25,
                                    display: 'inline-grid',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    border: `2px dashed ${blueGray[500]}`,
                                }}
                                key={project}
                                label={showAcronym ? getAcronym(project) : project}
                                onClick={handleClick}
                            />
                        ))
                    ) : (
                        <Chip
                            size='small'
                            sx={{
                                m: 0.25,
                                display: 'inline-grid',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                border: `none`,
                            }}
                            label={showAcronym ? 'N/A' : 'Nothing to remove'}
                            onClick={handleClick}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

function toGigaBytes(value: number): number {
    return value / ONE_GIGABYTE;
}
