import { ReactElement } from 'react';
import { TableCell, Typography } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import CustomLink from '../../commons/CustomLink';

interface BuildAgentCommitHashProps {
    gitInfo?: string;
    align?: MuiAlignSetting;
}

export default function BuildAgentCommitHash({ gitInfo = '', align }: BuildAgentCommitHashProps): ReactElement {
    const { gitHash, shortCommitHash } = getHashFromGitInfo(gitInfo);
    const gitCommitUrl = (gitInfo?.match(/http.*/)?.[0] || '').replace('https//', 'https://');

    return (
        <TableCell sx={{ py: 2, px: 1 }} align={align}>
            {gitCommitUrl ? (
                <CustomLink
                    noWrap
                    title={gitHash}
                    target='_blank'
                    to={{ pathname: gitCommitUrl }}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
                >
                    {shortCommitHash}
                </CustomLink>
            ) : (
                <Typography noWrap title={gitHash}>
                    {shortCommitHash}
                </Typography>
            )}
        </TableCell>
    );
}

export function getHashFromGitInfo(gitInfo?: string): { gitHash: string; shortCommitHash: string } {
    const gitHash = gitInfo?.split('/')?.pop() || '-';
    const shortCommitHash = gitHash?.slice(0, 7);
    return { gitHash, shortCommitHash };
}
