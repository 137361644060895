import { ReactElement } from 'react';
import { SxProps, TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../typings/material-ui';

export default function TableTextCell(props: {
    align?: MuiAlignSetting;
    text?: string;
    sx?: SxProps;
    style?: React.CSSProperties;
}): ReactElement {
    const { align, text, sx, style } = props;

    return (
        <TableCell align={align} sx={{ padding: 1, ...sx }} style={style} data-testid='table-text-cell' title={text}>
            <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {text}
            </span>
        </TableCell>
    );
}
