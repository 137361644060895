import { ReactElement, useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ShowIfCan } from '@tactileentertainment/core-shared.auth-react';
import { EAuthResource } from '../../commons/EAuthResource';
import EditTestAgentDialog from './EditTestAgentDialog';

export default function RegisterTestAgentButton(): ReactElement {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <ShowIfCan resource={EAuthResource.testAgents} operation='edit'>
                <Button
                    variant='contained'
                    color='primary'
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    onClick={() => setOpenDialog(true)}
                    startIcon={<AddIcon />}
                >
                    Register Agent
                </Button>
            </ShowIfCan>
            {openDialog && <EditTestAgentDialog open={openDialog} handleClose={() => setOpenDialog(false)} />}
        </>
    );
}
