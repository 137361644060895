import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Alert, CircularProgress, Typography, Box, Card, Divider } from '@mui/material';
import { NewBuildStatus } from './enums';
import NewBuildConfiguration from './NewBuildConfiguration';
import { selectNewBuildConfiguration, selectNewBuildError, selectNewBuildStatus } from './newBuildSlice';
import SelectNewBuildConfiguration from './SelectNewBuildConfiguration';
import SelectTestOptions from './SelectTestOptions';

export default function NewBuildOptions(): ReactElement {
    const buildConfiguration = useSelector(selectNewBuildConfiguration);
    const newBuildStatus = useSelector(selectNewBuildStatus);

    const isLoading = newBuildStatus === NewBuildStatus.loadingConfiguration;

    return (
        <>
            <Card
                data-testid='new-build-options'
                sx={{
                    overflow: 'auto',
                    boxShadow: 'none',
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.grey[200],
                    p: 3,
                }}
            >
                <Typography variant='h5' fontWeight={600} gutterBottom>
                    Build Options
                </Typography>
                <Divider sx={{ mx: -3, mt: 2 }} />
                <SelectNewBuildConfiguration />
            </Card>
            <Card
                data-testid='new-build-options-additional-settings'
                sx={{
                    overflow: 'auto',
                    boxShadow: 'none',
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.grey[200],
                    p: 3,
                    mt: 3,
                    mb: 10,
                }}
            >
                <Typography variant='h5' fontWeight={600} gutterBottom>
                    Additional Settings
                </Typography>
                <Divider sx={{ mx: -3, mt: 2 }} />

                {isLoading && (
                    <Box
                        data-testid='new-build-configuration-loading'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                                margin: 1,
                            },
                        }}
                    >
                        <CircularProgress size={20} />
                    </Box>
                )}
                <BuildConfigurationMessage />
                {!isLoading && buildConfiguration.selected && (
                    <>
                        <NewBuildConfiguration />
                        <SelectTestOptions />
                    </>
                )}
            </Card>
        </>
    );
}

function BuildConfigurationMessage(): ReactElement | null {
    const status = useSelector(selectNewBuildStatus);
    const error = useSelector(selectNewBuildError);
    const buildConfiguration = useSelector(selectNewBuildConfiguration);

    if (status === NewBuildStatus.error) {
        return <Alert severity='error'>{error}</Alert>;
    } else if (status !== NewBuildStatus.loadingConfiguration && !buildConfiguration.selected) {
        return (
            <Box sx={{ textAlign: 'center', mt: 7 }}>
                <SettingsOutlinedIcon color='secondary' />
                <Typography variant='body1' color='textSecondary' align='center' sx={{ my: 2 }}>
                    Select Project, Branch and Revision that has build_config.json first to see build configuration
                    options
                </Typography>
            </Box>
        );
    }
    return null;
}
