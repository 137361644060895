import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { ITestDevice, ITestDevicesSettings } from './interfaces';

export const testDevicesApi = createApi({
    reducerPath: 'testDevicesApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['Devices', 'DevicesSettings'],
    endpoints: (builder) => ({
        getTestDevice: builder.query<ITestDevice, string>({
            query: (uuid: string) => ({
                url: `test-devices/${uuid}`,
            }),
            providesTags: ['Devices'],
            transformResponse: (response: { testDevice: ITestDevice }): ITestDevice => response.testDevice,
        }),
        updateTestDevice: builder.mutation<void, ITestDevice>({
            query: (testDevice: ITestDevice) => ({
                method: 'PUT',
                url: `test-devices/${testDevice.uuid}`,
                body: {
                    name: testDevice.name,
                    priority: testDevice.priority,
                    isElegibleForQueuedTests: testDevice.isElegibleForQueuedTests,
                    isDisabled: testDevice.isDisabled,
                },
            }),
            invalidatesTags: ['Devices'],
        }),
        deleteTestDevice: builder.mutation<void, ITestDevice>({
            query: (testDevice: ITestDevice) => ({
                method: 'DELETE',
                url: `test-devices/${testDevice.uuid}`,
            }),
            invalidatesTags: ['Devices'],
        }),
        getTestDevices: builder.query<ITestDevice[], void>({
            query: () => ({
                url: 'test-devices',
            }),
            providesTags: ['Devices'],
        }),
        getActiveTestDevices: builder.query<ITestDevice[], void>({
            query: () => ({
                url: 'test-devices?state=idle&state=running&state=done',
            }),
            providesTags: ['Devices'],
        }),
        getTestDevicesSettings: builder.query<ITestDevicesSettings, void>({
            query: () => ({
                url: 'test-devices/settings',
            }),
            providesTags: ['DevicesSettings'],
        }),
        updateTestDevicesSettings: builder.mutation<void, ITestDevicesSettings>({
            query: (settings: ITestDevicesSettings) => ({
                method: 'PUT',
                url: 'test-devices/settings',
                body: settings,
            }),
            invalidatesTags: ['DevicesSettings'],
        }),
    }),
});

export const {
    useGetTestDeviceQuery,
    useUpdateTestDeviceMutation,
    useDeleteTestDeviceMutation,
    useGetTestDevicesQuery,
    useGetActiveTestDevicesQuery,
    useGetTestDevicesSettingsQuery,
    useUpdateTestDevicesSettingsMutation,
} = testDevicesApi;
