import { IToggleColumn } from '../../commons/interfaces';

export enum TestSuiteTestCaseListColumnsId {
    expandable = 'expandable',
    status = 'status',
    testCases = 'testCases',
    device = 'device',
    started = 'started',
    ended = 'ended',
    duration = 'duration',
    actions = 'actions',
}

export const testSuiteTestCaseListColumns: Array<IToggleColumn<TestSuiteTestCaseListColumnsId>> = [
    { id: TestSuiteTestCaseListColumnsId.expandable, label: '', align: 'left', width: '2%' },
    { id: TestSuiteTestCaseListColumnsId.status, label: 'Status', align: 'left', width: '8%' },
    { id: TestSuiteTestCaseListColumnsId.testCases, label: 'Test Cases', align: 'left', width: '26%' },
    { id: TestSuiteTestCaseListColumnsId.device, label: 'Device', align: 'left', width: '20%' },
    { id: TestSuiteTestCaseListColumnsId.started, label: 'Started', align: 'left', width: '12%' },
    { id: TestSuiteTestCaseListColumnsId.ended, label: 'Ended', align: 'left', width: '12%' },
    { id: TestSuiteTestCaseListColumnsId.duration, label: 'Duration', align: 'left', width: '10%' },
    { id: TestSuiteTestCaseListColumnsId.actions, label: 'Actions', align: 'left', width: '10%' },
];
