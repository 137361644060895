import React, { ReactElement } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TableCell, useTheme } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';

export interface IProjectAvailabilityCellProps {
    isOrderable: boolean;
    align?: MuiAlignSetting;
    style?: React.CSSProperties;
}

export default function ProjectAvailabilityCell({
    isOrderable,
    align,
    style,
}: IProjectAvailabilityCellProps): ReactElement | null {
    const theme = useTheme();

    return (
        <TableCell align={align} id='table-cell-availability'>
            {isOrderable ? (
                <CheckCircleIcon style={{ ...style, color: theme.palette.success.main }} />
            ) : (
                <CancelIcon style={{ ...style, color: theme.palette.text.disabled }} />
            )}
        </TableCell>
    );
}
