import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Alert,
    Autocomplete,
    createFilterOptions,
    FilterOptionsState,
    SxProps,
    TextField,
    Typography,
} from '@mui/material';
import { selectNewBuildProject } from '../newBuild/newBuildSlice';
import { useGetDefaultNotificationChanelQuery, useGetSlackRecipientsQuery } from '../subscriptions/subscriptionsApi';

interface SlackRecipientsProps {
    otherSubscribers: string[];
    setOtherSubscribers: (value: string[]) => void;
    sx?: SxProps;
}

export function SlackRecipients({ sx, otherSubscribers, setOtherSubscribers }: SlackRecipientsProps): ReactElement {
    const { selected: projectName } = useSelector(selectNewBuildProject);
    const { data: slackRecipientsData, isLoading: recipientsLoading, isError } = useGetSlackRecipientsQuery();
    const { data: defaultChannel, isLoading: defaultChanelLoading } = useGetDefaultNotificationChanelQuery(
        { projectName },
        { skip: !projectName },
    );

    const [recipients, setRecipients] = useState<string[]>([]);

    useEffect(() => {
        const slackRecipients = slackRecipientsData?.map((item) => item.email || item.name);
        if (defaultChannel) {
            setOtherSubscribers([defaultChannel, ...otherSubscribers]);
            setRecipients([defaultChannel]);
        }
        if (slackRecipients) {
            setRecipients([...recipients, ...slackRecipients]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slackRecipientsData, defaultChannel]);

    return (
        <>
            {isError && (
                <Alert sx={{ my: 1 }} severity='error'>
                    Unable to get recipients. Please try again later.
                </Alert>
            )}
            <Typography sx={{ mt: 1, mb: 2 }}>
                Add any other email, user name or channel name to get notified on Slack
            </Typography>
            <Autocomplete
                sx={{ ...sx }}
                options={recipients}
                loading={recipientsLoading || defaultChanelLoading}
                autoHighlight
                clearOnBlur
                multiple={true}
                value={otherSubscribers}
                onChange={(_event: any, newValue: string[]) => setOtherSubscribers(newValue)}
                filterOptions={filterOptions}
                renderInput={(params) => <TextField {...params} label='Emails or channels' variant='outlined' />}
            />
        </>
    );
}

const muiFilter = createFilterOptions<string>();
const filterOptions = (options: string[], state: FilterOptionsState<string>) => {
    const inputValue = state.inputValue?.toLowerCase();
    const filtered = muiFilter(options, state).sort(
        (a, b) => a.toLowerCase().indexOf(inputValue) - b.toLowerCase().indexOf(inputValue),
    );

    return filtered;
};
