import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell, IconButton, useMediaQuery } from '@mui/material';
import { blueGray, gray } from '@tactileentertainment/core-designsystem';
import { useGetBuildsQuery } from '../../builds/buildsApi';
import ClickableTableRow from '../../commons/ClickableTableRow';
import { EBuildStatus } from '../../commons/enums';
import ExpandIcon from '../../commons/ExpandIcon';
import BuildAgentActions from '../BuildAgentActions';
import { selectBuildAgentColumns, selectHighlightBuildAgents, removeHighlightBuildAgent } from '../buildAgentsSlice';
import { AgentsColumnId, EBuildAgentState } from '../enums';
import { BuildAgent } from '../interfaces';
import BuildAgentChip from './BuildAgentChip';
import BuildAgentCommitHash from './BuildAgentCommitHash';
import BuildAgentCPU from './BuildAgentCPU';
import BuildAgentDiskSpace from './BuildAgentDiskSpace';
import BuildAgentLastBuildsInfoTable from './BuildAgentLastBuildsInfoTable';
import BuildAgentLastSeen from './BuildAgentLastSeen';
import BuildAgentMemory from './BuildAgentMemory';
import BuildAgentName from './BuildAgentName';
import BuildAgentProjects from './BuildAgentProject';
import BuildAgentState from './BuildAgentState';
import BuildAgentUpdatedBy from './BuildAgentUpdatedBy';
import BuildAgentVersion from './BuildAgentVersion';

interface BuildAgentRowProps {
    buildAgent: BuildAgent;
}

export default function BuildAgentRow({ buildAgent }: BuildAgentRowProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [highlightBuildAgent, setHighlightBuildAgent] = useState(false);
    const highlightedBuildAgents = useSelector(selectHighlightBuildAgents);

    useEffect(() => {
        const isHighlighted = highlightedBuildAgents.some((agent) => agent === buildAgent._id);
        let timeoutRef: NodeJS.Timeout;

        if (isHighlighted) {
            setHighlightBuildAgent(true);
            timeoutRef = setTimeout(() => {
                dispatch(removeHighlightBuildAgent(buildAgent._id));
                setHighlightBuildAgent(false);
            }, 5000);
        }

        return () => {
            if (timeoutRef) {
                dispatch(removeHighlightBuildAgent(buildAgent._id));
                clearTimeout(timeoutRef);
            }
        };
    }, [highlightedBuildAgents, buildAgent, dispatch]);

    return (
        <>
            <ClickableTableRow
                tabIndex={-1}
                data-testid='build-agent-row'
                selected={highlightBuildAgent}
                sx={{
                    '& td': {
                        borderBottom: 'unset',
                    },
                    ...(!buildAgent.enabled && {
                        backgroundColor: gray[300],
                        '& *': {
                            color: gray[500],
                        },
                    }),
                }}
                // eslint-disable-next-line no-unused-vars
                onClick={(e) => !isMobile && setOpen(!open)}
            >
                <TableCell>
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => setOpen(!open)}
                        sx={{ color: blueGray[800] }}
                    >
                        <ExpandIcon expanded={open} mode='tree' />
                    </IconButton>
                </TableCell>
                <FullBuildAgentRow buildAgent={buildAgent} />
            </ClickableTableRow>
            <BuildAgentLastBuildsInfoTable open={open} buildAgent={buildAgent} />
        </>
    );
}

export function FullBuildAgentRow({ buildAgent }: BuildAgentRowProps): ReactElement {
    const buildAgentColumns = useSelector(selectBuildAgentColumns);
    const [ignoreSkip, setIgnoreSkip] = useState(false);
    const skip = ignoreSkip ? false : buildAgent.state !== EBuildAgentState.running;
    const { data: builds, isLoading } = useGetBuildsQuery(
        { buildAgent: buildAgent._id, limit: 1, status: EBuildStatus.running },
        { skip },
    );
    const [build = undefined] = builds || [];

    return (
        <>
            {buildAgentColumns
                .filter((column) => column.visible)
                .map((column) => {
                    switch (column.id) {
                        case AgentsColumnId.name:
                            return <BuildAgentName key={column.id} buildAgent={buildAgent} />;

                        case AgentsColumnId.state:
                            return (
                                <BuildAgentState
                                    key={column.id}
                                    buildAgent={buildAgent}
                                    build={build}
                                    isLoading={isLoading}
                                    onMouseEnter={() => {
                                        setIgnoreSkip(true);
                                    }}
                                />
                            );

                        case AgentsColumnId.projects:
                            return (
                                <BuildAgentProjects
                                    key={column.id}
                                    projects={buildAgent.supportedProjects}
                                    dynamicCheckouts={buildAgent.dynamicCheckouts}
                                    build={build}
                                />
                            );

                        case AgentsColumnId.ip:
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    <span
                                        style={{
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {buildAgent.ip}:{buildAgent.port}
                                    </span>
                                </TableCell>
                            );

                        case AgentsColumnId.lastSeen:
                            return (
                                <BuildAgentLastSeen
                                    key={column.id}
                                    align={column.align}
                                    lastSeen={buildAgent.lastSeen}
                                />
                            );

                        case AgentsColumnId.sdk:
                        case AgentsColumnId.ndk:
                        case AgentsColumnId.jdk:
                        case AgentsColumnId.xcode:
                        case AgentsColumnId.cocoapods:
                        case AgentsColumnId.unityVersions:
                            return <BuildAgentChip key={column.id} values={buildAgent.supportedTools[column.id]} />;

                        case AgentsColumnId.svn:
                        case AgentsColumnId.os:
                            return <BuildAgentChip key={column.id} values={buildAgent.agentInfo[column.id]} />;

                        case AgentsColumnId.disk:
                            return (
                                <BuildAgentDiskSpace
                                    key={column.id}
                                    align={column.align}
                                    diskInfo={buildAgent.agentInfo[column.id]}
                                />
                            );

                        case AgentsColumnId.memory:
                            return (
                                <BuildAgentMemory
                                    key={column.id}
                                    align={column.align}
                                    memoryInfo={buildAgent.agentInfo[column.id]}
                                />
                            );

                        case AgentsColumnId.cpu:
                            return (
                                <BuildAgentCPU
                                    key={column.id}
                                    align={column.align}
                                    cpuInfo={buildAgent.agentInfo[column.id]}
                                />
                            );

                        case AgentsColumnId.commit:
                            return (
                                <BuildAgentCommitHash
                                    key={column.id}
                                    align={column.align}
                                    gitInfo={buildAgent.agentInfo[column.id]}
                                />
                            );

                        case AgentsColumnId.updatedBy:
                            return <BuildAgentUpdatedBy key={column.id} user={buildAgent.updatedBy} />;

                        case AgentsColumnId.actions:
                            return <BuildAgentActions key={column.id} buildAgent={buildAgent} align={column.align} />;

                        case AgentsColumnId.version:
                            return (
                                <BuildAgentVersion
                                    key={column.id}
                                    version={buildAgent.agentInfo.version}
                                    align={column.align}
                                />
                            );

                        default:
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    {' '}
                                    {buildAgent[column.id]}{' '}
                                </TableCell>
                            );
                    }
                })}
        </>
    );
}
