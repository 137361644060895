import { ReactElement } from 'react';
import { KeyboardArrowRight } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material';

interface ExpandIconProps {
    expanded?: boolean;
    mode?: 'default' | 'tree';
    sx?: SxProps<Theme>;
}

const ExpandIcon = ({ expanded = false, mode = 'default', sx = {} }: ExpandIconProps): ReactElement => {
    const isTree = mode === 'tree';
    const expandedStyle = isTree ? 'rotate(90deg)' : 'rotate(270deg)';
    const collapsedStyle = isTree ? 'rotate(0deg)' : 'rotate(90deg)';

    return (
        <KeyboardArrowRight
            sx={{
                transform: expanded ? expandedStyle : collapsedStyle,
                transition: (theme) => `transform 150ms ${theme.transitions.easing.easeInOut} 0ms`,
                ...sx,
            }}
        />
    );
};

export default ExpandIcon;
