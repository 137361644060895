import { ReactElement } from 'react';
import { TablePagination as MuiTablePagination, TablePaginationTypeMap } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';

function TablePagination(props: TablePaginationTypeMap<any, any>['props']): ReactElement {
    return (
        <MuiTablePagination
            sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                borderTop: `1px solid ${blueGray[200]}`,
                color: blueGray[600],
                position: 'sticky',
                display: 'inline-table',
                bottom: 0,
                width: '100%',
                '.MuiTablePagination-displayedRows': {
                    color: blueGray[800],
                },
            }}
            rowsPerPageOptions={[]}
            component='div'
            rowsPerPage={50}
            {...props}
        />
    );
}

export default TablePagination;
