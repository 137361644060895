import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColumnFilter from '../../commons/filters/ColumnFilter';
import { parseFilterOptions } from '../../commons/filters/utils';
import { useGetAvailableOptionsQuery } from '../testsApi';
import { selectBuildTypeFilter, setTestsFilter } from '../testsSlice';
import { ITestFilterProps } from './TestsFilters';

export default function BuildTypeSelector({ showOnlyValues }: ITestFilterProps): ReactElement {
    const dispatch = useDispatch();
    const selected = useSelector(selectBuildTypeFilter);
    const { data, isFetching: loading } = useGetAvailableOptionsQuery();

    const parsedOptions = parseFilterOptions(data?.options.build?.type || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setTestsFilter({ build: { type: newValue || undefined } }));
    };

    return (
        <ColumnFilter
            id='buildType-selector'
            label='Build Type'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}
