import { ReactElement } from 'react';
import { Chip, TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';

export default function BuildAgentVersion({
    version,
    align,
}: {
    version?: string;
    align?: MuiAlignSetting;
}): ReactElement {
    return (
        <TableCell sx={{ py: 2, px: 1 }} align={align}>
            {version ? <Chip size='small' sx={{ m: 0.25, border: 'none' }} title='Version' label={version} /> : <></>}
        </TableCell>
    );
}
