import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, MenuItem, SwipeableDrawer } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { IBuildsSliceState } from '../builds/interfaces';
import ExpandIcon from '../commons/ExpandIcon';
import { selectDrawerOpen, setDrawerOpen, setSelectedMenu } from './headerSlice';
import { menuItems } from './menuItems';

export default function BtcDrawer(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const open = useSelector(selectDrawerOpen);
    const history = useHistory();
    const [openOptions, setOpenOptions] = useState<Record<string, boolean>>({});

    const handleDrawerToggle = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        dispatch(setDrawerOpen(open));
    };

    const handleMenuClick = (to: string, text: string, newTab?: boolean) => {
        dispatch(setDrawerOpen(false));
        dispatch(setSelectedMenu({ to, text }));
        if (newTab) {
            window.open(to, '_blank');
        } else {
            history.push(to);
        }
    };

    return (
        <SwipeableDrawer
            anchor='left'
            open={open}
            onClose={handleDrawerToggle(false)}
            onOpen={handleDrawerToggle(true)}
            sx={{ '.MuiPaper-root': { width: '80%' } }}
        >
            <List>
                {menuItems.map((route, index) => {
                    const isExpanded = !!openOptions[route.to];

                    return (
                        <React.Fragment key={index}>
                            <ListItem
                                key={index}
                                onClick={() => {
                                    if (route.options) {
                                        setOpenOptions((current) => ({ ...current, [route.to]: !isExpanded }));
                                    } else {
                                        handleMenuClick(route.to, route.text);
                                    }
                                }}
                            >
                                <ListItemIcon sx={{ color: blueGray[700], minWidth: '40px' }}>
                                    {route.icon}
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        fontWeight: 700,
                                        '.MuiTypography-root': {
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        },
                                    }}
                                >
                                    {route.text}
                                    {route.options && <ExpandIcon expanded={isExpanded} />}
                                </ListItemText>
                            </ListItem>
                            <Collapse in={isExpanded} collapsedSize={0}>
                                <Box>
                                    {route.options && (
                                        <MenuItem onClick={() => handleMenuClick(route.to, route.text)}>
                                            List view
                                        </MenuItem>
                                    )}
                                    {route.options?.map((option) => (
                                        <MenuItem
                                            key={option.to}
                                            onClick={() => handleMenuClick(option.to, option.text)}
                                        >
                                            {option.text}
                                        </MenuItem>
                                    ))}
                                </Box>
                            </Collapse>
                        </React.Fragment>
                    );
                })}
            </List>
        </SwipeableDrawer>
    );
}
