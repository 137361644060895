import { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { AppThunkDispatch } from '../../store';
import { IBuildsSliceState } from '../builds/interfaces';
import ChangeLogDialog from '../changeLog/ChangeLogDialog';
import BuildActionConfirmationDialog from '../commons/BuildActionConfirmationDialog';
import BuildStatusChip from '../commons/BuildStatusChip';
import { EBuildStatus, SliceStatus } from '../commons/enums';
import { getAcronym } from '../commons/getAcronym';
import { IBuild } from '../commons/interfaces';
import { LoadingSpinner } from '../commons/LoadSpinner';
import { openSocket } from '../sockets';
import BuildTestResultsMatrix from '../tests/BuildTestResultsMatrix';
import OrderTestDialog from '../tests/newTest/OrderTestDialog';
import BuildRelatedTestSuites from '../testSuites/BuildRelatedTestSuites';
import AppUploaderDialog from './AppUploaderDialog';
import BuildBreadcrumbs from './BuildBreadcrumbs';
import BuildDetailsDialog from './BuildDetailsDialog';
import {
    clearBuildDetails,
    getBuildResult,
    selectBuildDetailsBuild,
    selectBuildDetailsStatus,
    updateBuild,
    updateBuildResult,
    updateChildBuild,
} from './buildDetailsSlice';
import BuildInfo from './BuildInfo';
import BuildMessagesChips from './BuildMessagesChips';
import BuildResult from './BuildResult';
import ChildBuilds from './ChildBuilds';
import { IArtifact, IBuildResult } from './interfaces';

export default function BuildDetails(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const theme = useTheme();
    const build = useSelector(selectBuildDetailsBuild);
    const status = useSelector(selectBuildDetailsStatus);
    const { projectName, buildNumber } = useParams<{ projectName: string; buildNumber: string }>();
    useEffect(() => {
        dispatch(getBuildResult({ projectName, buildNumber }));
    }, [dispatch, projectName, buildNumber]);

    useEffect(() => {
        function handleUpdateBuild(updateData: { build: IBuild }) {
            if (updateData.build && updateData.build._id === build?._id) {
                dispatch(updateBuild(updateData.build));
            } else if (updateData.build.parentBuild === build?._id) {
                dispatch(updateChildBuild(updateData.build));
            }
        }

        function handleUpdateBuildResult(updateData: {
            buildResult: IBuildResult;
            artifactsNotInSteps: IArtifact[];
            airTestsFile: IArtifact;
        }) {
            if (updateData.buildResult && updateData.buildResult.build === build?._id) {
                dispatch(updateBuildResult(updateData));
            }
        }

        const isRunning = build?.status === EBuildStatus.queued || build?.status === EBuildStatus.running;

        const socket = openSocket('builds');
        socket.on('new-build', handleUpdateBuild);
        if (isRunning) {
            socket.on('update-build', handleUpdateBuild);
            socket.on('update-build-result', handleUpdateBuildResult);
        }

        return () => {
            socket.off('new-build', handleUpdateBuild);
            if (isRunning) {
                socket.off('update-build', handleUpdateBuild);
                socket.off('update-build-result', handleUpdateBuildResult);
            }
        };
    }, [dispatch, build]);

    useEffect(() => {
        return () => {
            dispatch(clearBuildDetails());
        };
    }, [dispatch]);

    if (status === SliceStatus.loading || status === SliceStatus.idle) {
        return <LoadingSpinner loading={true} />;
    }

    return (
        <>
            <Helmet title={`${getAcronym(build?.projectName)} #${build?.buildNumber} ${build?.status}`} />
            <Container
                sx={{ [theme.breakpoints.down('sm')]: { padding: 0.25 } }}
                maxWidth={false}
                data-testid='build-details'
            >
                <BuildDetailsHeader status={build?.status} />
                {build ? (
                    <>
                        <BuildInfo />
                        {build.childBuilds && <ChildBuilds builds={build.childBuilds} />}
                        <BuildRelatedTestSuites buildId={build._id} />
                        <BuildTestResultsMatrix buildId={build._id} />
                        <BuildResult />
                    </>
                ) : (
                    <Typography
                        variant='body1'
                        align='center'
                        color='textSecondary'
                        gutterBottom
                        style={{ marginTop: '8px' }}
                    >
                        Build not found
                    </Typography>
                )}
            </Container>
            <BuildDetailsDialog />
            <BuildActionConfirmationDialog />
            {build && <OrderTestDialog build={build} />}
            <AppUploaderDialog build={build} />
            <ChangeLogDialog buildBranch={build?.branch} />
        </>
    );
}

function BuildDetailsHeader({ status }: { status?: EBuildStatus }): ReactElement {
    return (
        <Box
            sx={{
                padding: 1,
                display: { xs: 'block', lg: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center',
                my: 1.5,
                mt: 2,
            }}
        >
            <BuildBreadcrumbs />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2, lg: 0 } }}>
                {status && <BuildStatusChip sx={{ mr: 1 }} status={status} />}
                <BuildMessagesChips />
            </Box>
        </Box>
    );
}
