import { ReactElement } from 'react';
import { SxProps, TableCell } from '@mui/material';
import { IColumn } from '../../commons/interfaces';

export default function TestSuiteDetailsTableCell({
    column,
    sx,
    children,
}: {
    column: IColumn<string>;
    sx?: SxProps;
    children?: React.ReactNode;
}): ReactElement {
    return (
        <TableCell
            id={column.id}
            align={column.align}
            sx={{ p: 1, ...sx }}
            style={{
                width: column.width,
                minWidth: column.minWidth,
            }}
        >
            {children}
        </TableCell>
    );
}
