import { ReactElement } from 'react';
import { Box, TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import { ITestSuiteBase } from '../interfaces';
import TestSuiteLink from '../TestSuiteLink';
import TestSuiteTypeIcon from './TestSuiteTypeIcon';

export default function TestSuiteCell({
    align,
    testSuite,
}: {
    align?: MuiAlignSetting;
    testSuite: ITestSuiteBase;
}): ReactElement {
    return (
        <TableCell
            align={align}
            sx={{ p: 1 }}
            id='table-cell-test-number'
            data-testsuiteid='tests-table-test-suite-number'
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: align, gap: 1 }}>
                <TestSuiteTypeIcon type={testSuite.type} />
                <TestSuiteLink projectName={testSuite.build.projectName} testSuiteNumber={testSuite.testSuiteNumber} />
            </Box>
        </TableCell>
    );
}
