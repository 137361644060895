import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { BarChart as AnalyticsIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { ColumnToggle } from '../commons/ColumnToggle';
import { selectTestsColumns } from './testsSlice';

export default function TestActionBox(): ReactElement {
    const testColumns = useSelector(selectTestsColumns);

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                alignItems: { xs: 'flex-start', md: 'center' },
                flexDirection: { xs: 'column', md: 'row' },
            }}
        >
            <Button
                variant='outlined'
                color='secondary'
                size='large'
                sx={{
                    margin: '0px 8px',
                    width: { xs: '100%', md: 'auto' },
                    m: 0,
                    mb: { xs: 2, md: 0 },
                    mr: { xs: 0, md: 1 },
                }}
                onClick={() =>
                    window.open('https://app.datadoghq.com/dashboard/3h8-qn4-hpz/qa-automation---airtests', '_blank')
                }
                startIcon={<AnalyticsIcon />}
            >
                Analytics
            </Button>
            <ColumnToggle columns={testColumns} target='tests' mode='button' />
        </Box>
    );
}
