import { SelectedFilter } from '../commons/filters/interfaces';
import getUrlParam, { getUrlFilterValue } from '../commons/getUrlPrams';
import * as queryString from '../commons/queryString';
import { ETestStatus, ITestCase } from '../tests/interfaces';
import {
    ETestSuiteAllocationType,
    ITestSuiteCase,
    ITestSuiteDetails,
    ITestSuiteFilter,
    ITestSuiteListItem,
    ITestSuiteResultsCount,
    ITestSuiteTest,
} from './interfaces';

export function setTestSuitesFiltersToUrl(filters: ITestSuiteFilter): void {
    const params = queryString.stringify(filters);
    window.history.replaceState(null, `set filters filters`, `test-suites${params && '?' + params}`);
}

export function getTestSuitesFiltersFromUrl(): ITestSuiteFilter {
    return {
        page: getUrlParam('page') as number,
        author: getUrlFilterValue('author') || undefined,
        testSuiteNumber: getUrlFilterValue('testSuiteNumber') || undefined,
        status: (getUrlFilterValue('status') as ETestStatus[]) || undefined,
        devices: getUrlFilterValue('devices') || undefined,
        testBranch: getUrlFilterValue('testBranch') || undefined,
        build: {
            projectName: getUrlFilterValue('build[projectName]') || undefined,
            branch: getUrlFilterValue('build[branch]') || undefined,
            type: getUrlFilterValue('build[type]') || undefined,
            versionName: getUrlFilterValue('build[versionName]') || undefined,
            platform: getUrlFilterValue('build[platform]') || undefined,
        },
    };
}

export function selectAmountOfActiveTestSuiteFilters(filters: ITestSuiteFilter): number {
    return [
        filters?.testSuiteNumber,
        filters?.status,
        filters?.devices,
        filters?.testBranch,
        filters?.build?.projectName,
        filters?.build?.versionName,
        filters?.build?.platform,
        filters?.build?.type,
        filters?.build?.branch,
    ].reduce((total: number, filter: SelectedFilter) => {
        return filter ? total + 1 : total;
    }, 0);
}

export function getTestSuiteCases(testSuite: ITestSuiteDetails): ITestSuiteCase[] {
    const testCases: ITestSuiteCase[] = [];
    for (const test of testSuite.tests) {
        const mapDevice = test.testCases?.map((testCase: ITestCase) => {
            const device = test.testDevice
                ? testSuite.devices.find((device) => device._id === test.testDevice)
                : undefined;
            return {
                ...testCase,
                parentTest: test,
                device,
                gameDeviceId: test.gameDeviceId || device?.gameDeviceId,
            };
        });
        if (mapDevice) {
            testCases.push(...mapDevice);
        }
    }
    return testCases;
}

export function countTestsRuntime(tests: ITestSuiteTest[]): { runtimeInMs: number; isRunning: boolean } {
    let runtimeInMs = 0;
    let isRunning = false;
    for (const test of tests) {
        if (test.startedAt && test.completedAt) {
            runtimeInMs += new Date(test.completedAt).getTime() - new Date(test.startedAt).getTime();
        }
        if (test.startedAt && !test.completedAt) {
            isRunning = true;
        }
    }
    return { runtimeInMs, isRunning };
}

export function getTestsDuration(tests: ITestSuiteTest[]): { startDate: Date | undefined; endDate: Date | undefined } {
    if (!tests.length) {
        return { startDate: undefined, endDate: undefined };
    }
    const { runtimeInMs, isRunning } = countTestsRuntime(tests);
    const startDate = tests[0].startedAt ? new Date(tests[0].startedAt) : undefined;
    const endDate = startDate && !isRunning ? new Date(startDate.getTime() + runtimeInMs) : undefined;
    return { startDate, endDate };
}

export const isTestStatusCompleted = (status: ETestStatus) =>
    ![ETestStatus.waiting, ETestStatus.queued, ETestStatus.running, ETestStatus.finishing].includes(status);

export const sumStatuses = (results: ITestSuiteResultsCount) =>
    results.waiting +
    results.queued +
    results.running +
    results.success +
    results.cancelled +
    results.failed +
    results.inconclusive;

export const sumCompletedStatuses = (results: ITestSuiteResultsCount): number =>
    results.success + results.cancelled + results.failed + results.inconclusive;

export const sumInCompletedStatuses = (results: ITestSuiteResultsCount): number =>
    results.waiting + results.queued + results.running;

export function countRemainingTests(testSuite: ITestSuiteListItem, deviceId: string): number {
    switch (testSuite.type) {
        case ETestSuiteAllocationType.sequential:
            return sumInCompletedStatuses(testSuite.resultsCount);
        case ETestSuiteAllocationType.replicate: {
            const deviceResults = testSuite.resultsCountPerDevice[deviceId];
            return deviceResults ? sumInCompletedStatuses(deviceResults) : 0;
        }
        default:
            return Math.ceil(sumInCompletedStatuses(testSuite.resultsCount) / (testSuite.maxDevices || 1));
    }
}

export function getTestsQueuePerDevice(testSuites?: ITestSuiteListItem[]) {
    const testsQueuePerDevice = new Map<string, { queue: number; testSuites: ITestSuiteListItem[] }>();
    if (testSuites) {
        for (const testSuite of testSuites) {
            for (const device of testSuite.devices) {
                const remainingTests = countRemainingTests(testSuite, device._id);
                const deviceQueue = testsQueuePerDevice.get(device._id);
                if (deviceQueue) {
                    deviceQueue.testSuites.push(testSuite);
                    deviceQueue.queue += remainingTests;
                } else {
                    testsQueuePerDevice.set(device._id, {
                        testSuites: [testSuite],
                        queue: remainingTests,
                    });
                }
            }
        }
    }
    return testsQueuePerDevice;
}
