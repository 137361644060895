import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import BuildLiveStats from './BuildLiveStats';
import BuildSnapshotStats from './BuildSnapshotStats';
import { IBuildStatsParams } from './interfaces';

export default function BuildStats(params: IBuildStatsParams): ReactElement {
    const { id, startedAt, stoppedAt, hostname, status, width, height } = params;
    const isLive = status === 'running';
    const env = process.env.REACT_APP_ENVIRONMENT !== 'production' ? 'staging' : 'production';

    return (
        <Grid container justifyContent='space-between' spacing={2} sx={{ display: 'flex', my: 2 }}>
            {isLive ? (
                <BuildLiveStats {...{ id, startedAt, hostname, env, width, height }} />
            ) : (
                <BuildSnapshotStats {...{ id, startedAt, stoppedAt, hostname, env, width, height }} />
            )}
        </Grid>
    );
}

export function BuildGraphItem({ children }: { children: ReactElement }): ReactElement {
    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            {children}
        </Grid>
    );
}
