import { SelectedFilter } from '../commons/filters/interfaces';
import { IAuthor, IBuild } from '../commons/interfaces';
import { IConfigurationSettings, ISelectedSettings } from '../configurationSettings/interfaces';
import { IMessage } from '../messages/interfaces';
import { ITestDevice } from '../testDevices/interfaces';
import { ITestSuiteDeviceResultsCount, ITestSuiteListItem } from '../testSuites/interfaces';

export interface ITest {
    _id: string;
    testSuite?: ITestSuiteListItem;
    testNumber: number;
    status: ETestStatus;
    projectName: string;
    build: IBuild;
    testDevice?: ITestDevice;
    testBranch?: string;
    commitSha?: string;
    author: IAuthor;
    updatedBy?: IAuthor;

    createdAt?: string;
    queuedAt?: string;
    startedAt?: string;
    completedAt?: string;

    testCases?: ITestCase[];
    package?: string;
    logFile?: string;
    gameDeviceId?: string;
}

export enum ETestStatus {
    waiting = 'waiting',
    queued = 'queued',
    running = 'running',
    cancelled = 'cancelled',
    finishing = 'finishing',
    failed = 'failed',
    success = 'success',
    inconclusive = 'inconclusive',
}

export const runningTestStatus = [ETestStatus.waiting, ETestStatus.queued, ETestStatus.running];

export interface ITestCase {
    id: string;
    testName: string;
    displayName: string;
    startedAt?: string;
    completedAt?: string;
    status: ETestStatus;
    messages?: IMessage[];
    reportFile?: string;
    logFile?: string;
    videoRecording?: boolean;
    settings?: ISelectedSettings;
    dependsOn?: string[];
    customBuild?: ITestCustomBuild;
}

export interface ITestCustomBuild {
    build: string;
    testBranch?: string;
    commitSha?: string;
}

export interface ITestFilter {
    page?: number;
    skip?: number;
    author?: SelectedFilter;
    testNumber?: SelectedFilter;
    status?: SelectedFilter;
    projectName?: SelectedFilter;
    testDevice?: SelectedFilter;
    testBranch?: SelectedFilter;
    build?: {
        _id?: SelectedFilter;
        versionName?: SelectedFilter;
        branch?: SelectedFilter;
        platform?: SelectedFilter;
        type?: SelectedFilter;
    };
    sort?: string;
}

export interface ITestFilterOptions {
    author?: IAuthor[];
    projectName?: string[];
    testNumber?: number[];
    testDevice?: ITestDevice[];
    testBranch?: string[];
    build?: {
        versionName?: string[];
        branch?: string[];
        platform?: string[];
        type?: string[];
    };
}

export interface ITestPayload {
    build: string;
    author?: string;
    testDevice?: string;
    testBranch: string;
    commitSha?: string;
    testCases: Array<{
        id: string;
        testName: string;
        displayName: string;
        videoRecording: boolean;
        dependsOn: string[];
        customBuild?: ITestCustomBuild;
        settings?: ISelectedSettings;
    }>;
    subscribeToResult?: boolean;
}

export interface ITestCaseConfig {
    testName: string;
    buildTypes: string[];
    platforms: string[];
    tags: string[];
    videoRecording: boolean;
    dependsOn: string[];
    selectBuild: boolean;
    settings?: IConfigurationSettings;
    displayName: string;
    docString: string;
}

export interface ITestCaseResults {
    testName: string;
    resultsCountPerDevice: ITestSuiteDeviceResultsCount;
}

export interface ISelectedTestCase extends ITestCaseConfig {
    id: string;
    selected?: boolean;
    visible?: boolean;
    customBuild?: ITestCustomBuild;
    selectedSettings?: ISelectedSettings;
}

export enum ESelectedProperty {
    selected = 'selected',
    videoRecording = 'videoRecording',
    customBuild = 'customBuild',
    selectedSettings = 'selectedSettings',
}

export interface ITestBranch {
    branchName: string;
    sha: string;
}

export interface ITestProject {
    projectName: string;
    defaultBranch: string;
    releaseBranchPattern: string;
    defaultTestTag: string;
    slackChannel: string;
}
