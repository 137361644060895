import { ReactElement, Suspense, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { YoutubeSearchedFor as SeachAgainIcon, Add as AddIcon } from '@mui/icons-material';
import { Paper, Table, TableHead, TableCell, Button, Box, useMediaQuery } from '@mui/material';
import ContentLoading from '../commons/ContentLoading';
import HeaderSection from '../commons/HeaderSection';
import SearchField from '../commons/inputs/SearchField';
import NoContent from '../commons/NoContent';
import Page from '../commons/Page';
import TableBody from '../commons/TableBody';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import { IProject } from './interfaces';
import { useGetProjectsQuery } from './projectsApi';
import ProjectLoadingRows from './row/ProjectLoadingRows';
import ProjectRow from './row/ProjectRow';
import useProjectColumns from './useProjectColumns';

export default function ProjectsList(): ReactElement {
    const columns = useProjectColumns();
    const [searchText, setSearchText] = useState('');
    const isMobile = useMediaQuery('(max-width: 760px)');
    const { data, isFetching } = useGetProjectsQuery();

    const projectsFiltered = useMemo(() => {
        const projects = data || [];
        return filterProjectsByName(projects, searchText);
    }, [data, searchText]);

    const isLoading = isFetching && !data;

    return (
        <Suspense fallback={<ContentLoading />}>
            <Page>
                <HeaderSection
                    title='Projects'
                    isMobile={isMobile}
                    actionBox={
                        <Box>
                            <Button
                                variant='outlined'
                                color='secondary'
                                component={Link}
                                to={`/projects/release-notes`}
                                sx={{ mr: 1, mb: { xs: 2, sm: 0 }, ml: 0, width: isMobile ? '100%' : '200px' }}
                            >
                                Release Notes
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<AddIcon />}
                                component={Link}
                                to={'/projects/new'}
                                sx={{ width: isMobile ? '100%' : '200px', ml: 0 }}
                            >
                                New Project
                            </Button>
                        </Box>
                    }
                >
                    <Box sx={{ mt: 2, mx: 1 }}>
                        <SearchField
                            size='small'
                            placeholder='Search'
                            value={searchText || ''}
                            timeout={200}
                            onSearch={(text) => setSearchText(text as string)}
                            sx={{ width: { xs: '100%', sm: '320px' } }}
                        />
                    </Box>
                </HeaderSection>

                <Paper data-testid='projects' sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                    <TableContainer>
                        {!isLoading && !projectsFiltered.length && (
                            <NoContent text="We couldn't find any projects with this name" icon={<SeachAgainIcon />} />
                        )}
                        {(isLoading || projectsFiltered.length > 0) && (
                            <Table aria-label='projects table' style={{ width: '100%', tableLayout: 'fixed' }}>
                                <TableHead data-testid='projects-table-head'>
                                    <TableHeaderRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                size='small'
                                                sx={{ width: column.width, minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody data-testid='projects-table-body'>
                                    {isLoading ? (
                                        <ProjectLoadingRows />
                                    ) : (
                                        projectsFiltered.map((project) => (
                                            <ProjectRow key={project._id} project={project} columns={columns} />
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Paper>
            </Page>
        </Suspense>
    );
}

const filterProjectsByName = (projects: IProject[], searchText: string): IProject[] => {
    const text = searchText || '';
    return projects.filter((item) => item.name.toLowerCase().includes(text.toLowerCase()));
};
