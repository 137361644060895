import { useMemo } from 'react';
import { ITestBranch, ITestProject } from '../interfaces';
import { useGetTestBranchesQuery, useGetTestProjectsQuery } from '../testsApi';

export interface IUseTestBranches {
    isFetching: boolean;
    project?: ITestProject;
    testBranches?: ITestBranch[];
}

export function useTestBranches(projectName: string): IUseTestBranches {
    const { data: projects } = useGetTestProjectsQuery(undefined, { refetchOnMountOrArgChange: false });
    const { data: testBranches, isFetching } = useGetTestBranchesQuery(projectName, {
        refetchOnMountOrArgChange: false,
    });

    const project: ITestProject | undefined = useMemo(
        () => projects?.find((project) => project.projectName?.toLowerCase() === projectName?.toLowerCase()),
        [projects, projectName],
    );

    return {
        project,
        testBranches,
        isFetching,
    };
}
