import { ReactElement } from 'react';
import { Card } from '@mui/material';

interface BuildSnapshotStatsGraphProps {
    url: string;
    startedAt: string;
    stoppedAt: string;
    hostname: string;
    env: 'staging' | 'production';
}

export default function BuildSnapshotStatsGraph({
    url,
    startedAt,
    stoppedAt,
    hostname,
    env,
}: BuildSnapshotStatsGraphProps): ReactElement {
    const start = Math.round(new Date(startedAt).getTime());
    const stop = Math.round(new Date(stoppedAt).getTime());

    return (
        <Card
            data-testid='test-build-static-stats-graph'
            sx={{
                overflow: 'auto',
                boxShadow: 'none',
                border: '1px solid',
                borderColor: (theme) => theme.palette.grey[200],
                maxWidth: 500,
                p: 1,
                mx: 'auto',
                cursor: 'pointer',
            }}
            onClick={() => {
                const url = `https://app.datadoghq.com/dashboard/g45-q5x-hzj?tpl_var_host=${hostname}&tpl_var_env=${env}&from_ts=${start}&to_ts=${stop}&live=false`;
                window.open(url, '_blank');
            }}
        >
            <img title='datadog' src={url} width='100%' height='100%' alt='datadog-snapshot' />
        </Card>
    );
}
