import { ReactElement } from 'react';
import { Warning } from '@mui/icons-material';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { IBuild } from '../../commons/interfaces';
import NoContent from '../../commons/NoContent';
import { ISelectedTestCase } from '../interfaces';
import { useGetTestCasesConfigQuery } from '../testsApi';
import SearchField from './SearchField';
import SelectTags from './SelectTags';
import SelectTestCasesTable from './SelectTestCasesTable';
import { useSelectTestCases } from './useSelectTestCases';

export interface ISelectTestCasesProps {
    build: IBuild;
    branchName: string | undefined;
    testCases: ISelectedTestCase[];
    setTestCases: (testCases: ISelectedTestCase[]) => void;
}

export default function SelectTestCases({
    build,
    branchName,
    testCases,
    setTestCases,
}: ISelectTestCasesProps): ReactElement {
    return (
        <div>
            <Typography variant='h6'>Select Testcases</Typography>
            <div style={{ height: '60vh' }}>
                {!branchName && <NoContent text='Select test branch to load testcases' />}
                {branchName && (
                    <SelectTestCasesForm
                        build={build}
                        branchName={branchName}
                        testCases={testCases}
                        setTestCases={setTestCases}
                    />
                )}
            </div>
        </div>
    );
}

function SelectTestCasesForm({
    build,
    branchName,
    testCases,
    setTestCases,
}: ISelectTestCasesProps & { branchName: string }): ReactElement {
    const {
        data: testCasesConfig,
        isFetching,
        error,
    } = useGetTestCasesConfigQuery({ build, branchName }, { refetchOnMountOrArgChange: false });
    const { onSelect, onSelectAll, allTags, selectedTags, onSelectByTags, searchTerm, onSearch, onDuplicate } =
        useSelectTestCases(testCasesConfig, testCases, setTestCases);

    if (error) {
        return (
            <NoContent text={`Failed to load testcases. ${(error as any).data?.error?.message}`} icon={<Warning />} />
        );
    }

    if (!testCasesConfig || isFetching) {
        return <NoContent text='Loading testcases and tags based on selection' icon={<CircularProgress size={20} />} />;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} container alignItems='center'>
                <SearchField search={searchTerm} onSearchChange={onSearch} />
                <SelectTags tags={allTags} selectedTags={selectedTags} onTagsChange={onSelectByTags} />
                <Typography variant='body2' color='textSecondary' style={{ flexGrow: 1, textAlign: 'right' }}>
                    {testCases.filter((testCase) => testCase.selected).length} of {testCases.length} selected
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <SelectTestCasesTable
                    build={build}
                    testCases={testCases}
                    onSelect={onSelect}
                    onSelectAll={onSelectAll}
                    onDuplicate={onDuplicate}
                />
            </Grid>
        </Grid>
    );
}
