import { ReactElement } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { IConfigurationBuild } from './interfaces';

interface NewBuildValidationErrorsProps {
    validationErrors: string[];
    selectedBuilds?: IConfigurationBuild[];
}

export default function NewBuildValidationErrors(props: NewBuildValidationErrorsProps): ReactElement {
    const { validationErrors, selectedBuilds } = props;
    const buildsWithoutAvailableAgents = (selectedBuilds || [])?.filter((build) => build.viableAgentsCount === 0);
    const hasError = buildsWithoutAvailableAgents.length > 0 || validationErrors.length > 0;

    if (!hasError) {
        return <></>;
    }

    const invalidBuildNames = buildsWithoutAvailableAgents.map((build) => build.display_name).join(', ');

    return (
        <Box sx={{ mb: 3 }}>
            {validationErrors.map((error, key) => (
                <Alert key={key} severity='info' sx={{ padding: (theme) => theme.spacing(0.25, 1), mb: 2 }}>
                    {error}
                </Alert>
            ))}

            {buildsWithoutAvailableAgents.length > 0 && (
                <Alert key={invalidBuildNames} severity='error' sx={{ px: 1, py: 0.5, mb: 2 }}>
                    <strong>There was a problem with that action</strong>
                    <Typography color='error'>{`${invalidBuildNames}'s requirements cannot be satisfied by any agents`}</Typography>
                </Alert>
            )}
        </Box>
    );
}
