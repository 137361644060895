import React, { ReactElement } from 'react';
import {
    Autocomplete,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
    Box,
} from '@mui/material';
import { ISelectedSettings, ISetting } from './interfaces';

export interface IConfigurationSettingProps {
    setting: ISetting;
    selectedSettings?: ISelectedSettings;
    setSetting: (setting: ISetting, value: string | string[] | boolean | null) => void;
}

export default function ConfigurationSetting({
    setting,
    selectedSettings,
    setSetting,
}: IConfigurationSettingProps): ReactElement {
    switch (setting.type) {
        case 'select':
            return (
                <SelectConfiguration setting={setting} selectedSettings={selectedSettings} setSetting={setSetting} />
            );
        case 'checkbox':
            return (
                <CheckboxConfiguration setting={setting} selectedSettings={selectedSettings} setSetting={setSetting} />
            );
        case 'radio':
            return <RadioConfiguration setting={setting} selectedSettings={selectedSettings} setSetting={setSetting} />;
        case 'switch':
            return (
                <SwitchConfiguration setting={setting} selectedSettings={selectedSettings} setSetting={setSetting} />
            );
        case 'text':
        case 'number':
            return (
                <NumberTextConfiguration
                    setting={setting}
                    selectedSettings={selectedSettings}
                    setSetting={setSetting}
                />
            );
        default:
            return <InvalidSetting setting={setting} />;
    }
}

function InvalidSetting({ setting }: { setting: ISetting }): ReactElement {
    return (
        <Box
            data-testid='configuration-setting-invalid'
            sx={{
                borderRadius: (theme) => theme.shape.borderRadius,
                backgroundColor: (theme) => theme.palette.warning.light,
                opacity: '0.75',
                width: '100%',
                padding: 2,
                margin: 1,
                overflowX: 'scroll',
            }}
        >
            <Typography variant='body1' gutterBottom>
                Invalid setting &quot;{setting.display_name}&quot; of type &quot;{setting.type}&quot;
            </Typography>
            <pre>{JSON.stringify(setting, null, 2)}</pre>
        </Box>
    );
}

function CheckboxConfiguration({
    setting,
    selectedSettings,
    setSetting,
}: IConfigurationSettingProps): ReactElement | null {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setting: ISetting, value?: string) => {
        if (event.target.checked) {
            setSetting(setting, value || true);
        } else {
            setSetting(setting, false);
        }
    };

    if (!selectedSettings) {
        return null;
    }

    return (
        <FormGroup row data-testid='configuration-setting-checkbox'>
            {setting.values ? (
                <Box sx={{ pl: 4 }}>
                    <Typography variant='subtitle2' gutterBottom>
                        {setting.display_name}
                    </Typography>
                    {setting.values.map((value, index) => (
                        <FormControlLabel
                            key={index}
                            sx={{ my: 1, mx: 2 }}
                            label={value}
                            control={
                                <Checkbox
                                    checked={settingIsSelected(selectedSettings, setting, value)}
                                    required={setting.required}
                                    onChange={(e) => handleChange(e, setting, value)}
                                />
                            }
                        />
                    ))}
                </Box>
            ) : (
                <FormControlLabel
                    sx={{ m: 1 }}
                    label={setting.display_name}
                    control={
                        <Checkbox
                            checked={settingIsSelected(selectedSettings, setting)}
                            required={setting.required}
                            onChange={(e) => handleChange(e, setting)}
                        />
                    }
                />
            )}
        </FormGroup>
    );
}

function RadioConfiguration({
    setting,
    selectedSettings,
    setSetting,
}: IConfigurationSettingProps): ReactElement | null {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setting: ISetting, value: string) => {
        setSetting(setting, value);
    };

    if (!selectedSettings) {
        return null;
    }

    return (
        <RadioGroup row>
            {setting.values && !setting.multi ? (
                <Box sx={{ pl: 4 }} data-testid='configuration-setting-radio'>
                    <Typography variant='subtitle2' gutterBottom>
                        {setting.display_name}
                    </Typography>
                    {setting.values.map((value, index) => (
                        <FormControlLabel
                            key={index}
                            sx={{ my: 1, mx: 2 }}
                            label={value}
                            control={
                                <Radio
                                    checked={settingIsSelected(selectedSettings, setting, value)}
                                    required={setting.required}
                                    onChange={(e) => handleChange(e, setting, value)}
                                />
                            }
                        />
                    ))}
                </Box>
            ) : (
                <InvalidSetting setting={setting} />
            )}
        </RadioGroup>
    );
}

function SwitchConfiguration({
    setting,
    selectedSettings,
    setSetting,
}: IConfigurationSettingProps): ReactElement | null {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setting: ISetting) => {
        setSetting(setting, event.target.checked);
    };

    if (!selectedSettings) {
        return null;
    }

    return (
        <FormGroup row>
            {setting.values || setting.multi ? (
                <InvalidSetting setting={setting} />
            ) : (
                <FormControlLabel
                    sx={{ my: 1, mx: 2 }}
                    label={setting.display_name}
                    control={
                        <Switch
                            checked={settingIsSelected(selectedSettings, setting)}
                            required={setting.required}
                            onChange={(e) => handleChange(e, setting)}
                        />
                    }
                    data-testid='configuration-setting-switch'
                />
            )}
        </FormGroup>
    );
}

function SelectConfiguration({ setting, selectedSettings, setSetting }: IConfigurationSettingProps): ReactElement {
    let value = setting.multi ? [] : ('' as string | string[] | null);
    if (selectedSettings && selectedSettings[setting.settingKey as string]) {
        value = selectedSettings[setting.settingKey as string] as string | string[] | null;
    }

    return (
        <Autocomplete
            sx={{ py: 1, px: 2, minWidth: '200px' }}
            data-testid='configuration-setting-selector'
            options={setting.values || []}
            autoHighlight
            disableCloseOnSelect
            clearOnBlur
            clearOnEscape
            value={value}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                </li>
            )}
            multiple={setting.multi}
            onChange={(_event: any, newValue) => {
                setSetting(setting, newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{ width: '100%' }}
                    label={setting.display_name}
                    required={setting.required}
                    variant='outlined'
                />
            )}
        />
    );
}

function NumberTextConfiguration({ setting, selectedSettings, setSetting }: IConfigurationSettingProps): ReactElement {
    const value = (selectedSettings && selectedSettings[setting.settingKey as string]) || '';

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSetting(setting, event.target.value);
    };

    return (
        <Box sx={{ py: 1, px: 2 }} data-testid='configuration-setting-number-text'>
            <TextField
                sx={{ width: '100%' }}
                label={setting.display_name}
                value={value}
                onChange={handleChange}
                type={setting.type === 'number' ? 'number' : undefined}
                required={setting.required}
                variant='outlined'
            />
        </Box>
    );
}

export function settingIsSelected(selectedSettings: ISelectedSettings, setting: ISetting, value?: string): boolean {
    if (!setting.settingKey || !selectedSettings) {
        return false;
    } else if (setting.multi) {
        const selectedSettingValue = selectedSettings[setting.settingKey];

        return (
            !!selectedSettingValue &&
            Array.isArray(selectedSettingValue) &&
            (selectedSettings[setting.settingKey] as string[]).some((selected: string) => selected === value)
        );
    }

    return (value && selectedSettings[setting.settingKey] === value) || selectedSettings[setting.settingKey] === true;
}
