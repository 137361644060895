import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip, ListItem, Menu, useMediaQuery } from '@mui/material';
import { selectBuildDetailsSettings } from './buildDetailsSlice';
import { IBuildTag } from './interfaces';

const numberOfVisibleChips = 5;
const numberOfVisibleChipsMobile = 1;

export default function BuildConfigurationTags(): ReactElement | null {
    const settings = useSelector(selectBuildDetailsSettings);
    const isMobile = useMediaQuery('(max-width: 760px)');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const visibleChips = isMobile ? numberOfVisibleChipsMobile : numberOfVisibleChips;

    const handleMenuOpen = (event: React.MouseEvent<any, MouseEvent>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleMenuClose = (): void => setAnchorEl(null);
    if (!settings) {
        return null;
    }
    const tags = mapSettingsToTags(settings);

    return (
        <Box
            sx={{
                '& > *': {
                    margin: (theme) => theme.spacing(0.5, 1, 0.5, 0),
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                },
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
            }}
        >
            {tags.slice(0, visibleChips).map((tag) => (
                <Chip
                    key={tag.label}
                    variant='outlined'
                    size='small'
                    label={tag.label}
                    sx={{
                        ...(tag.level === 'error' && {
                            color: (theme) => theme.palette.error.dark,
                            borderColor: (theme) => theme.palette.error.dark,
                        }),
                        ...(tag.level === 'warning' && {
                            color: (theme) => theme.palette.warning.main,
                            borderColor: (theme) => theme.palette.warning.main,
                        }),
                        ...(tag.level === 'info' && {
                            color: (theme) => theme.palette.info.main,
                            borderColor: (theme) => theme.palette.info.main,
                        }),
                    }}
                    data-testid='build-configuration-tag'
                />
            ))}
            {tags.length > visibleChips && (
                <Chip
                    label={`+${tags.length - visibleChips}`}
                    variant='outlined'
                    size='small'
                    onMouseEnter={handleMenuOpen}
                    onMouseLeave={handleMenuClose}
                    onClick={handleMenuOpen}
                    sx={{
                        borderColor: (theme) => theme.palette.text.primary,
                    }}
                />
            )}
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleMenuClose}
                sx={{ mt: 1, pointerEvents: 'none' }}
                disableScrollLock
            >
                {tags.slice(visibleChips).map((tag) => (
                    <ListItem key={tag.label}>{tag.label}</ListItem>
                ))}
            </Menu>
        </Box>
    );
}

function mapSettingsToTags(settings: any): IBuildTag[] {
    const tags = [];
    for (const [key, value] of Object.entries(settings)) {
        if (typeof value === 'boolean' && value) {
            tags.push({
                label: capitalizeLabel(key),
                level: 'info',
                order: 2,
            });
        } else if (typeof value !== 'object' && String(value).length < 32) {
            tags.push({
                label: `${capitalizeLabel(key)}: ${value}`,
                level: 'warning',
                order: 1,
            });
        } else if (key === 'precompiler_directives' && Array.isArray(value)) {
            tags.push(
                ...value.map(
                    (item) =>
                        ({
                            label: capitalizeLabel(item),
                            level: 'error',
                            order: 0,
                        }) as any,
                ),
            );
        } else {
            tags.push({
                label: String(capitalizeLabel(key)),
                level: 'warning',
                order: 1,
            });
        }
    }
    tags.sort((a, b) => a.order - b.order);
    return tags;
}

export function capitalizeLabel(key: string): string {
    return key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
}
