import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Assignment as CopyIcon, Check as CheckIcon } from '@mui/icons-material';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    SxProps,
    Box,
    SelectChangeEvent,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import { getRequest } from '../../api';
import { AppThunkDispatch } from '../../store';
import JiraTaskInput from '../commons/inputs/JiraTaskInput';
import ArtifactContentRenderer from './ArtifactContentRenderer';
import {
    getBuildResultArtifactContent,
    selectBuildDetailsBuildId,
    selectBuildDetailsResult,
    selectBuildDialog,
    setBuildDialog,
    updateBuildJiraTasks,
} from './buildDetailsSlice';
import DownloadArtifactBuildDetailsButton from './DownloadArtifactBuildDetailsButton';
import { IArtifact } from './interfaces';

export default function BuildDetailsDialog(): ReactElement | null {
    const dialog = useSelector(selectBuildDialog);

    if (dialog.share && dialog.artifact) {
        return <ShareBuildDownloadDialog shareArtifact={dialog.artifact} />;
    } else if (dialog.artifact) {
        return <ArtifactContentDialog artifact={dialog.artifact} />;
    } else if (dialog.buildConfiguration) {
        return <BuildConfigurationDialog buildConfiguration={dialog.buildConfiguration} />;
    } else if (dialog.jiraTasks) {
        return <EditJiraTasksDialog jiraTasks={dialog.jiraTasks} />;
    }

    return null;
}

const textEllipsisStyle: SxProps = {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

function ArtifactContentDialog({ artifact }: { artifact: IArtifact }): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const dialog = useSelector(selectBuildDialog);
    const [loadingContent, setLoadingContent] = useState(true);
    const [content, setContent] = useState('');
    const fileExtension = artifact.file.split('.')?.pop();

    useEffect(() => {
        dispatch(getBuildResultArtifactContent(artifact.file)).then((action) => {
            setContent(action.payload);
            setLoadingContent(false);
        });
    });

    const handleClose = () => {
        dispatch(setBuildDialog({ open: false }));
    };

    return (
        <Dialog open={dialog.open} onClose={handleClose} fullWidth maxWidth='xl'>
            <DialogTitle>
                <span style={{ display: 'flex' }}>
                    <Typography component='span' sx={{ ...textEllipsisStyle, flexGrow: 1 }}>
                        {dialog.title}
                    </Typography>
                    <Typography
                        component='span'
                        sx={{ ...textEllipsisStyle, color: (theme) => theme.palette.text.secondary, minWidth: '100px' }}
                    >
                        {dialog.artifact?.file}
                    </Typography>
                </span>
            </DialogTitle>
            <DialogContent>
                {!content && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                                margin: 1,
                            },
                        }}
                    >
                        {loadingContent ? (
                            <CircularProgress variant='indeterminate' size={20} />
                        ) : (
                            <Typography>Cannot display content</Typography>
                        )}
                    </Box>
                )}
                {content && (
                    <div style={{ padding: 0, height: '70vh', width: '100%' }}>
                        <ArtifactContentRenderer content={content} type={fileExtension} />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='secondary' autoFocus variant='outlined'>
                    Close
                </Button>
                <DownloadArtifactBuildDetailsButton artifact={artifact} isIconButton={false} />
            </DialogActions>
        </Dialog>
    );
}

function BuildConfigurationDialog({ buildConfiguration }: { buildConfiguration: any }): ReactElement {
    const dispatch = useDispatch();
    const dialog = useSelector(selectBuildDialog);

    const handleClose = () => {
        dispatch(setBuildDialog({ open: false }));
    };

    return (
        <Dialog open={dialog.open} onClose={handleClose} fullWidth maxWidth='lg'>
            <DialogTitle>
                <span style={{ display: 'flex' }}>
                    <Typography component='span' sx={{ ...textEllipsisStyle, flexGrow: 1 }}>
                        {dialog.title}
                    </Typography>
                    <Typography
                        component='span'
                        sx={{ ...textEllipsisStyle, color: (theme) => theme.palette.text.secondary, minWidth: '100px' }}
                    >
                        {dialog.artifact?.file}
                    </Typography>
                </span>
            </DialogTitle>
            <DialogContent>
                {buildConfiguration && (
                    <Typography
                        component='pre'
                        sx={{
                            padding: 2.5,
                            backgroundColor: (theme) => theme.palette.tactile.neutral[900],
                            color: (theme) => theme.palette.common.white,
                            fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
                            whiteSpace: 'pre-wrap',
                            maxHeight: '70vh',
                            overflowY: 'auto',
                            scrollbarColor: 'light',
                        }}
                    >
                        {JSON.stringify(buildConfiguration, null, 2)}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary' autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ShareBuildDownloadDialog({ shareArtifact }: { shareArtifact: IArtifact }): ReactElement | null {
    const DEFAULT_EXPIRATION_DAYS = 30;
    const dispatch = useDispatch<AppThunkDispatch>();
    const dialog = useSelector(selectBuildDialog);
    const buildId = useSelector(selectBuildDetailsBuildId);
    const buildResult = useSelector(selectBuildDetailsResult);
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [loadingLink, setLoadingLink] = useState(true);
    const [shareBuildUrl, setShareBuildUrl] = useState<string>();
    const [shareBuildUrlError, setShareBuildUrlError] = useState<string>();
    const [expirationLinkDate, setExpirationLinkDate] = useState(DEFAULT_EXPIRATION_DAYS);

    const handleClose = () => {
        dispatch(setBuildDialog({ open: false }));
    };

    const handleExpirationChange = (event: SelectChangeEvent<number>) => {
        setExpirationLinkDate(event.target.value as number);
        getRequest(`/builds/${buildId}/share-link/`, { expirationDays: event.target.value.toString() })
            .then((response) => {
                setShareBuildUrl(response.shareLink);
            })
            .catch((error) => {
                setShareBuildUrlError(error.message);
            });
    };

    useEffect(() => {
        if (buildId && buildResult && !shareBuildUrl && !shareBuildUrlError) {
            getRequest(`/builds/${buildId}/share-link`, { expirationDays: DEFAULT_EXPIRATION_DAYS.toString() })
                .then((response) => {
                    setLoadingLink(false);
                    setShareBuildUrl(response.shareLink);
                })
                .catch((error) => {
                    setLoadingLink(false);
                    setShareBuildUrlError(error.message);
                });

            const missingProperties = ['versionCode', 'versionName', 'packageName', 'productName'].filter(
                (property) => !(property in buildResult),
            );
            if (missingProperties.length) {
                setShareBuildUrlError(`This build is missing ${missingProperties.toString().replaceAll(',', ', ')}.`);
            }
        }
    }, [buildId, buildResult, dispatch, shareBuildUrl, shareBuildUrlError]);

    const copyToClipboard = (valueToCopy: string, setCopiedUrl?: React.Dispatch<React.SetStateAction<boolean>>) => {
        copy(valueToCopy);

        if (setCopiedUrl) {
            setCopiedUrl(true);
            setTimeout(() => {
                setCopiedUrl(false);
            }, 3000);
        }
    };

    return (
        <Dialog open={dialog.open} onClose={handleClose} fullWidth>
            <DialogTitle onClick={() => buildId && copyToClipboard(buildId)}>
                <span style={{ display: 'flex' }}>
                    <Typography component='span' sx={{ ...textEllipsisStyle, flexGrow: 1 }}>
                        Share Build
                    </Typography>
                    <Typography
                        component='span'
                        sx={{ ...textEllipsisStyle, color: (theme) => theme.palette.text.secondary, minWidth: '100px' }}
                    >
                        {shareArtifact.file}
                    </Typography>
                </span>
            </DialogTitle>
            <DialogContent>
                {shareBuildUrl && !shareBuildUrlError && (
                    <>
                        <Box sx={{ textAlign: 'center', p: 1 }}>
                            <Typography variant='body1' gutterBottom>
                                Scan the QR code or copy the share link to install this build on a device
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', p: 1 }}>
                            <Typography variant='body1' gutterBottom>
                                This link will expire after&nbsp;
                                <Select
                                    labelId='select-expiration-link-date-label'
                                    id='select-expiration-link-date'
                                    value={expirationLinkDate}
                                    onChange={handleExpirationChange}
                                    label='Expiration date'
                                >
                                    <MenuItem value={1}>
                                        <b>one day</b>
                                    </MenuItem>
                                    <MenuItem value={7}>
                                        <b>one week</b>
                                    </MenuItem>
                                    <MenuItem value={30}>
                                        <b>one month</b>
                                    </MenuItem>
                                    <MenuItem value={365}>
                                        <b>one year</b>
                                    </MenuItem>
                                </Select>
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', p: 1 }}>
                            <QRCode size={230} value={shareBuildUrl} />
                        </Box>
                        <Box sx={{ display: 'flex', textAlign: 'center', p: 1 }}>
                            <Typography variant='body1' noWrap>
                                {shareBuildUrl}
                            </Typography>
                            <IconButton
                                sx={{ padding: 0, my: 0, mx: 1 }}
                                onClick={() => copyToClipboard(shareBuildUrl, setCopiedUrl)}
                            >
                                {copiedUrl ? (
                                    <CheckIcon fontSize='small' style={{ fill: 'green' }} />
                                ) : (
                                    <CopyIcon fontSize='small' />
                                )}
                            </IconButton>
                        </Box>
                    </>
                )}
                {loadingLink && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                                margin: 1,
                            },
                        }}
                    >
                        <CircularProgress variant='indeterminate' size={20} />
                    </Box>
                )}
                {shareBuildUrlError && (
                    <Box sx={{ textAlign: 'center', p: 1 }}>
                        <Alert severity='error'>{shareBuildUrlError}</Alert>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function EditJiraTasksDialog({ jiraTasks }: { jiraTasks: string[] }): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const dialog = useSelector(selectBuildDialog);
    const [loading, setLoading] = useState(false);
    const [newJiraTasks, setNewJiraTasks] = useState(jiraTasks || []);

    const handleClose = () => {
        dispatch(setBuildDialog({ open: false }));
    };

    const handleSave = () => {
        setLoading(true);
        dispatch(updateBuildJiraTasks(newJiraTasks)).then(() => {
            setLoading(false);
            dispatch(setBuildDialog({ open: false }));
        });
    };

    return (
        <Dialog open={dialog.open} onClose={handleClose} fullWidth maxWidth='md'>
            <DialogTitle>Edit JIRA Tasks</DialogTitle>
            <DialogContent>
                <Box sx={{ p: 2, flexGrow: 1 }} data-testid='select-new-build-configuration-jira'>
                    <JiraTaskInput
                        value={newJiraTasks}
                        onChange={(newValues) => setNewJiraTasks(newValues)}
                        disabled={loading}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='secondary'>
                    Cancel
                </Button>
                <Button onClick={handleSave} color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
