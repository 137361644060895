import { Box, styled } from '@mui/material';

const navbarHeight = '48px';

export default styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${navbarHeight})`,
    [theme.breakpoints.down('sm')]: {
        height: 'unset',
        minHeight: `calc(100vh - ${navbarHeight})`,
    },
}));
