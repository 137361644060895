import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedFilter } from '../commons/filters/interfaces';
import getUrlParam, { getUrlFilterValue } from '../commons/getUrlPrams';
import { IToggleColumn } from '../commons/interfaces';
import * as queryString from '../commons/queryString';
import { persistColumnVisibleStateInCookie } from '../commons/toggleableColumnsState';
import { ETestStatus, ITestFilter } from './interfaces';
import { columns, COOKIE_NAME, TestsColumnId } from './testsColumns';

export interface ITestsSlice {
    columns: Array<IToggleColumn<TestsColumnId>>;
    showFilters: boolean;
    filters?: ITestFilter;
}

const initialState: ITestsSlice = {
    columns,
    showFilters: true,
};

export const testsSlice = createSlice({
    name: 'tests',
    initialState,
    reducers: {
        setTestsColumnVisibility: (state, action: PayloadAction<{ columnId: TestsColumnId; value: boolean }>) => {
            const { columnId, value } = action.payload;
            const columnIndex = state.columns.findIndex((column) => column.id === columnId);
            state.columns[columnIndex].visible = value;
            persistColumnVisibleStateInCookie(COOKIE_NAME, state.columns);
        },
        toggleShowTestsFilters: (state) => {
            state.showFilters = !state.showFilters;
        },
        initTestsFilter: (state) => {
            state.filters = getTestsFiltersFromUrl();
        },
        setTestsFilter: (state, action: PayloadAction<Partial<ITestFilter>>) => {
            const { build, ...filters } = action.payload;
            state.filters = {
                ...(state.filters || {}),
                page: 0,
                ...filters,
                build: {
                    ...(state.filters?.build || {}),
                    ...build,
                },
            };
            setTestsFiltersToUrl(state.filters);
        },
        clearTestsFilter: (state) => {
            state.filters = {
                page: 0,
            };
            setTestsFiltersToUrl(state.filters);
        },
        cleanupTestsFilter: (state) => {
            state.filters = undefined;
        },
    },
});

export const selectTestsColumns = (state: { tests: ITestsSlice }): Array<IToggleColumn<TestsColumnId>> =>
    state.tests.columns;
export const selectShowTestsFilters = (state: { tests: ITestsSlice }): boolean => state.tests.showFilters;
export const selectTestsFilters = (state: { tests: ITestsSlice }): ITestFilter | undefined => state.tests.filters;
export const selectAuthorFilter = (state: { tests: ITestsSlice }): SelectedFilter => state.tests.filters?.author;
export const selectTestNumberFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.testNumber;
export const selectTestStatusFilter = (state: { tests: ITestsSlice }): SelectedFilter => state.tests.filters?.status;
export const selectTestDeviceFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.testDevice;
export const selectTestBranchFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.testBranch;
export const selectProjectNameFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.projectName;
export const selectBuildVersionFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.build?.versionName;
export const selectBuildPlatformFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.build?.platform;
export const selectBuildTypeFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.build?.type;
export const selectBuildBranchFilter = (state: { tests: ITestsSlice }): SelectedFilter =>
    state.tests.filters?.build?.branch;
export const selectAmountOfActiveTestFilters = (state: { tests: ITestsSlice }): number => {
    return [
        state.tests.filters?.author,
        state.tests.filters?.testNumber,
        state.tests.filters?.status,
        state.tests.filters?.testDevice,
        state.tests.filters?.testBranch,
        state.tests.filters?.projectName,
        state.tests.filters?.build?.versionName,
        state.tests.filters?.build?.platform,
        state.tests.filters?.build?.type,
        state.tests.filters?.build?.branch,
    ].reduce((total: number, filter: SelectedFilter) => {
        return filter ? total + 1 : total;
    }, 0);
};

export const {
    setTestsColumnVisibility,
    toggleShowTestsFilters,
    initTestsFilter,
    setTestsFilter,
    clearTestsFilter,
    cleanupTestsFilter,
} = testsSlice.actions;

export default testsSlice.reducer;

function setTestsFiltersToUrl(filters: ITestFilter): void {
    const params = queryString.stringify(filters);

    window.history.replaceState(null, `set filters filters`, `tests${params && '?' + params}`);
}

function getTestsFiltersFromUrl(): ITestFilter {
    return {
        page: getUrlParam('page') as number,
        author: getUrlFilterValue('author') || undefined,
        testNumber: getUrlFilterValue('testNumber') || undefined,
        projectName: getUrlFilterValue('projectName') || undefined,
        status: (getUrlFilterValue('status') as ETestStatus[]) || undefined,
        testDevice: getUrlFilterValue('testDevice') || undefined,
        testBranch: getUrlFilterValue('testBranch') || undefined,
        build: {
            branch: getUrlFilterValue('build[branch]') || undefined,
            type: getUrlFilterValue('build[type]') || undefined,
            versionName: getUrlFilterValue('build[versionName]') || undefined,
            platform: getUrlFilterValue('build[platform]') || undefined,
        },
    };
}
