import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Person as UserIcon } from '@mui/icons-material';
import { Avatar, Menu, IconButton, MenuItem, Typography } from '@mui/material';
import { AvatarChip } from '@tactileentertainment/core-designsystem';
import { ShowIfCan, useAuthUser, useLogout } from '@tactileentertainment/core-shared.auth-react';
import { EAuthResource } from '../commons/EAuthResource';
import { setSelectedMenu } from './headerSlice';

export default function ProfileContextMenu(): ReactElement {
    const dispatch = useDispatch();
    const { isLoggedIn, user } = useAuthUser();
    const logout = useLogout();

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const openMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const selectMenuItem = () => {
        dispatch(setSelectedMenu(undefined));
        closeMenu();
    };

    return (
        <>
            <IconButton title='Profile Menu' onClick={openMenu} sx={{ padding: 0 }}>
                {user ? (
                    <AvatarChip label={user.email} altLabel={user.name} src={user.imageUrl} avatarOnly />
                ) : (
                    <Avatar
                        sx={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '4px',
                        }}
                    >
                        <UserIcon />
                    </Avatar>
                )}
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu} disableScrollLock>
                <MenuItem onClick={selectMenuItem}>
                    <Typography component={Link} to='/profile' sx={{ textDecoration: 'none' }}>
                        Account Settings
                    </Typography>
                </MenuItem>
                <MenuItem onClick={selectMenuItem}>
                    <ShowIfCan resource={EAuthResource.accessControl} operation='edit'>
                        <Typography component={Link} to='/access-control' sx={{ textDecoration: 'none' }}>
                            Access Control
                        </Typography>
                    </ShowIfCan>
                </MenuItem>
                {isLoggedIn && <MenuItem onClick={() => logout()}>Logout</MenuItem>}
            </Menu>
        </>
    );
}
