import { IToggleColumn } from '../../commons/interfaces';

export enum TestDevicesColumnId {
    dummy = 'dummy',
    state = 'state',
    uuid = 'uuid',
    gameDeviceId = 'gameDeviceId',
    name = 'name',
    platform = 'platform',
    battery = 'battery',
    temperature = 'temperature',
    queueLength = 'queueLength',
    testRunning = 'testRunning',
    testSuiteRunning = 'testSuiteRunning',
    lastSeen = 'lastSeen',
    actions = 'actions',
    testAgent = 'testAgent',
}

export const columns: Array<IToggleColumn<TestDevicesColumnId>> = [
    { id: TestDevicesColumnId.state, label: 'Status', align: 'left', width: '8%' },
    { id: TestDevicesColumnId.name, label: 'Name', align: 'left', width: '14%' },
    { id: TestDevicesColumnId.uuid, label: 'UUID', align: 'left', width: '10%' },
    { id: TestDevicesColumnId.gameDeviceId, label: 'Game Device UID', align: 'left', width: '12%' },
    { id: TestDevicesColumnId.platform, label: 'OS', align: 'left', width: '6%' },
    { id: TestDevicesColumnId.battery, label: 'Battery', align: 'center', width: '4%' },
    { id: TestDevicesColumnId.temperature, label: 'Temperature', align: 'center', width: '5%' },
    { id: TestDevicesColumnId.queueLength, label: 'Queue Length', align: 'center', width: '6%' },
    { id: TestDevicesColumnId.testSuiteRunning, label: 'Test Suite ID Running', align: 'center', width: '8%' },
    { id: TestDevicesColumnId.lastSeen, label: 'Last Seen', align: 'left', width: '8%' },
    { id: TestDevicesColumnId.testAgent, label: 'Test Agent', align: 'center', width: '8%' },
    { id: TestDevicesColumnId.actions, label: 'Actions', align: 'center', width: '8%' },
];

export const mobileColumns: TestDevicesColumnId[] = [
    TestDevicesColumnId.state,
    TestDevicesColumnId.name,
    TestDevicesColumnId.platform,
    TestDevicesColumnId.testRunning,
];
