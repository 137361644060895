import { ReactElement } from 'react';
import DisabledForDeploy from '@mui/icons-material/SyncDisabled';
import { Tooltip, Typography } from '@mui/material';
import moment from 'moment/moment';

export default function TestAgentDisabledIcon({
    disabledReason,
    disabledDate,
    updatedBy,
    size,
}: {
    disabledDate?: Date;
    disabledReason?: string;
    updatedBy?: string;
    size?: 'large' | 'medium' | 'small';
}): ReactElement {
    return (
        <Tooltip
            title={
                <Typography variant='subtitle2'>
                    The test agent is disabled by {updatedBy || 'someone'} on&nbsp;
                    {moment(disabledDate).format('DD-MM-YYYY HH:mm')} because of&nbsp;
                    {disabledReason || 'unspecified reason'}. It will finish currently running tests, but will not start
                    new ones.
                </Typography>
            }
        >
            <DisabledForDeploy fontSize={size} sx={{ verticalAlign: 'bottom' }} />
        </Tooltip>
    );
}
