import { ReactElement } from 'react';
import { TextField, useMediaQuery } from '@mui/material';

interface ChannelInputProps {
    channel?: string;
    onChange: (channel?: string) => void;
}

export function DefaultNotificationChannel({ channel, onChange }: ChannelInputProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');

    return (
        <TextField
            id='channel-input'
            required
            label='Notification Channel'
            variant='outlined'
            value={channel}
            onChange={(e) => onChange(e.target.value || '')}
            sx={{ minWidth: isMobile ? '0px' : '350px' }}
        />
    );
}
