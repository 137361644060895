export enum BuildsColumnId {
    projectName = 'projectName',
    status = 'status',
    branch = 'branch',
    revision = 'revision',
    assetRevision = 'settings.assetRevision',
    versionName = 'versionName',
    platform = 'platform',
    type = 'type',
    kind = 'kind',
    buildAgent = 'buildAgent',
    duration = 'duration',
    jiraTasks = 'jiraTasks',
    author = 'author',
    messages = 'messages',
    actions = 'actions',
}

export enum TableSize {
    small = 'small',
    medium = 'medium',
}

export enum EBuildPlatform {
    ios = 'ios',
    android = 'android',
    kindle = 'kindle',
    webgl = 'webgl',
    macos = 'macos',
    osx = 'osx',
    appletv = 'appletv',
    tvos = 'tvos',
    udp = 'udp',
}
