import { ReactElement } from 'react';
import { TableCell, TableRow, Skeleton } from '@mui/material';
import { IToggleColumn } from '../../commons/interfaces';
import { TestDevicesColumnId } from './testDevicesColumns';

export default function TestDeviceLoadingRows({
    rows = 5,
    columns,
}: {
    rows?: number;
    columns: Array<IToggleColumn<TestDevicesColumnId>>;
}): ReactElement {
    const array = [...Array(rows)].map((_value, index) => index);

    return (
        <>
            {array.map((key) => (
                <LoadingRow key={key} columns={columns} random={Math.random() * 0.5 + 0.5} />
            ))}
        </>
    );
}

function LoadingRow({
    random,
    columns,
}: {
    random: number;
    columns: Array<IToggleColumn<TestDevicesColumnId>>;
}): ReactElement {
    return (
        <TableRow data-testid='test-device-loading-row'>
            {columns
                .filter((col) => col.visible)
                .map((column, index) => {
                    return (
                        <TableCell key={index} style={{ padding: '16px 8px' }}>
                            <Skeleton width={`${random * 100}%`} height={20} />
                        </TableCell>
                    );
                })}
        </TableRow>
    );
}
