import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import BuildNumberLink from '../../builds/BuildNumberLink';
import BuildStatusChip from '../../commons/BuildStatusChip';
import GridItem from '../../commons/GridItem';
import TestBranchLink from '../../tests/TestBranchLink';
import { ITestSuiteBase } from '../interfaces';
import { isTestStatusCompleted } from '../utils';
import CancelTestSuiteAction from './CancelTestSuiteAction';

export default function TestSuiteInfoBuildRow({ testSuite }: { testSuite: ITestSuiteBase }): ReactElement {
    const build = testSuite.build;

    return (
        <Grid
            container
            item
            spacing={1}
            justifyContent='space-between'
            data-testid='test-suite-details-build-info'
            sx={{ mr: 6 }}
        >
            <GridItem label='Project'>{build.projectName}</GridItem>
            <GridItem label='Build Branch'>{build.branch}</GridItem>
            <GridItem label='Build Type'>{build.type}</GridItem>
            <GridItem label='Build ID'>
                <BuildNumberLink projectName={build.projectName} buildNumber={build.buildNumber} sx={{ mr: 1 }} />
                <BuildStatusChip status={build.status} forcedMobile size={18} />
            </GridItem>
            <GridItem label='Version'>{build.versionName}</GridItem>
            <GridItem label='Revision'>{build.revision}</GridItem>
            <GridItem label='Test Branch'>
                <TestBranchLink
                    projectName={build.projectName}
                    testBranch={testSuite.testBranch}
                    commitSha={testSuite.commitSha}
                />
            </GridItem>
            {!isTestStatusCompleted(testSuite.status) ? (
                <GridItem label='' align='center' sx={{ justifyContent: 'baseline' }}>
                    <CancelTestSuiteAction testSuite={testSuite} />
                </GridItem>
            ) : null}
        </Grid>
    );
}
