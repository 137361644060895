import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { EBuildStatus } from '../commons/enums';
import { ETestStatus, ITest, ITestCase } from './interfaces';
import TestStatusChip from './TestStatusChip';

export default function TestStatus({ test, detailed }: { test: ITest; detailed?: boolean }): ReactElement {
    if (
        test.status === ETestStatus.cancelled &&
        [EBuildStatus.failed, EBuildStatus.cancelled].includes(test.build.status)
    ) {
        return <TestStatusChip status={test.status} label={`Build ${test.build.status}`} />;
    }
    if (test.testCases?.length && test.status !== ETestStatus.waiting && test.status !== ETestStatus.queued) {
        return <TestCasesStatus status={test.status} testCases={test.testCases} detailed={detailed} />;
    }
    return <TestStatusChip status={test.status} />;
}

function TestCasesStatus({
    status,
    testCases,
    detailed,
}: {
    status: ETestStatus;
    testCases: ITestCase[];
    detailed?: boolean;
}): ReactElement | null {
    const { waiting, success, failed, cancelled, inconclusive } = countTestCasesByStatus(testCases);

    return (
        <Box>
            {status === ETestStatus.running && (
                <TestStatusChip
                    status={ETestStatus.running}
                    label={detailed ? detailedStatusLabel(waiting, 'Being Processed') : waiting}
                    noLabel={waiting === 0}
                />
            )}
            {status === ETestStatus.cancelled && cancelled === 0 && (
                <TestStatusChip status={ETestStatus.cancelled} noLabel={!detailed} />
            )}
            {cancelled > 0 && (
                <TestStatusChip
                    status={ETestStatus.cancelled}
                    label={detailed ? detailedStatusLabel(cancelled, 'Cancelled') : cancelled}
                />
            )}
            {success > 0 && (
                <TestStatusChip
                    status={ETestStatus.success}
                    label={detailed ? detailedStatusLabel(success, 'Passed') : success}
                />
            )}
            {inconclusive > 0 && (
                <TestStatusChip
                    status={ETestStatus.inconclusive}
                    label={detailed ? detailedStatusLabel(inconclusive, 'Inconclusive') : inconclusive}
                />
            )}
            {failed > 0 && (
                <TestStatusChip
                    status={ETestStatus.failed}
                    label={detailed ? detailedStatusLabel(failed, 'Failed') : failed}
                />
            )}
        </Box>
    );
}

function countTestCasesByStatus(
    testCases: ITestCase[],
): Omit<{ [status in ETestStatus]: number }, ETestStatus.queued | ETestStatus.running | ETestStatus.finishing> {
    const result: Omit<
        { [status in ETestStatus]: number },
        ETestStatus.queued | ETestStatus.running | ETestStatus.finishing
    > = {
        [ETestStatus.waiting]: 0,
        [ETestStatus.success]: 0,
        [ETestStatus.failed]: 0,
        [ETestStatus.inconclusive]: 0,
        [ETestStatus.cancelled]: 0,
    };
    for (const testCase of testCases) {
        if (
            testCase.status === ETestStatus.success ||
            testCase.status === ETestStatus.failed ||
            testCase.status === ETestStatus.inconclusive ||
            testCase.status === ETestStatus.cancelled
        ) {
            result[testCase.status] += 1;
        } else {
            result[ETestStatus.waiting] += 1;
        }
    }
    return result;
}

function detailedStatusLabel(testsCount: number, status: string): string {
    return `${testsCount} Test${testsCount > 1 ? 's' : ''} ${status}`;
}
