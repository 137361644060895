import { GameModuleCategory } from '../enums';
import { IGameModules, IModule } from '../interfaces';

interface FilterModulesParams {
    modules?: IGameModules;
    searchText: string;
    category: GameModuleCategory;
    selectedProjects: string[];
    hideModules: boolean;
    branchesOnly: boolean;
    showOnlyNonUpToDate: boolean;
    primary: boolean;
    deprecated: boolean;
}

export const filterModules = ({
    modules,
    searchText,
    category,
    selectedProjects,
    hideModules,
    branchesOnly,
    showOnlyNonUpToDate,
    primary,
    deprecated,
}: FilterModulesParams): IModule[] => {
    const modulesByCategory = modules?.[category] || [];
    const text = searchText || '';

    let filteredModules = selectedProjects?.length
        ? getModulesByProjects(modulesByCategory, selectedProjects)
        : modulesByCategory;

    if (hideModules) {
        filteredModules = filteredModules.filter((module) => {
            const projectsModuleArray = module.projects.filter((item) => item);
            return projectsModuleArray.length;
        });
    }

    if (branchesOnly) {
        filteredModules = filterOutMainBranches(filteredModules, category, selectedProjects);
    }

    if (showOnlyNonUpToDate) {
        filteredModules = filterOutUpdatedModules(filteredModules, selectedProjects);
    }

    if (primary) {
        filteredModules = filterPrimary(filteredModules);
    }

    if (deprecated) {
        filteredModules = filterDeprecated(filteredModules);
    }

    return filteredModules
        ?.filter((item) => item.moduleName.toLowerCase().includes(text.toLowerCase()))
        .sort((a, b) => a.moduleName.localeCompare(b.moduleName));
};

const filterOutMainBranches = (
    modules: IModule[],
    category: GameModuleCategory,
    selectedProjects: string[],
): IModule[] => {
    let filtered: IModule[] = [];
    if (category === GameModuleCategory.MODULE) {
        const copy = structuredClone(modules);
        copy.forEach((module) => {
            const filteredProjects = module.projects.filter((project) => project?.branch !== 'trunk');
            module.projects = filteredProjects;
            return module;
        });
        filtered = copy.filter((module) => {
            const projectsModuleArray = module.projects.filter((item) => item);
            return projectsModuleArray.length;
        });
    }
    if (category === GameModuleCategory.PACKAGE || category === GameModuleCategory.UNITY_PACKAGE) {
        filtered = modules.filter((module) => {
            const hashPattern = /[0-9a-f]{40}/;
            return !module.projects.some((project) => !RegExp(hashPattern).test(project?.version));
        });
    }

    return selectedProjects?.length ? getModulesByProjects(filtered, selectedProjects) : filtered;
};

const filterOutUpdatedModules = (modules: IModule[], selectedProjects: string[]) => {
    const copy = structuredClone(modules);
    copy.forEach((module) => {
        const filteredProjects = module.projects.filter((project) => project?.versionDiff);
        module.projects = filteredProjects;
    });
    const filtered = copy.filter((module) => {
        const projectsModuleArray = module.projects.filter((item) => item);
        return projectsModuleArray.length;
    });

    return selectedProjects?.length ? getModulesByProjects(filtered, selectedProjects) : filtered;
};

const getModulesByProjects = (modules: IModule[], selectedProjects: string[]): IModule[] => {
    return modules?.filter((module) => {
        const matchingProjects = module.projects?.filter((project) =>
            selectedProjects.find((selectedProject) => selectedProject === project?.projectName),
        );

        return matchingProjects.length;
    });
};

const filterPrimary = (modules: IModule[]) => {
    const copy = structuredClone(modules);
    return copy.filter((module) => module.primary && module.projects.length);
};

const filterDeprecated = (modules: IModule[]) => {
    const copy = structuredClone(modules);
    return copy.filter((module) => module.deprecated && module.projects.length);
};
