import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRequest, postRequest } from '../../api';
import { BuildAgent } from '../buildAgents/interfaces';
import { IConfigurationSettings, ISelectedSettings, ISetting, SettingType } from '../configurationSettings/interfaces';
import updateSelectedSettings from '../configurationSettings/updateSelectedSettings';
import { ETestSuiteAllocationType } from '../testSuites/interfaces';
import { NewBuildStatus } from './enums';
import {
    IAutocomplete,
    IAutocompleteBuildAgent,
    IAutocompleteBuildConfig,
    IBuildOrder,
    IConfiguration,
    IConfigurationBuild,
    IKnownRevisions,
    INewBuildSlice,
    ISelectedBuildConfiguration,
    ISimilarBuild,
} from './interfaces';

const initialState: INewBuildSlice = {
    status: NewBuildStatus.idle,
    project: {
        selected: null,
        options: [],
        loading: true,
    },
    branch: {
        selected: null,
        options: [],
        disabled: true,
    },
    revision: {
        selected: null,
        options: [],
        disabled: true,
    },
    buildAgent: {
        selected: null,
        options: [],
        loading: true,
        disabled: true,
    },
    buildConfiguration: {
        selected: undefined,
        options: [],
        disabled: true,
    },
    buildViability: [],
    similarBuilds: [],
    expedited: false,
    jiraTasks: [],
    failedSettings: [],
    orderIsValid: false,
    validationErrors: [],
};

export const getProjects = createAsyncThunk('newBuild/getProjects', async (_args, thunkAPI) => {
    return getRequest('/projects/names', undefined, thunkAPI);
});

export const getBranches = createAsyncThunk('newBuild/getBranches', async (_args, thunkAPI) => {
    const state = thunkAPI.getState() as { newBuild: INewBuildSlice };
    return getRequest(
        '/version-control-access/project-branches',
        { projectName: state.newBuild.project.selected },
        thunkAPI,
    );
});

export const getRevisions = createAsyncThunk('newBuild/getRevisions', async (_args, thunkAPI) => {
    const state = thunkAPI.getState() as { newBuild: INewBuildSlice };
    return getRequest(
        '/version-control-access/known-revisions',
        { projectName: state.newBuild.project.selected, branch: state.newBuild.branch.selected },
        thunkAPI,
    );
});

const createGetBuildConfigurationsHash = (projectName = '', branch = '', revision = '') =>
    `${projectName}-${branch}-${revision}`;
export const getBuildConfigurations = createAsyncThunk<
    any,
    { projectName: string; branch: string; revision: string },
    { state: any }
>('builds/getBuildConfiguration', async (payload, thunkAPI) => {
    const { projectName, branch, revision } = payload;

    const body = await getRequest('/version-control-access/build-configs', { projectName, branch, revision }, thunkAPI);

    return {
        ...body,
        hash: createGetBuildConfigurationsHash(projectName, branch, revision),
    } as { buildConfigs: { [key: string]: IConfiguration }; hash: string };
});

export const orderBuilds = createAsyncThunk('newBuild/orderBuilds', async (_args, thunkAPI) => {
    const state = thunkAPI.getState() as { newBuild: INewBuildSlice };
    const builds = mapToIndividualBuilds(state.newBuild);

    return postRequest('/builds', builds, thunkAPI);
});

export const getViableAgentsCount = createAsyncThunk('newBuild/viableAgents', async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as { newBuild: INewBuildSlice };
    if (!state.newBuild.revision.selected?.revision) {
        return;
    }

    const query = {
        projectName: state.newBuild.project.selected,
        branch: state.newBuild.branch.selected,
        revision: state.newBuild.revision.selected?.revision,
    };

    return getRequest('/builds/viable-agents', query, thunkAPI);
});

export const getSimilarBuilds = createAsyncThunk('newBuild/similarBuilds', async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as { newBuild: INewBuildSlice };
    if (!state.newBuild.revision.selected?.revision) {
        return;
    }

    const query = {
        projectName: state.newBuild.project.selected,
        branch: state.newBuild.branch.selected,
        revision: state.newBuild.revision.selected?.revision,
        settings: JSON.stringify(state.newBuild.selectedBuildConfiguration?.settings || {}),
    };

    return getRequest('/builds/similar-builds', query, thunkAPI);
});

export const getBuildAgents = createAsyncThunk('newBuild/getBuildAgents', async (_args, thunkAPI) => {
    return getRequest('/build-agents', undefined, thunkAPI);
});

export const newBuildSlice = createSlice({
    name: 'newBuild',
    initialState,
    reducers: {
        clearNewBuild: () => initialState,
        setNewBuildProject: (state: INewBuildSlice, action: PayloadAction<string | null>) => {
            state.project.selected = action.payload;
            state.buildViability = [];
            state.similarBuilds = [];

            state.branch.selected = null;
            state.branch.options = [];
            state.branch.disabled = true;

            state.revision.selected = null;
            state.revision.options = [];
            state.revision.disabled = true;

            state.buildConfiguration.selected = undefined;
            state.buildConfiguration.options = [];
            state.buildConfiguration.disabled = true;

            state.status = NewBuildStatus.loaded;
            state.error = undefined;

            if (state.project.selected) {
                state.branch.disabled = false;
            } else {
                state.branch.disabled = true;
                state.revision.disabled = true;
            }

            state.validationErrors = [];
            state.orderIsValid = false;
        },
        setNewBuildBranch: (state: INewBuildSlice, action: PayloadAction<string | null>) => {
            state.branch.selected = action.payload;
            state.buildViability = [];
            state.similarBuilds = [];

            state.revision.selected = null;
            state.revision.options = [];
            state.revision.disabled = true;

            state.buildConfiguration.selected = undefined;
            state.buildConfiguration.options = [];
            state.buildConfiguration.disabled = true;

            state.status = NewBuildStatus.loaded;
            state.error = undefined;

            if (state.branch.selected) {
                state.revision.disabled = false;
            } else {
                state.revision.disabled = true;
            }

            state.validationErrors = [];
            state.orderIsValid = false;
        },
        setNewBuildRevision: (state: INewBuildSlice, action: PayloadAction<IKnownRevisions | undefined>) => {
            state.revision.disabled = false;
            state.buildViability = [];
            state.similarBuilds = [];

            if (action.payload) {
                state.revision.selected = action.payload;
            }

            state.buildConfiguration.selected = undefined;
            state.buildConfiguration.options = [];
            state.buildConfiguration.disabled = true;

            state.status = NewBuildStatus.loaded;
            state.error = undefined;

            state.validationErrors = [];
            state.orderIsValid = false;
        },
        setNewBuildConfiguration: (
            state: INewBuildSlice,
            action: PayloadAction<{ fileName: string; configuration: IConfiguration } | undefined>,
        ) => {
            state.buildConfiguration.disabled = false;
            state.buildViability = [];
            state.similarBuilds = [];

            if (action.payload) {
                state.buildConfiguration.selected = {
                    ...action.payload,
                    configuration: addSettingKey(action.payload.configuration),
                };
                state.buildConfigFileName = action.payload.fileName;

                const { configuration, defaultSettings } = selectSettingDefaults(
                    state.buildConfiguration.selected?.configuration,
                );
                const selectedBuildConfiguration = {
                    builds: [],
                    settings: defaultSettings,
                };
                const { valid, validationErrors } = validateSelectedBuildConfiguration(
                    configuration,
                    selectedBuildConfiguration,
                    state.version,
                );

                state.selectedBuildConfiguration = selectedBuildConfiguration;
                state.validationErrors = validationErrors;
                state.orderIsValid = valid;
                state.buildAgent.disabled = false;
            } else {
                state.validationErrors = [];
                state.orderIsValid = false;
            }

            state.status = NewBuildStatus.loaded;
            state.error = undefined;
        },
        selectBuild: (state: INewBuildSlice, action: PayloadAction<number>) => {
            if (state.selectedBuildConfiguration) {
                const buildToSelect = state.buildConfiguration?.selected?.configuration?.builds?.find(
                    (build) => build.id === action.payload,
                );
                if (buildToSelect) {
                    buildToSelect.viableAgentsCount = state.buildViability.find(
                        (buildConfig) => buildConfig.id === buildToSelect.id,
                    )?.viableAgentsCount;
                    state.selectedBuildConfiguration.builds.push(buildToSelect);

                    const { valid, validationErrors } = validateSelectedBuildConfiguration(
                        state.buildConfiguration?.selected?.configuration,
                        state.selectedBuildConfiguration,
                        state.version,
                    );
                    state.validationErrors = validationErrors;
                    state.orderIsValid = valid;
                }
            }
        },
        removeBuild: (state: INewBuildSlice, action: PayloadAction<number>) => {
            if (state.selectedBuildConfiguration) {
                state.selectedBuildConfiguration.builds = state.selectedBuildConfiguration.builds.filter(
                    (build) => build.id !== action.payload,
                );

                const { valid, validationErrors } = validateSelectedBuildConfiguration(
                    state.buildConfiguration?.selected?.configuration,
                    state.selectedBuildConfiguration,
                    state.version,
                );
                state.validationErrors = validationErrors;
                state.orderIsValid = valid;
            }
        },
        setSetting: (
            state: INewBuildSlice,
            action: PayloadAction<{ setting: ISetting; value: string | string[] | boolean | null }>,
        ) => {
            const { setting, value } = action.payload;
            if (state.selectedBuildConfiguration) {
                state.selectedBuildConfiguration.settings = updateSelectedSettings(
                    state.selectedBuildConfiguration.settings,
                    setting,
                    value,
                );

                const { valid, validationErrors } = validateSelectedBuildConfiguration(
                    state.buildConfiguration?.selected?.configuration,
                    state.selectedBuildConfiguration,
                    state.version,
                );
                state.validationErrors = validationErrors;
                state.orderIsValid = valid;
            }
        },
        setVersion: (state: INewBuildSlice, action: PayloadAction<string | undefined>) => {
            state.version = action.payload;

            const { valid, validationErrors } = validateSelectedBuildConfiguration(
                state.buildConfiguration?.selected?.configuration,
                state.selectedBuildConfiguration,
                state.version,
            );
            state.validationErrors = validationErrors;
            state.orderIsValid = valid;
        },
        setJiraTasks: (state: INewBuildSlice, action: PayloadAction<string[]>) => {
            state.jiraTasks = action.payload;
        },
        setSubscribed: (state: INewBuildSlice, action: PayloadAction<boolean>) => {
            state.subscribed = action.payload;
        },
        setSubscribers: (state: INewBuildSlice, action: PayloadAction<string[]>) => {
            state.subscribers = action.payload;
        },
        setBuildAgent: (state: INewBuildSlice, action: PayloadAction<BuildAgent | null>) => {
            state.buildAgent.selected = action.payload;
        },
        setExpedited: (state: INewBuildSlice, action: PayloadAction<boolean>) => {
            state.expedited = action.payload;
        },
        setRunTests: (state: INewBuildSlice, action: PayloadAction<boolean>) => {
            state.runTests = action.payload;
        },
        applyFavouriteSettings: (state: INewBuildSlice, action: PayloadAction<ISelectedSettings>) => {
            state.selectedBuildConfiguration = {
                builds: [],
                settings: {},
            };

            const buildConfigSettings = state.buildConfiguration?.selected?.configuration?.settings;
            if (buildConfigSettings) {
                for (const key in action.payload) {
                    if (buildConfigSettings[key]) {
                        state.selectedBuildConfiguration.settings[key] = action.payload[key];
                    } else {
                        state.failedSettings.push(key);
                    }
                }
            }

            const { valid, validationErrors } = validateSelectedBuildConfiguration(
                state.buildConfiguration?.selected?.configuration,
                state.selectedBuildConfiguration,
                state.version,
            );
            state.validationErrors = validationErrors;
            state.orderIsValid = valid;
        },
        setFailedSettings: (state: INewBuildSlice, action: PayloadAction<string[]>) => {
            state.failedSettings = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.pending, (state) => {
                state.status = NewBuildStatus.loading;
                state.error = undefined;
                state.project.loading = true;
            })
            .addCase(getProjects.fulfilled, (state, action: PayloadAction<{ projects: string[] }>) => {
                state.status = NewBuildStatus.loaded;
                state.project.loading = false;
                state.project.options = action.payload.projects;
            })
            .addCase(getBranches.pending, (state) => {
                state.status = NewBuildStatus.loading;
                state.error = undefined;
                state.branch.loading = true;
            })
            .addCase(getBranches.fulfilled, (state, action: PayloadAction<{ branches: string[] }>) => {
                state.status = NewBuildStatus.loaded;
                state.branch.loading = false;
                state.branch.options = action.payload.branches.concat('trunk');
                state.branch.selected = 'trunk';
            })
            .addCase(getBranches.rejected, (state, action: any) => {
                state.status = NewBuildStatus.error;
                state.branch.loading = false;
                state.branch.disabled = false;

                state.error = action?.payload?.error?.message || 'There was a problem fetching branches';
            })
            .addCase(getRevisions.pending, (state) => {
                state.status = NewBuildStatus.loading;
                state.error = undefined;
                state.revision.loading = true;
            })
            .addCase(getRevisions.fulfilled, (state, action: PayloadAction<IKnownRevisions[]>) => {
                state.status = NewBuildStatus.loaded;
                state.revision.loading = false;
                state.revision.options = action.payload;
                const allRevisions: string[] = action.payload.map((rev) => rev.revision);
                state.headRevision = Math.max(...(allRevisions as unknown as number[]))?.toString();
                state.revision.selected = action.payload[0];
                state.revision.disabled = false;
            })
            .addCase(getRevisions.rejected, (state, action: any) => {
                state.revision.loading = false;
                state.revision.disabled = false;
                state.error = action?.payload?.error?.message || 'There was a problem fetching revisions';
            })
            .addCase(getBuildConfigurations.pending, (state) => {
                state.status = NewBuildStatus.loadingConfiguration;
                state.error = undefined;
                state.buildConfiguration.loading = true;
                state.buildConfiguration.hash = createGetBuildConfigurationsHash(
                    state.project.selected,
                    state.branch.selected,
                    state.revision.selected?.revision,
                );
            })
            .addCase(
                getBuildConfigurations.fulfilled,
                (state, action: PayloadAction<{ buildConfigs: { [key: string]: IConfiguration }; hash: string }>) => {
                    if (state.buildConfiguration.hash !== action.payload.hash) {
                        // Ignore the response if the hash has changed
                        return;
                    }

                    state.status = NewBuildStatus.loaded;
                    state.buildConfiguration.loading = false;
                    state.buildConfiguration.options = Object.entries(action.payload.buildConfigs).map(
                        ([key, configuration]) => ({ fileName: key, configuration }),
                    );
                    state.buildConfiguration.selected = action.payload.buildConfigs['build_config.json']
                        ? {
                              fileName: 'build_config.json',
                              configuration: addSettingKey(action.payload.buildConfigs['build_config.json']),
                          }
                        : undefined;
                    state.buildConfiguration.disabled = false;

                    state.buildConfigFileName = state.buildConfiguration.selected?.fileName || undefined;

                    const { configuration, defaultSettings } = selectSettingDefaults(
                        state.buildConfiguration.selected?.configuration,
                    );
                    const selectedBuildConfiguration = {
                        builds: [],
                        settings: defaultSettings,
                    };
                    const { valid, validationErrors } = validateSelectedBuildConfiguration(
                        configuration,
                        selectedBuildConfiguration,
                        state.version,
                    );

                    state.selectedBuildConfiguration = selectedBuildConfiguration;
                    state.validationErrors = validationErrors;
                    state.orderIsValid = valid;
                    state.buildAgent.disabled = false;
                },
            )
            .addCase(getBuildConfigurations.rejected, (state, action: any) => {
                state.status = NewBuildStatus.error;
                state.buildConfiguration.loading = false;
                state.buildConfiguration.disabled = false;

                state.error = action?.payload?.error?.message || 'There was a problem fetching "build_config.json"';
            })
            .addCase(orderBuilds.pending, (state) => {
                state.status = NewBuildStatus.loading;
            })
            .addCase(orderBuilds.fulfilled, (state) => {
                state.status = NewBuildStatus.loaded;
            })
            .addCase(orderBuilds.rejected, (state, action: any) => {
                state.status = NewBuildStatus.error;
                state.error = action?.payload?.error?.message || 'There was a problem while ordering the build(s)';
            })
            .addCase(getBuildAgents.pending, (state) => {
                state.buildAgent.loading = true;
            })
            .addCase(getBuildAgents.fulfilled, (state, action: PayloadAction<{ buildAgents: [] }>) => {
                state.buildAgent.options = action.payload.buildAgents;
                state.buildAgent.loading = false;
            })
            .addCase(getBuildAgents.rejected, (state, action: any) => {
                state.status = NewBuildStatus.error;
                state.error = action?.payload?.error?.message || 'There was a problem while fetching the build agents';
            })
            .addCase(getViableAgentsCount.rejected, (state, action: any) => {
                state.status = NewBuildStatus.error;
                state.error =
                    action?.payload?.error?.message || 'There was a problem while looking-up viable build agents';
            })
            .addCase(getViableAgentsCount.fulfilled, (state, action) => {
                state.buildViability = action.payload?.viableAgents || [];
            })
            .addCase(getSimilarBuilds.rejected, (state, action: any) => {
                state.status = NewBuildStatus.error;
                state.error = action?.payload?.error?.message || 'There was a problem while looking-up similar builds';
            })
            .addCase(getSimilarBuilds.fulfilled, (state, action) => {
                state.similarBuilds = action.payload?.similarBuilds || [];
            });
    },
});

export const selectNewBuild = (state: { newBuild: INewBuildSlice }): INewBuildSlice => state.newBuild;
export const selectNewBuildOrderIsValid = (state: { newBuild: INewBuildSlice }): boolean => {
    const selectedBuilds = state.newBuild.selectedBuildConfiguration?.builds || [];
    const noAgentsAvailable = selectedBuilds?.filter?.((build) => build.viableAgentsCount === 0)?.length > 0;

    if (noAgentsAvailable) {
        return false;
    }

    return state.newBuild.orderIsValid;
};
export const selectNewBuildConfiguration = (state: { newBuild: INewBuildSlice }): IAutocompleteBuildConfig =>
    state.newBuild.buildConfiguration;
export const selectNewBuildConfigurationBuilds = (state: {
    newBuild: INewBuildSlice;
}): IConfigurationBuild[] | undefined => state.newBuild.buildConfiguration?.selected?.configuration?.builds;
export const selectNewBuildConfigurationSelectedBuilds = (state: {
    newBuild: INewBuildSlice;
}): IConfigurationBuild[] | undefined => state.newBuild.selectedBuildConfiguration?.builds;
export const selectNewBuildConfigurationSettings = (state: {
    newBuild: INewBuildSlice;
}): IConfigurationSettings | undefined => state.newBuild.buildConfiguration?.selected?.configuration?.settings;
export const selectNewBuildConfigurationSelectedSettings = (state: {
    newBuild: INewBuildSlice;
}): ISelectedSettings | undefined => state.newBuild.selectedBuildConfiguration?.settings;
export const selectNewBuildStatus = (state: { newBuild: INewBuildSlice }): NewBuildStatus => state.newBuild.status;
export const selectNewBuildProject = (state: { newBuild: INewBuildSlice }): IAutocomplete => state.newBuild.project;
export const selectNewBuildBranch = (state: { newBuild: INewBuildSlice }): IAutocomplete => state.newBuild.branch;
export const selectNewBuildRevision = (state: {
    newBuild: INewBuildSlice;
}): Omit<IAutocomplete, 'options' | 'selected'> & { options: IKnownRevisions[]; selected: any } =>
    state.newBuild.revision;
export const selectNewBuildVersion = (state: { newBuild: INewBuildSlice }): string | undefined =>
    state.newBuild.version;
export const selectNewBuildAgent = (state: { newBuild: INewBuildSlice }): IAutocompleteBuildAgent =>
    state.newBuild.buildAgent;
export const selectNewBuildHeadRevision = (state: { newBuild: INewBuildSlice }): string | undefined =>
    state.newBuild.headRevision;
export const selectNewBuildJiraTasks = (state: { newBuild: INewBuildSlice }): string[] => state.newBuild.jiraTasks;
export const selectNewBuildSubscribed = (state: { newBuild: INewBuildSlice }): boolean => !!state.newBuild.subscribed;
export const selectNewBuildFailedSettings = (state: { newBuild: INewBuildSlice }): string[] =>
    state.newBuild.failedSettings;
export const selectNewBuildValidationErrors = (state: { newBuild: INewBuildSlice }): string[] =>
    state.newBuild.validationErrors;
export const selectNewBuildError = (state: { newBuild: INewBuildSlice }): string | undefined => state.newBuild.error;
export const selectNewBuildSubscribers = (state: { newBuild: INewBuildSlice }): string[] | undefined =>
    state.newBuild.subscribers;
export const selectNewBuildSimilarBuilds = (state: { newBuild: INewBuildSlice }): ISimilarBuild[] =>
    state.newBuild.similarBuilds;

export const {
    clearNewBuild,
    setNewBuildProject,
    setNewBuildBranch,
    setNewBuildRevision,
    setNewBuildConfiguration,
    selectBuild,
    removeBuild,
    setSetting,
    setVersion,
    setJiraTasks,
    setSubscribed,
    setBuildAgent,
    applyFavouriteSettings,
    setFailedSettings,
    setExpedited,
    setRunTests,
    setSubscribers,
} = newBuildSlice.actions;

export default newBuildSlice.reducer;

export function addSettingKey(configuration?: IConfiguration): IConfiguration {
    const newSettings: IConfigurationSettings = {};

    Object.keys(configuration?.settings || {}).forEach((settingKey) => {
        newSettings[settingKey] = {
            ...(configuration?.settings?.[settingKey] || {}),
            display_name: configuration?.settings?.[settingKey]?.display_name || settingKey,
            settingKey,
        } as ISetting;
    });

    return { ...configuration, settings: newSettings };
}

export function selectSettingDefaults(configuration?: IConfiguration): {
    configuration?: IConfiguration;
    defaultSettings: ISelectedSettings;
} {
    const defaultSettings: ISelectedSettings = {};

    if (configuration?.settings) {
        Object.keys(configuration.settings).forEach((settingKey) => {
            if (configuration && configuration.settings && configuration.settings[settingKey]) {
                const setting = configuration.settings[settingKey];

                if (setting.default) {
                    defaultSettings[settingKey] = setting.default as string | string[] | boolean;
                } else if (setting.type === SettingType.radio) {
                    // radio elements need to have one item selected
                    const values = setting.values;
                    if (values) {
                        defaultSettings[settingKey] = values[0];
                    }
                } else if (setting.type === SettingType.switch && setting.required) {
                    // switch elements need to be false if required
                    defaultSettings[settingKey] = false;
                }
            }
        });
    }

    let newConfiguration = configuration ? { ...configuration } : undefined;
    if (configuration?.builds) {
        newConfiguration = {
            ...newConfiguration,
            builds: configuration.builds.filter((build) => !build.hidden && build.id >= 0),
        };
    }

    return {
        configuration: newConfiguration,
        defaultSettings,
    };
}

export function sanitizeSelectedSettings(
    buildConfiguration?: IConfigurationSettings,
    settings?: ISelectedSettings,
): ISelectedSettings | undefined {
    if (!buildConfiguration) {
        return settings;
    }

    const sanitizedSettings: ISelectedSettings = {};

    for (const settingKey in settings) {
        const settingValue = settings[settingKey];

        if (
            settingValue &&
            buildConfiguration[settingKey].type === SettingType.number &&
            typeof settingValue === 'string'
        ) {
            sanitizedSettings[settingKey] = parseInt(settingValue);
        } else if (
            settingValue === undefined ||
            settingValue === null ||
            settingValue === '' ||
            (Array.isArray(settingValue) && settingValue.length === 0) ||
            (settingValue === false && !buildConfiguration[settingKey].required)
        ) {
            continue;
        } else {
            sanitizedSettings[settingKey] = settingValue;
        }
    }

    return sanitizedSettings;
}

export function mapToIndividualBuilds(newBuildState: INewBuildSlice): IBuildOrder[] {
    const projectName = newBuildState.project.selected;
    const branch = newBuildState.branch.selected;
    const revision = newBuildState.revision.selected;
    const version = newBuildState.version;
    const jiraTasks = newBuildState.jiraTasks;
    const buildAgent = newBuildState.buildAgent.selected;
    const settings = sanitizeSelectedSettings(
        newBuildState.buildConfiguration?.selected?.configuration?.settings,
        newBuildState.selectedBuildConfiguration?.settings,
    );
    const buildConfigFileName = newBuildState.buildConfiguration?.selected?.fileName;
    const expedited = newBuildState.expedited;
    const subscribers = newBuildState.subscribers;
    if (!newBuildState.selectedBuildConfiguration) {
        return [];
    }

    const builds: IBuildOrder[] = newBuildState.selectedBuildConfiguration.builds.map((build) => {
        const buildOrder: IBuildOrder = {
            projectName,
            branch,
            revision: revision?.revision,
            buildConfigId: build.id,
            versionName: version,
            jiraTasks,
            settings,
            expedited,
            buildConfigFileName,
            subscribeToResult: newBuildState.subscribed,
            subscribers,
        };

        if (buildAgent?._id) {
            buildOrder.buildAgent = buildAgent._id;
        }

        if (newBuildState.runTests) {
            buildOrder.testSuites = [
                {
                    type: ETestSuiteAllocationType.sequential,
                    testTags: [],
                    numberOfDevices: 1,
                },
            ];
        }

        return buildOrder;
    });
    return builds;
}

const VERSION_REGEX = /^\d{1,3}\.\d{1,3}\.\d{1,3}$/;

export function validateSelectedBuildConfiguration(
    buildConfiguration?: IConfiguration,
    selectedBuildConfiguration?: ISelectedBuildConfiguration,
    version?: string,
): { valid: boolean; validationErrors: string[] } {
    const hasBuildConfiguration = !!buildConfiguration;
    const validationErrors: string[] = [];
    if (!hasBuildConfiguration) {
        return { valid: false, validationErrors };
    }

    const buildsLength = selectedBuildConfiguration?.builds.filter((build) => !build.hidden).length || 0;

    if (buildsLength < 1) {
        validationErrors.push('select at least one supported build');
    }

    const buildSettings = buildConfiguration?.settings || {};
    for (const settingKey in buildSettings) {
        const setting: ISetting = buildSettings[settingKey];
        const selectedSetting = selectedBuildConfiguration?.settings[settingKey];
        const selectedSettingExists = settingKey in (selectedBuildConfiguration?.settings || {});

        if (setting.required && (!selectedSettingExists || selectedSetting === '')) {
            validationErrors.push(`"${setting.display_name}" is required`);
        } else if (selectedSetting) {
            if (setting.multi) {
                if (!Array.isArray(selectedSetting)) {
                    validationErrors.push(`"${setting.display_name}"'s should be an array`);
                } else if (
                    setting.values &&
                    selectedSetting.some((selected: string | number) => !setting.values?.includes(selected as string))
                ) {
                    validationErrors.push(`"${setting.display_name}"'s value is invalid`);
                }
            } else if (setting.values && !setting.values.find((value) => value === selectedSetting)) {
                validationErrors.push(`"${setting.display_name}"'s value is invalid`);
            } else if (
                (setting.type === SettingType.checkbox &&
                    typeof selectedSetting !== 'boolean' &&
                    typeof selectedSetting !== 'string') ||
                (setting.type === SettingType.switch && typeof selectedSetting !== 'boolean') ||
                (setting.type === SettingType.text && typeof selectedSetting !== 'string') ||
                (setting.type === SettingType.number &&
                    typeof selectedSetting !== 'string' &&
                    typeof selectedSetting !== 'number')
            ) {
                validationErrors.push(`"${setting.display_name}"'s type is invalid`);
            }
        } else if (
            selectedSettingExists &&
            (setting.type === SettingType.number ||
                setting.type === SettingType.text ||
                setting.type === SettingType.select)
        ) {
            validationErrors.push(`"${setting.display_name}"'s type is invalid`);
        }
    }

    if (version && !VERSION_REGEX.test(version)) {
        validationErrors.push('use semantic version');
    }

    return {
        valid: validationErrors.length < 1,
        validationErrors,
    };
}
