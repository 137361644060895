import { ReactElement, useMemo, useState } from 'react';
import AodIcon from '@mui/icons-material/Aod';
import { FormControlLabel, IconButton, Switch, TableCell, TableRow } from '@mui/material';
import Duration from '../../commons/Duration';
import TestDeviceName from '../../testDevices/TestDeviceName';
import { useGetTestDeviceQuery } from '../../testDevices/testDevicesApi';
import LogFile from '../../tests/details/LogFile';
import { ETestSuiteAllocationType, ITestSuiteDevice, ITestSuiteResultsCount, ITestSuiteTest } from '../interfaces';
import { getTestsDuration, isTestStatusCompleted } from '../utils';
import TestDeviceStatus from './TestDeviceStatus';
import TestSuiteDetailsTableCell from './TestSuiteDetailsTableCell';
import { TestSuiteDevicesColumnsId as columnIds, testSuiteDevicesColumns } from './testSuiteDevicesColumns';

export default function TestSuiteDeviceRow({
    results,
    tests,
    device,
    testSuiteType,
}: {
    results?: ITestSuiteResultsCount;
    tests: ITestSuiteTest[];
    device: ITestSuiteDevice;
    testSuiteType: ETestSuiteAllocationType;
}): ReactElement | null {
    const { data: testDevice } = useGetTestDeviceQuery(device.uuid);

    const parentTest = tests && tests.length ? tests[0] : undefined;
    const { startDate, endDate } = getTestsDuration(tests);
    const [followLogs, setFollowLogs] = useState<boolean>(true);
    const [expandedContent, setExpandedContent] = useState<boolean>(false);

    const expandContentToggle = () => {
        if (expandedContent) {
            setExpandedContent(false);
        } else {
            setExpandedContent(true);
        }
    };

    const showFollowSwitch = useMemo(
        () => expandedContent && parentTest && !isTestStatusCompleted(parentTest.status),
        [expandedContent, parentTest],
    );

    if (!testDevice) {
        return null;
    }

    return (
        <>
            <TableRow data-testid='test-device-row'>
                {testSuiteDevicesColumns.map((column) => {
                    switch (column.id) {
                        case columnIds.status:
                            return (
                                <TestSuiteDetailsTableCell
                                    key={column.id}
                                    column={column}
                                    sx={{ px: { xs: 2, sm: 4 } }}
                                >
                                    <TestDeviceStatus results={results} />
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.devices:
                            return (
                                <TestSuiteDetailsTableCell
                                    key={column.id}
                                    column={column}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <TestDeviceName testDevice={testDevice} variant='link' size='small' />
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.duration:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    <Duration started={startDate} completed={endDate} />
                                </TestSuiteDetailsTableCell>
                            );
                        case columnIds.actions:
                            return (
                                <TestSuiteDetailsTableCell
                                    key={column.id}
                                    column={column}
                                    sx={{ display: 'flex', gap: 1 }}
                                >
                                    {parentTest && testSuiteType !== ETestSuiteAllocationType.divide ? (
                                        <IconButton
                                            disabled={!parentTest.logFile}
                                            title='Device Logs'
                                            size='small'
                                            onClick={() => expandContentToggle()}
                                        >
                                            <AodIcon sx={{ color: (theme) => theme.palette.tactile.blueGray[700] }} />
                                        </IconButton>
                                    ) : null}
                                    {showFollowSwitch && (
                                        <FormControlLabel
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                setFollowLogs(!followLogs);
                                            }}
                                            control={<Switch size='small' checked={followLogs} />}
                                            label='Follow'
                                        />
                                    )}
                                </TestSuiteDetailsTableCell>
                            );
                        default:
                            return (
                                <TestSuiteDetailsTableCell key={column.id} column={column}>
                                    -
                                </TestSuiteDetailsTableCell>
                            );
                    }
                })}
            </TableRow>
            <TableRow>
                <TableCell colSpan={8} sx={{ py: 0, px: 6 }}>
                    {expandedContent && parentTest && parentTest.logFile && (
                        <LogFile testId={parentTest._id} scrollFollow={followLogs} logFile={parentTest.logFile} />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
}
