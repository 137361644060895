import { ReactElement } from 'react';
import { Button, Grid } from '@mui/material';
import useOpenLink from '../../commons/useOpenLink';
import { ITest } from '../../tests/interfaces';
import { ITestDevice } from '../interfaces';

export default function TestDeviceActions({
    testDevice,
    runningTest,
}: {
    testDevice: ITestDevice;
    runningTest?: ITest;
}): ReactElement {
    const openLogTab = useOpenLink(`/test-devices/${testDevice.uuid}/log`);

    return (
        <Grid container justifyContent='flex-end' sx={{ mt: 2, mb: 3 }}>
            <Button
                disabled={!runningTest}
                variant='outlined'
                size='small'
                onClick={(e) => openLogTab(e.metaKey || e.ctrlKey)}
            >
                Log File
            </Button>
        </Grid>
    );
}
