import { ReactElement } from 'react';
import Moment from 'react-moment';
import { Box, TableCell } from '@mui/material';
import ClickableTableRow from '../../commons/ClickableTableRow';
import CopyToClipboardBox from '../../commons/CopyToClipboardBox';
import { IToggleColumn } from '../../commons/interfaces';
import PlatformIcon from '../../commons/PlatformIcon';
import TableTextCell from '../../commons/TableTextCell';
import TestAgentsLink from '../../testAgents/TestAgentsLink';
import TestNumberCell from '../../tests/row/TestNumberCell';
import TestSuiteCell from '../../testSuites/row/TestSuiteCell';
import { ETestDeviceState, ITestDevice } from '../interfaces';
import TestDeviceLink from '../TestDeviceLink';
import TestDeviceName from '../TestDeviceName';
import TestDeviceState from '../TestDeviceState';
import { useCheckBatteryIsLow, useCheckTemperatureIsHigh } from '../useCheckDeviceThresholds';
import BatteryLevel from './BatteryLevel';
import Temperature from './Temperature';
import TestDeviceActions from './TestDeviceActions';
import { TestDevicesColumnId } from './testDevicesColumns';

export default function TestDeviceRow({
    testDevice,
    columns,
    nestedRow,
}: {
    testDevice: ITestDevice;
    columns: Array<IToggleColumn<TestDevicesColumnId>>;
    nestedRow?: boolean;
}): ReactElement {
    const isOffline = testDevice.state === ETestDeviceState.offline;
    const isBatteryLow = useCheckBatteryIsLow(testDevice.deviceInfo.battery, isOffline);
    const isTemperatureHigh = useCheckTemperatureIsHigh(testDevice.deviceInfo.temperature, isOffline);
    const isDisabled = testDevice.isDisabled || isBatteryLow || isTemperatureHigh;

    return (
        <ClickableTableRow
            sx={{
                ...(nestedRow && {
                    backgroundColor: (theme) => theme.palette.tactile.gray[100],
                }),
                ...((isDisabled || testDevice.testAgent?.manuallyDisabled) && {
                    backgroundColor: (theme) => theme.palette.tactile.gray[300],
                    '& *': {
                        color: (theme) => theme.palette.tactile.gray[500],
                    },
                }),
            }}
            key={`/test-devices/${testDevice.uuid}`}
            redirectUrl={`/test-devices/${testDevice.uuid}`}
            data-testid='test-device-row'
        >
            {columns.map((column, index) => {
                const key = `${column.id}-${index}`;
                switch (column.id) {
                    case TestDevicesColumnId.state:
                        return (
                            <TableCell key={key} align={column.align}>
                                <TestDeviceState state={testDevice.state} />
                            </TableCell>
                        );

                    case TestDevicesColumnId.name:
                        return (
                            <TableCell key={key} align={column.align}>
                                <TestDeviceName testDevice={testDevice} variant='text' hideErrorIcons />
                            </TableCell>
                        );

                    case TestDevicesColumnId.uuid:
                        return (
                            <TableCell key={key} align={column.align}>
                                <TestDeviceLink uuid={testDevice.uuid} />
                            </TableCell>
                        );
                    case TestDevicesColumnId.gameDeviceId:
                        return (
                            <TableCell key={key} align={column.align}>
                                {testDevice.gameDeviceId && (
                                    <CopyToClipboardBox value={testDevice.gameDeviceId}>
                                        {testDevice.gameDeviceId}
                                    </CopyToClipboardBox>
                                )}
                            </TableCell>
                        );

                    case TestDevicesColumnId.platform:
                        return (
                            <TableCell key={key} align={column.align}>
                                <Box display='flex' alignItems='flex-end'>
                                    <PlatformIcon
                                        platform={testDevice.platform}
                                        disableDisplayBehavior
                                        sx={{ mr: 0.5, ml: 0 }}
                                    />
                                    {testDevice.deviceInfo.sdkversion}
                                </Box>
                            </TableCell>
                        );

                    case TestDevicesColumnId.battery:
                        return (
                            <TableCell key={key} align={column.align}>
                                <BatteryLevel
                                    battery={testDevice.deviceInfo.battery}
                                    isOffline={testDevice.state === ETestDeviceState.offline}
                                />
                            </TableCell>
                        );

                    case TestDevicesColumnId.temperature:
                        return (
                            <TableCell key={key} align={column.align}>
                                <Temperature
                                    temperature={testDevice.deviceInfo.temperature}
                                    isOffline={testDevice.state === ETestDeviceState.offline}
                                />
                            </TableCell>
                        );

                    case TestDevicesColumnId.queueLength:
                        return (
                            <TableTextCell key={key} align={column.align} text={String(testDevice.queueLength || '')} />
                        );

                    case TestDevicesColumnId.testRunning:
                        return (
                            <TestNumberCell
                                key={key}
                                align={column.align}
                                projectName={testDevice.testRunning?.projectName}
                                testNumber={testDevice.testRunning?.testNumber}
                            />
                        );
                    case TestDevicesColumnId.testSuiteRunning:
                        if (testDevice.testSuiteRunning) {
                            return <TestSuiteCell align={column.align} testSuite={testDevice.testSuiteRunning} />;
                        }
                        return <TableTextCell key={key} align={column.align} />;

                    case TestDevicesColumnId.lastSeen:
                        return (
                            <TableCell
                                key={key}
                                sx={{ padding: (theme) => theme.spacing(2, 1) }}
                                title={testDevice.lastSeen}
                                align={column.align}
                            >
                                <Moment fromNow interval={0}>
                                    {testDevice.lastSeen}
                                </Moment>
                            </TableCell>
                        );

                    case TestDevicesColumnId.testAgent:
                        return (
                            <TableCell key={key} align={column.align}>
                                {testDevice.testAgent && <TestAgentsLink title={testDevice.testAgent.hostname} />}
                            </TableCell>
                        );

                    case TestDevicesColumnId.actions:
                        return (
                            <TableCell key={key} align={column.align}>
                                <TestDeviceActions testDevice={testDevice} />
                            </TableCell>
                        );

                    case TestDevicesColumnId.dummy:
                        return <TableCell key={key} align={column.align} />;

                    default:
                        return <TableTextCell key={key} align={column.align} text={testDevice[column.id]} />;
                }
            })}
        </ClickableTableRow>
    );
}
