import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, Table, TableBody, TableCell, TableHead } from '@mui/material';
import { AppThunkDispatch } from '../../store';
import { ColumnToggle } from '../commons/ColumnToggle';
import { SliceStatus } from '../commons/enums';
import HeaderSection from '../commons/HeaderSection';
import { useRefreshPage } from '../commons/hooks';
import NoResultsFound from '../commons/NoResultsFound';
import Page from '../commons/Page';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import BuildAgentActionBox from './BuildAgentActionBox';
import BuildAgentDialog from './BuildAgentDialog';
import BuildAgentsAppliedFilters, { filters as buildAgentsFilters } from './BuildAgentsAppliedFilters';
import {
    getAvailableOptions,
    getBuildAgents,
    initializeAllFilters,
    selectAllBuildAgents,
    selectBuildAgentColumns,
    selectBuildAgentPageStatus,
} from './buildAgentsSlice';
import { BuildAgent, IBuildAgentSliceState } from './interfaces';
import AgentMaintenanceDialog from './maintenance/AgentMaintenanceDialog';
import BuildAgentLoadingRows from './table/BuildAgentLoadingRows';
import BuildAgentRow from './table/BuildAgentRow';

export const tableMinWidth = 1400;

export default function BuildAgents(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildAgentSliceState>>();
    const buildAgents = useSelector(selectAllBuildAgents);
    const status = useSelector(selectBuildAgentPageStatus);
    const buildAgentColumns = useSelector(selectBuildAgentColumns);

    useEffect(() => {
        dispatch(initializeAllFilters());
        dispatch(getAvailableOptions());
        dispatch(getBuildAgents());
    }, [dispatch]);

    useRefreshPage(getBuildAgents, 15000);

    return (
        <Page>
            <HeaderSection title='Build Agents' helmetTitle='Agents' actionBox={<BuildAgentActionBox />} />
            <Paper
                data-testid='build-agents'
                sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}
            >
                <Box
                    sx={{
                        display: { xs: 'block', sm: 'flex' },
                        justifyContent: 'space-between',
                        background: (theme) => theme.palette.grey[100],
                        pl: 1,
                    }}
                >
                    <BuildAgentsAppliedFilters />
                    <BuildAgentFilterBox />
                </Box>
                <TableContainer>
                    <Table stickyHeader aria-label='agents table' sx={{ minWidth: tableMinWidth }}>
                        <TableHead data-testid='build-agents-table-head'>
                            <TableHeaderRow sx={{ '.MuiTableCell-root:first-of-type': { px: 0 } }}>
                                <TableCell
                                    key='expand-column'
                                    align='left'
                                    size='small'
                                    style={{ width: '1%', minWidth: '48px' }}
                                />
                                {buildAgentColumns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        size='small'
                                        style={{
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            display: column.visible ? undefined : 'none',
                                        }}
                                    >
                                        {column.label}
                                        {buildAgentsFilters[column.id]?.({ showOnlyValues: false })}
                                    </TableCell>
                                ))}
                            </TableHeaderRow>
                        </TableHead>
                        <TableBody data-testid='build-agents-table-body'>
                            {status === SliceStatus.loading ? (
                                <BuildAgentLoadingRows rows={10} />
                            ) : (
                                buildAgents.map((buildAgent) => (
                                    <BuildAgentRow key={buildAgent._id} buildAgent={buildAgent as BuildAgent} />
                                ))
                            )}
                        </TableBody>
                    </Table>
                    {status === SliceStatus.loaded && !buildAgents.length && (
                        <NoResultsFound text="Your search didn't return build agents!" />
                    )}
                </TableContainer>
                <AgentMaintenanceDialog />
                <BuildAgentDialog />
            </Paper>
        </Page>
    );
}

function BuildAgentFilterBox(): ReactElement {
    const buildAgentColumns = useSelector(selectBuildAgentColumns);

    return (
        <Box sx={{ minWidth: 'fit-content', mb: 3, pr: { xs: 2.5, sm: 4 }, pl: 2 }}>
            <ColumnToggle columns={buildAgentColumns} mode='button' target='agents' />
        </Box>
    );
}
