import { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import ContentLoading from '../../commons/ContentLoading';
import NoContent from '../../commons/NoContent';
import { openSocket } from '../../sockets';
import LogFile from '../../tests/details/LogFile';
import { ETestStatus, ITest } from '../../tests/interfaces';
import { useGetTestsQuery } from '../../tests/testsApi';
import TestDeviceBreadcrumbs from '../TestDeviceBreadcrumbs';
import { useGetTestDeviceQuery } from '../testDevicesApi';

const runningTestStatus = [ETestStatus.running];

export default function TestDeviceLog(): ReactElement {
    const { uuid } = useParams<{ uuid: string }>();
    const { data: testDevice } = useGetTestDeviceQuery(uuid);
    const { data: testsData, isFetching } = useGetTestsQuery(
        { status: runningTestStatus, testDevice: testDevice?._id },
        { skip: !testDevice },
    );

    const [runningTest, setRunningTest] = useState<ITest | undefined>(testsData?.tests[0]);
    const isLoading = isFetching && !testDevice;

    useEffect(() => {
        setRunningTest(testsData?.tests[0]);
    }, [testsData]);

    useEffect(() => {
        const handleTestUpdate = ({ test }: { test: ITest }) => {
            if (test.testDevice?._id === testDevice?._id) {
                setRunningTest(test.status === ETestStatus.running ? test : undefined);
            }
        };

        const socket = openSocket('tests');
        socket.on('update-test', handleTestUpdate);

        return () => {
            socket.off('update-test', handleTestUpdate);
        };
    }, [testDevice]);

    return (
        <>
            <Helmet title={testDevice?.name} />
            <Container maxWidth={false} data-testid='test-device-log'>
                <TestDeviceBreadcrumbs testDevice={testDevice} tab='log' />

                {isLoading && <ContentLoading />}

                {runningTest?.logFile && (
                    <>
                        <Typography variant='h6'>{runningTest.logFile}</Typography>
                        <LogFile testId={runningTest._id} logFile={runningTest.logFile} scrollFollow maxHeight='85vh' />
                    </>
                )}

                {!runningTest?.logFile && <NoContent text='Log file is not available yet' />}
            </Container>
        </>
    );
}
