export function compareRevisions(revisionA?: string, revisionB?: string): number {
    if (!revisionA || !revisionB) {
        return 0;
    }

    const numRevisionA = Number(revisionA);
    const numRevisionB = Number(revisionB);

    return numRevisionA - numRevisionB;
}
