import { ReactElement } from 'react';
import { TextField, useMediaQuery } from '@mui/material';

interface DiskSpaceRequirementInputProps {
    spaceRequirement: number;
    onChange: (newSpaceRequirement?: number) => void;
}

export default function DiskSpaceRequirementInput({
    spaceRequirement,
    onChange,
}: DiskSpaceRequirementInputProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');

    return (
        <TextField
            id='disk-space-input'
            label='Required disk space (Gb)'
            variant='outlined'
            type='number'
            value={spaceRequirement}
            onChange={(e) => onChange(parseInt(e.target.value) || 0)}
            sx={{ minWidth: isMobile ? '0px' : '350px' }}
        />
    );
}
