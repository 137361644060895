import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { isSemanticOrXBranch } from '../../utils';
import { ITestProject } from '../tests/interfaces';
import { getBranchName, useGetTestProjectsQuery } from '../tests/testsApi';
import { selectNewBuildBranch, selectNewBuildProject, setRunTests } from './newBuildSlice';

export default function SelectTestOptions(): ReactElement | null {
    const dispatch = useDispatch();
    const { selected: projectName } = useSelector(selectNewBuildProject);
    const { selected: buildBranch } = useSelector(selectNewBuildBranch);
    const { data: projects } = useGetTestProjectsQuery(undefined, { refetchOnMountOrArgChange: false });
    const [runTests, toggleRunTests] = useState(false);
    const [showAirtestAutomaticSelection, setShowAirtestAutomaticSelection] = useState(false);

    const project: ITestProject | undefined = useMemo(() => {
        const selectedProject = projectName?.toLowerCase();
        return projects?.find((project) => project.projectName?.toLowerCase() === selectedProject);
    }, [projectName, projects]);

    const handleRunTests = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        toggleRunTests(checked);
        dispatch(setRunTests(checked));
        setShowAirtestAutomaticSelection(false);
    };

    useEffect(() => {
        if (isSemanticOrXBranch(buildBranch)) {
            setShowAirtestAutomaticSelection(true);
        } else {
            setShowAirtestAutomaticSelection(false);
        }
    }, [buildBranch, dispatch]);

    if (!projectName || !project) {
        return null;
    }

    return (
        <>
            <Typography variant='h5' gutterBottom>
                Test Options
            </Typography>
            <Box>
                <FormControl style={{ margin: '8px 16px' }}>
                    <FormControlLabel
                        control={<Checkbox checked={runTests} onChange={handleRunTests} />}
                        label={
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>Run AirTests</Typography>
                                {runTests && showAirtestAutomaticSelection && (
                                    <Alert
                                        severity='info'
                                        title='Selected branch is either a release branch or .x'
                                        style={{ marginLeft: '8px', padding: '0 8px' }}
                                    >
                                        Automatically selected
                                    </Alert>
                                )}
                            </Box>
                        }
                    />
                    <FormHelperText margin='dense'>
                        This will run all tests with {project.defaultTestTag} tag from{' '}
                        {getBranchName(project, buildBranch)} branch. You can run a custom test on the build details
                        view.
                    </FormHelperText>
                </FormControl>
            </Box>
        </>
    );
}
