import Cookies from 'js-cookie';
import { IToggleColumn } from './interfaces';

export function getInitialColumnVisibilityState(
    cookieName: string,
    defaultInitialState: Record<string, boolean>,
): Record<string, boolean> {
    const cookiesState = JSON.parse(Cookies.get(cookieName) || '{}');
    return { ...defaultInitialState, ...cookiesState };
}

export function persistColumnVisibleStateInCookie(cookieName: string, columns: Array<IToggleColumn<string>>): void {
    const visibleState = getColumnVisibleState(columns);
    Cookies.set(cookieName, JSON.stringify(visibleState));
}

function getColumnVisibleState(columns: Array<IToggleColumn<string>>): Record<string, boolean> {
    return columns.reduce((columnsState: Record<string, boolean>, column) => {
        columnsState[column.id] = !!column.visible;
        return columnsState;
    }, {});
}
