import { ReactElement, useEffect, useState } from 'react';
import ConfirmIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, Box, IconButton, TextField, Typography } from '@mui/material';
import { ISlackRecipient } from '../subscriptions/interfaces';
import { useGetSlackRecipientsQuery } from '../subscriptions/subscriptionsApi';

interface SlackIdSelectorProps {
    slackId?: string;
    email: string;
    onChange: (slackId?: string) => void;
}

export default function SlackIdSelector({ slackId, email, onChange }: SlackIdSelectorProps): ReactElement | null {
    const [editMode, setEditMode] = useState<boolean>();
    const [slackRecipient, setSlackRecipient] = useState<ISlackRecipient | null>(null);
    const { data: slackRecipients, isFetching } = useGetSlackRecipientsQuery();

    useEffect(() => {
        const resolvedSlackRecipient =
            slackRecipients?.find((item) => item.id === slackId) ||
            slackRecipients?.find((item) => item.email === email) ||
            null;
        setSlackRecipient(resolvedSlackRecipient);
    }, [email, slackId, slackRecipients]);

    const editSlackId = () => {
        setEditMode(true);
    };

    const confirmSlackId = () => {
        onChange(slackRecipient?.id);
        setEditMode(false);
    };

    if (isFetching && !slackId) {
        return null;
    }

    return (
        <Box display='flex' alignItems='center'>
            <Autocomplete
                options={slackRecipients || []}
                getOptionLabel={(option) => option.email || option.name}
                renderOption={(props, option) => {
                    const optionText = option.email ? `${option.name} (${option.email})` : option.name;
                    return (
                        <Box component='li' {...props} title={optionText}>
                            <Typography noWrap>{optionText}</Typography>
                        </Box>
                    );
                }}
                autoHighlight
                clearOnBlur
                value={slackRecipient}
                onChange={(_event: any, newValue: ISlackRecipient | null) => setSlackRecipient(newValue)}
                renderInput={(params) => <TextField {...params} variant='outlined' label='Slack ID' />}
                sx={{ minWidth: { xs: '260px', sm: '450px' } }}
                disabled={!editMode}
            />
            {editMode ? (
                <>
                    <IconButton size='small' color='secondary' onClick={() => confirmSlackId()} sx={{ ml: 1 }}>
                        <ConfirmIcon />
                    </IconButton>
                    <IconButton size='small' color='secondary' onClick={() => setEditMode(false)} sx={{ ml: 1 }}>
                        <CancelIcon />
                    </IconButton>
                </>
            ) : (
                <IconButton size='small' color='secondary' onClick={() => editSlackId()} sx={{ ml: 1 }}>
                    <EditIcon fontSize='small' />
                </IconButton>
            )}
        </Box>
    );
}
