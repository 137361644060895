export function makeNotification(title: string, options?: NotificationOptions, onClickUrl?: string): void {
    const defaultOptions: NotificationOptions = {
        badge: `${process.env.PUBLIC_URL}/tactile-t.png`,
        icon: `${process.env.PUBLIC_URL}/tactile-t.png`,
    };

    const notification = new Notification(title, { ...defaultOptions, ...options });
    if (onClickUrl) {
        notification.onclick = () => window.open(`${window.location.origin}/${onClickUrl}`);
    }
}
