import { ReactElement, useMemo } from 'react';
import ColumnFilterSelector from '../../commons/filters/ColumnFilterSelector';
import SelectedFilters from '../../commons/filters/SelectedFilters';
import { parseFilterOptions } from '../../commons/filters/utils';
import { useGetAvailableOptionsQuery } from '../testSuitesApi';
import { ITestSuitesFilterProps } from './TestSuitesFilters';

export default function TestSuiteNumberSelector({
    filters,
    onFiltersChange,
    showOnlyValues,
}: ITestSuitesFilterProps): ReactElement {
    const selected = filters.testSuiteNumber;
    const { data, isFetching: loading } = useGetAvailableOptionsQuery();

    const sortedOptions = useMemo(() => (data?.options.testSuiteNumber || [1]).slice().sort((a, b) => b - a), [data]);
    const parsedOptions = parseFilterOptions(sortedOptions || []);

    const handleFiltersChange = (newValue: string[] | null) => {
        onFiltersChange({
            ...filters,
            testSuiteNumber: newValue || undefined,
        });
    };

    if (showOnlyValues) {
        return (
            <SelectedFilters
                name='Test ID'
                selected={selected}
                options={parsedOptions}
                onDelete={(newValue) => handleFiltersChange(newValue)}
            />
        );
    }

    return (
        <ColumnFilterSelector
            id='testNumber-selector'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={(newValue) => handleFiltersChange(newValue)}
        />
    );
}
