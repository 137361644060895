import { ReactElement } from 'react';
import { Box } from '@mui/material';
import DatadogLink, { buildLogsUrl } from '../../commons/DatadogLink';
import CancelTestAction from '../../tests/details/CancelTestAction';
import { runningTestStatus } from '../../tests/interfaces';
import { ITestDevice } from '../interfaces';

export default function TestDeviceActions({ testDevice }: { testDevice: ITestDevice }): ReactElement {
    return (
        <Box display='flex' justifyContent='center'>
            {testDevice.testRunning && runningTestStatus.includes(testDevice.testRunning.status) && (
                <CancelTestAction test={testDevice.testRunning} />
            )}
            {testDevice.gameDeviceId && (
                <DatadogLink
                    url={buildLogsUrl({ '@device.uid': testDevice.gameDeviceId }, ['host', 'service', '@url'])}
                />
            )}
        </Box>
    );
}
