import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountTree as ChildBuildsIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TableRow,
    Table,
    TableBody,
    TableCell,
    Box,
} from '@mui/material';
import BuildNumber from '../builds/BuildNumber';
import BuildStarted from '../builds/BuildStarted';
import BuildStatus from '../builds/BuildStatus';
import Duration from '../commons/Duration';
import { IBuild } from '../commons/interfaces';
import { preventAndStopClick } from './buildDetailsSlice';

export default function ChildBuilds({ builds }: { builds: IBuild[] }): ReactElement {
    return (
        <Box
            sx={{
                width: '100%',
                padding: 1,
                borderRadius: (theme) => theme.shape.borderRadius,
            }}
        >
            <Accordion elevation={0} sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
                <AccordionSummary
                    sx={{
                        '& > .MuiAccordionSummary-content': {
                            margin: '4px 0',
                        },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box sx={{ color: (theme) => theme.palette.text.secondary, p: 1, pl: 0 }}>
                        <ChildBuildsIcon />
                    </Box>
                    <Box>
                        <Typography variant='body2'>Related builds</Typography>
                        <Typography variant='caption' color='textSecondary'>
                            {builds.length} child build{builds.length > 1 ? 's' : ''}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, overflowX: 'auto' }} onClick={preventAndStopClick}>
                    <Table>
                        <TableBody
                            sx={{
                                borderTop: '1px solid',
                                borderColor: (theme) => theme.palette.grey[200],
                                '& > * > td': {
                                    padding: 1,
                                },
                            }}
                        >
                            {builds.map((build) => (
                                <ChildBuildRow key={build._id} build={build} />
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

function ChildBuildRow({ build }: { build: IBuild }): ReactElement {
    const history = useHistory();

    const redirectToBuild = (openNewTab: boolean) => {
        const buildUrl = `/builds/${build.projectName}/${build.buildNumber}`;
        if (openNewTab) {
            window.open(buildUrl, '_blank');
        } else {
            history.push(buildUrl);
        }
    };

    return (
        <TableRow
            sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                '&:hover': {
                    backgroundColor: (theme) => theme.palette.grey[100],
                },
            }}
            onClick={(e) => redirectToBuild(e.metaKey || e.ctrlKey)}
            data-testid='child-build-row'
        >
            <BuildStatus build={build} />
            <BuildNumber projectName={build.projectName} buildNumber={build.buildNumber} />
            <TableCell style={{ width: '70%' }}>{build.description || build.projectName}</TableCell>
            <BuildStarted started={build.started} />
            <TableCell>
                <Duration started={build.started} completed={build.completed} />
            </TableCell>
        </TableRow>
    );
}
