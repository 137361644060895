import { IToggleColumn } from '../commons/interfaces';

export enum ReleaseNotesColumnId {
    projectName = 'projectName',
    title = 'title',
    message = 'message',
    languages = 'languages',
    actions = 'actions',
}

export const columns: Array<IToggleColumn<ReleaseNotesColumnId>> = [
    { id: ReleaseNotesColumnId.projectName, label: 'Project Name', align: 'left', width: '15%', visible: true },
    { id: ReleaseNotesColumnId.title, label: 'Title', align: 'left', width: '15%', visible: true },
    { id: ReleaseNotesColumnId.message, label: 'Message', align: 'left', width: '35%', visible: true },
    { id: ReleaseNotesColumnId.languages, label: 'Languages', align: 'left', width: '20%', visible: true },
    { id: ReleaseNotesColumnId.actions, label: 'Actions', align: 'center', width: '15%', visible: true },
];
