import { ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Autocomplete, TextField, Typography, Checkbox, Chip } from '@tactileentertainment/core-designsystem';
import { IGameModulesFilterOptions } from './interfaces';

interface ModuleAttributesFilterProps {
    onFilterChange: (value: Partial<IGameModulesFilterOptions>) => void;
    filters: Partial<IGameModulesFilterOptions>;
}

interface FilterOption {
    name: string;
    description: string;
    key: keyof Partial<IGameModulesFilterOptions>;
}

const options: FilterOption[] = [
    { name: 'Applicable', description: 'Modules relevant to the project', key: 'hideModules' },
    { name: 'Branches only', description: 'Only display branches', key: 'branchesOnly' },
    { name: 'Outdated only', description: 'Only display out of date modules', key: 'showOnlyNonUpToDate' },
    { name: 'Primary', description: 'Important modules or packages', key: 'primary' },
    { name: 'Deprecated', description: 'Only display deprecated modules', key: 'deprecated' },
];

export default function ModuleAttributesFilter({ filters, onFilterChange }: ModuleAttributesFilterProps): ReactElement {
    const [selected, setSelected] = useState<FilterOption[]>(options.filter((opt) => filters[opt.key]));

    useEffect(() => {
        const newFilters: Partial<IGameModulesFilterOptions> = {};
        options.forEach((itm) => {
            (newFilters as any)[itm.key] = selected.includes(itm);
        });
        onFilterChange({ ...newFilters });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return (
        <Autocomplete
            size='small'
            data-testid='module-attribuites-filter'
            options={options}
            value={selected}
            multiple
            disableCloseOnSelect
            renderInput={(params) => <TextField {...params} size='small' placeholder={'Filters'} />}
            getOptionLabel={(option) => option.description}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.key} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Checkbox sx={{ alignSelf: 'flex-start' }} checked={selected} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                        <Typography sx={{ fontFamily: 'Inter' }}>{option.name}</Typography>
                        <Typography fontFamily='Inter' color='light'>
                            {option.description}
                        </Typography>
                    </Box>
                </li>
            )}
            renderTags={(values) =>
                values.map((value) => (
                    <Chip key={value.name} size='small' label={value.name || ''} sx={{ ml: 1 }}></Chip>
                ))
            }
            onChange={(_, values: FilterOption[]) => {
                setSelected(values);
            }}
            sx={{
                minWidth: { xs: '100%', md: 320 },
                maxWidth: { xs: '100%', md: 550 },
                width: { xs: '100%', md: 'auto' },
                mt: { xs: 2, md: 0 },
                ml: { xs: 0, md: 2 },
                display: 'flex',
                alignItems: 'center',
                '.MuiAutocomplete-inputRoot': {
                    '.MuiAutocomplete-input': { minWidth: 90 },
                },
            }}
        />
    );
}
