import { ReactElement, useState } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Divider, TableCell, Typography } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { MuiAlignSetting } from '../../typings/material-ui';
import { DataValueBox, IDataValueBoxProps } from '../commons/DataValueBox';
import ExtraInfoPopover from '../commons/ExtraInfoPopover';
import { IScheduleBuild } from './interfaces';

interface MessagesCellProps {
    scheduledBuild: IScheduleBuild;
    align?: MuiAlignSetting;
}

export default function MessagesCell({ scheduledBuild, align }: MessagesCellProps): ReactElement {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorElement);

    const { settings = {} } = scheduledBuild?.buildConfigurations?.[0] || {};
    const settingsKeys = Object.keys(settings);

    return (
        <TableCell
            align={align}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            onMouseEnter={(event) => setAnchorElement(event.currentTarget)}
            onMouseLeave={() => setAnchorElement(null)}
        >
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <InfoIcon sx={{ cursor: 'pointer', color: blueGray[800] }} />
            </Box>

            <ExtraInfoPopover
                id='mouse-over-popover'
                open={open}
                anchorEl={anchorElement}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorElement(null)}
                disableRestoreFocus
                disableScrollLock
                sx={{
                    minWidth: 350,
                    maxWidth: 460,
                }}
            >
                <Typography variant='subtitle2' sx={{ fontWeight: 600 }}>
                    Build Details
                </Typography>
                <DataValueBoxWrapper label='Game Name' value={scheduledBuild.project.name} sx={{ mt: 1 }} />
                <DataValueBoxWrapper label='Branch' value={scheduledBuild.branch} sx={{ mt: 1 }} />
                <Divider sx={{ mt: 1 }} />
                <Typography variant='subtitle2' sx={{ fontWeight: 600, mt: 1 }}>
                    Flags
                </Typography>
                {settingsKeys.length ? (
                    settingsKeys.map((key, index) => (
                        <DataValueBoxWrapper key={index} label={key} value={settings[key].toString()} sx={{ mt: 1 }} />
                    ))
                ) : (
                    <Typography variant='body2' sx={{ mt: 1, fontWeight: 500 }}>
                        No flags defined
                    </Typography>
                )}
            </ExtraInfoPopover>
        </TableCell>
    );
}

function DataValueBoxWrapper(props: IDataValueBoxProps): ReactElement | null {
    return <DataValueBox {...props} valueSx={{ ...props.valueSx, fontWeight: 700 }} />;
}
