import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, capitalize, CircularProgress, debounce, Divider, Skeleton, TableCell, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import { getRequest } from '../../api';
import { AppThunkDispatch } from '../../store';
import { MuiAlignSetting } from '../../typings/material-ui';
import { getBuildResult, selectBuildDetailsResult, selectBuildDetailsStatus } from '../buildDetails/buildDetailsSlice';
import { StepState } from '../buildDetails/enums';
import BuildStatusChip from '../commons/BuildStatusChip';
import { DataValueBox, IDataValueBoxProps } from '../commons/DataValueBox';
import { EBuildStatus, SliceStatus } from '../commons/enums';
import ExtraInfoPopover from '../commons/ExtraInfoPopover';
import { IBuildRow } from './interfaces';
import { parsePropertyToLabel } from './parsePropertyToLabel';

const DEFAULT_EXPIRATION_DAYS = 30;

export default function BuildStatus({ build, align }: { build: IBuildRow; align?: MuiAlignSetting }): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const [shareBuildUrl, setShareBuildUrl] = useState<string>();
    const [isFetchingUrl, setIsFetchingUrl] = useState<boolean>(false);
    const buildResult = useSelector(selectBuildDetailsResult);
    const status = useSelector(selectBuildDetailsStatus);
    const open = Boolean(anchorElement);

    const hasSettings = Object.keys(build?.settings || {}).length > 0;
    const hasRequirements = Object.keys(build?.requirements || {}).length > 0;

    const isBuildRunning = build.status === EBuildStatus.running;
    const isCurrentBuild = buildResult?.build === build._id;
    const currentStep =
        isBuildRunning && isCurrentBuild
            ? buildResult?.steps?.find((step) => step.state === StepState.running)
            : undefined;

    const handleOnMouseOver = useCallback(() => {
        const getSharedLink = () => {
            if (build?.status === EBuildStatus.finished && build?.mainArtifact && !shareBuildUrl && !isFetchingUrl) {
                setIsFetchingUrl(true);
                getRequest(`/builds/${build._id}/share-link`, {
                    expirationDays: DEFAULT_EXPIRATION_DAYS.toString(),
                }).then((response) => {
                    setIsFetchingUrl(false);
                    setShareBuildUrl(response.shareLink);
                });
            }
        };

        getSharedLink();

        if (build.status === EBuildStatus.running && status !== SliceStatus.loading) {
            dispatch(getBuildResult({ projectName: build.projectName, buildNumber: build.buildNumber }));
        }
    }, [build, dispatch, isFetchingUrl, shareBuildUrl, status]);

    const debouncedHandleOnMouseOver = useMemo(() => debounce(handleOnMouseOver), [handleOnMouseOver]);

    return (
        <TableCell
            sx={{ padding: 1 }}
            align={align}
            data-testid='builds-table-build-status'
            aria-owns={open ? 'mouse-over-popover' : undefined}
            onMouseOver={(event) => {
                setAnchorElement(event.currentTarget);
                debouncedHandleOnMouseOver();
            }}
            onMouseLeave={() => setAnchorElement(null)}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', pointerEvents: 'none' }}>
                <BuildStatusChip status={build.status} />
                {build && (
                    <ExtraInfoPopover
                        id='mouse-over-popover'
                        open={open}
                        anchorEl={anchorElement}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        onClose={() => setAnchorElement(null)}
                        disableRestoreFocus
                        disableScrollLock
                        sx={{ minWidth: 250 }}
                    >
                        <DataValueBoxWrapper label='Status' value={<BuildStatusChip status={build.status} />} />
                        {(currentStep || status === SliceStatus.loading) && (
                            <DataValueBoxWrapper
                                label='Step'
                                value={currentStep?.name || <Skeleton variant='text' width={150} />}
                                sx={{ mt: 1 }}
                                valueSx={{ maxWidth: 150, wordBreak: 'break-word' }}
                            />
                        )}
                        {hasSettings && (
                            <>
                                <Typography variant='subtitle2' sx={{ fontWeight: 600, mt: 1 }}>
                                    Settings
                                </Typography>
                                {Object.keys(build.settings).map((property) => (
                                    <DataValueBoxWrapper
                                        key={property}
                                        label={parsePropertyToLabel(property)}
                                        value={capitalize(build.settings?.[property]?.toString() || '')}
                                        sx={{ mt: 1 }}
                                        valueSx={{ maxWidth: 300, wordBreak: 'break-word' }}
                                    />
                                ))}
                            </>
                        )}
                        {hasRequirements && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                <Typography variant='subtitle2' sx={{ fontWeight: 600, mt: hasSettings ? 1 : 0 }}>
                                    Requirements
                                </Typography>
                                {Object.keys(build.requirements).map((property) => (
                                    <DataValueBoxWrapper
                                        key={property}
                                        label={parsePropertyToLabel(property)}
                                        value={build.requirements?.[property] || ''}
                                        sx={{ mt: 1, maxWidth: 200 }}
                                    />
                                ))}
                            </>
                        )}
                        {(shareBuildUrl || isFetchingUrl) && (
                            <>
                                {hasRequirements && <Divider sx={{ mt: 1 }} />}
                                <Typography variant='subtitle2' sx={{ fontWeight: 600, mt: 1, mb: 1 }}>
                                    Download
                                </Typography>
                                {isFetchingUrl && (
                                    <Box display='flex' justifyContent='center' style={{ margin: '8px 0px' }}>
                                        <CircularProgress variant='indeterminate' size={20} />
                                    </Box>
                                )}
                                {shareBuildUrl && <QRCode size={230} value={shareBuildUrl} />}
                            </>
                        )}
                    </ExtraInfoPopover>
                )}
            </Box>
        </TableCell>
    );
}

function DataValueBoxWrapper(props: IDataValueBoxProps): ReactElement | null {
    return <DataValueBox {...props} valueSx={{ ...props.valueSx, fontWeight: 700 }} />;
}
