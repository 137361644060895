import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import * as queryString from '../commons/queryString';
import {
    ITestSuiteDetails,
    ITestSuiteFilter,
    ITestSuiteFilterOptions,
    ITestSuiteListItem,
    ITestSuitePayload,
} from './interfaces';

const PAGE_SIZE: number = Number(process.env.REACT_APP_BUILDS_PAGE_SIZE || 50);

export const testSuitesApi = createApi({
    reducerPath: 'testSuitesApi',
    baseQuery: apiBaseQuery(),
    tagTypes: ['TestSuites'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getTestSuites: builder.query<
            { testSuites: ITestSuiteListItem[]; totalCount: number },
            ITestSuiteFilter | undefined
        >({
            query: (filter?: ITestSuiteFilter) => {
                const { page, ...params } = filter || {};
                if (page) {
                    params.skip = PAGE_SIZE * Number(page);
                }
                return {
                    url: `test-suites?${queryString.stringify(params)}`,
                };
            },
            providesTags: ['TestSuites'],
        }),
        getTestSuiteDetails: builder.query<ITestSuiteDetails, { testSuiteNumber: number }>({
            query: (filter: { testSuiteNumber: number }) => ({
                url: `test-suites/${filter.testSuiteNumber}`,
            }),
            transformResponse: (response: { testSuite: ITestSuiteDetails }): ITestSuiteDetails => {
                response.testSuite.tests.sort((a, b) => {
                    if (!a.startedAt) {
                        return 1;
                    }
                    if (!b.startedAt) {
                        return 1;
                    }
                    return new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime();
                });

                return response.testSuite;
            },
            providesTags: ['TestSuites'],
        }),
        getAvailableOptions: builder.query<{ options: ITestSuiteFilterOptions }, void>({
            query: () => ({
                url: 'test-suites/available-options',
                params: {
                    fields: [
                        'author',
                        'testSuiteNumber',
                        'devices',
                        'testBranch',
                        'build[projectName]',
                        'build[versionName]',
                        'build[branch]',
                        'build[platform]',
                        'build[type]',
                    ],
                },
            }),
            providesTags: ['TestSuites'],
        }),
        createTestSuite: builder.mutation<void, ITestSuitePayload>({
            query: (payload) => ({
                method: 'POST',
                url: `test-suites`,
                body: payload,
            }),
            invalidatesTags: ['TestSuites'],
        }),
        cancelTestSuite: builder.mutation<void, number>({
            query: (testSuiteNumber) => ({
                method: 'POST',
                url: `test-suites/${testSuiteNumber}/cancel`,
            }),
            invalidatesTags: ['TestSuites'],
        }),
    }),
});

export const {
    useGetTestSuitesQuery,
    useGetTestSuiteDetailsQuery,
    useGetAvailableOptionsQuery,
    useCreateTestSuiteMutation,
    useCancelTestSuiteMutation,
} = testSuitesApi;
