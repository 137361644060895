import React, { ReactElement, useState } from 'react';
import { NotInterested, SyncDisabled, Explicit as ExplicitIcon } from '@mui/icons-material';
import { Box, TableCell, Popover, Tooltip, Typography } from '@mui/material';
import getBuildAgentName from '../../commons/getBuildAgentName';
import { BuildAgent } from '../interfaces';

export default function BuildAgentName({ buildAgent }: { buildAgent: BuildAgent }): ReactElement {
    const name = getBuildAgentName(buildAgent);

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

    const open = Boolean(anchorElement);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorElement(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorElement(null);
    };

    return (
        <TableCell
            sx={{ padding: (theme) => theme.spacing(2, 1) }}
            aria-owns={open ? 'mouse-over-build-agent-row' : undefined}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {name}
                {buildAgent.reserved && (
                    <>
                        <ExplicitIcon sx={{ verticalAlign: 'sub' }} />
                        <Popover
                            id='mouse-over-build-agent-row'
                            sx={{
                                pointerEvents: 'none',
                                '.MuiPaper-root': {
                                    padding: 1,
                                },
                            }}
                            open={open}
                            anchorEl={anchorElement}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            disableScrollLock
                        >
                            Reserved
                        </Popover>
                    </>
                )}
                {!buildAgent.enabled && <DisabledAgentIcon />}
                {buildAgent.deploying && <DeployingAgentIcon />}
            </Box>
        </TableCell>
    );
}

function DeployingAgentIcon(): ReactElement {
    return (
        <Tooltip
            sx={{ ml: 2 }}
            title={
                <Typography variant='subtitle2'>
                    This build agent does not accept new builds. It could be that is being deployed. It will finish any
                    running builds but will not accept new ones
                </Typography>
            }
        >
            <SyncDisabled sx={{ verticalAlign: 'middle', marginLeft: '2px' }} />
        </Tooltip>
    );
}

function DisabledAgentIcon(): ReactElement {
    return (
        <Tooltip
            sx={{ ml: 2 }}
            title={
                <Typography variant='subtitle2'>
                    This build agent has been disabled. It will finish any running builds but will not accept new ones
                </Typography>
            }
        >
            <NotInterested sx={{ verticalAlign: 'middle', marginLeft: '2px' }} />
        </Tooltip>
    );
}
