import {
    AddBoxOutlined as AddIcon,
    BuildOutlined as BuildsIcon,
    StorageOutlined as AgentsIcon,
    BugReport as TestsIcon,
    ExtensionOutlined as ModulesIcon,
    SourceOutlined as ProjectsIcon,
} from '@mui/icons-material';
import { IAppBarMenuItem } from './interfaces';

export const newBuildMenu: IAppBarMenuItem = {
    to: '/new-build',
    text: 'Order New',
    mobileOnly: true,
    icon: <AddIcon />,
};

export const buildMenu: IAppBarMenuItem = { to: '/builds', text: 'Builds', icon: <BuildsIcon /> };
export const buildAgentsMenu: IAppBarMenuItem = { to: '/agents', text: 'Agents', icon: <AgentsIcon /> };
export const testsMenu: IAppBarMenuItem = {
    to: '/test-suites',
    text: 'Tests',
    icon: <TestsIcon />,
    options: [
        {
            text: 'Test Devices',
            to: '/test-devices',
        },
        {
            text: 'Test Agents',
            to: '/test-agents',
        },
    ],
};
export const modulesMenu: IAppBarMenuItem = { to: '/modules', text: 'Modules', icon: <ModulesIcon /> };
export const projectsMenu: IAppBarMenuItem = {
    to: '/projects',
    text: 'Projects',
    icon: <ProjectsIcon />,
    options: [
        {
            text: 'Scheduled Builds',
            to: '/scheduled-builds',
        },
        {
            text: 'Unity Accelerators',
            to: '/unity-accelerators',
        },
        {
            text: 'Release Notes',
            to: '/projects/release-notes',
        },
    ],
};

export const menuItems: IAppBarMenuItem[] = [buildMenu, buildAgentsMenu, testsMenu, modulesMenu, projectsMenu];

export function getInitialMenuItem(): { to: string; text: string } | undefined {
    let currentSelectedMenu = undefined;
    const pathname = window.location.pathname;

    menuItems.forEach((item) => {
        const option = item.options?.find((option) => pathname.startsWith(option.to));
        if (option) {
            currentSelectedMenu = { to: option.to, text: option.text };
        } else if (item.to.includes(pathname)) {
            currentSelectedMenu = { to: item.to, text: item.text };
        }
    });

    return currentSelectedMenu;
}
