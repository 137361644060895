import { ReactElement } from 'react';
import { CallSplitOutlined, DynamicFeedOutlined, GroupOutlined } from '@mui/icons-material';
import { ETestSuiteAllocationType } from '../interfaces';

export default function TestSuiteTypeIcon({ type }: { type?: ETestSuiteAllocationType }): ReactElement {
    switch (type) {
        case ETestSuiteAllocationType.divide:
            return <CallSplitOutlined sx={{ color: ({ palette }) => palette.tactile.green[500] }} />;
        case ETestSuiteAllocationType.replicate:
            return <GroupOutlined sx={{ color: ({ palette }) => palette.tactile.orange[500] }} />;
        case ETestSuiteAllocationType.sequential:
        default:
            return <DynamicFeedOutlined />;
    }
}
