export enum EBuildStatus {
    queued = 'queued',
    running = 'running',
    cancelled = 'cancelled',
    failed = 'failed',
    finished = 'finished',
}

export enum BuildDialogActionType {
    orderTest = 'orderTest',
    reorder = 'reorder',
    cancel = 'cancel',
}

export enum SliceStatus {
    idle = 'idle',
    loading = 'loading',
    loaded = 'loaded',
}
