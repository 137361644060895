import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import * as queryString from '../commons/queryString';
import { IReleaseNote } from './interfaces';

export const releaseNotesApi = createApi({
    reducerPath: 'releaseNotesApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['ReleaseNotes'],
    endpoints: (builder) => ({
        getReleaseNotes: builder.query<IReleaseNote[], { projectName: string[] }>({
            query: ({ projectName }) => ({
                url: `/release-notes?${queryString.stringify({ projectName: projectName.length ? [...projectName, 'all'] : [] })}`,
            }),
            providesTags: ['ReleaseNotes'],
        }),
        createReleaseNotes: builder.mutation<void, IReleaseNote>({
            query: (releaseNotes: IReleaseNote) => ({
                method: 'POST',
                url: `/release-notes`,
                body: releaseNotes,
            }),
            invalidatesTags: ['ReleaseNotes'],
        }),
        deleteReleaseNotes: builder.mutation<void, IReleaseNote>({
            query: (releaseNotes: IReleaseNote) => ({
                method: 'DELETE',
                url: `/release-notes`,
                body: {
                    _id: releaseNotes._id,
                },
            }),
            invalidatesTags: ['ReleaseNotes'],
        }),
    }),
});

export const { useGetReleaseNotesQuery, useCreateReleaseNotesMutation, useDeleteReleaseNotesMutation } =
    releaseNotesApi;
