import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Alert,
    Button,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Typography,
} from '@mui/material';
import { successToast } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { setRunningStepAsCancelled } from '../buildDetails/buildDetailsSlice';
import { BuildInfoTable } from '../buildDetails/BuildInfoTable';
import BuildSuccessToastContent from '../newBuild/BuildSuccessToastContent';
import { useGetCurrentUserQuery } from '../userProfile/usersApi';
import {
    cancelBuild,
    reorderBuild,
    selectActionConfirmationDialog,
    toggleShowActionConfirmationDialog,
} from './commonsSlice';
import { BuildDialogActionType } from './enums';
import { IBuild } from './interfaces';

export default function BuildActionConfirmationDialog(): ReactElement | null {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { data: userData } = useGetCurrentUserQuery();
    const userPreferences = userData?.user?.preferences;
    const { show, action, build, error } = useSelector(selectActionConfirmationDialog);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        dispatch(toggleShowActionConfirmationDialog({}));
        setLoading(false);
    };

    const handleOk = () => {
        setLoading(true);
        if (build) {
            switch (action) {
                case BuildDialogActionType.reorder:
                    dispatch(reorderBuild({ build, userPreferences })).then(({ payload }) => {
                        if (payload.builds?.length) {
                            payload.builds.forEach((build: IBuild) => {
                                successToast(<BuildSuccessToastContent build={build} />, { duration: 15000 });
                            });

                            handleClose();
                        }
                    });
                    break;
                case BuildDialogActionType.cancel:
                    dispatch(cancelBuild(build)).then(() => {
                        dispatch(setRunningStepAsCancelled());
                        handleClose();
                    });
                    break;
            }
        }
    };

    if (!action || !build) {
        return null;
    }

    const { title, dialogText, cancelText, confirmText } = getTextFromAction(action, build);

    return (
        <Dialog open={show} onClose={handleClose} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {dialogText}
                {error && <Alert severity='error'>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color='primary'>
                    {cancelText}
                </Button>
                <Button disabled={loading} onClick={handleOk} color='primary' style={{ minWidth: '140px' }}>
                    {loading ? <CircularProgress size={15} variant='indeterminate' /> : confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function getTextFromAction(action: BuildDialogActionType, build: IBuild) {
    let title,
        dialogText,
        cancelText = 'Cancel',
        confirmText = 'Ok';

    switch (action) {
        case BuildDialogActionType.cancel:
            title = 'Cancel build';
            dialogText = (
                <>
                    <Typography variant='body1'>Are you sure you want to cancel this build?</Typography>
                    <br />
                    <BuildInfoTable build={build} />
                </>
            );
            confirmText = 'Cancel build';
            cancelText = 'No';
            break;

        case BuildDialogActionType.reorder:
            title = 'Reorder build';
            dialogText = (
                <>
                    <Typography variant='body1'>
                        You are about to reorder a new build with the following parameters
                    </Typography>
                    <br />
                    <BuildInfoTable build={build} />
                </>
            );
            confirmText = 'Reorder build';
            break;
    }

    return { title, dialogText, confirmText, cancelText };
}
