import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import * as queryString from '../commons/queryString';

interface BuildParams {
    hostname: string;
    start: number;
}

interface BuildLiveParams extends BuildParams {
    id: string;
}

interface BuildSnapshotParams extends BuildParams {
    stop: number;
    height?: number;
    width?: number;
}

export const getBuildStatsGraphApi = createApi({
    baseQuery: apiBaseQuery(),
    reducerPath: 'buildStatsGraphApi',
    endpoints: (builder) => ({
        getLiveGraph: builder.query<{ embedIds: string[] }, BuildLiveParams>({
            query: (params: BuildLiveParams) => ({
                method: 'GET',
                url: `/builds/stats-graph/live?${queryString.stringify(params)}`,
            }),
        }),
        getSnapshotGraph: builder.query<{ urls: string[] }, BuildSnapshotParams>({
            query: (params: BuildSnapshotParams) => ({
                method: 'GET',
                url: `/builds/stats-graph/snapshot?${queryString.stringify(params)}`,
            }),
        }),
    }),
});

export const { useGetLiveGraphQuery, useGetSnapshotGraphQuery } = getBuildStatsGraphApi;
