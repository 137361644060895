import { ReactElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
    List,
    ListItem,
    IconButton,
    ListItemSecondaryAction,
    Typography,
    CircularProgress,
    Box,
    Chip,
    Popper,
    ListItemButton,
} from '@mui/material';
import { blueGray, successToast, warningToast } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import {
    getBuildConfigurations,
    getSimilarBuilds,
    setNewBuildConfiguration,
    applyFavouriteSettings,
    getBranches,
    getRevisions,
    getViableAgentsCount,
    selectNewBuildFailedSettings,
    setFailedSettings,
    setNewBuildBranch,
    setNewBuildProject,
    setNewBuildRevision,
    setVersion,
    setSubscribed,
} from '../newBuild/newBuildSlice';
import { IFavourite } from '../userProfile/interfaces';
import { useDeleteFavouriteMutation, useGetFavouritesQuery } from '../userProfile/usersApi';

export default function NewBuildFavourites(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const [selectedId, setSelectedId] = useState<string>();
    const { data: favourites, isLoading: isLoadingFavourites } = useGetFavouritesQuery();
    const [deleteFavourite] = useDeleteFavouriteMutation();
    const failedSettings = useSelector(selectNewBuildFailedSettings);

    const handleSelectFavourite = (favouriteId: string) => () => {
        setSelectedId(favouriteId);
        const favourite = favourites?.find((fav) => fav._id === favouriteId);
        if (favourite) {
            dispatch(setNewBuildProject(favourite.projectName));
            dispatch(setSubscribed(!!favourite.subscribed));
            dispatch(getBranches()).then(() => {
                dispatch(setNewBuildBranch(favourite.branch));
                dispatch(getRevisions()).then(({ payload }) => {
                    dispatch(setNewBuildRevision());
                    dispatch(setVersion(favourite.versionName));
                    const selectedRevision = payload?.[0]?.revision || '';

                    dispatch(
                        getBuildConfigurations({
                            projectName: favourite.projectName,
                            branch: favourite.branch,
                            revision: selectedRevision,
                        }),
                    ).then(({ payload }) => {
                        if (favourite?.buildConfigFileName && payload.buildConfigs?.[favourite?.buildConfigFileName]) {
                            dispatch(
                                setNewBuildConfiguration({
                                    fileName: favourite.buildConfigFileName,
                                    configuration: payload.buildConfigs[favourite.buildConfigFileName],
                                }),
                            );
                        }
                        if (favourite.settings) {
                            dispatch(applyFavouriteSettings(favourite.settings));
                            successToast('Favourite applied');
                        }
                        dispatch(getViableAgentsCount());
                        dispatch(getSimilarBuilds());
                    });
                });
            });
        }
    };
    if (failedSettings.length) {
        failedSettings.forEach((setting) => {
            warningToast(`Failed to apply setting "${setting}"`);
        });
        dispatch(setFailedSettings([]));
    }

    const handleDeleteFavourite = async (e: React.MouseEvent<HTMLElement, MouseEvent>, favourite: IFavourite) => {
        e.stopPropagation();
        const result = await deleteFavourite(favourite._id);
        if (!('error' in result)) {
            successToast(`Removed "${favourite.name}" from favourites`);
        }
    };

    return (
        <Box
            data-testid='new-build-favourites'
            sx={{
                width: '100%',
                mb: 10,
                ml: 1,
            }}
        >
            <Typography
                variant='h5'
                gutterBottom
                sx={{ borderBottom: '1px solid #EAECF0', py: 2.5, borderColor: blueGray[300] }}
            >
                Favorites
            </Typography>
            <List sx={{ minHeight: '300px' }}>
                {favourites && favourites.length ? (
                    favourites?.map((favourite) => (
                        <ListItemButton
                            key={favourite._id}
                            title={`apply saved ${favourite.projectName} - ${favourite.branch}${favourite.versionName ? ` - ${favourite.versionName}` : ''} configuration`}
                            selected={selectedId === favourite._id}
                            onClick={handleSelectFavourite(favourite._id)}
                            sx={{ mb: 2 }}
                        >
                            <ListItem sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    <Typography variant='subtitle1'>{favourite.name}</Typography>
                                    <Typography
                                        variant='caption'
                                        color={blueGray[800]}
                                        sx={{ mr: 4, mt: 0.5 }}
                                        gutterBottom
                                        noWrap
                                    >
                                        <strong>{favourite.projectName}</strong> - {favourite.branch}{' '}
                                        {favourite.versionName ? ` - ${favourite.versionName}` : ''}
                                    </Typography>
                                </Box>
                                {favourite.buildConfigFileName && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        <Typography variant='caption' color={blueGray[800]} gutterBottom noWrap>
                                            {favourite.buildConfigFileName}
                                        </Typography>
                                    </Box>
                                )}
                                <FavouriteChips favourite={favourite} />
                                {favourite.subscribed && (
                                    <Typography
                                        variant='caption'
                                        color='textSecondary'
                                        noWrap
                                        component='div'
                                        sx={{ mt: 0.25 }}
                                    >
                                        Notification enabled
                                    </Typography>
                                )}
                            </ListItem>
                            <ListItemSecondaryAction>
                                <IconButton
                                    title='Delete'
                                    size='small'
                                    onClick={(e) => handleDeleteFavourite(e, favourite)}
                                >
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    ))
                ) : (
                    <>
                        {isLoadingFavourites ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CircularProgress size={20} />
                            </Box>
                        ) : (
                            <Box sx={{ textAlign: 'center', my: 10 }}>
                                <BookmarkBorderOutlinedIcon color='secondary' />
                                <Typography variant='body1' color='textSecondary' gutterBottom>
                                    No favourites saved
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </List>
        </Box>
    );
}

function FavouriteChips({ favourite }: { favourite: IFavourite }): ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const visibleChips = 4;

    const handleMenuOpen = (event: React.MouseEvent<any, MouseEvent>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleMenuClose = (): void => setAnchorEl(null);

    const favouriteLabels = useMemo(() => {
        const { precompiler_directives, ...otherSettings } = favourite.settings || {};
        const chipLabels = Object.keys(otherSettings).reduce((labels, key) => {
            if (otherSettings[key]) {
                labels.push(key.replace('_', ' ').toUpperCase());
            }
            return labels;
        }, [] as string[]);

        return [...(precompiler_directives ? (precompiler_directives as string[]) : []), ...chipLabels];
    }, [favourite]);

    return (
        <Box sx={{ mr: 3 }}>
            {favouriteLabels.slice(0, visibleChips).map((value) => (
                <Chip variant='outlined' size='small' sx={{ mr: 0.5, my: 0.5 }} key={value} label={value} />
            ))}
            {favouriteLabels.length > visibleChips && (
                <Chip
                    label={`+${favouriteLabels.length - visibleChips}`}
                    variant='outlined'
                    onMouseEnter={handleMenuOpen}
                    onMouseLeave={handleMenuClose}
                    size='small'
                />
            )}
            <Popper
                anchorEl={anchorEl}
                open={!!anchorEl}
                sx={{
                    mt: 2,
                    pointerEvents: 'none',
                    background: (theme) => theme.palette.common.white,
                    borderRadius: 1,
                }}
            >
                {favouriteLabels.slice(visibleChips).map((label) => (
                    <ListItem key={label}>{label}</ListItem>
                ))}
            </Popper>
        </Box>
    );
}
