import { ReactElement } from 'react';
import { Box, Skeleton } from '@mui/material';

export default function LogSkeleton(): ReactElement {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 800 }}>
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
            <Skeleton variant='text' animation='wave' />
        </Box>
    );
}
