import { BuildAgent } from '../buildAgents/interfaces';
import { IBuildResult, IConfigurationProject } from '../buildDetails/interfaces';
import { EBuildPlatform } from '../builds/enums';
import { EBuildStatus } from '../commons/enums';
import { IBuild } from '../commons/interfaces';
import { IConfigurationSettings, ISelectedSettings } from '../configurationSettings/interfaces';
import { IScheduledTestSuitePayload } from '../testSuites/interfaces';
import { NewBuildStatus } from './enums';

export interface IConfigurationBuild {
    id: number;
    display_name: string;
    builder: string;
    platform: string;
    kind: string;
    type: string;
    hidden?: boolean;
    requirements?: IConfigurationRequirements;
    viableAgentsCount?: number;
}

export interface IConfiguration {
    project?: IConfigurationProject;
    builds?: IConfigurationBuild[];
    settings?: IConfigurationSettings;
    requirements?: IConfigurationRequirements;
    actions?: IConfigurationAction[];
}

export interface IConfigurationRequirements {
    [setting: string]: string;
}

export interface IConfigurationAction {
    name: string;
    display_name: string;
    prerequisites?: Partial<IBuild> & Partial<IBuildResult>;
    trigger: ActionTrigger;
    hidden?: boolean;
    available_states?: EBuildStatus[];
    request: {
        url: string;
        headers?: Record<string, string>;
        method: string;
        body?: any;
    };
    settings?: IConfigurationSettings;
}

export enum ActionTrigger {
    automatic = 'automatic',
    manual = 'manual',
}

export interface IAutocomplete {
    selected?: string | number | any;
    options: string[];
    loading?: boolean;
    disabled?: boolean;
}

export interface IBuildConfigurationFile {
    fileName: string;
    configuration: IConfiguration;
}

export interface IAutocompleteBuildConfig {
    selected?: IBuildConfigurationFile;
    options?: IBuildConfigurationFile[];
    loading?: boolean;
    hash?: string;
    disabled?: boolean;
}

export interface IAutocompleteBuildAgent extends Omit<IAutocomplete, 'options' | 'selected'> {
    selected?: BuildAgent | null;
    options: BuildAgent[];
}

export interface ISelectedBuildConfiguration {
    builds: IConfigurationBuild[];
    settings: ISelectedSettings;
}
export interface INewBuildSlice {
    status: NewBuildStatus;
    project: IAutocomplete;
    branch: IAutocomplete;
    revision: Omit<IAutocomplete, 'options' | 'selected'> & {
        options: IKnownRevisions[];
        selected: IKnownRevisions | null;
    };
    buildAgent: IAutocompleteBuildAgent;
    headRevision?: string;
    version?: string;
    buildConfigFileName?: string;
    jiraTasks: string[];
    buildConfiguration: IAutocompleteBuildConfig;
    selectedBuildConfiguration?: ISelectedBuildConfiguration;
    buildViability: IBuildViability[];
    similarBuilds: ISimilarBuild[];
    failedSettings: string[];
    orderIsValid: boolean;
    validationErrors: string[];
    error?: string;
    expedited: boolean;
    runTests?: boolean;
    subscribed?: boolean;
    subscribers?: string[];
}

export interface IBuildOrder {
    projectName: string;
    branch: string;
    revision?: string;
    buildConfigId: number;
    platform?: EBuildPlatform;
    type?: string;
    version?: string;
    buildConfigFileName?: string;
    jiraTasks?: string[];
    versionName?: string;
    settings?: ISelectedSettings;
    buildAgent?: string;
    expedited?: boolean;
    testSuites?: IScheduledTestSuitePayload[];
    subscribeToResult?: boolean;
    subscribers?: string[];
}

export interface IKnownRevisions {
    revision: string;
    commitMessage: string;
}

interface IBuildViability {
    id: number;
    viableAgentsCount: number;
}

export interface ISimilarBuild {
    _id: string;
    buildNumber: string;
    projectName: string;
    branch: string;
    revision: string;
    buildConfigId: number;
    platform: string;
    type: string;
    status: EBuildStatus;
}
