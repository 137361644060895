import { ReactElement } from 'react';
import { Skeleton, TableRow, TableCell } from '@mui/material';

export default function ScheduledBuildLoadingRows({ rows = 10 }: { rows?: number }): ReactElement {
    const array = [...Array(rows)].map((_value, index) => index);
    const bigRand = Math.random() * 0.5 + 0.5;
    const smallRand = Math.random() * 0.2 + 0.8;

    return (
        <>
            {array.map((key) => (
                <LoadingRow key={key} bigRand={bigRand} smallRand={smallRand} />
            ))}
        </>
    );
}

function LoadingRow({ bigRand, smallRand }: { bigRand: number; smallRand: number }): ReactElement {
    const showTag = Math.random() > 0.5;
    return (
        <TableRow data-testid='scheduled-build-loading-row'>
            <TableCell>
                <Skeleton width={`${bigRand * 100}%`} />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton width={`${bigRand * 100}%`} />
            </TableCell>
            <TableCell style={{ display: 'flex' }}>
                <Skeleton
                    variant='rectangular'
                    width={`${smallRand * 30}%`}
                    height={30}
                    style={{ borderRadius: 16, marginRight: 8 }}
                />
                {showTag && (
                    <Skeleton
                        variant='rectangular'
                        width={`${smallRand * 30}%`}
                        height={30}
                        style={{ borderRadius: 16, marginRight: 8 }}
                    />
                )}
                <Skeleton variant='rectangular' width={`${smallRand * 30}%`} height={30} style={{ borderRadius: 16 }} />
            </TableCell>
            <TableCell style={{ margin: 'auto' }} align='center'>
                <Skeleton variant='rounded' width={24} height={24} style={{ margin: 'auto' }} />
            </TableCell>
        </TableRow>
    );
}
