import { ReactElement, useEffect, useMemo, useState } from 'react';
import BatteryIcon from '@mui/icons-material/Battery2Bar';
import TemperatureIcon from '@mui/icons-material/DeviceThermostat';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { Dialog } from '@tactileentertainment/core-designsystem';
import { ITestDeviceError } from '../interfaces';
import { useGetTestDevicesSettingsQuery, useUpdateTestDevicesSettingsMutation } from '../testDevicesApi';
import TestDeviceErrorsTable from './TestDeviceErrorsTable';

export default function TestDevicesSettingsDialog({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}): ReactElement | null {
    const [minBatteryLevel, setMinBatteryLevel] = useState<number | null>();
    const [maxTemperature, setMaxTemperature] = useState<number | null>();
    const [errors, setErrors] = useState<ITestDeviceError[]>([]);

    const { data: devicesSettings, isLoading } = useGetTestDevicesSettingsQuery();
    const [updateDevicesSettings] = useUpdateTestDevicesSettingsMutation();

    useEffect(() => {
        if (open && devicesSettings) {
            setMinBatteryLevel(devicesSettings.minBatteryLevel);
            setMaxTemperature(devicesSettings.maxTemperature);
            setErrors(devicesSettings.errors);
        }
    }, [open, devicesSettings]);

    const isValid = useMemo(() => {
        const minBatteryValid = minBatteryLevel && minBatteryLevel >= 0;
        const maxTemperatureValid = maxTemperature && maxTemperature > 0 && maxTemperature <= 100;
        const errorsValid = !errors.find((error) => !error.code || error.code < 0 || !error.name || !error.action);
        const noDuplicates = new Set(errors.map((error) => error.code)).size === errors.length;
        return Boolean(minBatteryValid && maxTemperatureValid && errorsValid && noDuplicates);
    }, [minBatteryLevel, maxTemperature, errors]);

    const handleSave = () => {
        if (isValid) {
            updateDevicesSettings({
                minBatteryLevel: minBatteryLevel || 0,
                maxTemperature: maxTemperature || 100,
                errors,
            });
            onClose();
        }
    };

    if (isLoading) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            variant='custom'
            maxWidth='lg'
            title='Edit Global Settings'
            subtitle={
                devicesSettings?.updatedBy && (
                    <Typography variant='subtitle1' color='text.secondary'>
                        Last Updated By {devicesSettings?.updatedBy}
                    </Typography>
                )
            }
            content={
                <>
                    <TextField
                        value={minBatteryLevel || ''}
                        onChange={(event) => setMinBatteryLevel(event.target.value ? Number(event.target.value) : null)}
                        required
                        label='Min battery level'
                        type='number'
                        variant='outlined'
                        autoComplete='off'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='start'>
                                    <BatteryIcon />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                    <TextField
                        value={maxTemperature || ''}
                        onChange={(event) => setMaxTemperature(event.target.value ? Number(event.target.value) : null)}
                        required
                        label='Max temperature'
                        type='number'
                        variant='outlined'
                        autoComplete='off'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='start'>
                                    <TemperatureIcon />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        sx={{ my: 1 }}
                    />
                    <TestDeviceErrorsTable errors={errors} onErrorsChange={(errors) => setErrors(errors)} />
                </>
            }
            primaryButton={{
                text: 'Save',
                onClick: handleSave,
                color: 'primary',
                disabled: !isValid,
            }}
        />
    );
}
