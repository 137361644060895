import { ReactElement } from 'react';
import CustomLink from './CustomLink';

interface LinkToParentBuildProps {
    projectName: string;
    versionName: string;
    buildNumber: number;
}

export default function LinkToParentBuild({
    projectName,
    versionName,
    buildNumber,
}: LinkToParentBuildProps): ReactElement {
    return <CustomLink to={`/builds/${projectName}/${buildNumber}`}>{`${projectName} ${versionName}`}</CustomLink>;
}
