import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { EnableIfCan } from '@tactileentertainment/core-shared.auth-react';
import { EAuthResource } from '../commons/EAuthResource';
import { IBuild } from '../commons/interfaces';
import { canOrderTestOnBuild } from '../tests/canOrderTestOnBuild';
import { useGetTestProjectsQuery } from '../tests/testsApi';
import OrderTestSuiteDialog from '../testSuites/newTestSuite/OrderTestSuiteDialog';
import { selectBuildDetailsResult } from './buildDetailsSlice';

export default function OrderTestSuiteButtonDialog({ build }: { build: IBuild }): ReactElement | null {
    const [openOrderSuiteDialog, setOpenOrderSuiteDialog] = useState<boolean>(false);
    const { data: projects } = useGetTestProjectsQuery(undefined, { refetchOnMountOrArgChange: false });
    const buildResult = useSelector(selectBuildDetailsResult);

    const showOrderTestButton = canOrderTestOnBuild(build, buildResult, projects);

    if (!showOrderTestButton) {
        return null;
    }

    return (
        <EnableIfCan resource={EAuthResource.testSuites} operation='edit'>
            <Button
                variant='outlined'
                color='primary'
                startIcon={<Add />}
                aria-label='orderTest'
                sx={{ mr: 1.5, py: 1.25, my: 1 }}
                onClick={() => setOpenOrderSuiteDialog(true)}
            >
                Test Suite
            </Button>
            <OrderTestSuiteDialog
                open={openOrderSuiteDialog}
                onClose={() => setOpenOrderSuiteDialog(false)}
                build={build}
            />
        </EnableIfCan>
    );
}
