import { CSSProperties, ReactElement, useMemo } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism-coy.css';
import './styles.css';

interface CodeEditorProps {
    language: string;
    value: string;
    style?: CSSProperties;
    disabled?: boolean;
    onChange: (value: string) => void;
}

const shorterThan1000Lines = 1000;

export function CodeEditor({ language, value, style, disabled, onChange }: CodeEditorProps): ReactElement {
    const canHighlight = useMemo(() => {
        return value.split('\n').length <= shorterThan1000Lines;
    }, [value]);

    const highlightCode = (code: string) => {
        if (!canHighlight) {
            return code;
        }
        return highlight(code, languages[language], language)
            .split('\n')
            .map((line) => `<span class="codeEditorLineNum">${line}</span>`)
            .join('\n');
    };

    return (
        <div className='codeEditorContainer' style={style}>
            <Editor
                autoFocus
                insertSpaces
                value={value}
                onValueChange={onChange}
                highlight={highlightCode}
                className={`codeEditor ${canHighlight ? 'codeHighlighted' : ''}`}
                textareaClassName={`codeEditorTextArea ${canHighlight ? 'codeTextAreaHighlighted' : ''}`}
                disabled={disabled}
                onError={(e) => console.log('code editor error: ', e)}
            />
        </div>
    );
}
