import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { IRegisterTestAgentPayload, ITestAgent, IUpdateTestAgentPayload } from './interfaces';

export const testAgentsApi = createApi({
    reducerPath: 'testAgentsApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['TestAgents'],
    endpoints: (builder) => ({
        getTestAgents: builder.query<ITestAgent[], void>({
            query: () => ({
                url: 'test-agents',
            }),
            providesTags: ['TestAgents'],
        }),
        registerTestAgent: builder.mutation<ITestAgent, IRegisterTestAgentPayload>({
            query: (payload: IRegisterTestAgentPayload) => ({
                url: 'test-agents',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['TestAgents'],
        }),
        updateTestAgent: builder.mutation<ITestAgent, IUpdateTestAgentPayload>({
            query: (payload: IUpdateTestAgentPayload) => ({
                url: `test-agents/${payload._id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['TestAgents'],
        }),
        deleteTestAgent: builder.mutation<ITestAgent, string>({
            query: (id: string) => ({
                url: `test-agents/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['TestAgents'],
        }),
    }),
});

export const {
    useGetTestAgentsQuery,
    useRegisterTestAgentMutation,
    useUpdateTestAgentMutation,
    useDeleteTestAgentMutation,
} = testAgentsApi;
