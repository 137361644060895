import { AnyAction, configureStore, Store, ThunkDispatch } from '@reduxjs/toolkit';
import { buildAgentsApi } from '../components/buildAgents/buildAgentsApi';
import buildAgentSlice from '../components/buildAgents/buildAgentsSlice';
import buildDetailsSlice from '../components/buildDetails/buildDetailsSlice';
import { getBuildStatsGraphApi } from '../components/buildDetails/getBuildStatsGraphApi';
import { buildsApi } from '../components/builds/buildsApi';
import buildsSlice from '../components/builds/buildsSlice';
import { changeLogApi } from '../components/changeLog/changeLogApi';
import commonsSlice from '../components/commons/commonsSlice';
import { gameModulesApi } from '../components/gameModules/gameModulesApi';
import headerSlice from '../components/header/headerSlice';
import newBuildSlice from '../components/newBuild/newBuildSlice';
import { projectsApi } from '../components/projects/projectsApi';
import { releaseNotesApi } from '../components/releaseNotes/releaseNotesApi';
import { scheduledBuildsApi } from '../components/scheduledBuilds/scheduledBuildsApi';
import { subscriptionsApi } from '../components/subscriptions/subscriptionsApi';
import { testAgentsApi } from '../components/testAgents/testAgentsApi';
import { testDevicesApi } from '../components/testDevices/testDevicesApi';
import { testsApi } from '../components/tests/testsApi';
import testsSlice from '../components/tests/testsSlice';
import { testSuitesApi } from '../components/testSuites/testSuitesApi';
import acceleratorSlice from '../components/unityAccelerators/acceleratorSlice';
import { usersApi } from '../components/userProfile/usersApi';

const rootReducer = () => {
    return {
        newBuild: newBuildSlice,
        buildDetails: buildDetailsSlice,
        builds: buildsSlice,
        buildAgents: buildAgentSlice,
        accelerators: acceleratorSlice,
        header: headerSlice,
        commons: commonsSlice,
        tests: testsSlice,
        usersApi: usersApi.reducer,
        testsApi: testsApi.reducer,
        testSuitesApi: testSuitesApi.reducer,
        testDevicesApi: testDevicesApi.reducer,
        testAgentsApi: testAgentsApi.reducer,
        subscriptionsApi: subscriptionsApi.reducer,
        buildAgentsApi: buildAgentsApi.reducer,
        projectsApi: projectsApi.reducer,
        buildsApi: buildsApi.reducer,
        scheduledBuildsApi: scheduledBuildsApi.reducer,
        gameModulesApi: gameModulesApi.reducer,
        releaseNotesApi: releaseNotesApi.reducer,
        buildStatsGraphApi: getBuildStatsGraphApi.reducer,
        changeLogApi: changeLogApi.reducer,
    };
};

export function createStore(): Store<any> {
    const store = configureStore({
        reducer: rootReducer(),
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(usersApi.middleware)
                .concat(testsApi.middleware)
                .concat(testSuitesApi.middleware)
                .concat(testDevicesApi.middleware)
                .concat(testAgentsApi.middleware)
                .concat(subscriptionsApi.middleware)
                .concat(buildAgentsApi.middleware)
                .concat(projectsApi.middleware)
                .concat(buildsApi.middleware)
                .concat(scheduledBuildsApi.middleware)
                .concat(gameModulesApi.middleware)
                .concat(releaseNotesApi.middleware)
                .concat(getBuildStatsGraphApi.middleware)
                .concat(changeLogApi.middleware),
    });

    return store;
}

export type AppThunkDispatch<T = Record<string, unknown>> = ThunkDispatch<T, Record<string, unknown>, AnyAction>;
