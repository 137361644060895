import { ReactElement } from 'react';
import { TextField, useMediaQuery } from '@mui/material';
import { projectNameValidator } from '../../../utils';

interface ProjectNameInputProps {
    projectName?: string;
    onChange: (project?: string) => void;
}

export default function ProjectNameInput({ projectName, onChange }: ProjectNameInputProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const hasError = Boolean(projectName && !projectNameValidator(projectName));

    return (
        <TextField
            id='project-name-input'
            required
            label='Project'
            variant='outlined'
            value={projectName}
            onChange={(e) => onChange(e.target.value || '')}
            error={hasError}
            helperText={hasError && 'Invalid name, it should be like: ProjectName'}
            style={{ minWidth: isMobile ? '0px' : '350px' }}
        />
    );
}
