import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { KeyboardArrowDown as ArrowDropDownIcon } from '@mui/icons-material';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import { blueGray, indigo } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { handleRedirectToPage } from '../../utils';
import { setSelectedMenu } from './headerSlice';
import { IAppBarMenuItem } from './interfaces';

interface NavButtonProps {
    item: IAppBarMenuItem;
    selected?: { text: string; to: string };
}

export default function NavButton({ item, selected }: NavButtonProps): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch<AppThunkDispatch>();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);

    const handleClick = (e: React.MouseEvent<any>, to: string, text: string) => {
        handleRedirectToPage(e, to, history);
        dispatch(setSelectedMenu({ to, text }));
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const isSelected = item.text === selected?.text;
    const isMenuOptionSelected = item.options?.find((option) => option.to === selected?.to);

    return (
        <>
            <ButtonGroup variant='contained' ref={anchorRef} aria-label='nav menu button' sx={{ boxShadow: 'none' }}>
                <Button
                    onClick={(e) => handleClick(e, item.to, item.text)}
                    key={item.to}
                    sx={{
                        mr: 0,
                        textTransform: 'none',
                        letterSpacing: 0,
                        color: (theme) => theme.palette.common.white,
                        ...(isSelected && {
                            background: indigo[1000],
                        }),
                        '&:hover': {
                            background: indigo[600],
                        },
                    }}
                >
                    <Typography
                        component={Link}
                        to={item.to}
                        sx={{ color: (theme) => theme.palette.common.white, textDecoration: 'none', fontWeight: 600 }}
                    >
                        {item.text}
                    </Typography>
                </Button>
                <Button
                    aria-controls={open ? 'nav-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label='select menu option'
                    aria-haspopup='menu'
                    onClick={handleToggle}
                    sx={{
                        ml: 0,
                        ...(isMenuOptionSelected && {
                            background: indigo[1000],
                        }),
                        '&:hover': {
                            background: indigo[600],
                        },
                    }}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id='nav-button-menu' autoFocusItem>
                                    {item.options?.map((option) => (
                                        <MenuItem
                                            key={option.to}
                                            onClick={(e) => handleClick(e, option.to, option.text)}
                                            sx={{ background: option.to === selected?.to ? blueGray[300] : undefined }}
                                        >
                                            <Typography component={Link} to={item.to} sx={{ textDecoration: 'none' }}>
                                                {option.text}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
