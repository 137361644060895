import { ReactElement } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Checkbox,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { IBuild } from '../../commons/interfaces';
import TableHeaderRow from '../../commons/TableHeaderRow';
import SelectedSettings from '../../configurationSettings/SelectedSettings';
import { ESelectedProperty, ISelectedTestCase } from '../interfaces';
import { compareTestCases } from './compareTestCases';
import DuplicateTestCaseButton from './DuplicateTestCaseButton';
import SelectBuild from './SelectBuild';

export interface ISelectTestCasesTableProps {
    build: IBuild;
    testCases: ISelectedTestCase[];
    onSelect: (id: string, property: ESelectedProperty, value: ISelectedTestCase[ESelectedProperty]) => void;
    onSelectAll: (property: ESelectedProperty, value: boolean) => void;
    onDuplicate: (testCase: ISelectedTestCase) => void;
}

export default function SelectTestCasesTable({
    build,
    testCases,
    onSelect,
    onSelectAll,
    onDuplicate,
}: ISelectTestCasesTableProps): ReactElement {
    const { all: allSelected, some: someSelected } = compareTestCases(testCases, ESelectedProperty.selected);
    const { all: allVideoRecording } = compareTestCases(testCases, ESelectedProperty.videoRecording);

    return (
        <TableContainer style={{ maxHeight: '52vh' }}>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableHeaderRow>
                        <TableCell>
                            <Checkbox
                                size='small'
                                color='primary'
                                sx={{ py: 0 }}
                                checked={someSelected}
                                indeterminate={someSelected && !allSelected}
                                onChange={() => onSelectAll(ESelectedProperty.selected, !allSelected)}
                            />
                        </TableCell>
                        <TableCell>Tests</TableCell>
                        <TableCell>Tags</TableCell>
                        <TableCell sx={{ minWidth: 130 }}>Select Build</TableCell>
                        <TableCell sx={{ minWidth: 200 }}>Settings</TableCell>
                        <TableCell align='right' sx={{ minWidth: 150 }}>
                            <Box display='inline' sx={{ mr: someSelected ? 0 : 7 }}>
                                Video Recording
                            </Box>
                            {someSelected && (
                                <Switch
                                    size='small'
                                    color='primary'
                                    sx={{ my: 0 }}
                                    checked={allVideoRecording}
                                    onChange={(event) =>
                                        onSelectAll(ESelectedProperty.videoRecording, event.target.checked)
                                    }
                                />
                            )}
                        </TableCell>
                    </TableHeaderRow>
                </TableHead>
                <TableBody>
                    {testCases.map((testCase) => {
                        if (!testCase.visible) {
                            return null;
                        }
                        return (
                            <TableRow
                                data-testid='select-test-case-row'
                                key={testCase.id}
                                sx={{
                                    background: (theme) =>
                                        testCase.selected ? theme.palette.tactile.sky[100] : undefined,
                                }}
                                onClick={() => onSelect(testCase.id, ESelectedProperty.selected, !testCase.selected)}
                            >
                                <TableCell sx={{ pl: { xs: 2, sm: 4 } }}>
                                    <Checkbox
                                        size='small'
                                        color='primary'
                                        checked={testCase.selected}
                                        onChange={(event) =>
                                            onSelect(testCase.id, ESelectedProperty.selected, event.target.checked)
                                        }
                                        sx={{ mr: testCase.selected ? 0.25 : 2.75 }}
                                    />
                                    {testCase.selected && (
                                        <DuplicateTestCaseButton testCase={testCase} onDuplicate={onDuplicate} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Box
                                        sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}
                                    >
                                        <Typography>{testCase.displayName}</Typography>
                                        {testCase.docString !== '' && (
                                            <Tooltip
                                                title={
                                                    <Typography variant='subtitle2'>{testCase.docString}</Typography>
                                                }
                                            >
                                                <InfoIcon
                                                    fontSize='small'
                                                    color='secondary'
                                                    sx={{ verticalAlign: 'bottom' }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Box>

                                    {testCase.dependsOn.length > 0 && (
                                        <Typography color='secondary' fontSize={10}>
                                            depends on {testCase.dependsOn.join(', ')}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell>{testCase.tags.join(', ')}</TableCell>
                                <TableCell>
                                    {testCase.selected && testCase.selectBuild && (
                                        <SelectBuild build={build} testCase={testCase} onSelect={onSelect} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <SelectedSettings
                                        settings={testCase.settings}
                                        selectedSettings={testCase.selectedSettings}
                                        editEnabled={testCase.selected}
                                        onEdit={(selectedSettings) => {
                                            onSelect(testCase.id, ESelectedProperty.selectedSettings, selectedSettings);
                                        }}
                                    />
                                </TableCell>
                                <TableCell align='right' sx={{ height: 55 }}>
                                    {testCase.selected && (
                                        <Switch
                                            size='small'
                                            color='primary'
                                            checked={testCase.videoRecording}
                                            onChange={(event) =>
                                                onSelect(
                                                    testCase.id,
                                                    ESelectedProperty.videoRecording,
                                                    event.target.checked,
                                                )
                                            }
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
