import React, { ReactElement } from 'react';
import CustomLink from '../commons/CustomLink';

export default function TestSuiteLink({
    projectName,
    testSuiteNumber,
}: {
    projectName: string;
    testSuiteNumber?: number;
}): ReactElement {
    return (
        <CustomLink
            to={`/test-suites/${projectName}/${testSuiteNumber}`}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
            onMouseUp={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
        >
            {testSuiteNumber ? `#${testSuiteNumber}` : ``}
        </CustomLink>
    );
}
