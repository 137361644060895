import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { MuiAlignSetting } from '../../../typings/material-ui';
import CustomLink from '../../commons/CustomLink';

export default function TestAgentVersionCell({
    version,
    align,
}: {
    version: string;
    align?: MuiAlignSetting;
}): ReactElement {
    const gitVersionUrl = `https://github.com/tactileentertainment/build-tools.test-agent-ts/releases/tag/v${version}`;

    return (
        <TableCell align={align}>
            <CustomLink
                noWrap
                title={version}
                target='_blank'
                to={{ pathname: gitVersionUrl }}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
            >
                {version}
            </CustomLink>
        </TableCell>
    );
}
