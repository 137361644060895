import { ReactElement, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { getRequest } from '../../api';
import { IBuild } from '../commons/interfaces';
import { LoadingSpinner } from '../commons/LoadSpinner';

export default function BuildIdRedirect(): ReactElement {
    const { buildId } = useParams<{ buildId: string }>();
    const [build, setBuild] = useState<IBuild>();
    const [status, setStatus] = useState('idle');

    useEffect(() => {
        if (buildId && !build && status === 'idle') {
            setStatus('loading');
            getRequest(`/builds/${buildId}`).then((response) => {
                setBuild(response.build);
                setStatus('loaded');
            });
        }
    }, [build, buildId, status]);

    const redirectLink =
        (build?.projectName && build?.buildNumber && `/builds/${build.projectName}/${build.buildNumber}`) || '/builds';

    return status === 'idle' || status === 'loading' ? (
        <LoadingSpinner loading={true} />
    ) : (
        <Redirect to={redirectLink} data-testid='build-details-id-redirect' />
    );
}
