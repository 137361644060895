import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YoutubeSearchedFor as SearchAgainIcon, Add as AddIcon } from '@mui/icons-material';
import { Button, Paper, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { useLogin } from '@tactileentertainment/core-shared.auth-react';
import { AppThunkDispatch } from '../../store';
import { SliceStatus } from '../commons/enums';
import HeaderSection from '../commons/HeaderSection';
import NoContent from '../commons/NoContent';
import Page from '../commons/Page';
import TableBody from '../commons/TableBody';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import AcceleratorRow from './AcceleratorRow';
import {
    getAccelerators,
    selectAcceleratorColumns,
    selectAcceleratorStatus,
    selectAllAccelerators,
    toggleRegisterAcceleratorDialog,
} from './acceleratorSlice';
import AcceleratorOptionsDialog from './dialogs/AcceleratorOptionsDialog';
import { IAcceleratorDetails } from './interfaces';
import RegisterAcceleratorDialog from './RegisterAcceleratorDialog';

export default function AcceleratorList(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const status = useSelector(selectAcceleratorStatus);
    const accelerators = useSelector(selectAllAccelerators);
    const acceleratorColumns = useSelector(selectAcceleratorColumns);

    useEffect(() => {
        dispatch(getAccelerators());
    }, [dispatch]);

    return (
        <Page>
            <HeaderSection title='Accelerators' actionBox={<AcceleratorRegisterAction />} />
            <Paper
                data-testid='accelerator-list'
                sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
            >
                <TableContainer sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Table
                        stickyHeader
                        aria-label='accelerators table'
                        style={{ width: '100%', tableLayout: 'fixed', minWidth: 1200 }}
                    >
                        <TableHead>
                            <TableHeaderRow>
                                {acceleratorColumns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        size='small'
                                        align={column.align}
                                        sx={{ width: column.width }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableHeaderRow>
                        </TableHead>
                        <TableBody>
                            {status === SliceStatus.loaded && !accelerators.length && (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <NoContent text='No accelerators registered' icon={<SearchAgainIcon />} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {status === SliceStatus.loading
                                ? null
                                : accelerators.map((accelerator) => (
                                      <AcceleratorRow
                                          key={accelerator._id}
                                          acceleratorDetails={accelerator as unknown as IAcceleratorDetails}
                                      />
                                  ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <RegisterAcceleratorDialog />
            <AcceleratorOptionsDialog />
        </Page>
    );
}

function AcceleratorRegisterAction(): ReactElement {
    const dispatch = useDispatch();
    const { isLoggedIn, handleLogin } = useLogin();

    const handleShowAddAcceleratorDialog = () => {
        if (isLoggedIn) {
            dispatch(toggleRegisterAcceleratorDialog());
        } else {
            handleLogin();
        }
    };

    return (
        <>
            <Button
                variant='contained'
                color='primary'
                onClick={handleShowAddAcceleratorDialog}
                startIcon={<AddIcon />}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
                Accelerator Server
            </Button>
        </>
    );
}
