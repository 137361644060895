import { ITestCaseConfig } from '../../../interfaces';

export function getTags(testCasesConfig?: ITestCaseConfig[]): string[] {
    if (!testCasesConfig) {
        return [];
    }
    const tags = new Set<string>();
    for (const testCase of testCasesConfig) {
        for (const tag of testCase.tags) {
            tags.add(tag);
        }
    }
    return Array.from(tags);
}
