import { ITestAgentShortInfo } from '../testAgents/interfaces';
import { ITest } from '../tests/interfaces';
import { ITestSuiteListItem } from '../testSuites/interfaces';

export interface ITestDevice {
    _id: any;
    platform: string;
    uuid: string;
    name: string;
    state: ETestDeviceState;
    deviceInfo: ITestDeviceInfo;
    gameDeviceId?: string;
    lastSeen: string;
    priority: number;
    queueLength?: number;
    testRunning?: ITest;
    testSuiteRunning?: ITestSuiteListItem;
    testAgent?: ITestAgentShortInfo;
    isElegibleForQueuedTests?: boolean;
    isDeleted?: boolean;
    isDisabled?: boolean;
}

export interface ITestDeviceInfo {
    cpuabi?: string;
    cpufreq?: string;
    cpuinfo?: string;
    display?: string;
    gpu?: string;
    manufacturer?: string;
    memory?: string;
    model?: string;
    sdkversion?: number;
    storage?: string;
    battery?: number;
    temperature?: number;
}

export enum ETestDeviceState {
    offline = 'offline',
    idle = 'idle',
    running = 'running',
    done = 'done',
}

export interface ITestDevicesSettings {
    minBatteryLevel: number;
    maxTemperature: number;
    errors: ITestDeviceError[];
    createdAt?: Date;
    updatedAt?: Date;
    updatedBy?: string;
}

export interface ITestDeviceError {
    code: number;
    name: string;
    tag?: string;
    action: {
        isElegibleForQueuedTests?: boolean;
        isDisabled?: boolean;
    };
}
