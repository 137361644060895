import { ReactElement } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material';
import { tableMinWidth } from '../../buildAgents/BuildAgents';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { ITestSuiteDetails } from '../interfaces';
import TestSuiteDeviceRow from './TestSuiteDeviceRow';
import { testSuiteDevicesColumns } from './testSuiteDevicesColumns';

export default function TestSuiteDevicesList({ testSuite }: { testSuite: ITestSuiteDetails }): ReactElement {
    return (
        <>
            <Typography
                variant='h6'
                sx={{ m: 2, color: ({ palette }) => palette.tactile.blueGray[700], fontSize: '16px' }}
            >
                Devices ({testSuite.devices.length}/{testSuite.maxDevices})
            </Typography>
            <TableContainer>
                <Table stickyHeader aria-label='test cases table' sx={{ minWidth: tableMinWidth }}>
                    <TableHead data-testid='test-case-table-head'>
                        <TableHeaderRow>
                            {testSuiteDevicesColumns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    size='small'
                                    style={{
                                        width: column.width,
                                        minWidth: column.minWidth,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody data-testid='test-case'>
                        {testSuite.devices.map((device) => (
                            <TestSuiteDeviceRow
                                key={device._id}
                                results={testSuite.resultsCountPerDevice[device._id]}
                                tests={testSuite.tests.filter((test) => test.testDevice === device._id)}
                                testSuiteType={testSuite.type}
                                device={device}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
