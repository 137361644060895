import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearFiltersButton from '../../commons/filters/ClearFiltersButton';
import { CollapseFilters, FiltersContainer } from '../../commons/filters/commons';
import { clearTestsFilter, selectAmountOfActiveTestFilters, selectTestsColumns } from '../testsSlice';
import AuthorSelector from './AuthorSelector';
import BuildBranchSelector from './BuildBranchSelector';
import BuildPlatformSelector from './BuildPlatformSelector';
import BuildTypeSelector from './BuildTypeSelector';
import BuildVersionSelector from './BuildVersionSelector';
import ProjectNameSelector from './ProjectNameSelector';
import TestBranchSelector from './TestBranchSelector';
import TestDeviceSelector from './TestDeviceSelector';
import TestStatusSelector from './TestStatusSelector';

export interface ITestFilterProps {
    showOnlyValues?: boolean;
}

export const columnFilters: { [id: string]: (props: ITestFilterProps) => ReactElement } = {
    projectName: ProjectNameSelector,
    status: TestStatusSelector,
    testBranch: TestBranchSelector,
    buildBranch: BuildBranchSelector,
    buildType: BuildTypeSelector,
    version: BuildVersionSelector,
    platform: BuildPlatformSelector,
    testDevice: TestDeviceSelector,
    author: AuthorSelector,
};

export default function TestsFilters(): ReactElement | null {
    const hasFilters = !!useSelector(selectAmountOfActiveTestFilters);
    const columns = useSelector(selectTestsColumns);

    return (
        <CollapseFilters in={hasFilters} data-testid='tests-filters'>
            <FiltersContainer>
                {columns
                    .filter((column) => columnFilters[column.id])
                    .map((column): ReactElement => {
                        const Filter = columnFilters[column.id];
                        return <Filter key={column.id} showOnlyValues />;
                    })}
                <ClearAllFiltersButton />
            </FiltersContainer>
        </CollapseFilters>
    );
}

function ClearAllFiltersButton(): ReactElement {
    const dispatch = useDispatch();

    const handleClearClick = () => {
        dispatch(clearTestsFilter());
    };

    return <ClearFiltersButton onClick={handleClearClick} />;
}
