import React, { ReactElement } from 'react';
import { Cancel } from '@mui/icons-material';
import { Box, ButtonProps } from '@mui/material';
import { SquareButton, blueGray, errorToast } from '@tactileentertainment/core-designsystem';
import { ITestSuiteBase } from '../interfaces';
import { useCancelTestSuiteMutation } from '../testSuitesApi';

export default function CancelTestSuiteAction({
    testSuite,
    variant,
}: {
    testSuite: ITestSuiteBase;
    variant?: ButtonProps['variant'];
}): ReactElement {
    const [cancelTestSuite] = useCancelTestSuiteMutation();

    const handleCancelTestSuite = async (event: React.MouseEvent) => {
        event.stopPropagation();
        const result = await cancelTestSuite(testSuite.testSuiteNumber);
        if ('error' in result) {
            errorToast('Unable to cancel all the tests, try again later');
        }
    };

    return (
        <Box data-testid='cancel-test-action'>
            <SquareButton
                variant={variant}
                title='Cancel Test'
                aria-label='cancel'
                onMouseUp={handleCancelTestSuite}
                color='secondary'
                sx={{ p: 1.25 }}
            >
                <Cancel sx={{ color: blueGray[800] }} />
            </SquareButton>
        </Box>
    );
}
