import { ISelectedTestCase } from '../../../interfaces';

interface SelectByTagsOptions {
    testCases: ISelectedTestCase[]; // expects array of test cases already sorted by dependencies
    currentTags: string[];
    newTags: string[];
}

export function selectTestCasesByTags({ testCases, currentTags, newTags }: SelectByTagsOptions): ISelectedTestCase[] {
    const addedTags = newTags.filter((tag) => !currentTags.includes(tag));
    const removedTags = currentTags.filter((tag) => !newTags.includes(tag));

    const updatedTestCases = structuredClone(testCases);

    if (removedTags.length) {
        unselectByRemovedTags(updatedTestCases, removedTags);
    }

    if (addedTags.length) {
        selectByAddedTags(updatedTestCases, addedTags);
    }

    return updatedTestCases;
}

function unselectByRemovedTags(testCases: ISelectedTestCase[], removedTags: string[]) {
    const unselectedDependencies: string[] = [];
    for (let index = 0; index < testCases.length; index += 1) {
        const testCase = testCases[index];
        const isUnselectedByTag = Boolean(testCase.tags.find((tag) => removedTags.includes(tag)));
        const isDependencyUnselected = Boolean(
            testCase.dependsOn.find((deps) => unselectedDependencies.includes(deps)),
        );
        if (isUnselectedByTag || isDependencyUnselected || !testCase.selected) {
            testCases[index] = {
                ...testCase,
                selected: false,
            };
            unselectedDependencies.push(testCase.testName);
        }
    }
}

function selectByAddedTags(testCases: ISelectedTestCase[], addedTags: string[]) {
    const selectedDependencies: string[] = [];
    for (let index = testCases.length - 1; index >= 0; index -= 1) {
        const testCase = testCases[index];
        const isSelectedByTag = Boolean(testCase.tags.find((tag) => addedTags.includes(tag)));
        const isDependencySelected = selectedDependencies.includes(testCase.testName);
        if (isSelectedByTag || isDependencySelected || testCase.selected) {
            testCases[index] = {
                ...testCase,
                selected: true,
            };
            selectedDependencies.push(testCase.testName, ...testCase.dependsOn);
        }
    }
}
