import { ReactElement, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { ITestDevice } from '../interfaces';
import UpdateTestDeviceDialog from './dialogs/UpdateTestDeviceDialog';

export default function TestDeviceNameEdit({ testDevice }: { testDevice: ITestDevice }): ReactElement {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            {testDevice.name ? <Box sx={{ marginLeft: 1 }}>{testDevice.name}</Box> : ''}
            <Button onClick={() => setOpen(true)} color='secondary' size='small'>
                <Edit fontSize='small' />
            </Button>
            <UpdateTestDeviceDialog testDevice={testDevice} open={open} handleClose={() => setOpen(false)} />
        </>
    );
}
