import { ReactElement, useEffect } from 'react';
import { LazyLog, ScrollFollow } from 'react-lazylog';
import ContentLoading from '../../commons/ContentLoading';
import NoContent from '../../commons/NoContent';
import { useGetArtifactContentQuery } from '../testsApi';

export const LINE_HEIGHT = 19;

export default function LogFile({
    testId,
    logFile,
    scrollFollow,
    maxHeight,
}: {
    testId: string;
    logFile: string;
    scrollFollow: boolean;
    maxHeight?: string;
}): ReactElement {
    const { data: content, isFetching, refetch } = useGetArtifactContentQuery({ testId, filename: logFile });

    useEffect(() => {
        if (scrollFollow) {
            const interval = setInterval(() => {
                refetch();
            }, 5000);
            return () => {
                clearInterval(interval);
            };
        }
    });

    if (!content) {
        return isFetching ? <ContentLoading /> : <NoContent text='There is no displayable content' />;
    }

    const contentLines = content?.split('\n').length || 0;
    const contentHeight = 4 * LINE_HEIGHT + contentLines * LINE_HEIGHT;

    return (
        <div
            style={{ maxHeight: maxHeight || '40vh', width: '100%', height: `${contentHeight}px` }}
            data-testid='test-log-file'
        >
            {scrollFollow ? (
                <ScrollFollow
                    startFollowing
                    render={() => (
                        <LazyLog text={content} extraLines={1} enableSearch caseInsensitive selectableLines follow />
                    )}
                />
            ) : (
                <LazyLog text={content} extraLines={1} enableSearch caseInsensitive selectableLines follow />
            )}
        </div>
    );
}
