import { ReactElement } from 'react';
import { ITestSuiteResultsCount } from '../interfaces';
import { TestSuiteResults } from '../TestSuiteResults';

export default function TestDeviceStatus({ results }: { results?: ITestSuiteResultsCount }): ReactElement | null {
    return results && didRunAnyTest(results) ? <TestSuiteResults results={results} /> : null;
}

const didRunAnyTest = (results: ITestSuiteResultsCount): boolean => {
    for (const value of Object.values(results)) {
        if (value > 0) {
            return true;
        }
    }
    return false;
};
