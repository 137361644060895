import { ReactElement } from 'react';
import { TableCell } from '@mui/material';
import { AvatarChip } from '@tactileentertainment/core-designsystem';
import { MuiAlignSetting } from '../../../typings/material-ui';
import { IAuthor } from '../../commons/interfaces';

interface BuildAgentUpdatedByProps {
    align?: MuiAlignSetting;
    user?: IAuthor;
}

export default function BuildAgentUpdatedBy(props: BuildAgentUpdatedByProps): ReactElement {
    const { align = 'center', user } = props;

    return (
        <TableCell align={align} sx={{ padding: (theme) => theme.spacing(3, 1, 2, 1) }}>
            {user ? (
                <AvatarChip
                    label={user.email}
                    altLabel={user.name}
                    src={user.imageUrl}
                    sx={{ maxWidth: { xs: '120px', sm: 'fit-content' } }}
                />
            ) : null}
        </TableCell>
    );
}
