import React, { ReactElement } from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';

interface AvailabilitySelectorProps {
    checked?: boolean;
    style?: React.CSSProperties;
    onChange: (checked?: boolean) => void;
}

export default function AvailabilitySelector({
    checked = false,
    style,
    onChange,
}: AvailabilitySelectorProps): ReactElement {
    return (
        <Box sx={{ display: 'flex', mt: { xs: 1, lg: 0 }, ml: 0.5, ...style }}>
            <FormControlLabel
                control={
                    <Switch checked={checked} onChange={(event) => onChange(event.target.checked)} color='primary' />
                }
                label='Available for Ordering'
            />
        </Box>
    );
}
