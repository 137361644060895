import { IToggleColumn } from '../../commons/interfaces';

export enum TestSummaryColumnsId {
    status = 'status',
    project = 'project',
    branch = 'branch',
    id = 'id',
    startDate = 'startDate',
    duration = 'duration',
    user = 'user',
    details = 'details',
}

export const testSummaryColumns: Array<IToggleColumn<TestSummaryColumnsId>> = [
    { id: TestSummaryColumnsId.status, label: 'Status', align: 'left', width: '10%' },
    { id: TestSummaryColumnsId.id, label: 'Test Suite ID', align: 'left', width: '10%' },
    { id: TestSummaryColumnsId.project, label: 'Project', align: 'left', width: '10%' },
    { id: TestSummaryColumnsId.branch, label: 'Test Branch', align: 'left', width: '10%' },
    { id: TestSummaryColumnsId.startDate, label: 'Started', align: 'left', width: '10%' },
    { id: TestSummaryColumnsId.duration, label: 'Duration', align: 'left', width: '10%' },
    { id: TestSummaryColumnsId.user, label: 'User', align: 'center', width: '10%' },
];
