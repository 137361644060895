import { ReactElement, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Checkbox, Divider, FormControlLabel, Tab, Tabs, TextField, Typography } from '@mui/material';
import { blueGray } from '@tactileentertainment/core-designsystem';
import { EBuildPlatform } from '../builds/enums';
import { defaultLanguage } from './appUploaderLanguages';
import { ILocalizedMessage } from './interfaces';
import SelectLanguage from './SelectLanguage';

interface LocalizedMessagesProps {
    localizedMessages: ILocalizedMessage[];
    setLocalizedMessages: (localizedMessages: ILocalizedMessage[]) => void;
}

export default function LocalizedMessages({
    localizedMessages,
    setLocalizedMessages,
}: LocalizedMessagesProps): ReactElement {
    const [selectedItem, setSelectedItem] = useState<ILocalizedMessage>(localizedMessages[0]);

    const addLocalizedMessage = () => {
        const messages = [...localizedMessages];
        const newMessage = {
            language: '',
            message: '',
            platforms: [EBuildPlatform.android, EBuildPlatform.ios, EBuildPlatform.kindle],
        };
        messages.push(newMessage);
        setLocalizedMessages(messages);
        setSelectedItem(newMessage);
    };

    const updateLocalizedMessage = (data: Partial<ILocalizedMessage>) => {
        const index = localizedMessages.findIndex((item) => item === selectedItem);
        const item = {
            ...selectedItem,
            ...data,
        };
        const messages = [...localizedMessages];
        messages[index] = item;
        setLocalizedMessages(messages);
        setSelectedItem(item);
    };

    const selectLanguagePlatform = (platform: string, selected: boolean) => {
        const localizedMessage = localizedMessages.find((item) => item === selectedItem);
        const currentPlatforms = localizedMessage?.platforms || [];
        const platforms = selected
            ? [...currentPlatforms, platform]
            : currentPlatforms.filter((item) => item !== platform);
        updateLocalizedMessage({ platforms });
    };

    const deleteLocalizedMessage = () => {
        const index = localizedMessages.findIndex((item) => item === selectedItem);
        const messages = [...localizedMessages];
        messages.splice(index, 1);
        setLocalizedMessages(messages);
        setSelectedItem(messages[Math.max(index - 1, 0)]);
    };

    return (
        <Box sx={{ mt: 3, ml: 0.5 }}>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' color={blueGray[800]} fontWeight={600}>
                    Localization
                </Typography>
            </Box>
            <Tabs
                orientation='horizontal'
                variant='scrollable'
                value={selectedItem}
                onChange={(event, value) => setSelectedItem(value)}
            >
                {localizedMessages.map((item, index) => (
                    <Tab
                        key={index}
                        label={item.language || 'Language'}
                        value={item}
                        data-testid='localized-message-tab'
                    />
                ))}
                <Tab
                    label={<AddIcon color='primary' />}
                    data-testid='add-localized-message-button'
                    onClick={addLocalizedMessage}
                />
            </Tabs>
            <Divider sx={{ mb: 2 }} />
            <Box key={selectedItem.language} display='flex' flexDirection='column' flexGrow={1} sx={{ mt: 1 }}>
                <SelectLanguage
                    language={selectedItem.language}
                    setLanguage={(language) => updateLocalizedMessage({ language: language || '' })}
                    disabledLanguages={localizedMessages.map((item) => item.language)}
                />
                <TextField
                    multiline
                    minRows={6}
                    maxRows={6}
                    value={selectedItem.message}
                    onChange={(event) => updateLocalizedMessage({ message: event.target.value })}
                    label='Message'
                    sx={{ my: 2 }}
                />
                <Box display='flex' alignItems='center' sx={{ mb: 3 }} justifyContent='space-between'>
                    <Box display='flex' justifyContent='flex-start'>
                        {['Android', 'iOS', 'Kindle'].map((platformName) => (
                            <FormControlLabel
                                key={platformName}
                                control={
                                    <Checkbox
                                        color='primary'
                                        checked={selectedItem?.platforms?.includes(platformName.toLowerCase())}
                                        onChange={(event, selected) =>
                                            selectLanguagePlatform(platformName.toLowerCase(), selected)
                                        }
                                    />
                                }
                                label={platformName}
                            />
                        ))}
                    </Box>
                    <Box display='flex' justifyContent='flex-start'>
                        <Button
                            color='error'
                            size='small'
                            variant='outlined'
                            startIcon={<DeleteOutlineIcon />}
                            onClick={deleteLocalizedMessage}
                            disabled={selectedItem.language === defaultLanguage}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
