import { ReactElement, useState } from 'react';
import { Box, FormControlLabel, Grid, Slider, Switch, TextField } from '@mui/material';
import { Dialog, successToast } from '@tactileentertainment/core-designsystem';
import { ITestDevice } from '../../interfaces';
import { useUpdateTestDeviceMutation } from '../../testDevicesApi';

interface IUpdateTestDeviceDialogProps {
    testDevice: ITestDevice;
    open: boolean;
    handleClose: () => void;
}

export default function UpdateTestDeviceDialog({
    testDevice,
    open,
    handleClose,
}: IUpdateTestDeviceDialogProps): ReactElement {
    const [updateTestDevice] = useUpdateTestDeviceMutation();
    const [newTestDevice, setNewTestDevice] = useState(JSON.parse(JSON.stringify(testDevice)));
    const handleSave = async () => {
        const result = await updateTestDevice(newTestDevice);
        if (!('error' in result)) {
            successToast('Updated test device');
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            variant='custom'
            title='Edit test device'
            content={
                <>
                    <Grid sx={{ padding: '8px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
                        <TextField
                            id='test-device-name'
                            label='Device Name'
                            variant='outlined'
                            value={newTestDevice.name}
                            onChange={(event) => setNewTestDevice({ ...newTestDevice, name: event.target.value })}
                        />
                        <Box sx={{ mt: 3, mb: 1 }}>
                            <Slider
                                defaultValue={0}
                                value={newTestDevice.priority}
                                onChange={(event, value) => setNewTestDevice({ ...newTestDevice, priority: value })}
                                valueLabelDisplay='auto'
                                step={5}
                                marks={[
                                    { value: 5, label: 'Low' },
                                    { value: 25, label: 'Medium' },
                                    { value: 45, label: 'High' },
                                ]}
                                min={0}
                                max={50}
                                sx={{ color: (theme) => theme.palette.primary.main }}
                            />
                        </Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={newTestDevice.isElegibleForQueuedTests}
                                    onChange={(event) =>
                                        setNewTestDevice({
                                            ...newTestDevice,
                                            isElegibleForQueuedTests: event.target.checked,
                                            isDisabled: event.target.checked ? false : newTestDevice.isDisabled,
                                        })
                                    }
                                />
                            }
                            label='Elegible for scheduled tests'
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!newTestDevice.isDisabled}
                                    onChange={(event) =>
                                        setNewTestDevice({
                                            ...newTestDevice,
                                            isDisabled: !event.target.checked,
                                            isElegibleForQueuedTests: !event.target.checked
                                                ? false
                                                : newTestDevice.isElegibleForQueuedTests,
                                        })
                                    }
                                />
                            }
                            label='Allow tests in this device'
                        />
                    </Grid>
                </>
            }
            primaryButton={{
                text: 'Save',
                color: 'primary',
                onClick: handleSave,
            }}
        />
    );
}
