import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link, Typography, useMediaQuery } from '@mui/material';
import { BuildDetailsSingleArtifactRegExp } from '../../utils';
import Breadcrumbs from '../commons/Breadcrumbs';
import { getAcronym } from '../commons/getAcronym';
import { AppBarBuildCategories, defaultCategories } from '../header/enums';
import { selectBuildDetailsBuild } from './buildDetailsSlice';

export default function BuildBreadcrumbs(): ReactElement | null {
    const build = useSelector(selectBuildDetailsBuild);
    const location = useLocation();
    const isSingleArtifact = BuildDetailsSingleArtifactRegExp.test(location.pathname);
    const filename = location.pathname.split('/').pop();
    const isMobile1280 = useMediaQuery('(max-width: 1280px)');
    const kind: AppBarBuildCategories = defaultCategories.includes(build?.kind as AppBarBuildCategories)
        ? (build?.kind as AppBarBuildCategories)
        : AppBarBuildCategories.others;

    if (!build) {
        return <LocationBreadcrumbs />;
    }

    return (
        <Breadcrumbs>
            <Link color='inherit' component={RouterLink} to={`/builds?kind=${kind}&projectName=${build.projectName}`}>
                {isMobile1280 ? getAcronym(build.projectName) : build.projectName}
            </Link>
            {isSingleArtifact && (
                <Link color='inherit' component={RouterLink} to={`/builds/${build.projectName}/${build.buildNumber}`}>
                    <Typography
                        component='span'
                        sx={{
                            margin: (theme) => theme.spacing(0, 1, 0, 0.5),
                            color: (theme) => theme.palette.primary.main,
                        }}
                    >
                        #{build.buildNumber}
                    </Typography>
                </Link>
            )}
            <Link
                color='inherit'
                component={RouterLink}
                to={`/builds?kind=${kind}&projectName=${build.projectName}&branch=${build.branch}`}
            >
                {build.branch}
            </Link>
            {isSingleArtifact && <Typography color='textPrimary'>{filename}</Typography>}
            {!isSingleArtifact && (
                <Typography color='textPrimary'>
                    <Typography
                        component='span'
                        sx={{
                            margin: (theme) => theme.spacing(0, 1, 0, 0.5),
                            color: (theme) => theme.palette.primary.main,
                        }}
                    >
                        #{build.buildNumber}
                    </Typography>
                </Typography>
            )}
        </Breadcrumbs>
    );
}

function LocationBreadcrumbs(): ReactElement {
    const location = useLocation();
    const isSingleArtifact = BuildDetailsSingleArtifactRegExp.test(location.pathname);
    const isMobile1280 = useMediaQuery('(max-width: 1280px)');

    // build does not exist, so this is a best attempt at generating breadcrumbs based on location

    // eslint-disable-next-line no-unused-vars
    const [root, builds, projectName, buildNumber, filename] = location.pathname.split('/');

    return (
        <Breadcrumbs>
            <Link color='inherit' component={RouterLink} to='/builds'>
                Builds
            </Link>
            {projectName && (
                <Link color='inherit' component={RouterLink} to={`/builds?projectName=${projectName}`}>
                    {isMobile1280 ? getAcronym(projectName) : projectName}
                </Link>
            )}
            {isSingleArtifact && (
                <Link color='inherit' component={RouterLink} to={`/builds/${projectName}/${buildNumber}`}>
                    <Typography
                        component='span'
                        sx={{
                            margin: (theme) => theme.spacing(0, 1, 0, 0.5),
                            color: (theme) => theme.palette.text.secondary,
                        }}
                    >
                        #{buildNumber}
                    </Typography>
                </Link>
            )}
            {isSingleArtifact && <Typography color='textPrimary'>{filename}</Typography>}
            {!isSingleArtifact && (
                <Typography color='textPrimary'>
                    <Typography
                        component='span'
                        sx={{
                            margin: (theme) => theme.spacing(0, 1, 0, 0.5),
                            color: (theme) => theme.palette.text.secondary,
                        }}
                    >
                        #{buildNumber}
                    </Typography>
                </Typography>
            )}
        </Breadcrumbs>
    );
}
