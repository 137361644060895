import { ReactElement } from 'react';
import ClearFiltersButton from '../../commons/filters/ClearFiltersButton';
import { CollapseFilters, FiltersContainer } from '../../commons/filters/commons';
import { ITestSuiteFilter } from '../interfaces';
import { selectAmountOfActiveTestSuiteFilters } from '../utils';
import AuthorSelector from './AuthorSelector';
import BuildBranchSelector from './BuildBranchSelector';
import BuildPlatformSelector from './BuildPlatformSelector';
import BuildTypeSelector from './BuildTypeSelector';
import BuildVersionSelector from './BuildVersionSelector';
import ProjectNameSelector from './ProjectNameSelector';
import TestBranchSelector from './TestBranchSelector';
import TestDeviceSelector from './TestDeviceSelector';
import TestSuiteNumberSelector from './TestSuiteNumberSelector';

export interface ITestSuitesFilterProps {
    filters: ITestSuiteFilter;
    onFiltersChange: (newFilters: ITestSuiteFilter) => void;
    showOnlyValues?: boolean;
}

export const TestSuiteColumnFilters: { [id: string]: (props: ITestSuitesFilterProps) => ReactElement } = {
    testSuiteNumber: TestSuiteNumberSelector,
    projectName: ProjectNameSelector,
    testBranch: TestBranchSelector,
    buildBranch: BuildBranchSelector,
    buildType: BuildTypeSelector,
    platform: BuildPlatformSelector,
    version: BuildVersionSelector,
    devices: TestDeviceSelector,
    author: AuthorSelector,
};

export default function TestSuitesFilters({
    filters,
    onFiltersChange,
    showOnlyValues,
}: ITestSuitesFilterProps): ReactElement | null {
    const hasFilters = !!selectAmountOfActiveTestSuiteFilters(filters);
    return (
        <CollapseFilters in={hasFilters} data-testid='tests-filters'>
            <FiltersContainer>
                {Object.keys(TestSuiteColumnFilters).map((id): ReactElement => {
                    const Filter = TestSuiteColumnFilters[id];
                    return (
                        <Filter
                            key={id}
                            filters={filters}
                            onFiltersChange={onFiltersChange}
                            showOnlyValues={showOnlyValues}
                        />
                    );
                })}
                <ClearAllFiltersButton onFiltersChange={onFiltersChange} />
            </FiltersContainer>
        </CollapseFilters>
    );
}

export interface IClearAllFiltersProps {
    onFiltersChange: (newFilters: ITestSuiteFilter) => void;
}
function ClearAllFiltersButton({ onFiltersChange }: IClearAllFiltersProps): ReactElement {
    const handleClearClick = () => {
        onFiltersChange({
            page: 0,
        });
    };
    return <ClearFiltersButton onClick={handleClearClick} />;
}
