import { useState, useEffect, ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogActions, Button, CircularProgress, IconButton } from '@mui/material';
import { errorToast, successToast } from '@tactileentertainment/core-designsystem';
import { IScheduleBuild, IUpdateBuildSchedule } from '../interfaces';
import {
    useCreateScheduledBuildMutation,
    useRemoveScheduledBuildMutation,
    useUpdateScheduledBuildMutation,
} from '../scheduledBuildsApi';
import { parse5To6Cron, parse6To5Cron } from '../utils/parseCron';
import ScheduledBuildContent, { defaultCronPeriod } from './ScheduledBuildContent';

interface ScheduledBuildDialogProps {
    open: boolean;
    selectedBuild?: IScheduleBuild;
    onClose: () => void;
}

const newScheduleBuild = {
    _id: '',
    project: '',
    branch: '',
    description: '',
    cron: defaultCronPeriod,
    buildConfigurations: [],
};

export default function ScheduledBuildDialog({
    open,
    selectedBuild,
    onClose,
}: ScheduledBuildDialogProps): ReactElement {
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const initialBuild = selectedBuild
        ? { ...selectedBuild, project: selectedBuild.project._id, cron: parse6To5Cron(selectedBuild.cron) }
        : newScheduleBuild;
    const [buildSchedule, setBuildSchedule] = useState<IUpdateBuildSchedule>(initialBuild);
    const [buildConfigurations, setBuildConfigurations] = useState<string>(
        JSON.stringify(selectedBuild?.buildConfigurations || [], undefined, 2),
    );
    const [invalidConfig, setInvalidConfig] = useState(false);

    const [createScheduledBuild] = useCreateScheduledBuildMutation();
    const [updateScheduledBuild] = useUpdateScheduledBuildMutation();
    const [removeScheduledBuild] = useRemoveScheduledBuildMutation();

    const isInvalid = !buildSchedule.project || !buildSchedule.branch || !buildSchedule.cron;

    const handleSave = async () => {
        if (invalidConfig) {
            errorToast('Invalid configuration json');
            return;
        }

        setUpdating(true);
        const cron = parse5To6Cron(buildSchedule.cron);

        const { _id, project, description, branch } = buildSchedule;
        const newConfig = JSON.parse(buildConfigurations);
        let result = {};
        if (buildSchedule?._id) {
            result = await updateScheduledBuild({
                _id,
                project,
                branch,
                description,
                buildConfigurations: newConfig,
                cron,
            });
        } else {
            result = await createScheduledBuild({ project, branch, description, buildConfigurations: newConfig, cron });
        }

        setUpdating(false);
        if (!('error' in result)) {
            successToast('Scheduled Build saved');
            onClose();
        } else {
            errorToast('Failed to save Scheduled Build');
        }
    };

    const handleDelete = async () => {
        if (!buildSchedule?._id) {
            return;
        }

        setDeleting(true);
        const result = await removeScheduledBuild({ _id: buildSchedule?._id });

        setDeleting(false);
        if (!('error' in result)) {
            successToast('Scheduled Build deleted');
            onClose();
        }
    };

    useEffect(() => {
        try {
            JSON.parse(buildConfigurations);
            setInvalidConfig(false);
        } catch (e) {
            setInvalidConfig(true);
        }
    }, [buildConfigurations]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg' style={{ zIndex: 1000 }}>
            <DialogTitle>
                Schedule Builds
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <ScheduledBuildContent
                buildSchedule={buildSchedule}
                onChange={(newValue) => setBuildSchedule({ ...buildSchedule, ...newValue })}
                buildConfigurations={buildConfigurations}
                invalidConfig={invalidConfig}
                onConfigChange={(newConfig) => setBuildConfigurations(newConfig)}
            />
            <DialogActions sx={{ px: 3, py: 2, justifyContent: buildSchedule?._id ? 'space-between' : 'flex-end' }}>
                {buildSchedule?._id && (
                    <Button variant='text' color='error' onClick={() => handleDelete()} disabled={deleting}>
                        Delete this schedule
                        {deleting && (
                            <CircularProgress size={15} variant='indeterminate' style={{ marginLeft: '8px' }} />
                        )}
                    </Button>
                )}
                <Button
                    variant='contained'
                    color='primary'
                    style={{ minWidth: '80px', marginLeft: '24px' }}
                    onClick={() => handleSave()}
                    disabled={isInvalid || updating}
                >
                    Schedule Builds
                    {updating && <CircularProgress size={15} variant='indeterminate' style={{ marginLeft: '8px' }} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
