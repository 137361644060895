import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { AppThunkDispatch } from '../../store';
import { getAccelerators, selectAllAccelerators } from './acceleratorSlice';

interface AcceleratorSelectorProps {
    accelerator?: string;
    onAcceleratorSelected: (accelerator?: string) => void;
}

export default function AcceleratorSelector({
    accelerator,
    onAcceleratorSelected,
}: AcceleratorSelectorProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const dispatch = useDispatch<AppThunkDispatch>();
    const accelerators = useSelector(selectAllAccelerators);

    useEffect(() => {
        dispatch(getAccelerators());
    }, [dispatch]);

    return (
        <Autocomplete
            size='medium'
            options={accelerators || []}
            getOptionLabel={(option) => option.serviceName}
            renderOption={(props, option) => (
                <Box key={option._id} component='li' {...props}>
                    <Box display='flex' flexDirection='column' justifyContent='flex-start'>
                        <Typography>{option.serviceName}</Typography>
                        <Typography variant='caption'>
                            {option.ip}:{option.port}
                        </Typography>
                    </Box>
                </Box>
            )}
            autoHighlight
            clearOnBlur
            clearOnEscape
            onChange={(event, option) => onAcceleratorSelected(option?._id)}
            value={accelerators.find((item) => item._id === accelerator) || null}
            renderInput={(params) => (
                <TextField {...params} label='Unity Accelerator' placeholder='Select unity accelerator' />
            )}
            style={{ minWidth: isMobile ? '0px' : '350px' }}
        />
    );
}
